<!-- class students list -->
<template>
  <div class="content has_breadcrumb journal-inner">
    <base-breadcrumb
      :data="[
        {
          routeName: 'locations',
          name: user.schoolName,
        },
        {
          routeName: 'journal',
          name: 'Journal',
        },
        {
          name: classname,
        },
      ]"
      slot="breadcrumb"
    />
    <GgPage pageType="2" title="Journal">
      <div slot="topAppBar">
        <!-- <month-switch @change="monthChange" :defaultVal="defSwitchVal" /> -->
        <search />
      </div>
      <template v-slot:left>
        <div class="mar-l-b" v-if="hasPermission('Manage journals_Create')">
          <gg-create-button @click="hoverCreateDropdown" width="126px" label="Create" />
          <div v-if="dropdownCreateSw" class="create-dropdown-ui">
            <div class="create-dropdown-list">
              <div class="create-dropdown-list-inner">
                <ul class="create-class-list">
                  <li @click="toJournalCreate(item.type)" v-for="(item, index) in createBtn" :key="index">{{ item.name }}</li>
                </ul>
              </div>
            </div>
            <div class="create-dropdown-bg" v-if="dropdownCreateSw" @click="dropdownCreateSw = false"></div>
          </div>
        </div>
        <class-side-menu :classInt="classInt" :year="dataEnListYearIndex" :month="dataEnListIndex" :typename='typename' @sideJournal="sideJournal" />
      </template>
      <div class="journal-list-inner">
        <div class="journal-classname">
          {{ classname }}
        </div>
        <div class="main-inner" v-loading="loading">

          <div class="for-dropdown-ui month">
            <div class="for-dropdown" @click="hoverDropdown" :class="{ 'hover' : dropdownSw }">
              {{ dataEnListIndex }}
              <img class="arrow-drop" src="@/assets/images/icons/arrow_drop.svg" />
              <div class="dropdown-list">
                <div class="dropdown-list-inner">
                  <ul class="class-list">
                    <li v-for="(item, index) in dataEnList" :key="index" @click="dataEnListIndex = item" :class="{ 'active' : dataEnListIndex === item }">
                      {{ item }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="for-dropdown-bg" v-if="dropdownSw" @click="dropdownSw = false"></div>
          </div>

          <div class="for-dropdown-ui year">
            <div class="for-dropdown" @click="hoverDropdown1" :class="{ 'hover' : dropdownSw1 }">
              {{ dataEnListYearIndex }}
              <img class="arrow-drop" src="@/assets/images/icons/arrow_drop.svg" />
              <div class="dropdown-list">
                <div class="dropdown-list-inner">
                  <ul class="class-list">
                    <li v-for="(item, index) in dataEnListYear" :key="index" @click="dataEnListYearIndex = item" :class="{ 'active' : dataEnListYearIndex === item }">
                      {{ item }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="for-dropdown-bg" v-if="dropdownSw1" @click="dropdownSw1 = false"></div>
          </div>

          <div class="journal-list-box-inner" v-infinite-scroll="loadJournal" v-if="list.length > 0">
            <div class="journal-box" v-for="(item, index) in list" :key="index">
              <div class="journal-img" @click="toDetail(item.id, item.newsletter ? 0 : 1, item)" :style="'background-image:url(' + item.cover + ');'"></div>
              <div class="journal-tit" :title="item.title">{{ item.title }}</div>
              <div class="journal-col">
                <gg-menu-bar>
                  <i class="iconmore_vert-24px iconfont" />
                  <template slot="menus">
                    <gg-menu-bar-item
                      v-if="item.status === 'DRAFT' || item.status === 'PENDING' || item.status === 'POSTED'"
                      @click="toPreview(item.id, item.newsletter ? 0 : 1, item)"
                    >
                      <span>Preview</span>
                    </gg-menu-bar-item>

                    <gg-menu-bar-item
                      v-if="item.status === 'DRAFT' && hasPermission('Share journals_Create')"
                      @click="journalSubmitShow(item.id, item.newsletter ? 0 : 1, item)"
                    >
                      <span>Submit</span>
                    </gg-menu-bar-item>

                    <gg-menu-bar-item
                      v-if="item.status === 'PENDING' && hasPermission('Share journals_Edit')"
                      @click="journalUndoShow(item.id, item.newsletter ? 0 : 1, item)"
                    >
                      <span>Undo</span>
                    </gg-menu-bar-item>

                    <gg-menu-bar-item
                      v-if="item.status === 'PENDING' && hasPermission('Share journals_Full')"
                      @click="journalApproveShow(item.id, item.newsletter ? 0 : 1, item)"
                    >
                      <span>Approve</span>
                    </gg-menu-bar-item>

                    <gg-menu-bar-item
                      v-if="item.status === 'POSTED' && dateDiff(item.lastApprove.timestamp) === 0 && hasPermission('Share journals_Full')"
                      @click="journalRecallShow(item.id, item.newsletter ? 0 : 1, item)"
                    >
                      <span>Recall</span>
                    </gg-menu-bar-item>

                    <gg-menu-bar-item
                      v-if="((item.status === 'DRAFT' || item.status === 'PENDING') && hasPermission('Manage journals_Edit')) || (item.status === 'DRAFT' || item.status === 'PENDING') && hasPermission('Manage journals_Full')"
                      class="hr-class">
                      <hr class="hr" />
                    </gg-menu-bar-item>

                    <gg-menu-bar-item
                      v-if="(item.status === 'DRAFT' || item.status === 'PENDING') && hasPermission('Manage journals_Edit')"
                      @click="toEdit(item.id, item.newsletter ? 0 : 1, item)"
                    >
                      <span>Edit</span>
                    </gg-menu-bar-item>

                    <gg-menu-bar-item
                      v-if="(item.status === 'DRAFT' || item.status === 'PENDING') && hasPermission('Manage journals_Full')"
                      @click="journalDeleteShow(item.id, item.newsletter ? 0 : 1, item)"
                    >
                      <span>Delete</span>
                    </gg-menu-bar-item>

                  </template>
                </gg-menu-bar>
              </div>
            </div>
          </div>

          <div class="empty-box" slot="empty" v-else>
            <img :src="require('@/assets/images/moments/empty_photo.png')" />
            <!-- <p>No {{ $route.query.typename ? $route.query.typename.split('_')[0] : '' }} yet.</p> -->
            <p>No {{ journalListType }} yet.</p>
          </div>

        </div>
      </div>

      <Snackbar :visible.sync="loadingShow" :content="loadingText" />

      <gg-Dialog width="580px" :visible.sync="journalDeleteSw" :title="'Delete ' + (journalType === 0 ? 'newsletter' : 'portfolio') + '?'" class="draft-dialog mp-dialog">
        <div class="dialogContent">Once deleted, you will not be able to recover the {{ journalType === 0 ? 'newsletter' : 'portfolio' }} data.</div>
        <span slot="footer" class="dialog-footer">
          <gg-cancel-button @click="journalDeleteSw = false">Cancel</gg-cancel-button>
          <gg-submit-button @click="journalDelete">Delete</gg-submit-button>
        </span>
      </gg-Dialog>

      <gg-Dialog width="580px" :visible.sync="journalSubmitSw" :title="'Submit ' + (journalType === 0 ? 'newsletter' : 'portfolio') + '?'" class="draft-dialog mp-dialog">
        <div class="dialogContent">Once submitted, the {{ journalType === 0 ? 'newsletter' : 'portfolio' }} will be subjected to approval for sharing.</div>
        <span slot="footer" class="dialog-footer">
          <gg-cancel-button @click="journalSubmitSw = false">Cancel</gg-cancel-button>
          <gg-submit-button @click="journalSubmit">Submit</gg-submit-button>
        </span>
      </gg-Dialog>

      <gg-Dialog width="580px" :visible.sync="journalUndoSw" :title="'Undo submission?'" class="draft-dialog mp-dialog">
        <div class="dialogContent">Once undone, the {{ journalType === 0 ? 'newsletter' : 'portfolio' }} will change from pending to draft.</div>
        <span slot="footer" class="dialog-footer">
          <gg-cancel-button @click="journalUndoSw = false">Cancel</gg-cancel-button>
          <gg-submit-button @click="journalUndo">Undo</gg-submit-button>
        </span>
      </gg-Dialog>

      <gg-Dialog width="580px" :visible.sync="journalApproveSw" :title="'Approve ' + (journalType === 0 ? 'newsletter' : 'portfolio') + '?'" class="draft-dialog mp-dialog">
        <div class="dialogContent">Once approved, the {{ journalType === 0 ? 'newsletter' : 'portfolio' }} will be shared and viewed by parents.</div>
        <span slot="footer" class="dialog-footer">
          <gg-cancel-button @click="journalApproveSw = false">Cancel</gg-cancel-button>
          <gg-submit-button @click="journalApprove">Approve</gg-submit-button>
        </span>
      </gg-Dialog>

      <gg-Dialog width="580px" :visible.sync="journalRecallSw" :title="'Recall ' + (journalType === 0 ? 'newsletter' : 'portfolio') + '?'" class="draft-dialog mp-dialog">
        <div class="dialogContent">Once recalled, the {{ journalType === 0 ? 'newsletter' : 'portfolio' }} will change from posted to draft and not be seen by parents.</div>
        <span slot="footer" class="dialog-footer">
          <gg-cancel-button @click="journalRecallSw = false">Cancel</gg-cancel-button>
          <gg-submit-button @click="journalRecall">Recall</gg-submit-button>
        </span>
      </gg-Dialog>

      <select-user-popup @close="selectUsershow()" :selectUserSw="selectUsersp" :classId="classid"/>

    </GgPage>
  </div>
</template>

<script>
import moment from 'moment';
import { Ajax } from "@/common";
import { mapState } from "vuex";
import selectUserPopup from './../modules/selectUserPopup'
import classSideMenu from './../modules/classSideMenu'
import monthSwitch from './../modules/monthSwitch'
import search from './../modules/search'
import { hasPermission } from './../utils/utils.js'
export default {
  components: {monthSwitch, classSideMenu, selectUserPopup, search},
  computed: {
    ...mapState({
      user: (state) => state.user
    }),
  },
  data() {
    return {
      journalListType: 'newsletter',
      pages: 0,
      pageNum: 1,
      classInt: '',
      month: '',
      year: '',
      journalType: 0,
      journalIndex: '',
      journalRecallSw: false,
      journalApproveSw: false,
      journalUndoSw: false,
      journalSubmitSw: false,
      journalDeleteSw: false,
      typename: '',
      classid: '',
      selectUsersp: false,
      createBtn: [{
        name: 'Create a newsletter',
        type: 0
      },{
        name: 'Create a portfolio',
        type: 1
      }],
      loadingText: '',
      loadingShow: false,
      dropdownCreateSw: false,
      dropdownSw1: false,
      dropdownSw: false,
      loading: false,
      classname: '',
      defSwitchVal: 0,
      createTypeName: ['newsletter', 'portfolio'],
      date: this.$route.query.time?moment(parseInt(this.$route.query.time)):moment(),
      list: [],
      dataEnListIndex: 'All',
      dataEnListYearIndex: 'All',
      monthArr: {
        'All': '',
        'January': 1,
        'February': 2,
        'March': 3,
        'April': 4,
        'May': 5,
        'June': 6,
        'July': 7,
        'August': 8,
        'September': 9,
        'October': 10,
        'November': 11,
        'December': 12
      },
      dataEnListYear: [
        'All'
      ],
      dataEnList: [
        'All',
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
      isNewsletter: true,
      status: ''
    };
  },
  created() {
    // this.initMonthSwitchVal()
    this.classname = sessionStorage.getItem("journalClassName")
    this.classid = this.$route.params.id || ''

    this.typename = this.$route.query.typename
    if(!this.typename) {
      if((this.hasPermission('Manage journals_View') && this.hasPermission('Share journals_View')) || this.hasPermission('Manage journals_View')) {
        this.typename = 'newsletter_draft'
      }else{
        this.typename = 'newsletter_posted'
      }
    }
    let year  = sessionStorage.getItem("journalYear"),
    thisyear = moment().year().toString()
    this.dataEnListYear.push(year)
	let yearArr = []
	let count
    if(year != thisyear) {
	  count = thisyear-year
	  if(count>1){
		  for(let c = 1;c<count;c++){
			  this.dataEnListYear.push(Number(year)+c)
		  }
	  }
	  this.dataEnListYear.push(thisyear)
    }

  },
  methods: {

    moment,

    hasPermission,

    dateDiff(date) {
      let a = moment.utc(date),
      b = moment()
      return a.diff(b, 'days')
    },

    momentTime(date) {
      return moment.utc(date).local().format("DD/MM/YYYY, hh:mm A");
    },

    sideJournal(newsletter, status, typename) {
      let query = this.$route.query
      query.typename = status

      this.$router.replace({
        query: query
      })

      this.isNewsletter = newsletter
      this.typename = typename
      this.status = status
      this.pages = 0
      this.pageNum = 1
      this.getJournal()
    },

    loadJournal() {
      if((this.pageNum < this.pages) && !this.loading) {
        this.getJournal(true)
      }
    },

    getJournal(s) {
      this.loading = true
      this.pageNum = s ? this.pageNum + 1 : this.pageNum
      this.journalListType = this.isNewsletter ? 'newsletter' : 'portfolio'

      let data = {
        newsletter: this.isNewsletter,
        classId: this.$route.params.id,
        status: this.status,
        staffId: this.user.userId,
        year: this.dataEnListYearIndex === 'All' ? '' : this.dataEnListYearIndex,
        month: this.dataEnListIndex === 'All' ? '' : this.monthArr[this.dataEnListIndex],
        currentPage: this.pageNum,
        pageSize: 12
      }

      Ajax.get("journal/search/extraV1", data).then((res) => {
        if(res.code === '0000'){
          if(!s) {
            document.getElementsByClassName('ggPage-main-center')[0].scrollTo({
              top: 0
            })
            this.list = []
          }
          this.list.push(...res.data.records)
          this.pages = Math.round(res.data.count / res.data.size)
        }
        this.loading = false
      });
    },

    selectUsershow() {
      this.selectUsersp = false
    },

    toJournalCreate(type) {
      if(type === 0) {
        this.$router.push({ name: 'journalCreate', query: { type: type, classname: this.classname, classid: this.classid, time: this.moment().valueOf() }})
      }
      if(type === 1) {
        this.selectUsersp = true
        this.dropdownCreateSw = false
      }
    },

    toPreview(id, type) {
      let data = {
        id: id
      }
      Ajax.get("journal/" + id, data).then((res) => {
        if(res.code === '0000'){
          sessionStorage.setItem("journalPageData", res.data.content)
          sessionStorage.setItem("journalPageDataTit", res.data.title)
          sessionStorage.setItem("journalStatus", res.data.status)
          sessionStorage.setItem("journalFilePath", res.data.filePath)
          let url = this.$router.resolve({path: '/journal/view?type=' + this.createTypeName[type] });
          let win = window.open(url.href, '_blank')
          win.onload = function () {
            setTimeout(function () {
              win.document.title = 'Preview'
            }, 100)
          }
        }
      });
    },

    toDetail(id, type, item) {
      this.$router.push({ name: 'journalDraft', query: {
        id: id,
        name: item.title,
        type: type,
        classid: this.$route.params.id
      }})
    },

    toEdit(id, type, item) {
      this.$router.push({ name: 'journalCreate', query: { editid: id, type: type, classname: this.classname, classid: this.classid, time: this.moment().valueOf() }})
    },

    journalRecall() {
      Ajax.put('/journal/' + this.journalIndex + '/recall?staffId=' + this.user.userId).then((res) => {
        if(res.code === '0000'){
          this.loadingShow = true
          this.journalRecallSw = false
          this.loadingText = (this.journalType === 0 ? 'Newsletter' : 'Portfolio') + ' has been recalled'
          this.classInt = this.journalIndex + moment().valueOf()
          this.typename = (this.journalType === 0 ? 'newsletter' : 'portfolio') + '_draft'
          this.removeNote(this.journalIndex)
        }
      });
    },

    removeNote(id) {
      let data = {
        id: id
      }
      Ajax.get("journal/" + id, data).then((res) => {
        if(res.code === '0000'){
          let content = JSON.parse(res.data.content)
          for(let item of content) {
            item.note = ''
          }

          let data = {}
          data.classId = res.data.classId
          data.content = JSON.stringify(content)
          data.cover = res.data.cover
          data.isNewsletter = res.data.newsletter
          data.staffId = this.user.userId
          data.title = res.data.title
          data.isSubmit = false

          if(!res.data.newsletter) {
            data.recipient = res.data.recipient
          }

          Ajax.put('/journal/' + id, data)
          .then((res) => {
            if(res.code === '0000'){
            }
          })
        }
      });
    },

    journalRecallShow(id, type, item) {
      this.journalRecallSw = true
      this.journalIndex = id
      this.journalType = type
    },

    journalApprove() {
      Ajax.put('/journal/' + this.journalIndex + '/process?approve=' + true + '&staffId=' + this.user.userId).then((res) => {
        if(res.code === '0000'){
          this.loadingShow = true
          this.journalApproveSw = false
          this.loadingText = (this.journalType === 0 ? 'Newsletter' : 'Portfolio') + ' has been posted'
          this.classInt = this.journalIndex + moment().valueOf()
          this.typename = (this.journalType === 0 ? 'newsletter' : 'portfolio') + '_posted'
        }
      });
    },

    journalApproveShow(id, type, item) {
      this.journalApproveSw = true
      this.journalIndex = id
      this.journalType = type
    },

    journalUndo() {
      Ajax.put('/journal/' + this.journalIndex + '/cancel?staffId=' + this.user.userId).then((res) => {
        if(res.code === '0000'){
          this.loadingShow = true
          this.journalUndoSw = false
          this.loadingText = (this.journalType === 0 ? 'Newsletter' : 'Portfolio') + ' has been moved to draft'
          this.classInt = this.journalIndex + moment().valueOf()
          this.typename = (this.journalType === 0 ? 'newsletter' : 'portfolio') + '_draft'
        }
      });
    },

    journalUndoShow(id, type, item) {
      this.journalUndoSw = true
      this.journalIndex = id
      this.journalType = type
    },

    journalSubmit() {
      Ajax.put('/journal/' + this.journalIndex + '/submit?staffId=' + this.user.userId).then((res) => {
        if(res.code === '0000'){
          this.loadingShow = true
          this.journalSubmitSw = false
          this.loadingText = (this.journalType === 0 ? 'Newsletter' : 'Portfolio') + ' has been submitted'
          this.classInt = this.journalIndex + moment().valueOf()
          this.typename = (this.journalType === 0 ? 'newsletter' : 'portfolio') + '_pending'
        }
      });
    },

    journalSubmitShow(id, type, item) {
      this.journalSubmitSw = true
      this.journalIndex = id
      this.journalType = type
    },

    journalDelete() {
      Ajax.delete('/journal/' + this.journalIndex + '?staffId=' + this.user.userId).then((res) => {
        if(res.code === '0000'){
          this.loadingShow = true
          this.journalDeleteSw = false
          this.loadingText = (this.journalType === 0 ? 'Newsletter' : 'Portfolio') + ' has been deleted'
          this.classInt = this.journalIndex + moment().valueOf()
        }
      });
    },

    journalDeleteShow(id, type) {
      this.journalDeleteSw = true
      this.journalIndex = id
      this.journalType = type
    },

    hoverDropdown(){
      this.dropdownSw = !this.dropdownSw
    },

    hoverDropdown1(){
      this.dropdownSw1 = !this.dropdownSw1
    },

    hoverCreateDropdown() {
      this.dropdownCreateSw = !this.dropdownCreateSw
    },

    // initMonthSwitchVal(){
    //   var _def = moment()
    //   if(this.$route.query.time && this.$route.query.time.length == 13){
    //     _def = moment(parseInt(this.$route.query.time))
    //   }
    //   this.date = _def
    //   this.defSwitchVal = _def
    //   this.year = _def.format('YYYY')
    //   this.month = _def.format('M')
    // },

    // monthChange(val){
    //   this.date = val
    //   this.year = moment(val).format('YYYY')
    //   this.month = moment(val).format('M')

    //   if(this.date>new Date().getTime()){
    //     this.list = []
    //     return
    //   }

    //   this.getJournal()
    // }

  },
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.ggPage{
  /deep/.base-list .title{
    font-family:'opensans-semibold';
    color:#5f6368
  }
}
/deep/ .ggPage-main-center .base-list{
  padding-bottom:112px!important;
  .title{
    font-family: 'opensans-semibold';
    margin-left:0;
    padding-left:10px;
  }
  .main-column{
    font-family: 'opensans-semibold';
  }
  .main-column{
    .icon{
      border-radius: 50%;
    }
  }
  ul li {
    border-top:0 none;
    border-bottom: 1px solid #e0e0e0;
    &:nth-child(2) {
      border-bottom: 1px solid #e0e0e0;
    }
    // &:nth-last-child(1) {
    //   border-bottom: 2px solid #e0e0e0;
    // }
  }
}

.mar-l-b {
  position: relative;
}

.create-dropdown-ui {
  position: absolute;
  left: 140px;
  top: 0;
  width: 152px;

  .create-dropdown-bg {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 101;
    overflow: hidden;
  }

  .create-dropdown-list {
    position: relative;
    z-index: 102;
    width: 152px;
    background: #fff;
    box-shadow: 0px 3px 3px #0000004D;
    border-radius: 4px;

    .create-dropdown-list-inner {
      padding: 8px 0;

      .create-class-list {
        padding: 0;

        li {
          padding-left: 15px;
          cursor: pointer;
          text-align: left;
          line-height: 32px;
          font-size: 14px;
          font-family: Roboto;

          &.active,
          &:hover {
            background-color: #eee;
          }
        }
      }
    }
  }
}

.for-dropdown-ui {
  display: inline-block;
  margin: 20px 10px 0 0;
  width: 120px;
  height: 36px;

  .for-dropdown-bg {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    overflow: hidden;
  }

  .for-dropdown {
    position: relative;
    z-index: 100;
    padding: 0 16px;
    height: 36px;
    line-height: 36px;
    border: 1px solid #E0E0E0;
    border-radius: 4px;
    font-size: 14px;
    color: #202124;
    cursor: pointer;

    &.hover {
      .dropdown-list {
        display: block;
      }
    }

    .arrow-drop {
      position: absolute;
      right: 4px;
      top: 7px;
      width: 20px;
      height: 20px;
    }

    .dropdown-list {
      display: none;
      position: absolute;
      left: 0;
      top: 35px;
      padding: 0 2px 0 0;
      width: 119px;
      background: #fff;
      box-shadow: 0px 3px 3px #0000004D;
      border-radius: 4px;

      .dropdown-list-inner {
        padding: 8px 0;
        max-height: 176px;
        overflow-y: auto;

        &::-webkit-scrollbar {
          width: 4px;
          border-radius: 4px;
        }

        &::-webkit-scrollbar-track {
          background-color: #fff;
        }

        .select-all {
          padding-left: 16px;
          line-height: 28px;
          font-size: 14px;
          color: #202124;

          .el-checkbox {
            width: 100%;
            color: #202124;
          }

          &:hover {
            background-color: #eee;
          }
        }

        .class-list {
          padding: 0;

          li {
            padding-left: 16px;
            line-height: 32px;
            font-size: 14px;
            font-family: Roboto;

            .el-checkbox {
              width: 100%;
            }

            &.active,
            &:hover {
              background-color: #eee;
            }
          }
        }
      }
    }
  }
}

.journal-list-inner {
  margin-right: 150px;
}

.journal-classname {
  padding: 0 10px 25px 10px;
  line-height: 80px;
  border-bottom: 1px solid #e0e0e0;
  color: #202124;
  font: normal normal 600 22px/30px Open Sans;
}

.journal-list-box-inner {
  position: relative;
  overflow: hidden;
  padding: 20px 0 112px 0;
  margin-bottom: 92px;
  margin-right: -46px;

  .journal-box {
    float: left;
    position: relative;
    margin: 0 24px 24px 0;
    width: 328px;
    height: 200px;
    background: #fff;
    border: 1px solid #E0E0E0;
    border-radius: 8px;

    .journal-col {
      position: absolute;
      right: 10px;
      bottom: 10px;
    }

    .journal-tit {
      margin: 0 20px;
      width: 256px;
      line-height: 64px;
      font-size: 16px;
      text-align: left;
      font-family: Roboto;
      color: #202124;
      overflow: hidden;
      text-overflow:ellipsis;
      white-space: nowrap;
    }

    .journal-img {
      display: block;
      width: 100%;
      height: 136px;
      border-radius: 8px 8px 0 0;
      background-size: cover;
      background-position: top;
      cursor: pointer;
    }
  }
}

.main-inner {
  position: relative;
}
</style>
