<!-- class students list -->
<template>
  <div class="content has_breadcrumb">
    <base-breadcrumb
      :data="[
        {
          routeName: 'locations',
          name: user.schoolName,
        },
        {
          routeName: 'moments',
          name: 'Moments',
        },
        {
          routeName: 'momentClass',
          params:{id:$route.params.cid},
          name: classname,
        },
        {
          name: studentname,
        },
      ]"
      slot="breadcrumb"
    />
    <GgPage
      pageType="2"
      title="Moments"
      @forward="forward"
      @next="next"
    >
       <div slot="topAppBar" class="topAppBar">
        <!-- <month-switch @change="dayChange" :defaultVal="defSwitchVal"></month-switch> -->
        <search :classId="$route.params.cid"></search>
      </div>
      
     
      <template v-slot:left>
        <div class="mar-l-b">
          <upload-btn :classId="$route.params.cid" :studentId="$route.params.id"><gg-create-button label="Upload" /></upload-btn>
        </div>
        <student-side-menu activeIndex="momentStudentPending" />
      </template>
      <GgPageHeader2 class="mar-b-0"  :squareInfo="userInfo" :title="userInfo.title" :subTitle1="userInfo.subTitle1" >
        <div
          slot="title-label"
          class="title-label"
        >
          <span>{{user.schoolName}}</span>
        </div>
        <!-- <div slot="subTitleLabel" class="title-label">Teacher</div> -->
        <div slot="info">
          <div class="title-status mar-b-0">{{userInfo.status}}</div>
        </div>
      </GgPageHeader2>
       <div class="optbar" v-show="checkedList.length>0">
        <el-button type="text" @click="clearCheck"><i class="el-icon-close"/></el-button>
        <span class="count">{{checkedList.length}} Selected</span>
        <div class="btns">
          <baseButton @click="handleUndo()" :disabled="!hasPermission('Share media_Edit')" type="primary">Undo</baseButton>
          <baseButton @click="handleApprove()" :disabled="!hasPermission('Share media_Full')" type="primary">Approve</baseButton>
          <!-- <baseButton @click="handleReject()" type="primary">Reject</baseButton> -->
        </div>
      </div>
      <image-list :classId="$route.params.cid" :list="imgList" v-loading="loading" @checkChange="onCheckChange" :pending="true" ref="imageList" @refresh="refresh"/>
    </GgPage>
  </div>
</template>

<script>
import { Ajax } from "@/common";
import moment from 'moment';
import { mapState } from "vuex";
import uploadBtn from './../modules/uploadBtn'
import SvgIcon from "@/components/SvgIcon";
import studentSideMenu from './../modules/studentSideMenu'
import monthSwitch from './../modules/monthSwitch'
import search from './../modules/search'
import imageList from './../modules/imageList'
import bus from '../utils/bus'
import { hasPermission } from '../utils/utils'
import studentMixin from './../mixin/studentMixin'
export default {
  components: {SvgIcon,studentSideMenu,monthSwitch,search,imageList,uploadBtn},
  data() {
    return {
      boolean: true,
      loading:false,
      classname:this.$store.state.moments.curClassName,
      studentname:this.$store.state.moments.curStudentName,
      defSwitchVal:0,
      date:this.$route.query.time?moment(parseInt(this.$route.query.time)).valueOf():moment().valueOf(),
      imgList: [
       
      ],
      checkedList:[],
      userInfo: this.$store.state.moments.curStudent,
    };
  },
  mixins: [studentMixin],
  computed: {
    ...mapState({
      user: (state) => state.user
    }),
  },
  created() {
    this.initMonthSwitchVal()
    this.fetchData()
    bus.$on('refreshImageList', this.fetchData)  
  },
  watch:{
    
  },
  methods: {
    hasPermission,
    refresh(){
      bus.$emit('refreshImageList')
    },
    initMonthSwitchVal(){
      var _def = moment().valueOf()
      if(this.$route.query.time&&this.$route.query.time.length==13){
        _def = parseInt(this.$route.query.time)
      }
      this.date = _def
      this.defSwitchVal = _def
    },
    dayChange(val){
      this.date = val
      this.fetchData()
    },
    async fetchData(){
     
      this.fetchList()
      var resClass = await Ajax.get("/usermanage/class/selectByIdV1", {
        id: this.$route.params.cid,
      })
      
       var resStudent = await Ajax.get("/usermanage/student/selectByStuId", {
        stuId: this.$route.params.id,
      })
    
      if(resClass.code=='0000'&&resStudent.code=='0000'){
        this.classname = resClass.data.className
        this.$store.commit("SET_CURCLASSNAME", this.classname);
        this.studentname = resStudent.data.firstName+' '+resStudent.data.lastName
        this.$store.commit("SET_CURSTUDENTNAME", this.studentname);
        if(this.userInfo.id != resStudent.data.id){
          this.userInfo = {
            id: resStudent.data.id,
            title: resStudent.data.firstName+' '+resStudent.data.lastName,
            avatar: resStudent.data.photo,
            firstName: resStudent.data.firstName,
            lastName: resStudent.data.lastName,
            subTitle1: resStudent.data.studentId,
            status: resStudent.data.status,
          }
          this.$store.commit("SET_CURSTUDENT", this.userInfo);
        }
        
        
      }
      
    },
    async fetchList(){
      if(this.date>new Date().getTime()){
        this.imgList = []
        return
      }
      this.loading = true;
      var res = await Ajax.post("/moments/media/getMediaListV1", {
        classId: this.$route.params.cid,
        studentId: this.$route.params.id,
        status: 1,
        // date: moment(this.date).startOf('day').utc().format('YYYY-MM-DD HH:mm:ss'),
        size:9999
      })
      this.loading = false;
      if(res.code=='0000'){
         this.imgList = res.data.records
      }
    },
   handleApprove(){
      this.$confirm(
          "Once approved, the photos will be shared and viewed by parents.",
          'Approve sharing?',
          {
            cancelButtonText: "Cancel",
            confirmButtonText: "Approve",
            cancelButtonClass: "gg_cancel_button",
            confirmButtonClass: "gg_submit_button",
            customClass: "gg_confirm",
            showClose: false
          }
        )
          .then(() => {
            const _batchNoList = this.checkedList.map(o=>o.batchNo)
            Ajax.post("/moments/share/approveBatchV1", {
              classId: this.$route.params.cid,
              approvalUser:this.user.userId,
              batchNoList:_batchNoList
            }).then(res=>{
              if(res.code=='0000'){
                // this.handleSnack()
                bus.$emit('refreshImageList')
                this.clearCheck()
                this.fetchList()
              }else{

              }
            })
          })
          .catch(() => {});
     
    },
    handleUndo(){
      this.$confirm(
          "Once undone, the photos will change from pending to uploaded.",
          "Undo submission?",
          {
            cancelButtonText: "Cancel",
            confirmButtonText: "Undo",
            cancelButtonClass: "gg_cancel_button",
            confirmButtonClass: "gg_submit_button",
            customClass: "gg_confirm",
            showClose: false
          }
        )
          .then(() => {
            const _batchNoList = this.checkedList.map(o=>o.batchNo)
            Ajax.post("/moments/media/unDoBatch", 
              _batchNoList).then(res=>{
              if(res.code=='0000'){
                // this.handleSnack()
                bus.$emit('refreshImageList')
                this.clearCheck()
                this.fetchList()
              }else{

              }
            })
          })
          .catch(() => {});
     
    },
    handleReject(){
      this.$confirm(
          "Once rejected, the photos will not be shared and viewed by parents.",
          'Reject sharing?',
          {
            cancelButtonText: "Cancel",
            confirmButtonText: "Reject",
            cancelButtonClass: "gg_cancel_button",
            confirmButtonClass: "gg_submit_button",
            customClass: "gg_confirm",
            showClose: false
          }
        )
          .then(() => {
             const _batchNoList = this.checkedList.map(o=>o.batchNo)
            Ajax.post("/moments/share/rejectBatchV1", {
              classId: this.$route.params.cid,
              approvalUser:this.user.userId,
              batchNoList:_batchNoList
            }).then(res=>{
              if(res.code=='0000'){
                // this.handleSnack()
                this.clearCheck()
                this.fetchList()
              }else{

              }
            })
          })
          .catch(() => {});
    },
    onCheckChange(val){
      this.checkedList = val
    },
    clearCheck(){
      this.$refs.imageList.clearCheck()
    },
    forward(val) {
      console.log(val);
    },
    next(val) {
      console.log(val);
    },
  },
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
/deep/ .ggPage-main{
  position: relative;
}
/deep/ .ggPageTitle-left .squareBox {
    border-radius: 100%;
}
.content.has_breadcrumb .edoovo-breadcrumb {
    z-index: 10;
}
 .content.has_breadcrumb{
    /deep/ .ggPage-main-center-content{
      height:auto;
    }
  }

</style>