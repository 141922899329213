<!-- class students list -->
<template>
  <div>
    <div class="form-item">
      <div class="tit">Students</div>
      <student-selecter v-if="info.studentList" :defaultValue="info.studentList" :optionsAll="studentsOptions" @onCheck="handleStudentCheck" :readOnly="info.shareStatus == 2 || !hasPermission('Manage media_Edit')"/>
    </div>
    <div class="form-item">
      <div class="tit">Activities</div>
      <div class="activities">
        
        <div class="subtit">Tags</div>
        <tag-selecter v-if="info.studentList" :defaultValue="defaultTags" :options="tagsOptions" @onCheck="handleTagCheck" :readOnly="info.shareStatus == 2 || !hasPermission('Manage media_Edit')"/>
        <div class="subtit"></div>
        <div class="note">
          <md-field :class="{
            'md-invalid': !noteCheck
          }" v-if="info.shareStatus != 2 && hasPermission('Manage media_Edit')">
            <label>Note</label>
            <md-textarea v-model="note" md-autogrow></md-textarea>
            <span class="md-error">Character limit of 100</span>
          </md-field>
          <template v-else>
            <div class="subtit">Note</div>
            <span class="val">
              {{info.note}}
            </span>
          </template>
          
        </div>
      </div>
    </div>
    <div class="form-item">
      <div class="tit">Albums</div>
      <div class="album" v-for="item in info.albumDTOList" :key="item.id">
        <div class="face" :style="'background-image:url('+rootPath+item.logo+')'"> </div>
        <b>{{item.name}}</b>
        <span>{{item.mediaCount}} photos</span>
      </div>
    </div>
    <div class="form-item">
      <div class="tit">Details</div>
      <div class="detail">
        <div class="item">
          <svg-icon iconClass="info-uploaded"></svg-icon>
          <span>Uploaded from {{info.uploadFrom}}</span>
        </div>
        <div class="item">
          <svg-icon iconClass="info-date"></svg-icon>
          <span>{{generateTimeStr(info.createTime)}}</span>
        </div>
        <div class="item">
          <svg-icon iconClass="info-photo"></svg-icon>
          <span>{{info.fileDisplayName}}</span>
        </div>
        <div class="item">
          <svg-icon iconClass="info-person"></svg-icon>
          <span class="avatar" :style="'background-image:url('+info.uploadUserPhoto+')'" v-if="info.uploadUserPhoto"></span>
           <span
            v-else
            class="icon-text"
            >{{ ((info.uploadUserName)?(info.uploadUserName).toUpperCase():'') | getIconText }}</span>
          <span>{{info.uploadUserName}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Ajax } from "@/common";
import moment from 'moment';
import { mapState } from "vuex";
import SvgIcon from "@/components/SvgIcon";
import StudentSelecter from "./studentSelecter";
import TagSelecter from "./tagSelecter";
import { hasPermission } from './../../../utils/utils.js';
export default {
  components: {SvgIcon,StudentSelecter,TagSelecter},
  props: {
    classId:{
      default: '',
      type: String
    },
    item:{
      default: ()=>{},
      type: Object
    },
    info:{
      default: ()=>{},
      type: Object
    },
  },
  data() {
    return {
      loaded:false,
      studentsOptions:[],
      rootPath:process.env.VUE_APP_fileBasePath,
      tagsOptions:[
        
      ],
      studentList:[],
      tags:[],
      notetimer:null,
      note:''
    }
  },
  computed:{
    noteCheck(){
      return !this.info.note || this.info.note.length<=100
    },
    ...mapState({
      user: (state) => state.user
    }),
    defaultTags(){
      return this.tagsOptions.filter(o=>this.info.tagList.indexOf(o.id)>=0)
    }
  },
  watch:{
    note(){
      this.submitInfo()
    },
    info() {
      this.note = this.info.note
    }
  },
  filters: {
    getIconText(text) {
      if (!text) {
        return '';
      }
      const arr = text.split(' ');
      const first = arr[0].substr(0, 1);
      if (arr.length > 1) {
        const last = arr[arr.length - 1].substr(0, 1);
        return `${first}${last}`;
      }
      return first;
    }
  },
  created(){
    console.log(121212121212)
    this.note = this.info.note
    this.fetchStudents()
    this.fetchTags()
    this.$nextTick(()=>{
      this.loaded = true
    })
  },
  methods: {
    moment,
    hasPermission,
    generateTimeStr(timeStr){
      return moment.utc(timeStr).local().format('DD/MM/YYYY,hh:mm A')
    },
    handleStudentCheck(val){
      this.studentList = [...val]
      Ajax.post("/moments/media/updateStudents", {
        classId:this.classId,
        mediaId:this.info.id,
        studentIdList:this.studentList.map(o=>o.id)
      }).then(res=>{
        if(res.code=='0000'){
          this.$emit('infoOk')
        }
      })
    },
    handleTagCheck(val){
      this.tags = [...val]
     
      Ajax.post("/moments/media/updateMediaInfo", {
        mediaId:this.info.id,
        tagList:this.tags.map(o=>o.id)
      }).then(res=>{
        if(res.code=='0000'){
          this.$emit('infoOk')
        }
      })
    },
    submitInfo(){
      if(!this.loaded){
        return
      }
      clearTimeout(this.notetimer)
      this.notetimer = setTimeout(() => {
        Ajax.post("/moments/media/updateMediaInfo", {
          mediaId:this.info.id,
          note:this.note
        }).then(res=>{
          if(res.code=='0000'){
            this.$emit('infoOk')
          }
        })
      }, 500); 
    },
    async fetchStudents(){
      var res
      if(this.info.shareStatus == 2){
        res = await Ajax.get("/usermanage/class/change/listByParamsForMomentSharedV1", {
          classId:this.classId,
          searchDate:this.info.approvalTime
        })
      }else{
        res = await Ajax.get("/usermanage/student/getStudentByClassId", {
          classId:this.classId,
        })
      }
      
      if(res.code=='0000'){
        this.studentsOptions = res.data
      }
      
    },
    async fetchTags(){
      var res = await Ajax.post("/usermanage/setting/getList", {
        "dataLevel": "moments",
        "dataType": "learingDomain",
        "userId": this.user.userId

      })
      if(res.code=='0000'){
        this.tagsOptions = res.data
     
      }
      
    }
  },
};
</script>
<style lang='less' scoped>

  
</style>