<!-- class students list -->
<template>
  <div class="content has_breadcrumb journal-inner">
    <GgPage pageType="" title="Journal">
      <div slot="topAppBar">
        <search :query="query" />
      </div>
      <div class="journal-list-inner">
        <div class="journal-create-main">
          <div class="journal-top-fixed">
            <div class="title">
              Search Results
            </div>
          </div>

          <div class="journal-page-main" v-loading="loading">
            <div class="journal-page-list">
              <div class="journal-list-box-inner" v-infinite-scroll="loadJournal" v-if="list.length > 0">
                <div class="journal-box" v-for="(item, index) in list" :key="index">
                  <div class="journal-img" :style="'background-image:url(' + item.cover + ');'"></div>
                  <div class="journal-tit" :title="item.title">{{ item.title }}</div>
                  <div class="journal-tag" v-if="item.newsletter">All students</div>
                  <div class="journal-tag" v-if="!item.newsletter && item.recipientInfo">
                    <div class="avatar" v-if="item.recipientInfo.photo"><img :src="item.recipientInfo.photo"></div>
                    <div class="no" v-else>
                      {{ item.recipientInfo.firstName ? item.recipientInfo.firstName.substring(0, 1).toUpperCase() : '-' }}{{ item.recipientInfo.lastName ? item.recipientInfo.lastName.substring(0, 1).toUpperCase() : '-' }}
                    </div>
                    <div class="name">{{ item.recipientInfo.firstName || '' + ' ' + item.recipientInfo.lastName || '' }}</div>
                  </div>
                  <div class="journal-col">
                    <gg-menu-bar>
                      <i class="iconmore_vert-24px iconfont" />
                      <template slot="menus">
                        <gg-menu-bar-item 
                          v-if="item.status === 'DRAFT' || item.status === 'PENDING' || item.status === 'POSTED'" 
                          @click="toPreview(item.id, item.newsletter ? 0 : 1, item)"
                        >
                          <span>Preview</span>
                        </gg-menu-bar-item>

                        <gg-menu-bar-item 
                          v-if="item.status === 'DRAFT' && hasPermission('Share journals_Create')"
                          @click="journalSubmitShow(item.id, item.newsletter ? 0 : 1, item)"
                        >
                          <span>Submit</span>
                        </gg-menu-bar-item>

                        <gg-menu-bar-item 
                          v-if="item.status === 'PENDING' && hasPermission('Share journals_Edit')"
                          @click="journalUndoShow(item.id, item.newsletter ? 0 : 1, item)"
                        >
                          <span>Undo</span>
                        </gg-menu-bar-item>

                        <gg-menu-bar-item 
                          v-if="item.status === 'PENDING' && hasPermission('Share journals_Full')"
                          @click="journalApproveShow(item.id, item.newsletter ? 0 : 1, item)"
                        >
                          <span>Approve</span>
                        </gg-menu-bar-item>

                        <gg-menu-bar-item
                          v-if="item.status === 'POSTED' && dateDiff(item.lastApprove.timestamp) === 0 && hasPermission('Share journals_Full')"
                          @click="journalRecallShow(item.id, item.newsletter ? 0 : 1, item)"
                        >
                          <span>Recall</span>
                        </gg-menu-bar-item>

                        <gg-menu-bar-item class="hr-class" 
                        v-if="(item.status === 'DRAFT' || item.status === 'PENDING') && hasPermission('Manage journals_Edit') || (item.status === 'DRAFT' || item.status === 'PENDING') && hasPermission('Manage journals_Full')">
                          <hr class="hr" />
                        </gg-menu-bar-item>

                        <gg-menu-bar-item 
                          v-if="(item.status === 'DRAFT' || item.status === 'PENDING') && hasPermission('Manage journals_Edit')" 
                          @click="toEdit(item.id, item.newsletter ? 0 : 1, item)"
                        >
                          <span>Edit</span>
                        </gg-menu-bar-item>

                        <gg-menu-bar-item 
                          v-if="(item.status === 'DRAFT' || item.status === 'PENDING') && hasPermission('Manage journals_Full')"
                          @click="journalDeleteShow(item.id, item.newsletter ? 0 : 1, item)"
                        >
                          <span>Delete</span>
                        </gg-menu-bar-item>
                    
                      </template>
                    </gg-menu-bar>
                  </div>
                </div>
              </div>

              <div class="empty-box" slot="empty" v-else>
                <img :src="require('@/assets/images/moments/empty_photo.png')" />
                <p>No results found.</p>
              </div>
            </div>

          </div>

          <Snackbar :visible.sync="loadingShow" :content="loadingText" />

          <gg-Dialog width="580px" :visible.sync="journalDeleteSw" :title="'Delete ' + (journalType === 0 ? 'newsletter' : 'portfolio') + '?'" class="draft-dialog mp-dialog">
            <div class="dialogContent">Once deleted, you will not be able to recover the {{ journalType === 0 ? 'newsletter' : 'portfolio' }} data.</div>
            <span slot="footer" class="dialog-footer">
              <gg-cancel-button @click="journalDeleteSw = false">Cancel</gg-cancel-button>
              <gg-submit-button @click="journalDelete">Delete</gg-submit-button>
            </span>
          </gg-Dialog>

          <gg-Dialog width="580px" :visible.sync="journalSubmitSw" :title="'Submit ' + (journalType === 0 ? 'newsletter' : 'portfolio') + '?'" class="draft-dialog mp-dialog">
            <div class="dialogContent">Once submitted, the {{ journalType === 0 ? 'newsletter' : 'portfolio' }} will be subjected to approval for sharing.</div>
            <span slot="footer" class="dialog-footer">
              <gg-cancel-button @click="journalSubmitSw = false">Cancel</gg-cancel-button>
              <gg-submit-button @click="journalSubmit">Submit</gg-submit-button>
            </span>
          </gg-Dialog>

          <gg-Dialog width="580px" :visible.sync="journalUndoSw" :title="'Undo submission?'" class="draft-dialog mp-dialog">
            <div class="dialogContent">Once undone, the {{ journalType === 0 ? 'newsletter' : 'portfolio' }} will change from pending to draft.</div>
            <span slot="footer" class="dialog-footer">
              <gg-cancel-button @click="journalUndoSw = false">Cancel</gg-cancel-button>
              <gg-submit-button @click="journalUndo">Undo</gg-submit-button>
            </span>
          </gg-Dialog>

          <gg-Dialog width="580px" :visible.sync="journalApproveSw" :title="'Approve ' + (journalType === 0 ? 'newsletter' : 'portfolio') + '?'" class="draft-dialog mp-dialog">
            <div class="dialogContent">Once approved, the {{ journalType === 0 ? 'newsletter' : 'portfolio' }} will be shared and viewed by parents.</div>
            <span slot="footer" class="dialog-footer">
              <gg-cancel-button @click="journalApproveSw = false">Cancel</gg-cancel-button>
              <gg-submit-button @click="journalApprove">Approve</gg-submit-button>
            </span>
          </gg-Dialog>

          <gg-Dialog width="580px" :visible.sync="journalRecallSw" :title="'Recall ' + (journalType === 0 ? 'newsletter' : 'portfolio') + '?'" class="draft-dialog mp-dialog">
            <div class="dialogContent">Once recalled, the {{ journalType === 0 ? 'newsletter' : 'portfolio' }} will change from posted to draft and not be seen by parents.</div>
            <span slot="footer" class="dialog-footer">
              <gg-cancel-button @click="journalRecallSw = false">Cancel</gg-cancel-button>
              <gg-submit-button @click="journalRecall">Recall</gg-submit-button>
            </span>
          </gg-Dialog>

        </div>
      </div>

    </GgPage>
  </div>
</template>

<script>
import moment from 'moment';
import { Ajax } from "@/common";
import { mapState } from "vuex";
import { htmlDecode, hasPermission } from './../utils/utils.js';
import search from './../modules/search'
export default {
  components: {
    search
  },
  computed: {
    ...mapState({
      user: (state) => state.user
    }),
  },
  data() {
    return {
      pages: 0,
      pageNum: 1,
      loading: false,
      loadingText: '',
      loadingShow: false,
      journalType: 0,
      journalIndex: '',
      journalRecallSw: false,
      journalApproveSw: false,
      journalUndoSw: false,
      journalSubmitSw: false,
      journalDeleteSw: false,
      list: [],
      query: {}
    };
  },
  watch:{
    $route(){
      this.getJournal()
    }
  },
  created() {
    this.query = this.$route.query || {}
    this.getJournal()
  },
  mounted(){
  
  },
  methods: {

    moment,

    hasPermission,

    htmlDecode,

    dateDiff(date) {
      let a = moment.utc(date),
      b = moment()
      return a.diff(b, 'days')
    },

    loadJournal() {
      if((this.pageNum < this.pages) && !this.loading) {
        this.getJournal(true)
      }
    },

    getJournal(s) {
      this.loading = true
      console.log(s)
      if(!s) {
        this.pages = 0
        this.pageNum = 1
      }else{
        this.pageNum = s ? this.pageNum + 1 : this.pageNum
      }
      
      let studentName = this.$route.query.studentName,
      studentNameArr = (studentName && studentName.indexOf(' ') > -1) ? studentName.split(' ') : [studentName],
      data = {
        staffId: this.user.userId,
        keywords: this.$route.query.keywords,
        classId: sessionStorage.getItem("journalClassId"),
        studentName: studentNameArr.join(','),
        currentPage: this.pageNum,
        pageSize: 10
      }

      Ajax.get("journal/search/extraV1", data).then((res) => {
        if(res.code === '0000'){
          if(!s) {
            document.getElementsByClassName('ggPage-main-center')[0].scrollTo({ 
              top: 0
            })
            this.list = []
          }
          this.list.push(...res.data.records)
          this.pages = Math.round(res.data.count / res.data.size)
        }
        this.loading = false
      });
    },

    toPreview(id, type, item) {
      this.$router.push({ name: 'journalDraft', query: { 
        id: id, 
        name: item.title, 
        type: type,
        classid: this.$route.params.id
      }})
    },

    toEdit(id, type, item) {
      this.$router.push({ name: 'journalCreate', query: { editid: id, type: type, classname: this.classname, classid: this.classid, time: this.moment().valueOf() }})
    },

    journalRecall() {
      Ajax.put('/journal/' + this.journalIndex + '/recall?staffId=' + this.user.userId).then((res) => {
        if(res.code === '0000'){
          this.loadingShow = true
          this.journalRecallSw = false
          this.loadingText = (this.journalType === 0 ? 'Newsletter' : 'Portfolio') + ' has been recalled'

          setTimeout(()=>{
            let typename = (this.journalType === 0 ? 'newsletter' : 'portfolio') + '_draft',
            classid = this.$route.query.classid
            this.$router.push('/journal/class/' + classid + '?typename=' + typename)
          }, 1500)
        }
      });
    },

    journalRecallShow(id, type, item) {
      this.journalRecallSw = true
      this.journalIndex = id
      this.journalType = type
    },

    journalApprove() {
      Ajax.put('/journal/' + this.journalIndex + '/process?approve=' + true + '&staffId=' + this.user.userId).then((res) => {
        if(res.code === '0000'){
          this.loadingShow = true
          this.journalApproveSw = false
          this.loadingText = (this.journalType === 0 ? 'Newsletter' : 'Portfolio') + ' has been posted'

          setTimeout(()=>{
            let typename = (this.journalType === 0 ? 'newsletter' : 'portfolio') + '_posted',
            classid = this.$route.query.classid
            this.$router.push('/journal/class/' + classid + '?typename=' + typename)
          }, 1500)
        }
      });
    },
    
    journalApproveShow(id, type, item) {
      this.journalApproveSw = true
      this.journalIndex = id
      this.journalType = type
    },

    journalUndo() {
      Ajax.put('/journal/' + this.journalIndex + '/cancel?staffId=' + this.user.userId).then((res) => {
        if(res.code === '0000'){
          this.loadingShow = true
          this.journalUndoSw = false
          this.loadingText = (this.journalType === 0 ? 'Newsletter' : 'Portfolio') + ' has been moved to draft'

          setTimeout(()=>{
            let typename = (this.journalType === 0 ? 'newsletter' : 'portfolio') + '_draft',
            classid = this.$route.query.classid
            this.$router.push('/journal/class/' + classid + '?typename=' + typename)
          }, 1500)
        }
      });
    },

    journalUndoShow(id, type, item) {
      this.journalUndoSw = true
      this.journalIndex = id
      this.journalType = type
    },

    journalSubmit() {
      Ajax.put('/journal/' + this.journalIndex + '/submit?staffId=' + this.user.userId).then((res) => {
        if(res.code === '0000'){
          this.loadingShow = true
          this.journalSubmitSw = false
          this.loadingText = (this.journalType === 0 ? 'Newsletter' : 'Portfolio') + ' has been submitted'

          setTimeout(()=>{
            let typename = (this.journalType === 0 ? 'newsletter' : 'portfolio') + '_pending',
            classid = this.$route.query.classid
            this.$router.push('/journal/class/' + classid + '?typename=' + typename)
          }, 1500)
        }
      });
    },

    journalSubmitShow(id, type, item) {
      this.journalSubmitSw = true
      this.journalIndex = id
      this.journalType = type
    },

    journalDelete() {
      Ajax.delete('/journal/' + this.journalIndex + '?staffId=' + this.user.userId).then((res) => {
        if(res.code === '0000'){
          this.journalDeleteSw = false
          this.loadingText = (this.journalType === 0 ? 'Newsletter' : 'Portfolio') + ' has been deleted'
          this.getJournal()
        }
      });
    },

    journalDeleteShow(id, type) {
      this.journalDeleteSw = true
      this.journalIndex = id
      this.journalType = type
    },

    momentTime(date) {
      return moment.utc(date).local().format("DD/MM/YYYY, hh:mm A");
    }
    
  },
};
</script>
<style lang='less' scoped>
.journal-list-inner {
  margin: auto;
  width: 1373px;
}

.journal-create-main {
  position: relative;

  .journal-top-fixed {
    width: 1373px;
    background: #fff;

    .journal-people-tag {
      padding: 0 0 10px 0;

      .tag {
        display: inline-block;
        padding: 0 6px 0 4px;
        height: 24px;
        background: #fff;
        border: 1px solid #E0E0E0;
        border-radius: 16px;

        .no {
          display: inline-block;
          margin-right: 8px;
          width: 16px;
          height: 16px;
          background: #b4b4b4;
          border-radius: 50%;
          line-height: 16px;
          text-align: center;
          color: #fff;
          font-size: 12px;
          font-family: 'OpenSans-Bold';
          vertical-align: middle;
        }

        .avatar {
          margin-right: 8px;
          display: inline-block;
          width: 16px;
          height: 16px;
          vertical-align: middle;

          img {
            display: block;
            width: 16px;
            height: 16px;
            border-radius: 50%;
          }
        }

        .name {
          margin-right: 8px;
          display: inline-block;
          font-size: 12px;
          font-family: Roboto;
          font-weight: 400;
          line-height: 16px;
          color: #202124;
          vertical-align: middle;
        }
      }
    }

    .title {
      position: relative;
      margin-top: 0;
      padding-left: 20px;
      padding-bottom: 30px;
      border-bottom: 1px solid #e3e3e3;
      color: #606367;
      font-size: 24px;
      font-family: 'OpenSans-Bold';
    }

  }

  .journal-page-main {
    position: relative;

    .journal-page-list {
      padding-bottom: 112px;
    }
  } 
}

.journal-list-box-inner {
  position: relative;
  overflow: hidden;
  padding-top: 20px;
  margin-bottom: 92px;
  margin-right: -48px;

  .journal-box {
    float: left;
    position: relative;
    margin: 0 20px 20px 0;
    width: 328px;
    height: 228px;
    background: #fff;
    border: 1px solid #E0E0E0;
    border-radius: 8px;

    .journal-col {
      position: absolute;
      right: 10px;
      bottom: 10px;
    }

    .journal-tag {
      display: inline-block;
      margin: 0 0 0 20px;
      padding: 0 5px;
      background: #fff;
      border: 1px solid #E0E0E0;
      border-radius: 16px;
      line-height: 22px;
      text-align: left;
      font-family: Roboto;
      font-size: 12px;
      color: #202124;

      .no {
        display: inline-block;
        margin-right: 8px;
        width: 16px;
        height: 16px;
        background: #b4b4b4;
        border-radius: 50%;
        line-height: 16px;
        text-align: center;
        color: #fff;
        font-size: 12px;
        font-family: 'OpenSans-Bold';
        vertical-align: middle;
        overflow: hidden;
      }

      .avatar {
        margin-right: 8px;
        display: inline-block;
        width: 16px;
        height: 16px;
        vertical-align: middle;

        img {
          display: block;
          width: 16px;
          height: 16px;
          border-radius: 50%;
        }
      }

      .name {
        margin-right: 8px;
        display: inline-block;
        font-size: 12px;
        font-family: Roboto;
        font-weight: 400;
        line-height: 16px;
        color: #202124;
        vertical-align: middle;
      }
    }

    .journal-tit {
      margin: 20px 0 10px 20px;
      width: 256px;
      line-height: 20px;
      font-size: 16px;
      text-align: left;
      font-family: Roboto;
      color: #202124;
      overflow: hidden;
      text-overflow:ellipsis;
      white-space: nowrap;
    }

    .journal-img {
      display: block;
      width: 100%;
      height: 136px;
      border-radius: 8px 8px 0 0;
      background-size: cover; 
      background-position: top;
    }
  }
}

.journal-inner .empty-box {
  position: fixed;
}
</style>