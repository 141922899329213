
import store from './../../../store'

export function batchImgDownload(imgList){
  for(let i=0;i<imgList.length;i++){
    imgDownload(imgList[i].path,imgList[i].id)
  }
}

export function imgDownload(link,filename){
  let a = document.createElement('a')
  a.href = process.env.VUE_APP_fileBasePath+link
  a.download = filename || 'default.png'
  a.dispatchEvent(new MouseEvent('click'))
}

export function htmlDecode(text){
  var temp = document.createElement("div");
  temp.innerHTML = text;
  var output = temp.innerText || temp.textContent;
  temp = null;
  //output.replace(/\n/g, '\r\n')
  return output;
}

export function hasPermission(permission){
  const ls = store.getters.rolePermission
  const userType = store.state.user.userType
  // View Create Edit Full
  const kind = permission.split('_')[0]
  const _role = ls.find(o=>o.indexOf(kind)>=0) || ''
  
  if(userType=='1'){
    return true
  }else{
    if(_role.length==0){
      // 没有相关权限
      return false
    }else{
      const _opt = _role.split('_')[1] || ''
      if(_opt.length==0){
        return false
      }else{
        if(_opt == 'Full'){
          return permission == kind+'_View' || permission == kind+'_Create' || permission == kind+'_Edit' || permission == kind+'_Full'
        }else if(_opt == 'Edit'){
          return permission == kind+'_View' || permission == kind+'_Create' || permission == kind+'_Edit'
        }else if(_opt == 'Create'){
          return permission == kind+'_View' || permission == kind+'_Create'
        }else if(_opt == 'View'){
          return permission == kind+'_View'
        }else{
          return false
        }
      }
    }
  }
}