var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content has_breadcrumb"},[_c('base-breadcrumb',{attrs:{"slot":"breadcrumb","data":[
        {
          routeName: 'locations',
          name: _vm.schoolName,
        },
        {
          name: 'People',
        } ]},slot:"breadcrumb"}),_c('GgPage',{attrs:{"pageType":"2","title":"People"},scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('gg-flex-menus',{attrs:{"menus":_vm.menuDatas,"default-active":_vm.activeIndex},on:{"select":_vm.menuSelect}})]},proxy:true}])},[(_vm.dataType === 'schoolName')?[_c('GgPageHeader',{attrs:{"title":_vm.schoolName}}),_c('border-less-table',{attrs:{"data":_vm.personalInfoData}})]:_vm._e(),(_vm.dataType === 'teachers')?_c('edoovo-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.teachersData.isLoading),expression:"teachersData.isLoading"}],ref:_vm.teachersData.ref,staticClass:"list-tableBox",attrs:{"height":"100%","data":_vm.teachersData.tableData,"rowKey":"id","title":_vm.teachersData.title,"defaultValue":"-","sort":_vm.teachersData.sort},on:{"sortChange":_vm._onSortChangeTeachers}},[_c('div',{staticStyle:{"text-align":"center"},attrs:{"slot":"empty"},slot:"empty"},[_c('div',{staticStyle:{"width":"213px","text-align":"center","border-radius":"50%","margin-bottom":"12px","line-height":"200px"}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require('@/assets/images/products/noData.svg')}})]),_c('p',{staticStyle:{"font-size":"22px"}},[_vm._v("No Teachers yet.")])]),(_vm.filterRenderDataTeachers.length)?_c('div',{staticClass:"list-filterBox",attrs:{"slot":"filter"},slot:"filter"},[_c('base-filter',{ref:"tablefilter",attrs:{"renderData":_vm.filterRenderDataTeachers,"multKeys":[]},on:{"filterCallback":_vm._filterCallbackTeachers}})],1):_vm._e(),_c('edoovo-table-column',{staticClass:"user_name",attrs:{"size":"L","label":"Name","prop":"name","sortName":"firstName","tooltip":true,"getTooltipText":function (row) { return ((row.firstName) + " " + (row.lastName)); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var row = ref.row;
return [_c('div',{staticClass:"table-cell-box",on:{"click":function($event){return _vm.toStaffInfo(row)}}},[_c('head-img',{staticClass:"table-cell-img",attrs:{"src":row.photo ? ((row.photo) + "?") : "","firstName":row.firstName,"lastName":row.lastName,"line-height":"32"}}),_c('span',{staticClass:"table-cell-name"},[_vm._v(_vm._s(row.firstName)+" "+_vm._s(row.lastName))])],1)]}}],null,false,2285115637)}),_c('edoovo-table-column',{attrs:{"size":"L","label":"Email","prop":"emailAddress"}}),_c('edoovo-table-column',{attrs:{"size":"M","label":"Phone number","prop":"phone","getTooltipText":function (row) { return ((row.countryCode) + " " + (row.phoneNumber)); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var row = ref.row;
return [_vm._v(" "+_vm._s(row.countryCode)+" "+_vm._s(row.phoneNumber)+" ")]}}],null,false,519675820)}),_c('edoovo-table-column',{attrs:{"size":"M","label":"Working title","prop":"jobTitle","sortName":"jobTitle"}}),_c('edoovo-table-column',{attrs:{"size":"M","label":"Type","prop":"workType","sortName":"workType"}})],1):_vm._e(),(_vm.dataType === 'students')?_c('edoovo-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.studentsData.isLoading),expression:"studentsData.isLoading"}],ref:_vm.studentsData.ref,staticClass:"list-tableBox",attrs:{"height":"100%","data":_vm.studentsData.tableData,"rowKey":"id","title":_vm.studentsData.title,"defaultValue":"-","sort":_vm.studentsData.sort},on:{"sortChange":_vm._onSortChangeStudents}},[_c('div',{staticStyle:{"text-align":"center"},attrs:{"slot":"empty"},slot:"empty"},[_c('div',{staticStyle:{"width":"213px","text-align":"center","border-radius":"50%","margin-bottom":"12px","line-height":"200px"}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require('@/assets/images/products/noData.svg')}})]),_c('p',{staticStyle:{"font-size":"22px"}},[_vm._v("No Students yet.")])]),(_vm.filterRenderDataStudents.length)?_c('div',{staticClass:"list-filterBox",attrs:{"slot":"filter"},slot:"filter"},[_c('base-filter',{ref:"tablefilter",attrs:{"renderData":_vm.filterRenderDataStudents,"multKeys":[]},on:{"filterCallback":_vm._filterCallbackStudents}})],1):_vm._e(),_c('edoovo-table-column',{staticClass:"user_name",attrs:{"size":"L","label":"Name","prop":"name","sortName":"firstName","tooltip":true,"getTooltipText":function (row) { return ("" + (row.firstName) + (row.lastName)); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var row = ref.row;
return [_c('div',{staticClass:"table-cell-box",on:{"click":function($event){return _vm.toStudentInfo(row)}}},[_c('head-img',{staticClass:"table-cell-img",attrs:{"src":row.photo ? ("" + (row.photo)) : "","firstName":row.firstName,"lastName":row.lastName,"line-height":"32"}}),_c('span',{staticClass:"table-cell-name"},[_vm._v(_vm._s(row.firstName)+" "+_vm._s(row.lastName))])],1)]}}],null,false,3718051397)}),_c('edoovo-table-column',{attrs:{"size":"S","label":"Gender","prop":"gender","sortName":"gender"}}),_c('edoovo-table-column',{attrs:{"size":"M","label":"Age","prop":"age"}}),_c('edoovo-table-column',{staticClass:"table-cell-box",attrs:{"size":"M","label":"Contacts","prop":"contacts"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var row = ref.row;
return [_c('div',{on:{"click":function($event){return _vm.openContactsDialog(row)}}},[_vm._v(" "+_vm._s(row.contacts||'-')+" ")])]}}],null,false,4215082541)})],1):_vm._e()],2),_c('gg-dialog',{attrs:{"visible":_vm.contacts.visible,"title":"Contacts","showClose":true},on:{"update:visible":function($event){return _vm.$set(_vm.contacts, "visible", $event)}}},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.contacts.loading),expression:"contacts.loading"}],staticClass:"contactsDialog"},[_c('div',{staticClass:"title"},[_vm._v("Family")]),_c('edoovo-table',{ref:"multipleTable",staticClass:"list-tableBox",attrs:{"height":"300px","data":_vm.contacts.family.dataList,"rowKey":"id","defaultValue":"-"}},[_c('div',{staticStyle:{"text-align":"center"},attrs:{"slot":"empty"},slot:"empty"},[_c('div',{staticStyle:{"width":"213px","text-align":"center","border-radius":"50%","margin-bottom":"12px","line-height":"200px"}},[_c('img',{staticStyle:{"width":"100%","height":"100px"},attrs:{"src":require('@/assets/images/products/noData.svg')}})]),_c('p',{staticStyle:{"font-size":"22px"}},[_vm._v("No staff yet.")])]),_c('edoovo-table-column',{attrs:{"size":"L","label":"Name","prop":"name","tooltip":true,"getTooltipText":function (row) { return ("" + (row.firstName)); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var row = ref.row;
return [_c('div',[_c('head-img',{staticClass:"table-cell-img",attrs:{"src":row.photo ? ((row.photo) + "?" + (+new Date())) : "","firstName":row.firstName,"lastName":row.lastName,"line-height":"32"}}),_c('span',{staticClass:"table-cell-name"},[_vm._v(_vm._s(row.firstName)+" "+_vm._s(row.lastName))])],1)]}}])}),_c('edoovo-table-column',{attrs:{"size":"M","label":"Relationship","prop":"releationship","getTooltipText":function (row) { return ("" + (row.releationship)); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var row = ref.row;
return [_vm._v(" "+_vm._s(row.releationship)+" ")]}}])}),_c('edoovo-table-column',{attrs:{"size":"L","label":"Email","prop":"email"}}),_c('edoovo-table-column',{attrs:{"size":"M","label":"Phone","prop":"phoneNumber","getTooltipText":function (row) { return ((row.countryCode?row.countryCode:'') + " " + (row.phoneNumber?row.phoneNumber:'-')); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var row = ref.row;
return [_vm._v(" "+_vm._s(row.countryCode)+" "+_vm._s(row.phoneNumber || '-')+" ")]}}])}),_c('edoovo-table-column',{attrs:{"size":"M","label":"Status","prop":"parentStatus","getTooltipText":function (row) { return ("" + (row.parentStatus=='Connected'?'Connected':'Pending')); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var row = ref.row;
return [_vm._v(" "+_vm._s(row.parentStatus=='Connected'?'Connected':'Pending')+" ")]}}])})],1),(_vm.contacts.pickup.dataList.length!=0)?_c('div',{staticClass:"title"},[_vm._v("Pickup")]):_vm._e(),(_vm.contacts.pickup.dataList.length!=0)?_c('edoovo-table',{ref:"multipleTable",staticClass:"list-tableBox",attrs:{"max-height":"300px","data":_vm.contacts.pickup.dataList,"rowKey":"id","defaultValue":"-"}},[_c('div',{staticStyle:{"text-align":"center"},attrs:{"slot":"empty"},slot:"empty"},[_c('div',{staticStyle:{"width":"213px","text-align":"center","border-radius":"50%","margin-bottom":"12px","line-height":"100px"}},[_c('img',{staticStyle:{"width":"100%","height":"100px"},attrs:{"src":require('@/assets/images/products/noData.svg')}})]),_c('p',{staticStyle:{"font-size":"22px"}},[_vm._v("No staff yet.")])]),_c('edoovo-table-column',{attrs:{"size":"L","label":"Name","prop":"name","tooltip":true,"getTooltipText":function (row) { return ("" + (row.firstName)); }},on:{"click":function () {}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var row = ref.row;
return [_c('div',[_c('head-img',{staticClass:"table-cell-img",attrs:{"src":row.photo ? ((row.photo) + "?" + (+new Date())) : "","firstName":row.firstName,"lastName":row.lastName,"line-height":"32"}}),_c('span',{staticClass:"table-cell-name"},[_vm._v(_vm._s(row.firstName)+" "+_vm._s(row.lastName))])],1)]}}],null,false,1944493284)}),_c('edoovo-table-column',{attrs:{"size":"M","label":"Relationship","prop":"releationship","getTooltipText":function (row) { return ("" + (row.releationship)); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var row = ref.row;
return [_vm._v(" "+_vm._s(row.releationship)+" ")]}}],null,false,3416184606)}),_c('edoovo-table-column',{attrs:{"size":"M","label":"Phone","prop":"phoneNumber"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var row = ref.row;
return [_vm._v(" "+_vm._s(row.countryCode)+_vm._s(row.phoneNumber || "-")+" ")]}}],null,false,2844269418)})],1):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }