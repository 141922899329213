<!-- class students list -->
<template>
  <div>
    <div class="form-item">
      <div class="tit">Students</div>
      <student-selecter v-if="info.studentList" :defaultValue="info.studentList" :options="studentsOptions" readOnly/>
    </div>
    <div class="form-item">
      <div class="tit">Messages</div>
      <div class="note">
       
        
        <md-field :class="{
          'md-invalid': !captionCheck
        }" v-if="info.shareStatus == 0 && hasPermission('Share media_Create')">
          <label>Caption</label>
          <md-textarea v-model="caption" md-autogrow></md-textarea>
          <span class="md-error">Character limit of 80</span>
        </md-field>
        <md-field :class="{
          'md-invalid': !captionCheck
        }" v-else-if="info.shareStatus == 1 && hasPermission('Share media_Create')">
          <label>Caption</label>
          <md-textarea v-model="caption" md-autogrow></md-textarea>
          <span class="md-error">Character limit of 80</span>
        </md-field>
        <template v-else>
          <div class="subtit">Caption</div>
          <span class="val">
            {{info.caption}}
          </span>
        </template>
          
      </div>
    </div>
    
    <div class="form-item" v-if="info.shareStatus>0">
      <div class="tit">Submission</div>
      <div class="detail">
        <div class="item">
          <svg-icon iconClass="info-date"></svg-icon>
          <span>{{info.shareSubmissionTime}}</span>
        </div>
        <div class="item">
          <svg-icon iconClass="info-person"></svg-icon>
          <span class="avatar" :style="'background-image:url('+info.userPhoto+')'" v-if="info.userPhoto"></span>
          <span
            v-else
            class="icon-text"
            >{{ (info.userFirstName+' '+info.userLastName).toUpperCase() | getIconText }}</span
          >
          <span>{{(info.userFirstName||'')+(info.userLastName?' '+info.userLastName:'')}}</span>
        </div>
      </div>
    </div>
    <div class="form-item" v-if="info.shareStatus==2">
      <div class="tit">Approval</div>
      <div class="detail">
        <div class="item">
          <svg-icon iconClass="info-date"></svg-icon>
          <span>{{generateTimeStr(info.approvalTime)}}</span>
        </div>
        <div class="item">
          <svg-icon iconClass="info-person"></svg-icon>
          <span class="avatar" :style="'background-image:url('+info.approvalUserPhoto+')'" v-if="info.approvalUserPhoto"></span>
          <span
            v-else
            class="icon-text"
            >{{ (info.approvalFirstName+' '+info.approvalLastName).toUpperCase() | getIconText }}</span
          >
          <span>{{info.approvalFirstName||info.approvalLastName?(info.approvalFirstName+' '+info.approvalLastName):'Company admin'}}</span>
        </div>
      </div>
    </div>
    <div class="btns" v-if="info.shareStatus<=2">
      <el-button type="text" v-if="info.shareStatus==0&& hasPermission('Share media_Create')" @click="handleShare" :disabled="!captionCheck">Submit</el-button>
      <template  v-if="info.shareStatus==1">
        <template>
          <baseButton @click="handleUnshare" v-if="hasPermission('Share media_Edit')" type="primary">Undo</baseButton>
        </template>
        <template>
          <baseButton @click="handleApprove" v-if="hasPermission('Share media_Full')" type="primary" style="margin-left:8px">Approve</baseButton>
        </template>
      </template>
      <template  v-if="info.shareStatus==2 && timeRight() && hasPermission('Share media_Full')">
        <template>
          <baseButton @click="handleRecall" type="primary">Recall</baseButton>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import { Ajax } from "@/common";
import moment from 'moment';
import SvgIcon from "@/components/SvgIcon";
import StudentSelecter from "./studentSelecter";
import TagSelecter from "./tagSelecter";
import { hasPermission } from './../../../utils/utils.js';
import { mapState } from "vuex";
import bus from '../../../utils/bus'
export default {
  components: {SvgIcon,StudentSelecter,TagSelecter},
  props: {
    classId:{
      default: '',
      type: String
    },
    item:{
      default: ()=>{},
      type: Object
    },
    info:{
      default: ()=>{},
      type: Object
    },
  },
  data() {
    return {
      studentsOptions:[],
      caption:'',
      captiontimer: null,
      captionInited:false
    }
  },
  filters: {
    getIconText(text) {
      if (!text) {
        return '';
      }
      const arr = text.split(' ');
      const first = arr[0].substr(0, 1);
      if (arr.length > 1) {
        const last = arr[arr.length - 1].substr(0, 1);
        return `${first}${last}`;
      }
      return first;
    }
  },
  mounted(){
    
    this.fetchStudents()
    this.caption = this.info.caption
    console.log(this.caption)
    this.$nextTick(()=>{
      this.captionInited = true
    })
    
  },
  computed:{
    captionCheck(){
      return !this.caption || this.caption.length<=80
    },
    ...mapState({
      user: (state) => state.user
    }),
  },
  watch:{
    caption(newVal,oldVal){
      if(this.info.shareStatus == 1 && this.captionCheck && this.captionInited){
        this.submitCaption()
      }
      
    }
  },
  methods: {
    moment,
    hasPermission,
    generateTimeStr(timeStr){
      return moment.utc(timeStr).local().format('DD/MM/YYYY,hh:mm A')
    },
    timeRight(){
      return moment.utc().local()<moment.utc(this.info.approvalTime).local().add(1,'hours')
    },
    handleShare(){
      console.log(this.info)
      this.$confirm(
        `Once submitted, the photo will be sent for approval before being shared and viewed by parents. `,
        "Share photo?",
        {
          cancelButtonText: "Cancel",
          confirmButtonText: "Submit",
          cancelButtonClass: "gg_cancel_button",
          confirmButtonClass: "gg_submit_button",
          customClass: "gg_confirm",
          showClose: false,
        }
      )
        .then(() => {
          Ajax.post("/moments/share/share", {
            "caption": this.caption,
            "classId": this.classId,
            "mediaId": this.info.id,
            "fileDisplayName":this.info.fileDisplayName,
            "shareByTeacher": this.user.userId,
            "shareSubmissionTime": moment().format('DD/MM/YYYY, hh:mm A'),
            "studentIdList": this.info.studentList.map(o=>o.id),
            "tagList": this.info.tagList || []
          }).then((res) => {
            if (res.code === "0000") {
              this.$emit('ok',1)
              bus.$emit('refreshImageList')
            }
          });
        })
        .catch(() => {});
    },
    handleApprove(){
      this.$confirm(
        `Once approved, the photo will be shared and viewed by parents.  `,
        "Approve sharing?",
        {
          cancelButtonText: "Cancel",
          confirmButtonText: "Approve",
          cancelButtonClass: "gg_cancel_button",
          confirmButtonClass: "gg_submit_button",
          customClass: "gg_confirm",
          showClose: false,
        }
      )
        .then(() => {
          Ajax.post("/moments/share/approveBatchV1", {
              classId:this.classId,
              approvalTime:moment().format('DD/MM/YYYY, hh:mm A'),
              approvalUser:this.user.userId,
              batchNoList:[this.info.batchNo]
            }).then(res=>{
              if(res.code=='0000'){
                this.$emit('ok',2)
                bus.$emit('refreshImageList')
              }else{

              }
            })
          
        })
        .catch(() => {});
    },
    handleReject(){
      this.$confirm(
        `Once rejected, the submission for sharing approval will be cancelled and parent will not be able to view the photo.  `,
        "Reject sharing?",
        {
          cancelButtonText: "Cancel",
          confirmButtonText: "Reject",
          cancelButtonClass: "gg_cancel_button",
          confirmButtonClass: "gg_submit_button",
          customClass: "gg_confirm",
          showClose: false,
        }
      )
        .then(() => {
          Ajax.post("/moments/share/rejectBatchV1", {
              classId:this.classId,
              approvalUser:this.user.userId,
              batchNoList:[this.info.batchNo]
            }).then(res=>{
              if(res.code=='0000'){
                this.$emit('ok',0)
                bus.$emit('refreshImageList')
              }else{

              }
            })
          
        })
        .catch(() => {});
    },
    handleUnshare(){
      console.log(this.info)
      this.$confirm(
        `Once undone, the photo will change from pending to uploaded.`,
        "Undo submission?",
        {
          cancelButtonText: "Cancel",
          confirmButtonText: "Undo",
          cancelButtonClass: "gg_cancel_button",
          confirmButtonClass: "gg_submit_button",
          customClass: "gg_confirm",
          showClose: false,
        }
      )
        .then(() => {
          // Ajax.post("/moments/share/unShareBatch", {
          //   batchNoList:[this.info.batchNo]
          // }).then(res=>{
          //   if(res.code=='0000'){
          //     this.$emit('ok',0)
          //     bus.$emit('refreshImageList')
          //   }else{

          //   }
          // })
          Ajax.get("/moments/media/unDo", {
            mediaId:this.info.id
          }).then(res=>{
            if(res.code=='0000'){
              this.$emit('ok',0)
              bus.$emit('refreshImageList')
            }else{

            }
          })
          
        })
        .catch(() => {});
    },
    handleRecall(){
      this.$confirm(
        `Once recalled, the photo will change from shared to uploaded and not be seen by parents.`,
        "Recall photo?",
        {
          cancelButtonText: "Cancel",
          confirmButtonText: "Recall",
          cancelButtonClass: "gg_cancel_button",
          confirmButtonClass: "gg_submit_button",
          customClass: "gg_confirm",
          showClose: false,
        }
      )
        .then(() => {
          Ajax.get("/moments/media/reCall", {
              mediaId:this.info.id
            }).then(res=>{
              if(res.code=='0000'){
                this.$emit('ok',0)
                bus.$emit('refreshImageList')
              }else{

              }
            })
        })
        .catch(() => {});
    },
    submitCaption(){
      clearTimeout(this.captiontimer)
      this.captiontimer = setTimeout(() => {
        Ajax.post("/moments/share/updateCaption", {
          mediaId:this.info.id,
          caption:this.caption
        }).then(res=>{
          if(res.code=='0000'){
            // this.$emit('ok',this.info.shareStatus)
          }
        })
      }, 500); 
    },
    async fetchStudents(){
      var res = await Ajax.get("/usermanage/student/getStudentByClassId", {
        classId:this.classId,
      })
      if(res.code=='0000'){
        this.studentsOptions = res.data
      }
      
    },

  },
};
</script>
<style lang='less' scoped>
  .btns{
    .el-button{
      font-family:'opensans-semibold';
      color: #1a73e8
    }
  }
  
</style>