<template>
  <div>
    <div class="ui-img-upload">
      <div class="btn" @click="showimgbth">
        <!-- <el-upload
          class="ui-img-upload-wrapper"
          ref="uploadFileInp"
          list-type="picture"
          multiple
          :auto-upload="false"
          :show-file-list="false"
          action="/media/public/file/uploadExt"
          accept="image/jpeg,image/png,application/pdf"
          :on-change="uploadFileChange"
          >
          <el-button v-if="!file64" class="add-file-btn" size="small" type="primary">
            Logo
          </el-button>
          <div v-else class="img" :style="'background-image: url(' + file64 + ');'"></div>
        </el-upload> -->
        <div class="img" :style="'background-image: url(' + file64 + ');'"></div>
      </div>
    </div>

    <gg-Dialog width="580px" :visible.sync="fileSizeShow" title="File is too large" class="draft-dialog mp-dialog">
      <div class="dialogContent">
        The maximum size of a file that can be uploaded is 25MB.
        <div class="delete" @click="fileSizeShow = false"><img class="add-file-icon" src="@/assets/images/icons/close.svg" /></div>
      </div>
      <span slot="footer" class="dialog-footer"></span>
    </gg-Dialog>

  </div>
</template>

<script>
import moment from 'moment';
import { Ajax } from "@/common";
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      user: (state) => state.user
    }),
  },
  props:{
    query: {
      default:()=>{},
      type: Object
    },
    width:{
      default: 100,
      type: Number
    },
    modelkey:{
      default: '',
      type: String
    },
    height:{
      default: 100,
      type: Number
    },
    value:{
      default: '',
      type: String
    }
  },
  data() {
    return {
      isImgCutShow: false,
      searchResults: false,
      albumList: [],
      optbarSw: false,
      activePhotosAlbums: 'Photos',
      momentsPhotoSw: false,
      imgList: [],
      checkedList: [],
      date: this.$route.query.time ? moment(parseInt(this.$route.query.time)) : moment(),
      loading: false,
      classid: '',
      file: '',
      file64: '',
      h: '',
      rootPath: process.env.VUE_APP_fileBasePath,
      fileSizeShow: false,
      showmanu: false
    };
  },
  mounted(){
    window.onresize = () => {
      this.getHeight()
    }
    this.getHeight()
  },
  watch:{
    value: function() {
      this.file = this.value
    },
    file: function() {
      this.$emit('getImg', this.file, this.modelkey)
    }
  },
  created() {
    this.getLogo()
    this.classid = this.$route.query.classid || ''
    this.file = this.value
  },
  methods: {

    moment,

    getLogo() {
      let data = {
        userId: this.user.userId
      }
      Ajax.get("/usermanage/company/selectCompanyByUserId", data).then((res) => {
        if(res.code === '0000'){
          this.file = res.data.logo
          if(this.file) {
            this.base64Callback(this.file)
          }
        }
      });
    },

    async base64Callback(url) {
      try {
        const res = await this.getBase64(url)
        this.file64 = res
      } catch (err) {
        console.log(err)
      }
    },

    getBase64(url) {
      return new Promise((resolve, reject) => {
        var Img = new Image()
        var dataURL = ''
        Img.setAttribute('crossOrigin', 'Anonymous')
        Img.src = url + '?v=' + Math.random()
        Img.onload = function() {
          // 要先确保图片完整获取到，这是个异步事件
          var canvas = document.createElement('canvas') // 创建canvas元素
          var width = Img.width // 确保canvas的尺寸和图片一样
          var height = Img.height
          canvas.width = width
          canvas.height = height
          canvas.getContext('2d').fillStyle = "#fff"
          canvas.getContext('2d').fillRect(0, 0, canvas.width, canvas.height) 
          canvas.getContext('2d').drawImage(Img, 0, 0, width, height) // 将图片绘制到canvas中
          dataURL = canvas.toDataURL('image/jpeg') // 转换图片为dataURL
          resolve(dataURL)
        }
      })
    },

    uploadFileChange(file, fileList) {
      if (file) {
　　　　 let size = Math.floor(file.size / 1024)
        if(size > (25 * 1024)) {
          this.fileSizeShow = true
          return false
        }

        let formData = new FormData();
        formData.append("file", file);
        formData.append("multipartFile", file.raw);

        Ajax.post(
          "/media/public/file/uploadExt", 
          formData, {
          headers: { "Content-Type": "multipart/form-data" },
          onUploadProgress: (progressEvent) => {
            let num = progressEvent.loaded / progressEvent.total * 100 | 0;
            this.amount = num
          }
        })
          .then((res) => {
            if (res.code === "0000") {
              let reg = /\.([0-9a-z]+)(?:[\?#]|$)/i;
              let fileFormat = res.data.filePath.match(reg)[1];
              this.file = `${process.env.VUE_APP_fileBasePath}${res.data.filePath}`
              this.base64Callback(this.file)
              this.showmanu = false
            }
            if (res.code === "9999") {
              this.handleSnack("Maximum upload size exceeded")
            }
          })
          .catch((err) => {
            console.log(err)
          });
      }
    },

    showimgbth() {
      this.showmanu = !this.showmanu
    },

    getHeight(){
      const h = document.documentElement.clientHeight
      this.h = h - 190;
    },

  },
};
</script>
<style lang='less' scoped>
.ui-img-upload {
  position: relative;
  width: 200px;
  height: 100px;
  background: #F4F4F4;
  border: 1px solid #707070;
  box-sizing: initial;
  transition: ease .2s;

  img {
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -15px 0 0 -15px;
  }

  .img {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }

  &:hover {
    border-color: #1A73E8;
  }

  .create-dropdown-ui {
    position: absolute;
    right: -220px;
    top: 50%;
    margin-top: -40px;
    width: 202px;

    .create-dropdown-bg {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 101;
      overflow: hidden;
    }

    .create-dropdown-list {
      position: relative;
      z-index: 102;
      width: 202px;
      background: #fff;
      box-shadow: 0px 3px 3px #0000004D;
      border-radius: 4px;

      .create-dropdown-list-inner {
        padding: 8px 0;

        .create-class-list {
          padding: 0;

          li {
            cursor: pointer;
            text-align: center;
            line-height: 32px;
            font-size: 14px;
            font-family: Roboto;

            &.active,
            &:hover {
              background-color: #eee;
            }
          }
        }
      }
    }
  }

}

.ui-img-upload-wrapper {

  .el-button--primary {
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    border: 0;
    background: #fff;
    cursor: pointer;
    text-align: center;
    line-height: 32px;
    font-size: 14px;
    font-family: Roboto;
    color: #606367;
    transition: ease-out 0s;

    &:hover {
      background: #eee;
    }
  }
}

.moments-photo-inner {
  position: fixed;
  top: 64px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 998;
  background: #fff;
  overflow: hidden;

  .moments-photo-main {
    margin: auto auto auto 80px;
    background: #fff;
    
    .albums-list,
    .img-list {
      padding: 0 80px 82px 0;
      height: 500px;
      overflow-y: auto;
    }
  }

  .close-btn {
    position: absolute;
    left: 24px;
    top: 24px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
}

.optbar {
  z-index: 101;
  text-align: left;
} 
</style>