<template>
  <div>
    <div class="drop-box-overlay" v-if="droped" @click="droped=false"></div>
    <el-button type="text" class="search-btn" @click="searching=true" v-if="!searching">
      <img class="bar-search" :src="require('@/assets/images/bar_search_.png')" />
    </el-button>
    <template v-else>
      
      <div class="search-bar">
        <span class="close-btn" @click="searching=false"><i class="el-icon-close"></i></span>
        <div class="box">
          <label for="keyword">
            <div class="bar-search-icon" @click="search('keyword')">
              <img class="normal-icon" :src="require('@/assets/images/bar_search_.png')" />
              <img class="focus-icon" :src="require('@/assets/images/bar_search_1.png')" />
            </div>
          </label>
          <input type="text" class="input-x" placeholder="Search" v-model="keyword" id="keyword" v-mtfocus @keyup.enter="search('keyword')">
          <el-button type="text" icon="el-icon-caret-bottom" class="drop-btn" @click="openDrop"></el-button>
        </div>
        
        <div class="drop-box" v-if="droped">
          <div class="area">
            <div class="line">
              <div class="tit">Status</div>
             
                <!-- <md-select v-model="status" name="status" id="status">
                  <md-option :value="3">Any</md-option>
                  <md-option :value="0">Uploaded</md-option>
                  <md-option :value="1">Pending</md-option>
                  <md-option :value="2">Shared</md-option>
                </md-select> -->
                <baseGGSelect
                  v-model="status"
                  :inline="true"
                  placeholder=""
                >
                  <md-option :value="3">Any</md-option>
                  <md-option :value="0">Uploaded</md-option>
                  <md-option :value="1">Pending</md-option>
                  <md-option :value="2">Shared</md-option>
                </baseGGSelect>
              
            </div>
            <div class="line">
              <div class="tit">&nbsp;</div>
              <md-checkbox v-model="favourite"  class="md-primary" :true-value="1" :false-value="0">Favourite</md-checkbox>
            </div>
          </div>
          <div class="hr"></div>
          <div class="area">
            <div class="line">
              <div class="tit">Students</div>
              <md-field md-inline>
                <label>Enter a name or email address</label>
                <md-input v-model="students"></md-input>
              </md-field>
              <!-- <student-selecter :defaultValue="students" :options="studentsOptions" @onCheck="handleStudentCheck"/> -->
            </div>
            <div class="line">
              <div class="tit">Tags</div>
              <tag-selecter :options="tagsOptions" @onCheck="handleTagCheck"/>
            </div>
            <div class="line">
              <div class="tit">Note</div>
              <md-field md-inline>
                <label>Enter words found in the note</label>
                <md-input v-model="note"></md-input>
              </md-field>
            </div>
            <div class="line clearfix">
              <div class="tit">Date uploaded</div>
              <md-datepicker class="datepicker" v-model="beginDate"  md-immediately  :md-disabled-dates="(date) =>{
                if(!endDate) {
                  return false
                }
                return date.getTime()>endDate.getTime()
              }"> 
                <img src="@/assets/images/icons/arrow_drop.svg" class="calendar-arrow" />
                <label>From date</label>
              </md-datepicker>
              <span class="datepickerspan">to</span>
              <md-datepicker class="datepicker" v-model="endDate"  md-immediately :md-disabled-dates="(date) =>{
                if(!beginDate) {
                  return false
                }
                return date.getTime()<beginDate.getTime()
              }">
                <img src="@/assets/images/icons/arrow_drop.svg" class="calendar-arrow" />
                <label>To date</label>
              </md-datepicker>
            </div>
          </div>
          <div class="hr"></div>
          <div class="btns">
            <baseButton class="cancel-btn" @click="droped=false">Cancel</baseButton>
            <baseButton class="base-button primary" @click="search">Search</baseButton>
            <!-- <el-button type="text" class="cancel" @click="droped=false">cancel</el-button>
            <el-button type="text" @click="search">Search</el-button> -->
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>

import moment from "moment";
import TagSelecter from "./detail/modules/tagSelecter";
import StudentSelecter from "./detail/modules/studentSelecter";
import { mapState } from "vuex";
import { Ajax } from "@/common";
export default {
  components: {TagSelecter,StudentSelecter},
  props:{
    classId:{
      default: '',
      type: String
    }
  },
  data() {
    return {
      searching:false,
      droped:false,
      keyword:'',
      status: 3, //分享状态（0：normal 1：pending 2：shared 3：全部）
      favourite: 0,
      // students:[],
      students:'',
      studentsOptions:[],
      tagList:[],
      note:'',
      beginDate:null,
      endDate:null,
      tagsOptions:[
          
        ]
    };
  },
  mounted(){
    // this.fetchStudents()
    this.fetchTags()
  },
  directives: {
    'mtfocus' (el, binding, vnode) {
    let mtinput = el
    mtinput.onfocus = function () {
      mtinput.parentNode.className = 'box focus'
    }
    mtinput.onblur = function () {
      mtinput.parentNode.className = 'box'
    }
    } 
  },
   computed: {
    ...mapState({
      user: (state) => state.user
    }),
  },
  watch:{
    
  },
  methods: {
    initSearch(_keyword){
      this.keyword = _keyword || ''
      this.searching=true
    },
    openDrop(){
      this.droped = true
    },
    handleTagCheck(val){
      this.tagList = [...val]
    },
    handleStudentCheck(val){
      this.students = [...val]
    },
    async fetchTags(){
      var res = await Ajax.post("/usermanage/setting/getList", {
        "dataLevel": "moments",
        "dataType": "learingDomain",
        "userId": this.user.userId

      })
      if(res.code=='0000'){
        this.tagsOptions = res.data
      }
      
    },
    // async fetchStudents(){
    //   var res = await Ajax.get("/usermanage/student/getStudentByClassId", {
    //     classId:this.classId,
    //   })
    //   if(res.code=='0000'){
    //     this.studentsOptions = res.data.map(o=>{
    //       return {
    //         id:o.id,
    //         avatar:o.photo,
    //         name:o.firstName+' '+o.lastName
    //       }
    //     })
    //   }
      
    // },
    search(_type){
      var _query = {
        classId: this.classId,
        status:this.status.length==0?3:this.status,
        favourite:this.favourite,
        studentList:this.students,
        tagList:this.tagList.map(o=>o.id).join(','),
        note:this.note,
        beginDate:this.beginDate?moment(this.beginDate).format('YYYY-MM-DD'):'',
        endDate:this.endDate?moment(this.endDate).format('YYYY-MM-DD'):'',
      }
      if(_type=='keyword'){
        if(this.keyword.trim().length==0){
          return
        }
        _query = {
          q :this.keyword,
          classId: this.classId
        }
      }
      console.log(_query)
      this.$router.push({name:'momentsSearch',query:_query})
      this.droped = false
    }
  },
};
</script>
<style lang='less' scoped>
.search-btn{
  position: absolute;
  right:0;
  top:50%;
  transform: translate(0,-50%);    
  font-size: 18px;
  color:#fff;
}
.drop-box-overlay{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 4000;
  overflow: hidden;
}
.search-bar{
  font-family: Roboto;
  z-index: 4001;
  height:48px;
  position: absolute;
  left:0;
  top:50%;
  transform: translate(0,-50%);
  background: #1a73e8;
  
  .close-btn{
    width: 48px;
    height: 48px;
    border-radius: 100%;
    line-height: 48px;
    text-align: center;
    color:#fff;
    float:left;
    cursor: pointer;

    .el-icon-close{
      font-size: 22px;
      vertical-align: middle;
    }

    &:hover {
      background: #ffffff33;
    }
  }
  .box{
    margin-left:8px;
    margin-top:2px;
    float:left;
    height:44px;
    line-height:44px;
    border-radius: 4px;
    background: #4B8EE7;
    font-size: 0;
    .focus-icon {
      display: none;
    }

    &.focus {
      background: #fff;

      .bar-search-icon:hover {
        background: #eee;
      }

      .drop-btn:hover {
        background: #eee;
      }

      input {
        color: #20212480;

        &:-internal-autofill-previewed,
        &:-internal-autofill-selected {
          -webkit-text-fill-color: #20212480 !important;
          transition: background-color 5000s ease-in-out 0s !important;
        }
      }

      .focus-icon {
        display: inline-block;
      }
      
      .normal-icon {
        display: none;
      }

      .drop-btn {
        color: #606367 !important;
      }
    }

    label{
      display:inline-block;
      font-size: 18px;
      margin-left:10px;
    }
    i{
      vertical-align: middle;
    }
    input{
      background:transparent;
      display:inline-block;
      border:0 none;
      outline:none;
      color:#fff;
      margin-left:10px;
      width: 630px;
      height: 43px;
      font-size: 20px;
      font-family: 'Open Sans';

      &:-internal-autofill-previewed,
      &:-internal-autofill-selected {
        -webkit-text-fill-color: #fff !important;
        transition: background-color 5000s ease-in-out 0s !important;
      }

      &::placeholder {
        color: #fff;
        opacity: .5;
      }
    }
    .drop-btn{
      color:#fff;
      padding:10px;
      margin-right:8px;
      border-radius: 50%;
      vertical-align: middle;
      margin-top: -13px;

      &:hover {
        background: #ffffff33;
      }
    }
  }
 
  .drop-box{
    position: absolute;
    left:56px;
    top:60px;
    background:#fff;
    border-radius: 8px;
    width: 648px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
    font-size:16px;
    color:#202124;
    line-height: 30px;
    .area{
      padding:10px 24px 0;
    }
    .hr{
      height:0;
      border-bottom:1px solid #E0E0E0;
    }
    .line{
      position: relative;
      min-height: 60px;
      padding-left: 154px;
      .tit{
        position: absolute;
        left:0;
        top:0;
        line-height: 60px;
        color:#202124;
        font-size:14px;
      }
      .datepickerspan{
        float:left;
        line-height: 68px;
        margin:0 15px;
      }
    }
    .md-field>.md-icon~label{
        left:0;
      }
    .datepicker{
      float:left;
      width:160px;

      .md-date-icon {
        display: none;
      }
      /deep/ .md-input{
            margin-left: 0;
            text-align: left;
      }
      /deep/ .md-date-icon{
        display: none;
      }
      .calendar-arrow {
          position: absolute;
          right: 0;
          top: 21px;
          width: 24px;
          height: 24px;
      }
      
    }
    .btns{
      text-align: right;
      padding:10px 20px;
      .el-button{
        margin-left:30px;
        &.cancel{
          color:#5F6368;
        }
      }
      .base-button{
        height: 36px;
        line-height: 36px;
        padding: 0 12px;
      }
    }
  }
}
.student-selecter{
  min-height: 49px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  /deep/ .edit-btn{
    top:10px;
  }
}


.search-btn {
  width: 48px;
  height: 48px;
  vertical-align: middle;
  display: inherit;
  text-align: center;
  border-radius: 50%;

  img {
    width: 24px;
    height: 24px;
    vertical-align: middle;
  }

  &:hover {
    background: #ffffff33;
  }
}
.tag-selecter{
  min-height: 49px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42)!important;
  /deep/ .edit-btn{
    top:10px;
  }
}
.bar-search-icon {
  width: 36px;
  height: 36px;
  line-height: 34px;
  text-align: center;
  vertical-align: middle;
  display: inherit;
  border-radius: 50%;
  margin-top: -4px;

  img {
    display: inherit;
    width: 24px;
    height: 24px;
    vertical-align: middle;
  }

  &:hover {
    background: #ffffff33;
  }
}
</style>