
<template>
  <div class="tag-selecter" :class="{'readOnly':readOnly}" @click="openSelecter">
    <div class="value" v-for="item in selected" :key="item.id">
     
      <span class="name">{{item.value}}</span>
      <el-button type="text" icon="el-icon-error" v-if="!readOnly"  @click="checkItem(item)"></el-button>
    </div>
    <el-button class="drop-btn" type="text" v-if="!readOnly" icon="el-icon-caret-bottom"></el-button>
    <div class="selecter-box" v-if="selecting">
      
        <div class="options">
          
          <div class="item" v-for="item in options" :key="item.id"  @click="checkItem(item)">
            <span class="checkbox" :class="{'checked':selected.find(o=>o.id==item.id)}">
              <i class="el-icon-check"></i>
            </span>
            <div class="wd">
              <div class="wd-main">
                <span class="name">{{item.value}}</span>
              </div>
            </div>
          </div>
        </div>
      
    </div>
  </div>
    
</template>

<script>

import SvgIcon from "@/components/SvgIcon";
export default {
  components: {SvgIcon},
  props: {
    defaultValue:{
      type:Array,
      default:()=>[]
    },
    readOnly:{
      type: Boolean,
      default: false,
    },
    options:{
      type:Array,
      default:()=>[]
    },
  },
  data() {
    return {
      selected:[], // 当前值
      selecting:false,
    }
  },
  computed:{
    
  },
  mounted(){
    document.addEventListener('click',e => { 
     if(!this.$el.contains(e.target)){
        this.selecting = false//点击其他区域关闭
      }
     })
    this.init()
  },
  watch:{
    defaultValue(){
      this.init()
    }
  },
  methods: {
    init(){
      this.selected = [...this.defaultValue]
    },
    openSelecter(){
      if(this.readOnly){
        return
      }
      this.selecting = true
    },
    checkItem(item) {
      const _i = this.selected.findIndex(o=>o.id==item.id)
      if(_i>=0){
        // 取消选中
        this.selected.splice(_i,1)
      }else{
        // 选中
        this.selected.push(item)
      }
      this.$emit('onCheck',this.selected)
    },
    
  },
};
</script>
<style lang='less' scoped>
.tag-selecter{
  padding: 0 34px 8px 0;
  position: relative;
  border-bottom:1px solid rgba(0,0,0,0.42);
  min-height: 49px;
  cursor: pointer;
  &.tag-selecter{
    border-bottom:0px none;
  }
  .drop-btn{
    position: absolute;
    right:0;
    top:50%;
    transform: translate(0,-50%);
    padding: 0;
    color:#333;
  }
  .value{
    display: inline-block;
    height:32px;
    line-height: 30px;
    padding:0 8px;
    border:1px solid #E0E0E0;
    border-radius: 15px;
    font-size: 0;
    margin: 8px 8px 0 0;
    cursor:default;
   
    .name{
      font-size: 14px;
      font-family: Roboto;
      font-weight: 400;
      color:#202124;
      vertical-align: middle;
    }
    .el-button{
      padding:0;
      vertical-align: middle;
      color:#5F6368;
      font-size: 20px;
      margin-left:8px;
    }
  }
  
  .selecter-box{
    position: absolute;
    z-index: 999;
    left:0;
    top:calc(100% + 1px);
    width:100%;
    background:#FFFFFF;
    border-radius: 4px;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.3);
    
      .values{
        padding: 4px 0;
        border-bottom: 1px solid #1A73E8;
        min-height: 41px;
      }
      .options{
        padding:8px 0;
        max-height: 176px;
        overflow-y: auto;
        .tit{
          line-height: 16px;
          color:#5F6368;
          font-size: 12px;
        }
        .item{
          height:32px;
          line-height: 32px;
          padding:0 20px;
          font-size: 0;
          cursor:pointer;
          .checkbox{
            height:20px;
            width:20px;
            line-height: 20px;
            text-align: center;
            display: inline-block;
            border:1px solid #444;
            border-radius: 1px;
            vertical-align: middle;
            margin-right: 18px;
            .el-icon-check{
              font-size: 16px;
              color:#fff;
              display:  none;
            }
            &.checked{
              border-color:#1A73E8;
              background:#1A73E8;
              .el-icon-check{
                display: block;
              }
            }
          }
          
          .wd{
            vertical-align: middle;
            display: inline-block;
          }
          .wd-main{
            display: flex;
            flex-direction: column;
          }
          .name{
            font-size:14px;
            color:#202124;
            line-height: 20px;
          }
          
        }
      }
    
  }
}

</style>