<template>
<span>
  <gg-Dialog
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    :visible.sync="selectUserSw"
    width="580px"
    height="576px"
    class="select-user-popup"
    ref="studentSelecter"
  >
    <div class="title" slot="title">
      Create portfolio
    </div>
    <div class="ct" v-loading="loading">
      <div class="ptop">
        <div class="edit-tit" v-if="selectedTemp.length <= 0">
          <gg-input
            class="edit-tit-input"
            v-model="editTitle"
            placeholder="Recipents *"
            :error="errorObj['formTit']"
            @keyup="validate"
          />
        </div>
        <div class="values" v-else>
          <div class="recipents">Recipents *</div>
          <div class="value" v-for="item in selectedTemp" :key="item.id">
            <span class="avatar" :style="'background-image:url('+item.photo+')'" v-if="item.photo"></span>
            <div class="no" v-else>
              {{ item.firstName ? item.firstName.substring(0, 1).toUpperCase() : '-' }}{{ item.lastName ? item.lastName.substring(0, 1).toUpperCase() : '-' }}
            </div>
            <span class="name">{{ item.firstName + ' ' + item.lastName }}</span>
            <el-button type="text" icon="el-icon-error" @click="checkItemDel"></el-button>
          </div>
        </div>
      </div>

      <div class="options-inner">
        <template v-if="selectedTemp.length <= 0">
          <div class="options" v-if="editTitle === ''">
            <div class="list-inner">
              <div class="item" v-for="item in options" :key="item.id"  @click="checkItem(item)">
              <span class="avatar" :style="'background-image:url('+item.photo+')'" v-if="item.photo"></span>
                <div class="no" v-else>
                  {{ item.firstName ? item.firstName.substring(0, 1).toUpperCase() : '-' }}{{ item.lastName ? item.lastName.substring(0, 1).toUpperCase() : '-' }}
                </div>
                <div class="name">
                  {{ item.firstName + ' ' + item.lastName }}
                </div>
              </div>
            </div>
          </div>

          <div class="options" v-else>
            <div class="ptit">Search results</div>
            <div class="list-inner">
              <template v-if="searchList.length <= 0">
                <div class="item item-results">
                  <div class="no no-results">
                    <img class="arrow-drop" src="@/assets/images/icons/no_results.svg" />
                  </div>
                  <div class="name">No results</div>
                </div>
              </template>
              <template v-else>
                <div class="item" v-for="item in searchList" :key="item.id" @click="checkItem(item)">
                  <span class="avatar" :style="'background-image:url('+rootPath+item.photo+')'" v-if="!rootPath+item.photo"></span>
                  <div class="no" v-else>
                    {{ item.firstName ? item.firstName.substring(0, 1).toUpperCase() : '-' }}{{ item.lastName ? item.lastName.substring(0, 1).toUpperCase() : '-' }}
                  </div>
                  <div class="name">
                    {{ item.firstName + ' ' + item.lastName }}
                  </div>
                </div>
              </template>
            </div>
          </div>
        </template>
      </div>
      
      <div class="tips">* indicates a required field</div>
    </div>
    <span slot="footer" class="dialog-footer">
      <gg-cancel-button @click="close">Cancel</gg-cancel-button>
      <gg-submit-button
      :disabled="selectedTemp.length==0"
      @click="subData"
        >Create</gg-submit-button
      >
    </span>
  </gg-Dialog>
</span>
</template>
<script>
import moment from 'moment';
import { Ajax } from "@/common";
import SvgIcon from "@/components/SvgIcon";
export default {
  components: {SvgIcon},
  props:{
    selectUserSw:{
      default: false,
      type:Boolean
    },
    classId:{
      default:'',
      type:String
    }
  },
  data(){
    return {
      typename: '',
      classname: '',
      classid: '',
      searchList: [],
      errorObj: {
        formTit: {
          show: false,
          message: "Character limit of 40",
        }
      },
      editTitle: '',
      rootPath:process.env.VUE_APP_fileBasePath,
      loading:false,
      options:[],
      selected:[], // 当前值
      selectedTemp:[], // 当前操作中的值，未确定
      snackbarData: {
        visible: false,
        content: "",
      },
    }
  },
  watch: {
    selectUserSw: function() {
      this.editTitle= ''
      this.searchList = []
      this.selected = []
      this.selectedTemp = []
    }
  },
  created() {
    this.classname = sessionStorage.getItem("journalClassName")
    this.classid = this.$route.params.id || ''
    this.typename = this.$route.query.typename || ''
    this.fetchAlbums()
  },
  methods:{

    moment,

    validate(val) {
      if(this.editTitle.length > 40){
        this.errorObj['formTit'].show = true
      }else{
        this.errorObj['formTit'].show = false
      }
      
      let list = this.options, 
      temp = [], 
      v = this.editTitle

      for(let item of list) {
        let name = item.firstName + ' ' + item.lastName
        if(name.toLowerCase().indexOf(v.toLowerCase()) > -1) {
          temp.push(item)
        }
      }
      this.searchList = temp
    },

    close(){
      this.$emit('close')
    },

    checkItemDel(){
      this.selectedTemp = []
    },

    checkItem(item) {
      this.selectedTemp.push(item)
    },

    fetchAlbums(){
      this.loading = true;
      Ajax.get("/usermanage/student/getStudentByClassId", {
        classId: this.classId,
      })
		  .then((res) => {
		    this.loading = false;
        if (res.code == "0000") {
          this.options = res.data
        }
		  })
		  .catch(() => {
		    this.loading = false;
		  });
    },

    subData(){
      sessionStorage.setItem("journalCreatePortfolioUser", JSON.stringify(this.selectedTemp[0]))
      this.$router.push({ name: 'journalCreate', query: { type: 1, classname: this.classname, classid: this.classid, time: this.moment().valueOf() }})
    }
  }
}
</script>
<style lang="less" scoped>
.tips {
  padding: 0 20px;
  margin-top: 5px;
  font-family: Roboto;
  font-size: 12px;
  color: #B4B4B4;
}
.svg-icon{
  height:20px;
}

.title {
  font-family: 'opensans-semibold';
  color: #202124;
  font-size: 16px;
}

.value{
    margin-right: 10px;
    display: inline-block;
    padding: 2px 3px;
    background: #fff;
    border: 1px solid #E0E0E0;
    border-radius: 18px;
    cursor:default;

    .avatar{
      display: inline-block;
      vertical-align: middle;
      width:24px;
      height:24px;
      border-radius: 50%;
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color:#b4b4b4;
      vertical-align: middle;
      margin: 0 10px 0 0;
    }
    .no {
      display: inline-block;
      vertical-align: middle;
      margin: 0 10px 0 0;
      width:24px;
      height:24px;
      border-radius: 50%;
      background-color:#b4b4b4;
      line-height: 24px;
      color: #fff;
      text-align: center;
      font-size: 12px;
      font-family: 'opensans-semibold';
    }
    .name{
      overflow: hidden;
      font-family: Roboto;
      font-size: 14px;
      color: #202124;
      vertical-align: middle;
    }
    .el-button{
      padding:0;
      vertical-align: middle;
      color:#5F6368;
      font-size: 24px;
      margin-left:8px;
    }
  }
  .ct{
      .ptit {
        padding: 0 20px;
        margin: 30px 0 10px 0;
        font-size: 14px;
        font-family: Roboto;
        color: #202124;
      }
      .values{
        padding: 4px 0;
        border-bottom: 1px solid #1A73E8;
        min-height: 41px;
        transition: ease .1s;
      }
      .ptop {
        padding: 0 20px;
        margin-bottom:16px;
      }
      .recipents {
        margin-top: -2px;
        font-size: 12px;
        color: #1A73E8;
      }

      .options-inner {
        height: 333px;
      }

      .options{

        .list-inner {
          height: 333px;
          overflow-y: auto;

          &::-webkit-scrollbar {
            width: 4px;
            border-radius: 4px;
          }

          &::-webkit-scrollbar-track {
            background-color: #fff;
          }
        }
        
        .tit{
          line-height: 16px;
          color:#5F6368;
          font-size: 12px;
        }
        .item{
          padding: 8px 20px;
          height: 52px;
          line-height: 52px;
          font-size: 0;
          cursor:pointer;

          &.item-results {
            cursor: default;

            &:hover {
              background: #fff;
            }
          }

          &:hover {
            background: #eee;
          }

          .checkbox{
            height:20px;
            width:20px;
            line-height: 20px;
            text-align: center;
            display: inline-block;
            border:1px solid #444;
            border-radius: 1px;
            vertical-align: middle;
            margin-right: 18px;
            .el-icon-check{
              font-size: 16px;
              color:#fff;
              display:  none;
            }
            &.checked{
              border-color:#1A73E8;
              background:#1A73E8;
              .el-icon-check{
                display: block;
              }
            }
          }
           &.disabled{
            .checkbox{
              &.checked{
                border-color:#444;
                background:#444;
                .el-icon-check{
                  display: block;
                }
              }
            }
          }
          .avatar{
            float: left;
            width:36px;
            height:36px;
            border-radius: 50%;
            background-position: center center;
            background-size: cover;
            background-repeat: no-repeat;
            background-color:#b4b4b4;
            vertical-align: middle;
            margin: 0 20px 0 0;
          }
          .no {
            float: left;
            margin: 0 20px 0 0;
            width:36px;
            height:36px;
            border-radius: 50%;
            background-color:#b4b4b4;
            line-height: 36px;
            color: #fff;
            text-align: center;
            font-size: 14px;
            font-family: 'opensans-semibold';

            &.no-results {
              background: #e0e0e0;

              img {
                margin-top: -2px;
                vertical-align: middle;
              }
            }
          }
          .name {
            overflow: hidden;
            line-height: 36px;
            font-family: Roboto;
            font-size: 14px;
            color: #202124;
            width: 450px;
            overflow: hidden;
            text-overflow:ellipsis;
            white-space: nowrap;
          }
        }
      }
    }

.md-field {
  margin: 0;
  min-height: auto;
}
</style>