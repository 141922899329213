<!-- class students list -->
<template>
  <div class="content has_breadcrumb">
    <base-breadcrumb
      :data="[
        {
          routeName: 'locations',
          name: user.schoolName,
        },
        {
          routeName: 'moments',
          name: 'Moments',
        },
        {
          name: classname,
        },
      ]"
      slot="breadcrumb"
    />
    <GgPage
      pageType="2"
      title="Moments"
      @forward="forward"
      @next="next"
    >
      <div slot="topAppBar" class="topAppBar">
        <!-- <month-switch @change="dayChange" :defaultVal="defSwitchVal"></month-switch> -->
        <search :classId="$route.params.id"></search>
      </div>
      
      <template v-slot:left>
        <div class="mar-l-b">
          <upload-btn :classId="$route.params.id"><gg-create-button label="Upload" /></upload-btn>
        </div>
        <class-side-menu activeIndex="momentClassTrash" />
      </template>
      <GgPageHeader :title="classname" class="mar-b-0 trash-page-header"  :menuData="menuData" @menuCallback="handleMenuClick" />
      <div class="optbar" v-show="checkedList.length>0">
        <el-button type="text" @click="clearCheck"><i class="el-icon-close"/></el-button>
        <span class="count">{{checkedList.length}} Selected</span>
        <div class="btns">
          <baseButton @click="handleDelete()" :disabled="!hasPermission('Manage media_Full')" type="primary">Delete permanently</baseButton>
          <baseButton @click="handleRestore()" :disabled="!hasPermission('Manage media_Full')" type="primary">Restore</baseButton>
        </div>
      </div>
      <p class="trash-tip">Items will be permanently deleted after 30 days from Trash.</p>
      <image-list :classId="$route.params.id" type="trash" :list="imgList" :loading="loading" :showDate="false"  @checkChange="onCheckChange" ref="imageList"/>
    </GgPage>
    <Snackbar
      :visible.sync="snackbarData.visible"
      :content="snackbarData.content"
    />
  </div>
</template>

<script>
import { Ajax } from "@/common";
import moment from 'moment';
import { mapState } from "vuex";
import uploadBtn from './../modules/uploadBtn'
import SvgIcon from "@/components/SvgIcon";
import classSideMenu from './../modules/classSideMenu'
import monthSwitch from './../modules/monthSwitch'
import search from './../modules/search'
import imageList from './../modules/imageList'
import classMixin from './../mixin/classMixin'
import { hasPermission } from './../utils/utils'
// const _menuData = [
//         {
//           label:'Empty trash',
//           key:'empty'
//         }
//       ]
export default {
  components: {SvgIcon,classSideMenu,monthSwitch,search,imageList,uploadBtn},
  data() {
    return {
      boolean: true,
      loading:false,
      classname:this.$store.state.moments.curClassName,
      defSwitchVal:0,
      date:this.$route.query.time?moment(parseInt(this.$route.query.time)).valueOf():moment().valueOf(),
      imgList: [
       
      ],
      menuData:[
      ],
      checkedList:[],
      snackbarData: {
        visible: false,
        content: "",
      },
    };
  },
  mixins: [classMixin],
  computed: {
    ...mapState({
      user: (state) => state.user
    }),
  },
  created() {
    this.initMonthSwitchVal()
    this.fetchData()
  },
  methods: {
    hasPermission,
    initMonthSwitchVal(){
      var _def = moment().valueOf()
      if(this.$route.query.time&&this.$route.query.time.length==13){
        _def = parseInt(this.$route.query.time)
      }
      this.date = _def
      this.defSwitchVal = _def
    },
    dayChange(val){
      this.date = val
      this.fetchData()
    },
    async fetchData(){
      this.fetchList()
      var resClass = await Ajax.get("/usermanage/class/selectByIdV1", {
        id: this.$route.params.id,
      })
      if(resClass.code=='0000'){
        this.classname = resClass.data.className
        this.$store.commit("SET_CURCLASSNAME", this.classname);
      }
    },
    handleDelete(){
      this.$confirm(
        "Once photo is permanently deleted, it cannot be undone.",
        'Permanently delete photo?',
        {
          cancelButtonText: "Cancel",
          confirmButtonText: "Delete",
          cancelButtonClass: "gg_cancel_button",
          confirmButtonClass: "gg_submit_button",
          customClass: "gg_confirm",
          showClose: false
        }
      )
        .then(() => {
          Ajax.post("/moments/mediaRecycle/deleteBatch", this.checkedList).then(res=>{
            if(res.code=='0000'){
              this.$refs.imageList.clearCheck()
              this.handleSnack('Photo has been deleted')
              this.fetchList()
              
            }else{

            }
          })
        })
        .catch(() => {});
    },
    handleRestore(){
      this.$confirm(
        "Once restored, it will move back to photos.",
        'Restore photo?',
        {
          cancelButtonText: "Cancel",
          confirmButtonText: "Restore",
          cancelButtonClass: "gg_cancel_button",
          confirmButtonClass: "gg_submit_button",
          customClass: "gg_confirm",
          showClose: false
        }
      )
        .then(() => {
          const _arr = this.checkedList.map(o=>o.mediaId)
          Ajax.post("/moments/mediaRecycle/restore",_arr).then(res=>{
            if(res.code=='0000'){
              this.$refs.imageList.clearCheck()
              this.fetchList()
            }else{

            }
          })
        })
        .catch(() => {});
    },
    async fetchList(){
      if(this.date>new Date().getTime()){
        this.imgList = []
        return
      }
      this.loading = true;
      var res = await Ajax.get("/moments/mediaRecycle/getMediaListV1", {
        classId: this.$route.params.id,
        // date: moment(this.date).startOf('day').utc().format('YYYY-MM-DD HH:mm:ss'),
        size:9999
      })
      this.loading = false;
      if(res.code=='0000'){
       
        this.imgList = res.data.records
        // this.imgList = res.data
        this.menuData = []
        if(this.imgList.length>0&&this.hasPermission('Manage media_Full')){
          this.menuData=[
            {
              label:'Empty trash',
              key:'empty'
            }
          ]
        }
      }
    },
    handleMenuClick(item) {
     
      if(item.key=='empty'){
        this.$confirm(
          "Once empty trash, all photos will be permanently deleted and cannot be undone.",
          'Empty trash?',
          {
            cancelButtonText: "Cancel",
            confirmButtonText: "Empty",
            cancelButtonClass: "gg_cancel_button",
            confirmButtonClass: "gg_submit_button",
            customClass: "gg_confirm",
            showClose: false
          }
        )
          .then(() => {
            Ajax.get("/moments/mediaRecycle/emptyTrash", {
            }).then(res=>{
              if(res.code=='0000'){
                // this.handleSnack()
                this.fetchList()
              }else{

              }
            })
          })
          .catch(() => {});
      }
    },
    onCheckChange(val){
      this.checkedList = val
    },
    clearCheck(){
      this.$refs.imageList.clearCheck()
    },
    forward(val) {
      console.log(val);
    },
    next(val) {
      console.log(val);
    },
    handleSnack(content) {
      this.snackbarData.content = content;
      this.snackbarData.visible = true;
    },
  },
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
/deep/ .ggPage-main{
  position: relative;
}
.ggPageTitle{
  /deep/.ggPageTitle-left .titleInfo .title{
    font-family:'opensans-semibold';
    color:#5f6368
  }
}
.content.has_breadcrumb .edoovo-breadcrumb {
    z-index: 10;
}
.trash-tip{
  margin:20px 0 12px;
  color:#5F6368;
  font-size:14px;
  font-family: Roboto;
}
 .content.has_breadcrumb{
    /deep/ .ggPage-main-center-content{
      height:auto;
    }
  }
.trash-page-header{
  /deep/ .menu_bar{
    height:40px;
    width: 40px;
    line-height: 25px;
    font-size: 20px;
    .iconfont{
      font-size:20px;
    }
  }
}

</style>