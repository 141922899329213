<!-- class students list -->
<template>
  <div>
    <div class="form-item">
      <div class="tit">Students</div>
      <student-selecter v-if="info.studentList" :defaultValue="info.studentList" :options="[]" readOnly/>
    </div>
    <div class="form-item">
      <div class="tit">Messages</div>
      <div class="note">
       
        
        <!-- <md-field :class="{
          'md-invalid': !captionCheck
        }" v-if="info.shareStatus == 0 && hasPermission('Share media_Create')">
          <label>Caption</label>
          <md-textarea v-model="caption" md-autogrow></md-textarea>
          <span class="md-error">Character limit of 80</span>
        </md-field>
        <md-field :class="{
          'md-invalid': !captionCheck
        }" v-else-if="info.shareStatus == 1 && hasPermission('Share media_Create')">
          <label>Caption</label>
          <md-textarea v-model="caption" md-autogrow></md-textarea>
          <span class="md-error">Character limit of 80</span>
        </md-field>
        <template v-else> -->
          <div class="subtit">Caption</div>
          <span class="val">
            {{info.caption}}
          </span>
        <!-- </template> -->
          
      </div>
    </div>
    
    <div class="form-item" v-if="info.shareStatus>0">
      <div class="tit">Submission</div>
      <div class="detail">
        <div class="item">
          <svg-icon iconClass="info-date"></svg-icon>
          <span>{{info.shareSubmissionTime}}</span>
        </div>
        <div class="item">
          <svg-icon iconClass="info-person"></svg-icon>
          <span class="avatar" :style="'background-image:url('+info.userPhoto+')'"></span>
          <span>{{info.userName}}</span>
        </div>
      </div>
    </div>
    <div class="form-item" v-if="info.shareStatus==2">
      <div class="tit">Approval</div>
      <div class="detail">
        <div class="item">
          <svg-icon iconClass="info-date"></svg-icon>
          <span>{{moment(info.approvalTime).format('DD/MM/YYYY,h:mm a')}}</span>
        </div>
        <div class="item">
          <svg-icon iconClass="info-person"></svg-icon>
          <span class="avatar" :style="'background-image:url('+info.approvalUserPhoto+')'"></span>
          <span>{{info.approvalUserName}}</span>
        </div>
      </div>
    </div>
    <div class="btns" v-if="info.shareStatus<=2">
      <el-button type="text" v-if="info.shareStatus==0&& hasPermission('Share media_Create')" @click="handleShare" :disabled="!captionCheck">Submit</el-button>
      <template  v-if="info.shareStatus==1">
        <template>
          <!-- <el-button type="text" class="reject" @click="handleReject">Reject</el-button> -->
          <el-button type="text" @click="handleApprove" v-if="hasPermission('Share media_Full')">Approve</el-button>
        </template>
        <template>
          <el-button type="text" @click="handleUnshare" class="reject" v-if="hasPermission('Share media_Edit')">Undo</el-button>
        </template>
      </template>
      <template  v-if="info.shareStatus==2 && moment()<moment(info.approvalTime).add(1,'hours') && hasPermission('Share media_Full')">
        <template>
          <el-button type="text" @click="handleRecall" class="reject">Recall</el-button>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import { Ajax } from "@/common";
import moment from 'moment';
import SvgIcon from "@/components/SvgIcon";
import StudentSelecter from "./studentSelecter";
import TagSelecter from "./tagSelecter";
import { hasPermission } from './../../../../../utils/utils.js';
import { mapState } from "vuex";
export default {
  components: {SvgIcon,StudentSelecter,TagSelecter},
  props: {
    classId:{
      default: '',
      type: String
    },
    item:{
      default: ()=>{},
      type: Object
    },
    info:{
      default: ()=>{},
      type: Object
    },
  },
  data() {
    return {
      caption:'',
      captiontimer: null,
      captionInited:false
    }
  },
  mounted(){
    
    this.caption = this.info.caption
    console.log(this.caption)
    this.$nextTick(()=>{
      this.captionInited = true
    })
    
  },
  computed:{
    captionCheck(){
      return !this.caption || this.caption.length<=80
    },
    ...mapState({
      user: (state) => state.user
    }),
  },
  watch:{
    caption(newVal,oldVal){
      if(this.info.shareStatus == 1 && this.captionCheck && this.captionInited){
        this.submitCaption()
      }
      
    }
  },
  methods: {
    moment,
    hasPermission,
    handleShare(){
      console.log(this.info)
      this.$confirm(
        `Once submitted, the photo will be sent for approval before being shared and viewed by parents. `,
        "Share photo?",
        {
          cancelButtonText: "Cancel",
          confirmButtonText: "Submit",
          cancelButtonClass: "gg_cancel_button",
          confirmButtonClass: "gg_submit_button",
          customClass: "gg_confirm",
          showClose: false,
        }
      )
        .then(() => {
          Ajax.post("/moments/share/share", {
            "caption": this.caption,
            "classId": this.classId,
            "mediaId": this.info.id,
            "fileDisplayName":this.info.fileDisplayName,
            "shareByTeacher": this.user.userId,
            "shareSubmissionTime": moment().format('DD/MM/YYYY, hh:mm A'),
            "studentIdList": this.info.studentList.map(o=>o.id),
            "tagList": this.info.tagList || []
          }).then((res) => {
            if (res.code === "0000") {
              this.$emit('ok',1)
            }
          });
        })
        .catch(() => {});
    },
    handleApprove(){
      this.$confirm(
        `Once approved, the photo will be shared and viewed by parents.  `,
        "Approve sharing?",
        {
          cancelButtonText: "Cancel",
          confirmButtonText: "Approve",
          cancelButtonClass: "gg_cancel_button",
          confirmButtonClass: "gg_submit_button",
          customClass: "gg_confirm",
          showClose: false,
        }
      )
        .then(() => {
          Ajax.post("/moments/share/approveBatch", {
              approvalUser:this.user.userId,
              batchNoList:[this.info.batchNo]
            }).then(res=>{
              if(res.code=='0000'){
                 this.$emit('ok',2)
              }else{

              }
            })
          
        })
        .catch(() => {});
    },
    handleReject(){
      this.$confirm(
        `Once rejected, the submission for sharing approval will be cancelled and parent will not be able to view the photo.  `,
        "Reject sharing?",
        {
          cancelButtonText: "Cancel",
          confirmButtonText: "Reject",
          cancelButtonClass: "gg_cancel_button",
          confirmButtonClass: "gg_submit_button",
          customClass: "gg_confirm",
          showClose: false,
        }
      )
        .then(() => {
          Ajax.post("/moments/share/rejectBatch", {
              approvalUser:this.user.userId,
              batchNoList:[this.info.batchNo]
            }).then(res=>{
              if(res.code=='0000'){
                 this.$emit('ok',0)
              }else{

              }
            })
          
        })
        .catch(() => {});
    },
    handleUnshare(){
      this.$confirm(
        `Once undone, the photo will change from pending to uploaded.`,
        "Undo submission?",
        {
          cancelButtonText: "Cancel",
          confirmButtonText: "Undo",
          cancelButtonClass: "gg_cancel_button",
          confirmButtonClass: "gg_submit_button",
          customClass: "gg_confirm",
          showClose: false,
        }
      )
        .then(() => {
          Ajax.post("/moments/share/unShareBatch", {
              batchNoList:[this.info.batchNo]
            }).then(res=>{
              if(res.code=='0000'){
                 this.$emit('ok',0)
              }else{

              }
            })
          
        })
        .catch(() => {});
    },
    handleRecall(){
      this.$confirm(
        `Once recalled, the photo will change from shared to uploaded and not be seen by parents.`,
        "Recall photo?",
        {
          cancelButtonText: "Cancel",
          confirmButtonText: "Recall",
          cancelButtonClass: "gg_cancel_button",
          confirmButtonClass: "gg_submit_button",
          customClass: "gg_confirm",
          showClose: false,
        }
      )
        .then(() => {
          Ajax.get("/moments/media/reCall", {
              mediaId:this.info.id
            }).then(res=>{
              if(res.code=='0000'){
                 this.$emit('ok',0)
              }else{

              }
            })
        })
        .catch(() => {});
    },
    submitCaption(){
      clearTimeout(this.captiontimer)
      this.captiontimer = setTimeout(() => {
        Ajax.post("/moments/share/updateCaption", {
          mediaId:this.info.id,
          caption:this.caption
        }).then(res=>{
          if(res.code=='0000'){
            // this.$emit('ok',this.info.shareStatus)
          }
        })
      }, 500); 
    },

  },
};
</script>
<style lang='less' scoped>

  
</style>