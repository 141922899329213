
// import { Ajax } from "@/common";
import bus from '../utils/bus'
import moment from 'moment'
export default {
  
 
  watch: {
    date() {
      this.fetchPendingData();
    },
    
  },
  methods: {
    // async fetchPendingData(){
    //   var res = await Ajax.get("/moments/share/getPendingList", {
    //     id: this.classId,
    //     date: this.date.format('YYYY-MM')
    //   })
    //   if(res.code=='0000'){
    //     console.log(99999,res)
    //   }
    // }
    fetchPendingData(){
      console.log('this.$route.params',this.$route.params)
      this.$store.dispatch("fetchPendingDataStudent", {
        classId: this.$route.params.cid,
        studentId: this.$route.params.id,
        date: moment(this.date)
      })
    }
  },
  created() {
    this.fetchPendingData()
    bus.$on('refreshImageList', this.fetchPendingData)  
  },
  mounted() {
    
  }
};
