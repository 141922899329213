<template>
<div>
  <div style="text-align: center" v-if="list.length==0&&!loading">
    <div
      style="
        display:inline-block;
        width: 213px;
        text-align: center;
        margin-top: 210px;
        margin-bottom: 20px;
        line-height: 200px;
      "
    >
      <img
        style="width: 100%"
        :src="require('@/assets/images/moments/empty_photo.png')"
      />
    </div>
    <p style="font-size: 22px;color:#5f6368;font-family: Roboto;">
      {{ searchResults ? 'No results found.' : 'No Photo yet.' }}
    </p>
  </div>
  <div class="image-list" :class="{ 'check-mode': checkedList.length>0 }" v-loading="loading" v-else>
    <el-row :gutter="10">
      <el-col :lg="4" :sm="6"  v-for="(item, index) in list" :key="index">
        <div class="item">
          <div v-if="item.createTime" class="time" :class="{'first':isfirstDate(index)}">{{ generateTimeStr(item.createTime) }}</div>
          <div class="img-box">
            <span class="check-btn" v-if="canCheck" :class="{checked:checkedList.findIndex(o=>o.id==item.id)>=0}" @click="check(item)">
              <i class="el-icon-check"></i>
            </span>
         
            <span class="pending" v-if="item.shareStatus==1"><i class="el-icon-more"></i></span>
            <span class="shared" v-if="item.shareStatus==2"><i class="el-icon-check"></i></span>
            <span
              class="img-b"
              v-if="item.path"
              :style="'background-image:url('+rootPath+item.path+')'"
              @click.self="itemClick(item)"
            >
              <i class="el-icon-star-on fav" v-if="item.favourite==1"></i>
              <span class="count" v-if="item.count>1">{{item.count}}</span>
            </span>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
  <imgDetail ref="imgDetail" :classId="classId"/>
</div>
</template>

<script>
import moment from 'moment'
import { Ajax } from "@/common";
import imgDetail from './detail/index'
export default {
  components:{imgDetail},
  props:{
    classId:{
      default: '',
      type: String
    },
    list:{
      default: ()=>[],
      type: Array
    },
    canCheck:{
      default: true,
      type: Boolean
    },
    searchResults:{
      default: false,
      type: Boolean
    },
    loading:{
      default: false,
      type: Boolean
    },
    singleSelect:{
      default: false,
      type: Boolean
    },
    type:{
      default: '',
      type: String
    }
  },
  data() {
    return {
      checkedList:[],
      rootPath:process.env.VUE_APP_fileBasePath
    };
  },
  mounted(){
    
  },
  watch:{
    checkedList(){
      this.$emit('checkChange',this.checkedList)
    }
  },
  methods: {

    moment,

    generateTimeStr(timeStr){
      return moment.utc(timeStr).local().format('DD/MM/YYYY')
    },

    isfirstDate(index){
      if(index == 0){
        return true
      }
      if(moment(this.list[index].createTime).format('DD/MM/YYYY')!=moment(this.list[index-1].createTime).format('DD/MM/YYYY')){
        return true
      }
      return false
    },

    check(item){
      if(this.singleSelect){
        // 单选模式
        this.checkedList = [item]
      }else{
        // 多选模式
        const _i = this.checkedList.findIndex(o=>o.id===item.id)
        if(_i<0){
          this.checkedList.push(item)
        }else{
          this.checkedList.splice(_i,1)
        }
      }
    },

    clearCheck(){
      this.checkedList=[]
    },

    async itemClick(item){
      // this.$router.push({name:'momentsDetail',params:{id:item.id}})
      if(item.count>1){
        var res = await Ajax.get("/moments/share/getListByBatchNo", {
          batchNo: item.batchNo,
        })
        
        if(res.code=='0000'){
          console.log(item,res.data)
          this.$refs.imgDetail.show(item,res.data)
        }
      }else{
        this.$refs.imgDetail.show(item,this.list)
      }
    }
  }
};
</script>
<style lang='less' scoped>

  .image-list{
    width: 100%;
    padding-bottom:30px;
  min-height: 70vh;
    
    &.check-mode{
      .img-box{
        .check-btn{
          opacity: 1;
        }
      }
    }
    .item{
      // padding:10px 10px 0;
      // width: 14.28%;
      // float:left;
      border-radius: 4px;
      cursor: pointer;
      font-size: 0;
      line-height: 0;
    }
    .time{
      text-align: left;
      line-height: 30px;
      padding-top:15px;
      font-size: 14px;
      color:#5F6368;
      font-family: Roboto;
      opacity:0;
      padding-left: 2px;
      &.first{
        opacity:1;
      }
    }
    .img-box{
      width:100%;
      position: relative;
      // .el-image{
      //   width: 100%;
      // }
      .img-b{
        display: block;
        width:100%;
        height: 215px;
        padding-bottom:56.25%;
        background-size:contain;
        background-position: center center;
        background-repeat: no-repeat;
        position: relative;
        background-color:#e0e0e0;
        border-radius: 4px;
      }
      .fav{
        position: absolute;
        color:#fff;
        left: 6px;
        bottom: 6px;
        font-size: 18px;
      }
      .count{
        position: absolute;
        color:#fff;
        right: 6px;
        bottom: 6px;
        font-size: 14px;
        width:20px;
        height:20px;
        line-height:18px;
        text-align: center;
        border:1px solid #fff;
        border-radius: 2px;
        &:after{
          content:' ';
          position: absolute;
          left: -4px;
          bottom: -4px;
          font-size: 14px;
          width:20px;
          height:20px;
          border:1px solid #fff;
          border-width: 0 0 1px 1px;
          border-radius: 2px;
        }
      }
      &:before{
        content:' ';
        position: absolute;
        height:30px;
        width:100%;
        left:0;
        top:0;
        z-index: 2;
        opacity: 0;
        border-radius: 4px 4px 0 0;
        background: linear-gradient(to bottom, rgba(0,0,0,0.4), rgba(0,0,0,0));
      }
      .check-btn{
        width:24px;
        height:24px;
        position: absolute;
        z-index: 3;
        left:7px;
        top:7px;
        border-radius: 100%;
        background:transparent;
        border:2px solid #fff;
        opacity: 0;
        transition: ease-out .2s;
        
        .el-icon-check{
          color: #999;
          width: 20px;
          height: 20px;
          font-size: 18px;
          line-height: 20px;
          text-align: center;
          font-weight: bold;
          display: none;
        }
        &.checked{
          background:#1a73e8;
          border-color:#1a73e8;
          .el-icon-check{
             display: block;
            color:#fff;
          }
        }
      }
      .pending{
        background: #E5493D;
        width:24px;
        height:24px;
        border-radius: 100%;
        line-height: 24px;
        text-align: center;
        position: absolute;
        z-index: 3;
        right:8px;
        top:8px;
        color:#fff;
        .el-icon-more{
          font-size: 16px;
          vertical-align: middle;
          margin-top: -2px;
        }
      }

       .shared{
        background: #3DB175;
        width:24px;
        height:24px;
        border-radius: 100%;
        line-height: 24px;
        text-align: center;
        position: absolute;
        z-index: 3;
        right:8px;
        top:8px;
        color:#fff;
        .el-icon-check{
          font-size: 16px;
          vertical-align: middle;
          margin-top: -2px;
        }
      }
      
      &:hover{
        &:before{
          opacity: 1;
        }
        .check-btn:not(.checked){
          opacity: 0.5;
          background: #fff;
          .el-icon-check{
            display: block;
          }
          &:hover{
            opacity: 1;
          }
        }
      }
    }
    
  }
</style>