<!-- class students list -->
<template>
  <div class="content has_breadcrumb">
   <base-breadcrumb
      :data="[
        {
          routeName: 'locations',
          name: user.schoolName,
        },
        {
          routeName: 'moments',
          name: 'Moments',
        },
        {
          name: 'Search results',
        },
      ]"
      slot="breadcrumb"
    />
    <GgPage
      pageType="2"
      title="Moments"
      @forward="forward"
      @next="next"
    >
      <div slot="topAppBar" class="topAppBar">
        <!-- <month-switch @change="monthChange" :defaultVal="defSwitchVal"></month-switch> -->
        <search :classId="$route.query.classId" ref="search"></search>
      </div>
      
      <GgPageHeader title="Search results" class="mar-b-0" />
      <div class="optbar" v-show="checkedList.length>0">
        <el-button type="text" @click="clearCheck"><i class="el-icon-close"/></el-button>
        <span class="count">{{checkedList.length}} Selected</span>
        <div class="btns">
          <!-- <el-tooltip popper-class="file-tooltip-top" :visible-arrow="false" content="Share" placement="bottom"> -->
          <shareBtn @ok="onShareSuccess" :list="checkedList"/>
          <!-- </el-tooltip> -->
          <el-tooltip effect="light" popper-class="file-tooltip-top" :visible-arrow="false" content="Add to album" placement="bottom" v-if="hasPermission('Manage albums_Edit')">
          <addToAlbumBtn :classId="$route.params.id" @ok="onAddToAlbumSuccess" :list="checkedList"></addToAlbumBtn>
          </el-tooltip>
         <el-tooltip effect="light" popper-class="file-tooltip-top" :visible-arrow="false" content="Delete" placement="bottom">
          <deleteBtn @ok="onDeleteSuccess" :list="checkedList"/>
         </el-tooltip>
         
          <gg-menu-bar v-if="hasPermission('Manage media_Full') || hasPermission('Manage media_Edit')">
            <el-tooltip popper-class="file-tooltip-top" :visible-arrow="false" content="More options" placement="bottom">
            <i class="iconmore_vert-24px iconfont" />
            </el-tooltip>
            <template slot="menus">
              <gg-menu-bar-item
                v-if="hasPermission('Manage media_Full')"
                @click="handleDownload()"
                >Download</gg-menu-bar-item
              >
              <gg-menu-bar-item
                v-if="hasPermission('Manage media_Edit')"
                @click="handleFavorite()"
              >
                {{checkedListAllFavorited?'Remove from favorite':'Favorite'}}
              </gg-menu-bar-item>
            </template>
          </gg-menu-bar>
         
        </div>
      </div>
      <image-list :classId="$route.query.classId" :list="imgList"  :loading="loading" @checkChange="onCheckChange" ref="imageList" emptyWords="No results found." :canCheck="false"/>
    </GgPage>
  </div>
</template>

<script>
import { Ajax } from "@/common";
import moment from 'moment';
import { mapState } from "vuex";
import { batchImgDownload, hasPermission } from './utils/utils.js'
import uploadBtn from './modules/uploadBtn'
import SvgIcon from "@/components/SvgIcon";
import monthSwitch from './modules/monthSwitch'
import search from './modules/search'
import imageList from './modules/imageList'
import deleteBtn from './modules/deleteBtn'
import shareBtn from './modules/shareBtn'
import addToAlbumBtn from './modules/addToAlbumBtn'
export default {
  components: {SvgIcon,monthSwitch,search,imageList,uploadBtn,deleteBtn,addToAlbumBtn,shareBtn},
  data() {
    return {
      boolean: true,
      loading:false,
      classname:'',
      defSwitchVal:[],
      date:this.$route.query.time?moment(parseInt(this.$route.query.time)):moment(),
      imgList: [
        
      ],
      checkedList:[]
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user
    }),
    checkedListAllFavorited() {
      if(this.checkedList.length > 0){
        const unfavourite = this.checkedList.filter(o=>o.favourite == 0)
        if(unfavourite.length>0){
          return false
        }else{
          return true
        }
      }
      return false
    }
  },
  watch:{
    $route(){
      this.fetchList()
    }
  },
  created() {
    this.initMonthSwitchVal()
    this.fetchList()
  },
  mounted(){
    
    this.$refs.search.initSearch(this.$route.query.q)
  },
  methods: {
    hasPermission,
    initMonthSwitchVal(){
      var _def = moment()
      if(this.$route.query.time&&this.$route.query.time.length==13){
        _def = moment(parseInt(this.$route.query.time))
      }
      this.date = _def
      this.defSwitchVal = [_def.startOf('month'),_def.endOf('month')]
    },
    monthChange(val){
      // console.log(val)
      this.date = val[0]
      this.fetchData()
    },
    // async fetchData(){
    //   this.fetchList()
    //   var resClass = await Ajax.get("/usermanage/class/selectByIdV1", {
    //     id: this.$route.params.id,
    //   })
    //   if(resClass.code=='0000'){
    //     this.classname = resClass.data.className
    //   }
      
    // },
    onShareSuccess(){
      this.$refs.imageList.clearCheck()
    },
    onDeleteSuccess(){
      this.$refs.imageList.clearCheck()
      this.fetchList()
    },
    onAddToAlbumSuccess(){
      this.$refs.imageList.clearCheck()
    },
    async fetchList(){
      this.loading = true;
      const _thequery = this.$route.query
      var _query = Object.assign(_thequery,{studentList:_thequery.studentList&&_thequery.studentList.length>0?_thequery.studentList.split(','):[],tagList:_thequery.tagList&&_thequery.tagList.length>0?_thequery.tagList.split(','):[]})
      _query.keywords = this.$route.query.q
      delete _query.q
      if(_query.favourite == 0){
        delete _query.favourite
      }
      for(var _key in _query){//遍历json数组时，这么写p为索引，0,1
        if(_query[_key] === '' || (Array.isArray(_query[_key]) && _query[_key].length == 0)) {
          delete _query[_key]
        }
      }
      var res = await Ajax.post("/moments/media/getSearchMediaList", _query)
      this.loading = false;
      if(res.code=='0000'){
         this.imgList = res.data.records
      }
    },
    handleDownload(){
      batchImgDownload(this.checkedList)
      this.$refs.imageList.clearCheck()
    },
    async handleFavorite(){
      var res
      if(this.checkedListAllFavorited){
        const _arr = this.checkedList.map(o=>o.id)
        res = await Ajax.post("/moments/favourite/cancleFavorite", _arr)
      }else{
        const _arr = this.checkedList.map(o=>{
          return {
            "classId": this.$route.params.id,
            "mediaId": o.id,
          }
        })
        res = await Ajax.post("/moments/favourite/favoriteBatch", _arr)
      }
      if(res.code=='0000'){
        this.$refs.imageList.clearCheck()
        this.fetchList()
      }
    },
    onCheckChange(val){
      this.checkedList = val
    },
    clearCheck(){
      this.$refs.imageList.clearCheck()
    },
    forward(val) {
      console.log(val);
    },
    next(val) {
      console.log(val);
    },
  },
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
/deep/ .ggPage-main{
  position: relative;
}
.content.has_breadcrumb .edoovo-breadcrumb {
    z-index: 10;
}
.content.has_breadcrumb {
  /deep/ .ggPage-main-left{
    display: none;
  }
  /deep/ .ggPage-main-center{
    padding: 0 280px 112px 280px;
  }
  /deep/ .ggPageTitle{
    padding-bottom: 0;
  }
}
</style>