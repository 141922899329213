import { render, staticRenderFns } from "./studentSelecter.vue?vue&type=template&id=5a6aff70&scoped=true&"
import script from "./studentSelecter.vue?vue&type=script&lang=js&"
export * from "./studentSelecter.vue?vue&type=script&lang=js&"
import style0 from "./studentSelecter.vue?vue&type=style&index=0&id=5a6aff70&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5a6aff70",
  null
  
)

export default component.exports