<template>
  <div ref="imageTofile" class="model-box portfolio-a" :class="{ 'savecover' : saveCoverSw }">
    <div v-if="saveCoverSw" class="title">
      <div class="ui-title">{{ modelData.title }}</div>
    </div>
    <div v-else class="title">
      <input-edit 
        class="ui-title"
        :len="24"
        :placeholder="!saveCoverSw ? 'Click to add title' : ''"
        @getContent="getInput"
        @toBlur="toBlur"
        :modelkey="'title'"
        :value="modelData.title"
      />
    </div>

    <div class="sp clearfix">
      <div class="l">
        <div class="ui-img-box">
          <img-upload 
          class="ui-img"
          :fixednumber="[1,1.2]"
          @getImg="getImg"
          :modelkey="'img1'"
          :value="modelData.img1"
        />
        </div>
      </div>
      <div class="r">
        <div v-if="saveCoverSw" class="title-1">
          <div class="ui-title-1">{{ modelData.title1 }}</div>
        </div>
        <div v-else class="title-1">
          <input-edit 
            :len="24"
            class="ui-title-1"
            :placeholder="!saveCoverSw ? 'Click to add title' : ''"
            @getContent="getInput"
            @toBlur="toBlur"
            :modelkey="'title1'"
            :value="modelData.title1"
          />
        </div>
        <div v-if="saveCoverSw" class="title-2">
          <div class="ui-title-2">{{ modelData.title2 }}</div>
        </div>
        <div v-else class="title-2">
          <input-edit 
            class="ui-title-2"
            :len="30"
            :placeholder="!saveCoverSw ? 'Click to add title' : ''"
            @getContent="getInput"
            @toBlur="toBlur"
            :modelkey="'title2'"
            :value="modelData.title2"
          />
        </div>
        <div v-if="saveCoverSw" class="title-3">
          <div class="ui-title-3">{{ modelData.title3 }}</div>
        </div>
        <div v-else class="title-3">
          <input-edit 
            class="ui-title-3"
            :len="30"
            :placeholder="!saveCoverSw ? 'Click to add title' : ''"
            @getContent="getInput"
            @toBlur="toBlur"
            :modelkey="'title3'"
            :value="modelData.title3"
          />
        </div>
      </div>
    </div>

    <div class="logo">
      <logo-upload 
        class="ui-logo"
        @getImg="getLogo"
        :modelkey="'logo'"
        :value="modelData.logo"
      />
    </div>

  </div>
</template>

<script>
import { Ajax } from "@/common";
import { mapState } from "vuex";
import moment from 'moment'
import html2canvas from 'html2canvas'
import imgUpload from '../../modules/model/ui/imgUpload'
import textEdit from '../../modules/model/ui/textEdit'
import inputEdit from '../../modules/model/ui/inputEdit'
import logoUpload from '../../modules/model/ui/logoUpload'

export default {
  components: {
    textEdit,
    inputEdit,
    imgUpload,
    logoUpload
  },
  props:{
    saveCoverSw: {
      default: false,
      type: Boolean
    },
    data: {
      default: () => ({}),
      type: Object
    },
    index: {
      default: 0,
      type: Number
    }
  },
  data() {
    return {
      cover: '',
      htmlUrl: '',
      rootPath: process.env.VUE_APP_fileBasePath,
      modelData: {}
    };
  },
  mounted(){
    
  },
  watch:{
    saveCoverSw: function() {
      if(this.saveCoverSw && this.index === 0) {
        setTimeout(()=>{
          this.toImage()
        }, 300)
      }
    },
    data: function() {
      this.modelData = this.data
    },
    modelData: function() {
      this.$emit('postdata', this.modelData, this.index)
    }
  },
  created() {
    this.modelData = this.data
  },
  methods: {

    toBlur() {
      this.$emit('toBlur')
    },

    toImage() {
      // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
      html2canvas(this.$refs.imageTofile, {
        backgroundColor: null,
      }).then((canvas) => {
        let url = canvas.toDataURL("image/jpg");
        this.htmlUrl = url; // 把生成的base64位图片上传到服务器,生成在线图片地址
        if (this.htmlUrl) {
          // 渲染完成之后让图片显示，用v-show可以避免一些bug
          let blob = this.convertBase64UrlToBlob(this.htmlUrl)
          let file = this.blobToFile(this.htmlUrl, moment().valueOf() + 'files.jpg')
          const fd = new FormData();
          fd.append('multipartFile', file);
          // fd.append("multipartFile", file.raw);
          this.uploadFileChange(fd)
        }
      });
    }, 

    blobToFile(base64, fileName) {
      let arr = base64.split(','), 
      type = arr[0].match(/:(.*?);/)[1], 
      bstr = atob(arr[1]), 
      n = bstr.length, 
      u8arr = new Uint8Array(n);

      while(n--){
          u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], fileName, {type});
    },

    convertBase64UrlToBlob(urlData) {
      //去掉url的头，并转换为byte 
      var split = urlData.split(',');
      var bytes=window.atob(split[1]);        
      //处理异常,将ascii码小于0的转换为大于0  
      var ab = new ArrayBuffer(bytes.length);  
      var ia = new Uint8Array(ab);  
      for (var i = 0; i < bytes.length; i++) {  
        ia[i] = bytes.charCodeAt(i);  
      }  
      return new Blob( [ab] , {type : split[0]});  
    },

    uploadFileChange(fd) {
      if (fd) {
        Ajax.post(
          "/media/public/file/uploadExt", 
          fd, {
          headers: { "Content-Type": "multipart/form-data" }
        })
          .then((res) => {
            if (res.code === "0000") {
              this.cover = `${process.env.VUE_APP_fileBasePath}${res.data.filePath}`
              this.$emit('postCover', this.cover)
            }
            if (res.code === "9999") {
              this.handleSnack("Maximum upload size exceeded")
            }
          })
          .catch((err) => {
            console.log(err)
          });
      }
    },
    
    getImg(v, key) {
      let obj = this.modelData
      obj[key] = v
      this.modelData = null
      this.modelData = obj
    },

    getLogo(v, key) {
      let obj = this.modelData
      obj[key] = v
      this.modelData = null
      this.modelData = obj
    },

    getInput(v, key) {
      let obj = this.modelData
      obj[key] = v
      this.modelData = null
      this.modelData = obj
    },

    getContent(v, key) {
      let obj = this.modelData
      obj[key] = v
      this.modelData = null
      this.modelData = obj
    }
  }
};
</script>
<style lang='less'>
@import '~@/assets/css/journalModel.less';
</style>