
<template>
  <div class="image-detail" v-if="visible">
      <div class="wrap">
        <div class="image-area">
          <el-button type="text" class="back-btn" icon="el-icon-back" @click="handleBack()"></el-button>
          <!-- <div class="opts">
             <el-tooltip popper-class="file-tooltip-top" :visible-arrow="false" content="Info" placement="bottom" effect="light">
            <span class="btn" @click="handleInfo()"><svg-icon iconClass="info"/></span>
             </el-tooltip>
              <el-tooltip popper-class="file-tooltip-top" :visible-arrow="false" content="Share" placement="bottom" effect="light">
            <span class="btn" @click="handleShare()"><svg-icon iconClass="share"/></span>
              </el-tooltip>
               <el-tooltip popper-class="file-tooltip-top" :visible-arrow="false" content="Favorite" placement="bottom" effect="light">
            <span class="btn" @click="handleLike()">
              <svg-icon :iconClass="item.favourite==0?'star':'starfilled'"/>
            </span>
               </el-tooltip>
            <el-tooltip popper-class="file-tooltip-top" :visible-arrow="false" content="Connot delete a shared photo" placement="bottom"  effect="light" v-if="item.shareStatus == 2">
              <span class="btn"><svg-icon iconClass="delete-white"/></span>
            </el-tooltip>
             <el-tooltip popper-class="file-tooltip-top" :visible-arrow="false" content="Delete" placement="bottom"  effect="light" v-else>
            <span class="btn" @click="handleDelete()" ><svg-icon iconClass="delete-white"/></span>
             </el-tooltip>
            <gg-menu-bar>
               <el-tooltip popper-class="file-tooltip-top" :visible-arrow="false" content="More options" placement="bottom" effect="light">
              <i class="iconmore_vert-24px iconfont" />
               </el-tooltip>
              <template slot="menus">
                <gg-menu-bar-item
                  @click="handleDownload()"
                  >Download</gg-menu-bar-item
                >
                <gg-menu-bar-item
                  @click="handleRotate()"
                  >Rotate</gg-menu-bar-item
                >
                <gg-menu-bar-item
                  @click="handleAddToAlbum()"
                  >Add to album</gg-menu-bar-item
                >
              </template>
            </gg-menu-bar>
            
          </div> -->
          <div class="pic" ref="picWrap">
            <img :src="imagePath" alt="" class="image" :style="styleObject">
            <span class="status">
              <span class="pending" v-if="item.shareStatus==1"><i class="el-icon-more"></i></span>
              <span class="shared" v-if="item.shareStatus==2"><i class="el-icon-check"></i></span>
            </span>
          </div>
          <!-- <div class="delete-tip">30 days left until permanently deleted</div> -->
          <div class="switch-btn prev" @click="switchImg(-1)" v-if="index>0"><i class="el-icon-arrow-left"></i></div>
          <div class="switch-btn next" @click="switchImg(1)" v-if="index<list.length-1"><i class="el-icon-arrow-right"></i></div>
        </div>
        <imageSideBox ref="imageSideBox" :item="item" :classId="classId" @shareStatus="onShareStatus" @onClose="onImageSideBoxClose"/> 
      </div>
  </div>
</template>

<script>
import { Ajax } from "@/common";
import SvgIcon from "@/components/SvgIcon";
import imageSideBox from './modules/imageSideBox'
import { batchImgDownload } from './../../../../utils/utils.js'
const rotates=[0,90,180,270]
export default {
  components: {SvgIcon,imageSideBox},
  computed: {
    index(){
      return this.list.findIndex(o=>o.id==this.item.id)
    },
    imagePath(){
      return this.rootPath+this.item.path
    }
  },
  props:{
    classId:{
      default: '',
      type: String
    },
  },
  data() {
    return {
      visible: false,
      rootPath:process.env.VUE_APP_fileBasePath,
      item:{},
      list:[],
      curRouteIndex:0,
      imgSizeInfo: [],
      styleObject: {
        width: 0,
        height: 0
      },
    };
  },
  
  created() {
  },
  methods: {
    
    show(item,list){
      console.log(item,list)
      this.curRouteIndex = 0
      this.item=item
      this.list=list
      this.visible = true
      this.$nextTick(()=>{
        this.getImgSize()
      })
      console.log(this.index)
    },
    handleDownload(){
      batchImgDownload([this.item])
    },
    handleRotate(){
      // 计算旋转到90和270度时，需要缩放
      this.curRouteIndex++
      if(this.curRouteIndex>=rotates.length){
        this.curRouteIndex = 0
      }
      this.rotate(rotates[this.curRouteIndex])
      
    },
    rotate(degree) {
      this.styleObject.transform = `rotate(${degree}deg)`
      if (degree % 180 === 0) {
        this.styleObject.width = this.imgSizeInfo[0][0]
        this.styleObject.height = this.imgSizeInfo[0][1]
      } else {
        this.styleObject.width = this.imgSizeInfo[1][0]
        this.styleObject.height = this.imgSizeInfo[1][1]
      }
    },
    getImgSize () {
      let img = new Image()
      img.src = this.imagePath
      this.imgSizeInfo = []
      const wrapSize = {
        width: this.$refs.picWrap.offsetWidth,
        height: this.$refs.picWrap.offsetHeight
      }
      console.log(wrapSize) 
      img.onload =  ()=>{
        console.log(img.width,img.height) // 打印图片信息
        this.curRouteIndex = 0
        this.styleObject={
          width: 0,
          height: 0
        };
        let imgRatio = img.width / img.height // 图片宽高比
        let wrapRatio = wrapSize.width / wrapSize.height // 外框宽高比
        
       
        if(imgRatio>wrapRatio){
          if(img.width>wrapSize.width){
            this.imgSizeInfo.push([wrapSize.width + 'px', 'auto'])
          }else{
            this.imgSizeInfo.push([img.width + 'px', 'auto'])
          }

        }else{
          if(img.height>wrapSize.height){
           this.imgSizeInfo.push(['auto', wrapSize.height + 'px'])
          }else{
           this.imgSizeInfo.push(['auto', img.height + 'px'])
          }
        }

         let imgRatioRotate = img.height / img.width // 旋转后的图片宽高比
        if(imgRatioRotate>wrapRatio){
          if(img.height>wrapSize.width){
             this.imgSizeInfo.push(['auto', wrapSize.width + 'px'])
          }else{
           this.imgSizeInfo.push(['auto', img.height + 'px'])
          }

        }else{
          if(img.width>wrapSize.height){
            this.imgSizeInfo.push([wrapSize.height + 'px', 'auto'])
          }else{
            this.imgSizeInfo.push([img.width + 'px', 'auto'])
          }
        }
        this.styleObject.width = this.imgSizeInfo[0][0]
        this.styleObject.height = this.imgSizeInfo[0][1]
      }
    },
    handleAddToAlbum(){
      this.$refs.addToAlbumBtn.handleAlbum()
    },
    handleBack(){
      this.item={}
      this.list=[]
      this.visible = false
    },
    handleInfo(){
      
      this.$refs.imageSideBox.show('Info')
      this.$nextTick(()=>{
        this.getImgSize()
      })
    },
    handleShare(){
      this.$refs.imageSideBox.show('Share')
      this.$nextTick(()=>{
        this.getImgSize()
      })
    },
    onImageSideBoxClose(){
      setTimeout(() => {
        this.getImgSize()
      }, 500);
      // this.$nextTick(()=>{
      //   this.getImgSize()
      // })
    },
    onShareStatus(status){
        this.item.shareStatus = status
        this.$set(this.list,this.index,this.item);
    },
    async handleLike(){
      if(this.item.favourite==1){
        return
      }
       const _arr = [{
          "classId": this.classId,
          "mediaId": this.item.id,
        }]
      var res = await Ajax.post("/moments/favourite/favoriteBatch", _arr)
      
      if(res.code=='0000'){
        const fnVal = Math.abs(this.item.favourite-1)
        this.item.favourite = fnVal
        this.$set(this.list,this.index,this.item);
      }
    },
    switchImg(offset){
      console.log(this.index+offset)
      this.item = this.list[this.index+offset]
      this.getImgSize()
      this.$refs.imageSideBox.fetchData(false)
    },
    handleDelete(){
     
      let _tit='Delete Photo?'
      let _desc='Once deleted, the photo will move to trash and stay for 30 days .'
      if(this.item.shareStatus == 2){
         // 判断该照片是否是在shared中删除
         return
        // _tit='Delete shared photo?'
        // _desc='Once deleted, parent will not be able to view it any more. The deleted photo will move to trash and stay for 30 days .' 
      }
      this.$confirm(
        _desc,
        _tit,
        {
          cancelButtonText: "Cancel",
          confirmButtonText: "Delete",
          cancelButtonClass: "gg_cancel_button",
          confirmButtonClass: "gg_submit_button",
          customClass: "gg_confirm",
        }
      )
        .then(() => {
          var _path = "/moments/media/delete"
          var _arr = [this.item]
         
          // if(this.$route.name == 'momentClassAlbumPhotos'){
          //   _path = "/moments/albums/removeFromAlbum"
          //   _arr = [{
          //     "albumsId": this.$route.params.aid,
          //     "mediaId": this.item.id
          //   }]
          // }
          Ajax.post(_path, _arr).then((res) => {
            if (res.code === "0000") {
              // splice以后会触发computed，导致找不到对应的item 使index为-1
              const _index = this.index;
              this.list.splice(_index,1)
              console.log('after',_index,this.list)
              if(this.list.length>=1){
                if(_index<this.list.length-1){
                  this.item = this.list[_index]
                }else{
                  this.item = this.list[_index-1]
                }
              }else{
                // 删完以后，关闭详情展示
                this.handleBack()
              }
            }
          });
        })
        .catch(() => {});
    }
  },
};
</script>
<style lang='less' scoped>
.image-detail{
  height:calc(100vh - 64px);
  background:#333;
  position: fixed;
  z-index:999;
  left:0;
  top:64px;
  width:100%;
}
.wrap{
  height:100%;
  display: flex;
}
.image-area{
  flex:1;
  padding:72px 80px;
  box-sizing: border-box;
  height:100%;
  position: relative;
  .back-btn{
    position: absolute;
    top:20px;
    left:80px;
    font-size: 24px;
    color:#fff;
  }
  .opts{
    position: absolute;
    top:20px;
    right:80px;
    .menu_bar{
      display: inline-block;
      margin-left:16px;
      vertical-align: middle;
        
      width: 32px;
      height: 32px;
      /deep/ .menu_bar_button{
      padding: 4px;
        transition: all 0.4s linear;
        background:rgba(255,255,255,0);
        border-radius: 100%;
        &:hover{
          background:rgba(255,255,255,0.2);
        }
      }
      .iconfont{
        font-size: 24px;
        color: #fff;
      }
    }
    .btn{
      display: inline-block;
      padding:4px;
      margin-left:16px;
      cursor: pointer;
      transition: all 0.4s linear;
      background:rgba(255,255,255,0);
      border-radius: 100%;
      &:hover{
        background:rgba(255,255,255,0.2);
      }
      .svg-icon{
        width: 24px;
        height: 24px;
      }
    }
  }
  .switch-btn{
    position: absolute;
    top:50%;
    margin-top:-20px;
    height:40px;
    width:40px;
    line-height:40px;
    text-align: center;
    border-radius: 100%;
    transition: all 0.4s linear;
    background:#000;
    color:#fff;
    cursor: pointer;
    opacity: 0;
    &.prev{
      left:20px;
    }
    &.next{
      right:20px;
    }
    &:hover{
      background:#2073E2;
    }
  }
  &:hover{
    .switch-btn{
      opacity: 1;
    }
  }
  .pic{
    width:100%;
    height:100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    .image{
      display: block;
      transform-origin: center center;
      // max-height: 100%;
      max-width: 1000%;
    }
  }
  .delete-tip{
    position: absolute;
    left:80px;
    bottom:36px;
    color:#fff;
    font-size: 14px;
  }
  .status{
    position: absolute;
    z-index: 3;
    right:8px;
    top:8px;
    >span{
      display: block;
      width:24px;
      height:24px;
      border-radius: 100%;
      line-height: 24px;
      text-align: center;
      color:#fff;
    }
    .pending{
      background: #E5493D;
    }
    .shared{
      background: #3DB175;
    }
    i{
      font-size: 16px;
      vertical-align: middle;
      margin-top: -2px;
    }
  }
}

</style>