<!-- moments -->
<template>
  <div class="content has_breadcrumb">
    <base-breadcrumb
      :data="[
        {
          routeName: 'locations',
          name: user.schoolName,
        },
        {
          name: 'Moments',
        },
      ]"
      slot="breadcrumb"
    />
  
    <GgPage @forward="forward" @next="next" pageType="1" title="Moments">
      <div slot="topAppBar"><month-switch @change="dayChange" :defaultVal="defSwitchVal"></month-switch></div>
      <BaseList title="Classes" :data="list" @onClickItem="itemClick"  v-loading="loading" :type="1">
        <div slot="empty" style="text-align: center">
          <div
            style="
              width: 213px;
              text-align: center;
              border-radius: 50%;
              
              line-height: 200px;
          margin: auto;
          margin-bottom: 12px;
          margin-top: 80px;
            "
          >
            <img
              style="width: 100%"
              :src="require('@/assets/images/products/noData.svg')"
            />
          </div>
          <p style="font-size: 22px;color:rgba(32,33,36,0.5);font-family: Roboto;">No data for the day.</p>
        </div>
      </BaseList>
    </GgPage>
  </div>
</template>

<script>
import moment from 'moment';
import { Ajax } from "@/common";
import { mapState } from "vuex";
import monthSwitch from './modules/monthSwitch'
export default {
  components: {
    monthSwitch
  },
  computed: {
    ...mapState({
      user: (state) => state.user
    }),
  },
  data() {
    return {
      loading: false,
      defSwitchVal:0,
      date:moment().valueOf(),
      list: [
        
      ],
    };
  },
  created() {
    this.initMonthSwitchVal()
    this.fetchData()
  },
  methods: {
    forward(val){
      console.log(val);
    },
    next(val){
      console.log(val);
    },
    initMonthSwitchVal(){
      var _def = moment().valueOf()
      if(this.$route.query.time&&this.$route.query.time.length==13){
        _def = parseInt(this.$route.query.time)
      }
      this.date = _def
      this.defSwitchVal = _def
    },
    dayChange(val){
      this.date = val
      this.fetchData()
    },
    itemClick(item){
      console.log(item)
      if(item.id!=0){
        this.$store.commit("SET_CURCLASSNAME", item.leading.text);
        this.$router.push('/moment/class/'+item.id+'?time='+this.date)
      }
      
    },
    fetchData(){
      if(this.date>new Date().getTime()){
        this.list = []
        return
      }
      this.loading = true;
      Ajax.get("/moments/share/statisticsAllClassV1", {
        staffId:this.user.userId,
        schoolId:this.user.locationId,
        date:moment(this.date).startOf('day').utc().format('YYYY-MM-DD HH:mm:ss')
      })
		  .then((res) => {
		    this.loading = false;
        if (res.code == "0000") {
        
          this.list = res.data.map(o=>{
            return {
              id:o.classId || 0,
              leading: {
                icon: o.classId?'':'iconatt_all',
                bgColor: o.themeColor || '#B4B4B4',
                color:o.classId?'':'#437AD1',
                text: o.className,
              },
              classStatus: o.classStatus,
              list: [
                {
                  text: o.total,
                  subText: "Students",
                },
                {
                  text: o.shared,
                  subText: "Shared photos",
                },
              ],
              trailing: {
                text: o.withSharedPhotos,
                subText: "Students with shared photos",
              },
            }
          })
        
        }
		  })
		  .catch(() => {
		    this.loading = false;
		  });
     
    }
  },
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
/deep/ .ggPage-main-center .base-list{
  padding-bottom:112px!important;
  .title{
    font-family: 'opensans-semibold';
    margin-left:0;
    padding-left:10px;
  }
  .main-column{
    .icon{
      border-radius: 50%;
    }
    .icon-text{
      width:32px;
      height:32px;
    }
  }
  ul li {
    border-top:0 none;
    border-bottom: 1px solid #e0e0e0;
    &:nth-child(2) {
      border-bottom: 1px solid #e0e0e0;
    }
    // &:nth-last-child(1) {
    //   border-bottom: 2px solid #e0e0e0;
    // }
  }
}
.base-list /deep/ ul .sub-li:nth-of-type(1){
  cursor: default;
  &:hover{
    &:after{
      display: none;
    }
  }
}
</style>