<template>
  <div class="photo-selecter" v-if="visible">
    <div class="optbar">
      <el-button type="text" icon="el-icon-close" class="close-btn" @click="close()"></el-button>
      <span class="avatar" :style="'background-image:url('+rootPath+info.logo+')'"></span>
      <span class="album-name">{{info.albumsName}}</span>
     
        <upload-btn :classId="$route.params.id" :albumId="$route.params.aid" @uploadSuccess="onUploadSuccess()" @onFinish="uploadFinish"> <el-tooltip effect="light" popper-class="file-tooltip-top" :visible-arrow="false" content="select from computer" placement="bottom">  <el-button type="text" class="upload-photo-button"><svg-icon iconClass="important_devices"></svg-icon></el-button> </el-tooltip></upload-btn>
      
      <baseButton @click="handleSubmit()" :disabled="checkedList.length==0" class="down-btn" type="primary">Done</baseButton>
    </div>
    <GgPageHeader :title="classname" class="mar-b-0" />
    <image-list :classId="$route.params.id" :list="imgList"  :loading="loading" @checkChange="onCheckChange" ref="imageList"/>
    <Snackbar
      :visible.sync="snackbarData.visible"
      :content="snackbarData.content"
    />
  </div>
</template>
<script>
import { Ajax } from "@/common";
import moment from 'moment';
import SvgIcon from "@/components/SvgIcon";
import uploadBtn from './../../../modules/uploadBtn'
import imageList from './../../../modules/imageList'
export default {
  components: {SvgIcon,uploadBtn,imageList},
  props:{
    info:{
      default:()=>{
        return {
          'albumsName': "-",
          'logo':''
        }
      },
      type:Object
    }
  },
  data() {
    return {
      rootPath:process.env.VUE_APP_fileBasePath,
      visible: false,
      classname:'',
      imgList:[],
      loading:false,
      checkedList:[],
      snackbarData: {
        visible: false,
        content: "",
      },
    };
  },
  created() {
    this.fetchData()
  },
  methods: {
    show(){
      this.visible = true
      this.$nextTick(()=>{
        this.$refs.imageList.clearCheck()
      })
       this.fetchData()
    },
    close(){
      this.visible = false
    },
    async handleSubmit() {
      const _arr = this.checkedList.map(o=>{
        return {
          "albumsId": this.$route.params.aid,
          "mediaId": o.id
        }
      })
      this.loading = true;
      var res = await Ajax.post("/moments/albums/addToAlbumBatch", _arr)
      this.loading = false;
      if(res.code=='0000'){
        this.$refs.imageList.clearCheck()
       
        this.$emit('ok')
        this.close()
      }
    },
    uploadFinish(){
      this.$refs.imageList.clearCheck()
      this.$emit('ok')
      this.close()
    },
    onUploadSuccess(){
      this.fetchData()
    },
    onCheckChange(val){
      this.checkedList = val
    },
    async fetchData(){
      this.fetchList()
      var resClass = await Ajax.get("/usermanage/class/selectByIdV1", {
        id: this.$route.params.id,
      })
      if(resClass.code=='0000'){
        this.classname = resClass.data.className
      }
      
    },
    async fetchList(){
      this.loading = true;
      var res = await Ajax.post("/moments/media/getMediaList", {
        classId: this.$route.params.id,
        // status: 0,
        // date: this.$route.query.time&&this.$route.query.time.length==13?moment(this.$route.query.time-0).format('YYYY-MM'):'',
        size:9999
      })
      this.loading = false;
      if(res.code=='0000'){
         this.imgList = res.data.records
      }
    },
    handleSnack(content) {
      this.snackbarData.content = content;
      this.snackbarData.visible = true;
    },
  },
};
</script>
<style lang="less" scoped>
.photo-selecter{
  padding:100px 170px 20px;
  background: #fff;
  position: fixed;
  top: 64px;
  left:0;
  height:calc( 100vh - 64px);
  width:100%;
  z-index:10;
  overflow-y: auto;
}
.optbar{
  position: fixed;
  height:64px;
  left:0;
  top: 64px;
  width: 100%;
  z-index: 20;
  background: #fff;
}
.close-btn{
  position: absolute;
  left:20px;
  top:50%;
  transform: translate(0,-50%);
  font-size: 24px;
  color:#666;
}
.avatar{
  position: absolute;
  left:92px;
  top:50%;
  transform: translate(0,-50%);
  width:32px;
  height:32px;
  background-color:#eee;
  background-size:cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.album-name{
  position: absolute;
  line-height: 32px;
  top:16px;
  height:32px;
  color:#202124;
  left:144px;
  font-size:14px;
}
.down-btn{
  position: absolute;
  right:20px;
  top:50%;
  transform: translate(0,-50%);
  padding: 0 12px;
  height:32px;
  line-height: 32px;
}
.upload-photo-button{
  position: absolute;
  right: 95px;
  top:50%;
  transform: translate(0,-50%);
  padding: 9px 0;
}
</style>