var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content has_breadcrumb"},[_c('base-breadcrumb',{attrs:{"slot":"breadcrumb","data":[
      {
        routeName: 'locations',
        name: _vm.user.schoolName,
      },
      {
        routeName: 'moments',
        name: 'Moments',
      },
      {
        name: _vm.classname,
      } ]},slot:"breadcrumb"}),_c('GgPage',{attrs:{"pageType":"2","title":"Moments"},on:{"forward":_vm.forward,"next":_vm.next},scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('div',{staticClass:"mar-l-b"},[_c('upload-btn',{attrs:{"classId":_vm.$route.params.id}},[_c('gg-create-button',{attrs:{"label":"Upload"}})],1)],1),_c('class-side-menu',{attrs:{"activeIndex":"momentClassPending"}})]},proxy:true}])},[_c('div',{staticClass:"topAppBar",attrs:{"slot":"topAppBar"},slot:"topAppBar"},[_c('search',{attrs:{"classId":_vm.$route.params.id}})],1),_c('GgPageHeader',{staticClass:"mar-b-0",attrs:{"title":_vm.classname}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.checkedList.length>0),expression:"checkedList.length>0"}],staticClass:"optbar"},[_c('el-button',{attrs:{"type":"text"},on:{"click":_vm.clearCheck}},[_c('i',{staticClass:"el-icon-close"})]),_c('span',{staticClass:"count"},[_vm._v(_vm._s(_vm.checkedList.length)+" Selected")]),_c('div',{staticClass:"btns"},[_c('baseButton',{attrs:{"disabled":!_vm.hasPermission('Share media_Edit'),"type":"primary"},on:{"click":function($event){return _vm.handleUndo()}}},[_vm._v("Undo")]),_c('baseButton',{attrs:{"disabled":!_vm.hasPermission('Share media_Full'),"type":"primary"},on:{"click":function($event){return _vm.handleApprove()}}},[_vm._v("Approve")])],1)],1),_c('image-list',{ref:"imageList",attrs:{"classId":_vm.$route.params.id,"list":_vm.imgList,"loading":_vm.loading,"pending":true},on:{"checkChange":_vm.onCheckChange,"refresh":_vm.refresh}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }