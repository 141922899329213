<!-- class students list -->
<template>
  <div>
    <gg-flex-menus
      :menus="menuDatas"
      @select="menuSelect"
      :default-active="activeIndex"
    >
     
    </gg-flex-menus>
    <div class="sub-mn">
      <span class="tit">Library</span>
      <gg-flex-menus
        :menus="menuDatas2"
        @select="menuSelect"
        :default-active="activeIndex"
      >
      </gg-flex-menus>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
// const _menuDatas= [
//         {
//           name: "Students",
//           key: "momentClass",
//           children: [],
//         },
//         {
//           name: "Photos",
//           key: "momentClassPhotos",
//           children: [
//             {
//               name: "Uploaded",
//               key: "momentClassUploaded",
//               children: [],
//             },
//             {
//               name: "Pending",
//               key: "momentClassPending",
//               extra:'123',
//               children: [],
//             },
//             {
//               name: "Shared",
//               key: "momentClassShared",
//               children: [],
//             }
//           ],
//         },
//         // {
//         //   name: "Library",
//         //   active:true,
//         //   children: [
//         //     {
//         //       name: "Album",
//         //       key: "momentClassAlbum",
//         //       children: [],
//         //     },
//         //     {
//         //       name: "Trash",
//         //       key: "momentClassTrash",
//         //       children: [],
//         //     },
//         //   ],
//         // },
//       ];
const _menuDatas2= [
  {
    name: "Album",
    key: "momentClassAlbum",
    children: [],
  },
  {
    name: "Trash",
    key: "momentClassTrash",
    children: [],
  },
];
export default {
  props: {
    activeIndex:{
      default:'',
      type:String
    }
  },
  data() {
    return {
      menuDatas: [
        
      ],
      menuDatas2: [
        
      ],
    };
  },
  methods: {
    generateMenuDatas(){
      return [
        {
          name: "Students",
          key: "momentClass",
          children: [],
        },
        {
          name: "Photos",
          key: "momentClassPhotos",
          children: [
            {
              name: "Uploaded",
              key: "momentClassUploaded",
              children: [],
            },
            {
              name: "Pending",
              key: "momentClassPending",
              extra:this.pendingCount,
              children: [],
            },
            {
              name: "Shared",
              key: "momentClassShared",
              children: [],
            }
          ],
        },
        // {
        //   name: "Library",
        //   active:true,
        //   children: [
        //     {
        //       name: "Album",
        //       key: "momentClassAlbum",
        //       children: [],
        //     },
        //     {
        //       name: "Trash",
        //       key: "momentClassTrash",
        //       children: [],
        //     },
        //   ],
        // },
      ]
    },
    menuSelect(key, item) {
      this.$router.push({name:key,params:{id:this.$route.params.id},query:this.$route.query.time?{time:this.$route.query.time+''} : {}})
    },
    init(){
      const _menuDatas = this.generateMenuDatas()
      this.menuDatas = [..._menuDatas]
      this.menuDatas2 = [..._menuDatas2]
      for(var i=0;i<_menuDatas.length;i++){
        const _cIdx = _menuDatas[i].children.findIndex(o=>o.key === this.activeIndex)
        if(_cIdx>=0){
          this.menuDatas[i].active = true
          break;
        }
      }
      for(var i=0;i<_menuDatas2.length;i++){
        const _cIdx = _menuDatas2[i].children.findIndex(o=>o.key === this.activeIndex)
        if(_cIdx>=0){
          this.menuDatas2[i].active = true
          break;
        }
      }
    },
    
  },
   computed: {
    ...mapState({
      pendingCount: (state) => state.moments.pendingCount
    }),
  },
  watch:{
    pendingCount(){
      // console.log(3333,this.pendingCount)
      this.init()
    }
  },
  created() {
    this.init()
   
  },
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.sub-mn{
  .tit{
    padding-left:32px;
    font-size: 14px;
    color: #5f6368;
    height: 40px;
    line-height: 40px;
    font-family: OpenSans-Bold;
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 24px;
  }
  /deep/ .menus .menu_box .menu_item{padding-left:68px!important;}
}

</style>