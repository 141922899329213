<!-- class students list -->
<template>
  <div v-loading="loading">
    <gg-flex-menus
      :menus="menuDatas"
      @select="menuSelect"
      :default-active="activeIndex"
    >
    </gg-flex-menus>
  </div>
</template>

<script>
import moment from 'moment'
import { Ajax } from "@/common"
import { mapState } from "vuex"
import { hasPermission } from './../utils/utils.js';

export default {
  props: {
    classInt:{
      default: '',
      type: String
    },
    year:{
      default: '',
      type: String
    },
    month:{
      default: '',
      type: String
    },
    typename:{
      default: '',
      type: String
    }
  },
  data() {
    return {
      loading: false,
      list: [],
      monthArr: {
        'All': '',
        'January': 1,
        'February': 2,
        'March': 3,
        'April': 4,
        'May': 5,
        'June': 6,
        'July': 7,
        'August': 8,
        'September': 9,
        'October': 10,
        'November': 11,
        'December': 12
      },
      activeIndex: '',
      menuDatas: []
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user
    }),
  },
  watch:{
    classInt(){
      this.int()
    },
    year(){
      this.int()
    },
    month(){
      this.int()
    }
  },
  created() {
    let menuDatas = [
        {
          name: "Newsletter",
          active: true,
          children: [],
        },
        {
          name: "Portfolio",
          active: true,
          children: [],
        }
      ],
    menuDatasNewsletter = [],
    menuDatasPortfolio = []

    if(this.hasPermission('Manage journals_View')){
      menuDatasNewsletter.push({
              name: "Draft",
              key: "newsletter_draft",
              extra: ''
            },
            {
              name: "Pending",
              key: "newsletter_pending",
              extra: ''
            })
      menuDatasPortfolio.push({
              name: "Draft",
              key: "portfolio_draft",
              extra: ''
            },
            {
              name: "Pending",
              key: "portfolio_pending",
              extra: ''
            })
    }
    if(this.hasPermission('Share journals_View')){
      menuDatasNewsletter.push({
              name: "Posted",
              key: "newsletter_posted",
              extra: ''
            })
      menuDatasPortfolio.push({
              name: "Posted",
              key: "portfolio_posted",
              extra: ''
            })
    }
    menuDatas[0].children = menuDatasNewsletter
    menuDatas[1].children = menuDatasPortfolio
    this.menuDatas = menuDatas

    // if((this.hasPermission('Manage journals_View') && this.hasPermission('Share journals_View')) || this.hasPermission('Manage journals_View')) {
    //   this.activeIndex = 'newsletter_draft'
    // }else{
    //   this.activeIndex = 'newsletter_posted'
    // }

    this.initMonthSwitchVal()
    this.int()
  },
  methods: {

    moment,

    hasPermission,

    int(){
      const menu = this.menuDatas
      for(let i=0;i<menu.length;i++) {
        for(let j=0;j<menu[i].children.length;j++) {
          if(menu[i].children[j].name === 'Pending') {
            this.getJournalCount(menu[i].children[j].key, i, j)
          }
        }
      }

      if(this.typename) {
        this.menuSelect(this.typename)
      }
    },

    menuSelect(key, item) {
      this.activeIndex = key
      let newsletter = key.split('_')[0] === 'newsletter' ? true : false,
      status = key.split('_')[1].toUpperCase(),
      typename = key
      this.$emit('sideJournal', newsletter, status, typename)
    },

    initMonthSwitchVal(){
      var _def = moment()
      if(this.$route.query.time && this.$route.query.time.length == 13){
        _def = moment(parseInt(this.$route.query.time))
      }
      this.date = _def
    },

    getJournalCount(key, i, j) {
      let newsletter = key.split('_')[0] === 'newsletter' ? true : false,
      status = key.split('_')[1].toUpperCase(),
      m = this.monthArr[this.month],
      data = {
        newsletter: newsletter,
        classId: this.$route.params.id,
        year: this.year === 'All' ? '' : this.year,
        status: status
      }

      if(m) {
        data.month = m
      }

      Ajax.get("journal/count/classV1", data).then((res) => {
        if(res.code === '0000'){
          this.menuDatas[i].children[j].extra = res.data || ''
        }
      });
    }
  }
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.sub-mn{
  .tit{
    padding-left:32px;
    font-size: 14px;
    color: #5f6368;
    height: 40px;
    line-height: 40px;
    font-family: OpenSans-Bold;
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 24px;
  }
  /deep/ .menus .menu_box .menu_item{padding-left:68px!important;}
}

</style>