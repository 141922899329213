<template>
  <div class="album-list-inner">
    <div v-if="imgList.length > 0">
      <div class="breadcrumb">
        <span @click="returnAlbums" class="p">Albums</span>
        <i class="el-breadcrumb__separator iconfont iconnavigate_next-px"></i>
        <span>{{ classname }}</span>
      </div>
      <image-list :singleSelect="true" :classId="classId" :list="imgList" :loading="albumsLoading" @checkChange="onCheckChange" ref="imageList"/>
    </div>

    <el-row v-else :gutter="20">
      <el-col :lg="4" :sm="6"  v-for="(item, index) in list" :key="index">
        <div class="item">
          <div class="img-box">
            <div
              @click.self="itemClick(item)"
              class="cover"
              :style="'background-image:url('+rootPath+item.src+')'"
            >
            </div>
          </div>
          <b>{{item.name}}</b>
          <span v-if="item.count>0">{{item.count}} photos</span>
          <span v-else>No photo</span>
        </div>
      </el-col>
    </el-row>

    <div style="text-align: center" v-if="list.length==0&&!loading">
      <div
        style="
          display:inline-block;
          width: 213px;
          text-align: center;
          margin-top: 210px;
          margin-bottom: 20px;
          line-height: 200px;
        "
      >
        <img
          style="width: 100%"
          :src="require('@/assets/images/moments/empty_photo.png')"
        />
      </div>
      <p style="font-size: 22px;color:#5f6368;font-family: Roboto;">
        {{ 'No Album yet.' }}
      </p>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { Ajax } from "@/common";
import imageList from './imageList'
export default {
  components:{imageList},
  props:{
    classId:{
      default: '',
      type: String
    },
    list:{
      default: ()=>[],
      type: Array
    },
    canCheck:{
      default: true,
      type: Boolean
    },
    // pending:{
    //   default: false,
    //   type: Boolean
    // },
    loading:{
      default: false,
      type: Boolean
    },
    singleSelect:{
      default: false,
      type: Boolean
    },
    type:{
      default: '',
      type: String
    }
  },
  data() {
    return {
      classname: '',
      albumsLoading: false,
      info: {},
      imgList: [],
      aid: '',
      date: this.$route.query.time ? moment(parseInt(this.$route.query.time)) : moment(),
      checkedList:[],
      rootPath:process.env.VUE_APP_fileBasePath
    };
  },
  mounted(){
    
  },
  watch:{
    checkedList(){
      this.$emit('checkChange',this.checkedList)
    }
  },
  methods: {

    moment,

    returnAlbums() {
      this.imgList = []
    },

    onCheckChange(val){
      this.checkedList = val
    },

    itemClick(item){
      this.classname = item.name
      this.fetchData(item.id)
    },

    async fetchData(albumid){
      this.albumsLoading = true;
      var res = await Ajax.get("/moments/albums/getMediaListByAlbumId", {
        albumId: albumid,
        date: this.date.format('YYYY-MM'),
        size: 9999
      })
      
      this.albumsLoading = false;
      if(res.code=='0000'){
        this.info = res.data
        this.imgList = res.data.mediaDTOList
      }
      
    }
  },
};
</script>
<style lang='less' scoped>
.album-list-inner{
  margin-top:20px;
    width: 100%;
   
    .item{
      margin-bottom:20px;
      border-radius: 4px;
      cursor: pointer;
      font-size: 0;
      line-height: 0;
    }
    b{
      line-height: 30px;
      text-align: left;
      font-size: 14px;
      color:#202124;
      display: block;
      text-overflow: ellipsis;overflow: hidden;white-space: nowrap;
    }
    span{
      line-height: 20px;
      font-size: 14px;
      text-align: left;
      color:#202124;
      display: block;
    }
    .img-box{
      width:100%;
      position: relative;
      border-radius: 8px;
      overflow: hidden;
      transition: ease-out .2s;

      .cover{
        width: 100%;
        padding-bottom:100%;
        background-color:#7E868B;
        background-size:cover;
        background-position: center center;
        background-repeat: no-repeat;

      }
      &:before{
        transition: ease-out .2s;
        content:' ';
        position: absolute;
        height:30px;
        width:100%;
        left:0;
        top:0;
        z-index: 2;
        opacity: 0;
        background: linear-gradient(to bottom, rgba(0,0,0,0.4), rgba(0,0,0,0));
      }
      .menu_bar{
        position: absolute;
        right:8px;
        top:8px;
        z-index: 3;
        overflow: visible;
        opacity: 0;
        /deep/ .menu_bar_button{
          line-height: 22px;
          border-radius: 100%;
          background: rgba(255,255,255,0.2);
          // &:hover{
            
          // }
        }
        /deep/ .drop_down_menus{
          position: absolute;
          top: 28px!important;
          right:0!important;
          div{
            white-space: nowrap!important;
          }
        }

        .iconfont{
          color:#fff;
        }
      }
      &:hover{
        &:before{
          opacity: 1;
        }
        .menu_bar{
          opacity: 1;
        }
      }
    }
    
  }

.breadcrumb {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
  font-family: Roboto;
  font-weight: normal;
  height: 28px;
  line-height: 28px;
  overflow: hidden;

  .p {
    color: #707070;
    cursor: pointer;
  }

  i,
  span {
    height: 28px;
    line-height: 28px;
    float: left;
  }
}
</style>