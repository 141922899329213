<template>
<span>
   <el-button type="text" :class="{'disabled':disabled}" class="icon-button" @click="handleDelete" v-if="hasPermission('Manage media_Full')"><svg-icon iconClass="delete"></svg-icon></el-button>
    <Snackbar
      :visible.sync="snackbarData.visible"
      :content="snackbarData.content"
    />
</span>
</template>
<script>
import { Ajax } from "@/common";
import SvgIcon from "@/components/SvgIcon";
import { hasPermission } from './../utils/utils.js';
export default {
  components: {SvgIcon},
  props:{
    list:{
      // [{id:'xxxx',path:'xxxx'}]
      default:()=>[],
      type:Array
    },
    disabled:{
      default: false,
      type: Boolean
    }
  },
  data(){
    return {
      snackbarData: {
        visible: false,
        content: "",
      },
    }
  },
  methods:{
    hasPermission,
    handleDelete(){
      if(this.disabled){return}
      this.$confirm(
         "Once deleted, the photo will move to trash and stay for 30 days .",
        "Delete photo?",
          {
            cancelButtonText: "Cancel",
            confirmButtonText: "Delete",
            cancelButtonClass: "gg_cancel_button",
            confirmButtonClass: "gg_submit_button",
            customClass: "gg_confirm",
            showClose: false
          }
        )
          .then(() => {
             console.log(this.list)
            Ajax.post("/moments/media/delete", this.list).then(res=>{
              if(res.code=='0000'){
                this.handleSnack('Photo has been deleted')
                this.$emit('ok')
              }else{
                this.handleSnack(res.message)
              }
            }).catch(()=>{console.log(3434343)})
          })
          .catch(() => {});
    },
    handleSnack(content) {
      this.snackbarData.content = content;
      this.snackbarData.visible = true;
    },
  }
}
</script>
<style lang="less" scoped>
.icon-button{
  &.disabled{
    cursor: not-allowed;
  }
}
span{

    display: inline-block;
    text-align: center;
    margin: 0 10px;
}
.svg-icon{
  height:20px;
}
</style>