<template>
  <div class="ui-text-edit" :class="editorContentSw ? 'showbar' : 'hidebar'">
    <div class="announcement-inner">
      <div class="edit-editing">
        <editor v-if="editorInit" @blur="toBlur" :placeholder='placeholder' :init="editorInit" v-model="editorContent" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Editor from '@tinymce/tinymce-vue'
import { htmlDecode } from './../../../utils/utils.js'

export default {
  components: {
    Editor
  },
  props: {
    width:{
      default: 100,
      type: Number
    },
    height:{
      default: 100,
      type: Number
    },
    placeholder:{
      default: '',
      type: String
    },
    modelkey:{
      default: '',
      type: String
    },
    fontsize:{
      default: 15,
      type: Number
    },
    fontweight:{
      default: 'normal',
      type: String
    },
    color:{
      default: '#707070',
      type: String
    },
    value:{
      default: '',
      type: String
    }
  },
  data() {
    return {
      editorContentSw: false,
      editorContent: '',
      editorInit: null,
    };
  },
  watch: {
    value: function() {
      this.editorContent = this.value
    },
    editorContent: function() {
      this.$emit('getContent', this.editorContent, this.modelkey)
      // let num = this.removeHTMLTag(this.editorContent).length
      // if(num > 1000) {
      //   this.editorContentSw = false
      // } else {
      //   this.editorContentSw = true
      // }
    }
  },
  computed: {

  },
  created() {
    let that = this
    // this.editorContent = this.htmlDecode(this.value) 
    this.editorContent = this.value
    // alignright alignjustify
    this.editorInit = {
      language: 'en',
      height: that.height,
      convert_urls: false,
      statusbar: false,
      menubar: false, // 禁用菜单栏
      branding: false, // 隐藏右下角技术支持
      elementpath: false, // 隐藏底栏的元素路径
      advlist_bullet_styles: '',
      advlist_number_styles: '',
      body_class: 'panel-body',
      content_style: '.panel-body { background: transparent; font-weight: ' + this.fontweight + '; color: ' + this.color + '; font-size: ' + this.fontsize + 'px; font-family: Arial; line-height: 18px; } .panel-body p { margin: 0; }',
      plugins: [
        'placeholder autoresize advlist anchor autolink autosave code codesample colorpicker colorpicker directionality emoticons fullscreen hr image imagetools importcss insertdatetime link lists media nonbreaking noneditable pagebreak paste preview print save searchreplace spellchecker tabfocus table template textcolor textpattern visualblocks visualchars wordcount'
      ],
      toolbar: [
        'bold italic underline | bullist numlist | alignleft aligncenter alignjustify alignright | fontsizeselect | forecolor'
      ],
      fontsize_formats: "8pt 10pt 11pt 12pt 14pt 18pt 24pt 36pt",
      custom_colors: false,
      paste_preprocess: function(plugin, args) {
          args.content = that.strip_tags(args.content,'<strong><p><i><u><em><h1><h2><h3><h4><h5><ul><li><ol><span>');
      },
      autoresize_bottom_margin: 0,
      autoresize_max_height:  that.height, // 编辑区域的最大高
      autoresize_min_height: that.height, // 编辑区域的最小高度
      // plugin_preview_width: 1000,
      autoresize_on_init: true,
      autoresize_overflow_padding: 0,
      setup: function(editor) {
        editor.on('focus', function() {
          that.editorContentSw = true
        })
        editor.on('blur', function(){
          that.editorContentSw = false
          that.toBlur()
        })
      }
    }
  },
  mounted(){
    
  },
  methods: {

    htmlDecode,

    strip_tags (_str, allowed_tags){
      var str = _str.replace(new RegExp("<h(.*?)>","g"),'<strong>').replace(new RegExp("</h(.*?)>","g"),'</strong>');
      var key = '', allowed = false;
      var matches = [];    var allowed_array = [];
      var allowed_tag = '';
      var i = 0;
      var k = '';
      var html = ''; 
      var replacer = function (search, replace, str) {
          return str.split(search).join(replace);
      };
      // Build allowes tags associative array
      if (allowed_tags) {
          allowed_array = allowed_tags.match(/([a-zA-Z0-9]+)/gi);
      }
      console.log(allowed_tags,allowed_array)
      str += '';
      // Match tags
      matches = str.match(/(<\/?[\S][^>]*>)/gi);
      // Go through all HTML tags
      for (key in matches) {
          if (isNaN(key)) {
              // IE7 Hack
              continue;
          }
          // Save HTML tag
          html = matches[key].toString();
          // Is tag not in allowed list? Remove from str!
          allowed = false;
          // Go through all allowed tags
          for (k in allowed_array) {            // Init
              allowed_tag = allowed_array[k];
              i = -1;
              if (i != 0) { i = html.toLowerCase().indexOf('<'+allowed_tag+'>');}
              if (i != 0) { i = html.toLowerCase().indexOf('<'+allowed_tag+' ');}
              if (i != 0) { i = html.toLowerCase().indexOf('</'+allowed_tag)   ;}

              // Determine
              if (i == 0) {               
                  allowed = true;
                  break;
              }
          }
          if (!allowed) {
              str = replacer(html, "", str); // Custom replace. No regexing
          }
      }
      return str;
    },

    toBlur(){
      this.$emit('toBlur')
    },

    removeHTMLTag(str) {
      str = str.replace(/<\/?[^>]*>/g, '');
      str = str.replace(/[ | ]*\n/g, '\n');
      str = str.replace(/&nbsp;/ig, '');
      str = str.replace(/\s/g, '');
      return str;
    }
    
  }
};
</script>
<style lang='less' scoped>
.ui-text-edit {
  position: relative;
  width: 200px;
  height: 100px;
  border: 1px dashed #707070;
  background: transparent;
  box-sizing: initial;
  cursor: pointer;
  transition: ease .2s;

  &:hover {
    border-color: #1A73E8;
  }
}
</style>