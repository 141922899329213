<!-- class students list -->
<template>
  <div class="content has_breadcrumb">
    <base-breadcrumb
      :data="[
        {
          routeName: 'locations',
          name: user.schoolName,
        },
        {
          routeName: 'moments',
          name: 'Moments',
        },
        {
          name: classname,
        },
      ]"
      slot="breadcrumb"
    />
    <GgPage
      pageType="2"
      title="Moments"
      @forward="forward"
      @next="next"
    >
      <div slot="topAppBar" class="topAppBar">
        <!-- <month-switch @change="dayChange" :defaultVal="defSwitchVal"></month-switch> -->
        <search :classId="$route.params.id"></search>
      </div>
      
      <template v-slot:left>
        <div class="mar-l-b">
          <upload-btn :classId="$route.params.id"><gg-create-button label="Upload" /></upload-btn>
        </div>
        <class-side-menu activeIndex="momentClassPending" />
      </template>
      <GgPageHeader :title="classname" class="mar-b-0" />
      <div class="optbar" v-show="checkedList.length>0">
        <el-button type="text" @click="clearCheck"><i class="el-icon-close"/></el-button>
        <span class="count">{{checkedList.length}} Selected</span>
        <div class="btns">
          <baseButton @click="handleUndo()" :disabled="!hasPermission('Share media_Edit')" type="primary">Undo</baseButton>
          <baseButton @click="handleApprove()" :disabled="!hasPermission('Share media_Full')" type="primary">Approve</baseButton>
          <!-- <baseButton @click="handleReject()" type="primary">Reject</baseButton> -->
        </div>
      </div>
      <image-list :classId="$route.params.id" :list="imgList" :loading="loading" @checkChange="onCheckChange" :pending="true" ref="imageList" @refresh="refresh"/>
    </GgPage>
  </div>
</template>

<script>
import { Ajax } from "@/common";
import moment from 'moment';
import { mapState } from "vuex";
import uploadBtn from './../modules/uploadBtn'
import SvgIcon from "@/components/SvgIcon";
import classSideMenu from './../modules/classSideMenu'
import monthSwitch from './../modules/monthSwitch'
import search from './../modules/search'
import imageList from './../modules/imageList'
import classMixin from './../mixin/classMixin'
import bus from '../utils/bus'
import { hasPermission } from '../utils/utils'
export default {
  components: {SvgIcon,classSideMenu,monthSwitch,search,imageList,uploadBtn},
  mixins: [classMixin],
  data() {
    return {
      boolean: true,
      loading:false,
      classname:this.$store.state.moments.curClassName,
      defSwitchVal:0,
      date:this.$route.query.time?moment(parseInt(this.$route.query.time)).valueOf():moment().valueOf(),
      imgList: [
        
      ],
      checkedList:[]
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user
    }),
  },
  created() {
    this.initMonthSwitchVal()
    this.fetchData()
    bus.$on('refreshImageList', this.fetchData)  
  },
  methods: {
    hasPermission,
    refresh(){
      bus.$emit('refreshImageList')
    },
    initMonthSwitchVal(){
     
      var _def = moment().valueOf()
     
      if(this.$route.query.time&&this.$route.query.time.length==13){
        _def = parseInt(this.$route.query.time)
      }
      this.date = _def
      this.defSwitchVal = _def
    },
    dayChange(val){
      this.date = val
      this.fetchData()
    },
    async fetchData(){
      this.fetchList()
      var resClass = await Ajax.get("/usermanage/class/selectByIdV1", {
        id: this.$route.params.id,
      })
      if(resClass.code=='0000'){
        this.classname = resClass.data.className
        this.$store.commit("SET_CURCLASSNAME", this.classname);
      }
      
      
    },
    async fetchList(){
      if(this.date>new Date().getTime()){
        this.imgList = []
        return
      }
      this.loading = true;
     
      var res = await Ajax.post("/moments/media/getMediaListV1", {
        classId: this.$route.params.id,
        status: 1,
        // date: moment(this.date).startOf('day').utc().format('YYYY-MM-DD HH:mm:ss'),
        size:9999
      })
      
      this.loading = false;
      if(res.code=='0000'){
        this.imgList = res.data.records
      }
      
    },
    handleApprove(){
      this.$confirm(
          "Once approved, the photos will be shared and viewed by parents.",
          'Approve sharing?',
          {
            cancelButtonText: "Cancel",
            confirmButtonText: "Approve",
            cancelButtonClass: "gg_cancel_button",
            confirmButtonClass: "gg_submit_button",
            customClass: "gg_confirm",
            showClose: false
          }
        )
          .then(() => {
            const _batchNoList = this.checkedList.map(o=>o.batchNo)
            Ajax.post("/moments/share/approveBatchV1", {
              classId: this.$route.params.id,
              approvalUser:this.user.userId,
              batchNoList:_batchNoList
            }).then(res=>{
              if(res.code=='0000'){
                // this.handleSnack()
                bus.$emit('refreshImageList')
                this.clearCheck()
                this.fetchList()
              }else{

              }
            })
          })
          .catch(() => {});
     
    },
    handleUndo(){
      this.$confirm(
          "Once undone, the photos will change from pending to uploaded.",
          "Undo submission?",
          {
            cancelButtonText: "Cancel",
            confirmButtonText: "Undo",
            cancelButtonClass: "gg_cancel_button",
            confirmButtonClass: "gg_submit_button",
            customClass: "gg_confirm",
            showClose: false
          }
        )
          .then(() => {
            const _batchNoList = this.checkedList.map(o=>o.batchNo)
            Ajax.post("/moments/media/unDoBatch", 
              _batchNoList).then(res=>{
              if(res.code=='0000'){
                // this.handleSnack()
                bus.$emit('refreshImageList')
                this.clearCheck()
                this.fetchList()
              }else{

              }
            })
          })
          .catch(() => {});
     
    },
    handleReject(){
      this.$confirm(
          "Once rejected, the photos will not be shared and viewed by parents.",
          'Reject sharing?',
          {
            cancelButtonText: "Cancel",
            confirmButtonText: "Reject",
            cancelButtonClass: "gg_cancel_button",
            confirmButtonClass: "gg_submit_button",
            customClass: "gg_confirm",
            showClose: false
          }
        )
          .then(() => {
             const _batchNoList = this.checkedList.map(o=>o.batchNo)
            Ajax.post("/moments/share/rejectBatchV1", {
              classId: this.$route.params.id,
              approvalUser:this.user.userId,
              batchNoList:_batchNoList
            }).then(res=>{
              if(res.code=='0000'){
                // this.handleSnack()
                this.clearCheck()
                this.fetchList()
              }else{

              }
            })
          })
          .catch(() => {});
    },
    onCheckChange(val){
      this.checkedList = val
    },
    clearCheck(){
      this.$refs.imageList.clearCheck()
    },
    forward(val) {
      console.log(val);
    },
    next(val) {
      console.log(val);
    },
  },
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
/deep/ .ggPage-main{
  position: relative;
}
.ggPageTitle{
  /deep/.ggPageTitle-left .titleInfo .title{
    font-family:'opensans-semibold';
    color:#5f6368
  }
}
.content.has_breadcrumb .edoovo-breadcrumb {
    z-index: 10;
}
.optbar{
  position: fixed;
  top:64px;left:0;
  z-index: 99;
  width: 100%;
  background:#fff;
  box-shadow: 0px 0px 6px #00000029;
  padding:8px 20px;
  line-height: 40px;
  /deep/ .drop_down_menus{
    top:40px!important;
    right:10px!important;
  }
  .el-button{
    color:#777;
    margin-right:10px;
  }
  .btns{
    position: absolute;
    right:20px;
    top: 50%;
    // transform: translate(0, -50%);
    text-align: right;
    .base-button{
      padding: 0 12px;
   }
  }
}
.content.has_breadcrumb{
  /deep/ .ggPage-main-center-content{
    height:auto;
  }
}


</style>