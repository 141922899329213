
import store from './../../../store'

export function batchImgDownload(imgList){
  for(let i=0;i<imgList.length;i++){
    imgDownload(imgList[i].path,imgList[i].id)
  }
}

export function imgDownload(link,filename){
  // let a = document.createElement('a')
  // a.href = process.env.VUE_APP_fileBasePath+link
  // a.download = filename || 'default.png'
  // a.dispatchEvent(new MouseEvent('click'))
  console.log(1212112)
  let img = new Image()
  img.setAttribute('crossOrigin', 'Anonymous')
  img.src = process.env.VUE_APP_fileBasePath+ link + '?v=' + new Date().getTime()
  img.onload = function(){
    console.log(22212222212112)
    let canvas = document.createElement('canvas')
    let context = canvas.getContext('2d')
    canvas.width = img.width
    canvas.height = img.height
    context.drawImage(img, 0, 0, img.width, img.height)
    let url = canvas.toDataURL('images/png')
    let a = document.createElement('a')
    let event = new MouseEvent('click')
    a.download = filename || 'default.png'
    a.href = url
    a.dispatchEvent(event)
  }
  
}

export function hasPermission(permission){
  const ls = store.getters.rolePermission
  const userType = store.state.user.userType
  // View Create Edit Full
  const kind = permission.split('_')[0]
  const _role = ls.find(o=>o.indexOf(kind)>=0) || ''
  if(userType=='1'){
    return true
  }else{
    if(_role.length==0){
      // 没有相关权限
      return false
    }else{
      const _opt = _role.split('_')[1] || ''
      if(_opt.length==0){
        return false
      }else{
        if(_opt == 'Full'){
          return permission == kind+'_View' || permission == kind+'_Create' || permission == kind+'_Edit' || permission == kind+'_Full'
        }else if(_opt == 'Edit'){
          return permission == kind+'_View' || permission == kind+'_Create' || permission == kind+'_Edit'
        }else if(_opt == 'Create'){
          return permission == kind+'_View' || permission == kind+'_Create'
        }else if(_opt == 'View'){
          return permission == kind+'_View'
        }else{
          return false
        }
      }
    }
  }
}