<!-- class students list -->
<template>
  <div class="content has_breadcrumb journal-inner">
    <base-breadcrumb
      :data="[
        {
          routeName: 'locations',
          name: user.schoolName,
        },
        {
          routeName: 'journal',
          name: 'Journal',
        },
        {
          path: '/journal/class/' + classid,
          name: classname,
        },
        {
          name: createTypeArr[createType],
        },
      ]"
      slot="breadcrumb"
    />
    <GgPage pageType="2" title="Journal">
      <div slot="topAppBar">
        <search />
      </div>
      <template v-slot:left>
        <div class="journal-page-left" :class="createTypeName[createType]">
          <div class="title">TEMPLATES</div>
          <ul :style="{ height: pageLeftHeight + 'px' }" :class="{ 'nvalid' : templatesNvalid }">
            <li v-for="(item, index) in journalModel[createTypeName[createType]]" :key="index">
              <div @click="selectModel(item)" class="page-min" :class="item"></div>
            </li>
          </ul>
        </div>
      </template>
      <div class="journal-list-inner" v-loading="loading">
        <div class="journal-create-main" :style="{ paddingTop: (createTypeName[createType] === 'portfolio' ? '93' : '53') + 'px' }" :class="createTypeName[createType]">
          <div class="journal-top-fixed">
            <div class="journal-title" :class="{ 'error' : !journalTitSw }">
              <input
                v-model="journalTit"
                placeholder="Title *"
              />
              <div class="font-error">Character limit of 40</div>
              <div class="font-num">{{ journalTit.length }}/40</div>
            </div>

            <div class="journal-people-tag" v-if="createTypeName[createType] === 'portfolio' && journalStuInfo">
              <div class="tag">
                <span class="avatar" :style="'background-image:url('+journalStuInfo.photo+')'" v-if="journalStuInfo.photo"></span>
                <!-- <div class="no" v-else>
                  {{ journalStuInfo.firstName ? journalStuInfo.firstName.substring(0, 1).toUpperCase() : '-' }}{{ journalStuInfo.lastName ? journalStuInfo.lastName.substring(0, 1).toUpperCase() : '-' }}
                </div> -->
                <div class="no" v-else><img src="@/assets/images/icons/person_black_24dp.svg" /></div>
                <span class="name">{{ journalStuInfo.firstName + ' ' + journalStuInfo.lastName }}</span>
              </div>
            </div>
          </div>

          <div class="journal-page-main">
            <div class="journal-page-list">
              <div class="journal-page-box-inner" :class="{ 'savepdf' : saveCoverSw }">
                <div class="journal-page-box"
                  v-for="(item, index) in journalPageList"
                  :key="index"
                  :class="{ 'active': journalPageActive === index }"
                >
                  <div class="journal-page-bar-inner">
                    <div class="journal-page-bar">
                      <div class="journal-page-num">Page {{ index + 1 }}/{{ journalPageList.length }}</div>
                      <div class="journal-page-col">
                        <div v-if="item.note" @click="addNoteShow(true, index)" class="col-add col-btn"><div class="col-tit">Edit note</div><img src="@/assets/images/icons/journal_has_note.svg" /></div>
                        <div v-else @click="addNoteShow(true, index)" class="col-add col-btn"><div class="col-tit">Add note</div><img src="@/assets/images/icons/journal_add_note.svg" /></div>

                        <template v-if="journalPageList.length > 1">
                          <div v-if="index === 0" class="col-prev col-btn die"><div class="col-tit">Move up</div><img src="@/assets/images/icons/journal_prev.svg" /></div>
                          <div @click="pageMove('up', index)" v-else class="col-prev col-btn"><div class="col-tit">Move up</div><img src="@/assets/images/icons/journal_prev.svg" /></div>
                          <div v-if="index === journalPageList.length - 1" class="col-next col-btn die"><div class="col-tit p1">Move down</div><img src="@/assets/images/icons/journal_next.svg" /></div>
                          <div @click="pageMove('down', index)" v-else class="col-next col-btn"><div class="col-tit p1">Move down</div><img src="@/assets/images/icons/journal_next.svg" /></div>
                        </template>

                        <div v-if="journalPageList.length < 20" @click="pageAdd(index)" class="col-add col-btn"><div class="col-tit">Add page</div><img src="@/assets/images/icons/journal_note_add.svg" /></div>
                        <div v-else class="col-add col-btn die"><div class="col-tit p4">Cannot add more than 20 pages</div><img src="@/assets/images/icons/journal_note_add.svg" /></div>

                        <div v-if="journalPageList.length < 20" @click="pageDuplicate(index)" class="col-copy col-btn"><div class="col-tit p2">Duplicate page</div><img src="@/assets/images/icons/journal_file_copy.svg" /></div>
                        <div v-else class="col-copy col-btn die"><div class="col-tit p3">Cannot duplicate more than 20 pages</div><img src="@/assets/images/icons/journal_file_copy.svg" /></div>

                        <div v-if="journalPageList.length > 1" @click="pageDeleteOpen(index)" class="col-delete col-btn"><div class="col-tit p1">Delete page</div><img src="@/assets/images/icons/journal_file_delete.svg" /></div>
                      </div>
                    </div>
                  </div>
                  <div ref="page" class="journal-page" @click="selectJournal(index)">

                    <components
                      v-if="item.no"
                      @toBlur="toBlur"
                      @postdata="postdata"
                      @postCover="postCover"
                      :saveCoverSw="saveCoverSw"
                      :data="item.data"
                      :index="index"
                      :is="item.no"
                    ></components>

                    <div v-else class="normal-val">
                      <div class="nothing">Select a template {{ item.val }}</div>
                    </div>
                  </div>
                </div>
              </div>

              <div v-if="journalPageList.length < 20" @click="pageAddLast" class="add-page-btn"><img class="col-add-page" src="@/assets/images/icons/journal_file_add.svg" /> Add page</div>
            </div>
          </div>
        </div>
        <div class="cancel">
          <div class="tit">
            <baseButton class="cancel-btn" @click="backpage">Cancel</baseButton>
          </div>
          <div class="con">
            <gg-dropdown>
              <el-tooltip
                popper-class="edoovo-table-cell-tooltip"
                :visible-arrow="false"
                content="Preview"
                placement="bottom"
                effect="light"
              >
                <span>Preview</span>
              </el-tooltip>
              <template slot="menus">
                <gg-dropdown-item @click="toPreview"> Preview </gg-dropdown-item>
                <el-tooltip
                  v-if="journalTit.length <= 0"
                  popper-class="edoovo-table-cell-tooltip"
                  :visible-arrow="false"
                  :content="'Cannot save draft as ' + (this.createType === '0' ? 'newsletter' : 'portfolio') + ' has no title yet'"
                  placement="bottom"
                >
                  <gg-dropdown-item class="el-tooltip" :disabled="true"> Save </gg-dropdown-item>
                </el-tooltip>
                <gg-dropdown-item v-else class="el-tooltip" @click="saveDraft('draft')"> Save </gg-dropdown-item>

                <template v-if="!journalStatus || journalStatus === 'DRAFT'">
                  <el-tooltip
                    v-if="journalTit.length <= 0 || journalTit.length > 40"
                    popper-class="edoovo-table-cell-tooltip"
                    :visible-arrow="false"
                    :content="'Cannot submit as ' + (this.createType === '0' ? 'newsletter' : 'portfolio') + ' has no title yet'"
                    placement="bottom"
                  >
                    <gg-dropdown-item class="el-tooltip" :disabled="true"> Submit </gg-dropdown-item>
                  </el-tooltip>
                  <gg-dropdown-item v-else class="el-tooltip" @click="journalSubmitShow"> Submit </gg-dropdown-item>
                </template>

                <template v-if="journalStatus === 'PENDING'">
                  <el-tooltip
                    v-if="journalTit.length <= 0 || journalTit.length > 40"
                    popper-class="edoovo-table-cell-tooltip"
                    :visible-arrow="false"
                    :content="'Cannot Undo as ' + (this.createType === '0' ? 'newsletter' : 'portfolio') + ' has no title yet'"
                    placement="bottom"
                  >
                    <gg-dropdown-item class="el-tooltip" :disabled="true"> Undo </gg-dropdown-item>
                  </el-tooltip>
                  <gg-dropdown-item v-else class="el-tooltip" @click="journalUndoShow"> Undo </gg-dropdown-item>

                  <template v-if="user.userType === '1'">
                    <el-tooltip
                      v-if="journalTit.length <= 0 || journalTit.length > 40"
                      popper-class="edoovo-table-cell-tooltip"
                      :visible-arrow="false"
                      :content="'Cannot Approve as ' + (this.createType === '0' ? 'newsletter' : 'portfolio') + ' has no title yet'"
                      placement="bottom"
                    >
                      <gg-dropdown-item class="el-tooltip" :disabled="true"> Approve </gg-dropdown-item>
                    </el-tooltip>
                    <gg-dropdown-item v-else class="el-tooltip" @click="journalApproveShow"> Approve </gg-dropdown-item>
                  </template>

                </template>
              </template>

            </gg-dropdown>
          </div>
        </div>
      </div>

      <gg-dialog
        :close-on-press-escape="false"
        :close-on-click-modal="false"
        class="add-note-dialog mp-dialog"
        :visible.sync="addNoteSw"
        width="580px"
        :title="journalPageList[noteIndex].note ? 'Edit note' : 'Add new note'"
      >
        <div class="dialog-body" :class="{ 'error' : addNote.length > 500 }">
          <gg-input
            placeholder="Note"
            class="add-note-input"
            v-model="addNote"
            :error="errorNote['formTit']"
            :filedType="'textarea'"
          />
          <div class="font-error">Character limit of 500</div>
          <div class="font-num">{{ addNote.length }}/500</div>
        </div>

        <span slot="footer" class="dialog-footer">
          <baseButton @click="addNoteShow(false, null)">Cancel</baseButton>
          <baseButton @click="addNoteToList" :disabled="addNote.length > 500 || (addNote === journalPageList[noteIndex].note)" type="primary">Save</baseButton>
        </span>
      </gg-dialog>

      <gg-Dialog width="580px" :visible.sync="pageDeleteSw" title="Delete page?" class="draft-dialog mp-dialog">
        <div class="dialogContent">Once deleted, you will not be able to recover the page data.</div>
        <span slot="footer" class="dialog-footer">
          <gg-cancel-button @click="pageDeleteSw = false">Cancel</gg-cancel-button>
          <gg-submit-button @click="pageDelete">Delete</gg-submit-button>
        </span>
      </gg-Dialog>

      <gg-Dialog
        width="580px"
        title="Save changes"
        class="noborder-dialog mp-dialog create-dialog"
        :visible.sync="formDialogView.visible"
        :show-close="true"
        :before-close="
          () => {
            formDialogView.visible = !formDialogView.visible;
          }
        "
      >
        <div class="dialog-wrapper">
          You have some unsaved changes. Please save them before you continue.
        </div>
      </gg-Dialog>

      <Snackbar :visible.sync="loadingShow" :content="loadingText" />

      <gg-Dialog width="580px" :visible.sync="journalSubmitSw" :title="'Submit ' + (createType === '0' ? 'newsletter' : 'portfolio') + '?'" class="draft-dialog mp-dialog">
        <div class="dialogContent">Once submitted, the {{ createType === '0' ? 'newsletter' : 'portfolio' }} will be subjected to approval for sharing.</div>
        <span slot="footer" class="dialog-footer">
          <gg-cancel-button @click="journalSubmitSw = false">Cancel</gg-cancel-button>
          <gg-submit-button @click="saveDraft('submit')">Submit</gg-submit-button>
        </span>
      </gg-Dialog>

      <gg-Dialog width="580px" :visible.sync="journalUndoSw" :title="'Undo ' + (createType === '0' ? 'newsletter' : 'portfolio') + '?'" class="draft-dialog mp-dialog">
        <div class="dialogContent">Once undone, the {{ createType === '0' ? 'newsletter' : 'portfolio' }} will change from pending to draft.</div>
        <span slot="footer" class="dialog-footer">
          <gg-cancel-button @click="journalUndoSw = false">Cancel</gg-cancel-button>
          <gg-submit-button @click="saveDraft('undo')">Undo</gg-submit-button>
        </span>
      </gg-Dialog>

      <gg-Dialog width="580px" :visible.sync="journalApproveSw" :title="'Approve ' + (createType === '0' ? 'newsletter' : 'portfolio') + '?'" class="draft-dialog mp-dialog">
        <div class="dialogContent">Once approved, the {{ createType === '0' ? 'newsletter' : 'portfolio' }} will be shared and viewed by parents.</div>
        <span slot="footer" class="dialog-footer">
          <gg-cancel-button @click="journalApproveSw = false">Cancel</gg-cancel-button>
          <gg-submit-button @click="saveDraft('approve')">Approve</gg-submit-button>
        </span>
      </gg-Dialog>

    </GgPage>
  </div>
</template>

<script>
import domtoimage from 'dom-to-image';
import moment from 'moment';
import {Ajax} from "@/common";
import {mapState} from "vuex";
import {hasPermission, htmlDecode} from './../utils/utils.js';
import search from './../modules/search'
import newsletterA from './../modules/model/newsletterA'
import newsletterB from './../modules/model/newsletterB'
import newsletterC from './../modules/model/newsletterC'
import newsletterD from './../modules/model/newsletterD'
import newsletterE from './../modules/model/newsletterE'
import newsletterF from './../modules/model/newsletterF'
import newsletterG from './../modules/model/newsletterG'
import newsletterH from './../modules/model/newsletterH'
import newsletterI from './../modules/model/newsletterI'
import newsletterJ from './../modules/model/newsletterJ'
import newsletterK from './../modules/model/newsletterK'
import newsletterL from './../modules/model/newsletterL'
import newsletterM from './../modules/model/newsletterM'
import newsletterN from './../modules/model/newsletterN'
import portfolioA from './../modules/model/portfolioA'
import portfolioB from './../modules/model/portfolioB'
import portfolioC from './../modules/model/portfolioC'
import portfolioD from './../modules/model/portfolioD'
import portfolioE from './../modules/model/portfolioE'
import {jsPDF} from "jspdf"

export default {
  components: {
    search,
    newsletterA,
    newsletterB,
    newsletterC,
    newsletterD,
    newsletterE,
    newsletterF,
    newsletterG,
    newsletterH,
    newsletterI,
    newsletterJ,
    newsletterK,
    newsletterL,
    newsletterM,
    newsletterN,
    portfolioA,
    portfolioB,
    portfolioC,
    portfolioD,
    portfolioE
  },
  computed: {
    ...mapState({
      user: (state) => state.user
    }),
  },
  data() {
    return {
      pdfurl: '',
      journalStatus: '',
      loading: false,
      temp: '',
      cover: '',
      saveCoverSw: false,
      htmlUrl: '',
      editid: '',
      thetime: '',
      classname: '',
      classid: '',
      noteIndex: 0,
      journalUndoSw: false,
      journalApproveSw: false,
      journalSubmitSw: false,
      pageDeleteIndex: null,
      pageDeleteSw: false,
      addNoteSw: false,
      addNote: '',
      errorNote: {
        formTit: {
          show: false,
          message: "Character limit of 500",
        }
      },
      templatesNvalid: false,
      tempSw: false,
      journalData: {},
      journalStuInfo: {},
      loadingText: '',
      loadingShow: false,
      journalPageActive: 0,
      journalModel: {
        newsletter: [
          'newsletter-a',
          'newsletter-b',
          'newsletter-c',
          'newsletter-d',
          'newsletter-f',
          'newsletter-e',
          'newsletter-g',
          'newsletter-h',
          'newsletter-i',
          'newsletter-j',
          'newsletter-k',
          'newsletter-l',
          'newsletter-m',
          'newsletter-n',
        ],
        portfolio: [
          'portfolio-a',
          'portfolio-b',
          'portfolio-c',
          'portfolio-d',
          'portfolio-e'
        ]
      },
      journalPageList: [{
        id: '',
        no: '',
        data: {}
      }],
      journalTitSw: true,
      journalTit: '',
      isEdit: true,
      formDialogView: {
        visible: false,
      },
      pageLeftHeight: '',
      createType: '',
      createTypeName: ['newsletter', 'portfolio'],
      createTypeArr: ['Create a newsletter', 'Create a portfolio'],
      rootPath: process.env.VUE_APP_fileBasePath
    };
  },
  watch: {
    journalTit: function() {
      let num = this.journalTit.length
      if(num > 40) {
        this.journalTitSw = false
      } else {
        this.journalTitSw = true
      }
    }
  },
  created() {
    this.thetime = this.$route.query.time || ''
    this.classname = sessionStorage.getItem("journalClassName") || ''
    this.classid = this.$route.query.classid || sessionStorage.getItem("journalClassId")
    this.createType = this.$route.query.type || '0'
    this.journalStuInfo = JSON.parse(sessionStorage.getItem("journalCreatePortfolioUser"))

    if(this.journalPageList[this.journalPageActive].no){
      this.templatesNvalid = true
    }else{
      this.templatesNvalid = false
    }

    this.editid = this.$route.query.editid || ''
    if(this.editid) {
      this.getJournal(this.editid)
    }
  },
  beforeRouteLeave(to, form, next) {
    if (this.isEdit) {
      this.formDialogView.visible = true;
    } else {
      next();
    }
  },
  mounted(){
    window.onresize = () => {
      this.getHeight()
    }
    this.getHeight()
  },
  methods: {

    htmlDecode,

    hasPermission,

    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(',');
      var mime = arr[0].match(/:(.*?);/)[1];
      var bstr = atob(arr[1]);
      var n = bstr.length;
      var u8arr = new Uint8Array(n);

      while(n--){
        u8arr[n] = bstr.charCodeAt(n);
      }

      //转换成file对象
      return new File([u8arr], filename, {type:mime});

      //转换成成blob对象
      // return new Blob([u8arr],{type:mime});
    },

    journalSubmitShow() {
      this.journalSubmitSw = true
    },
    journalUndoShow() {
      this.journalUndoSw = true
    },
    journalApproveShow() {
      this.journalApproveSw = true
    },

    getStudentsInfo(id){
      let data = {
        stuId: id
      }
      Ajax.get('/usermanage/student/selectByStuId', data).then((res) => {
        if(res.code === '0000'){
          this.journalStuInfo = res.data
        }
      });
    },

    getJournal(id) {
      let data = {
        id: id
      }
      Ajax.get("journal/" + id, data).then((res) => {
        if(res.code === '0000'){
          this.journalData = res.data
          this.journalTit = res.data.title
          this.journalStatus = res.data.status
          this.journalPageList = JSON.parse(res.data.content)

          if(this.editid) {
            for(let item of this.journalPageList) {
              if(item.data.edit) {
                item.data.edit = this.htmlDecode(item.data.edit)
              }
            }
          }

          this.toBlur()
          if(!res.data.newsletter) {
            this.getStudentsInfo(res.data.recipient)
          }
        }
      });
    },

    postCover(v) {
      this.cover = v
      this.saveDraftFun(this.temp)
    },

    saveDraft(status) {
      this.loading = true
      this.journalSubmitSw = false
      this.journalUndoSw = false
      this.journalApproveSw = false
      this.temp = status
      this.saveCoverSw = true

      if(!this.journalPageList[0].no) {
        this.saveDraftFun(status)
      }
    },

    async saveDraftFun(status) {
      let data = {},
      editid = this.editid

      data.classId = this.$route.query.classid
      data.content = JSON.stringify(this.journalPageList)
      data.cover = this.cover
      data.isNewsletter = this.createType === '0' ? true : false
      data.staffId = this.user.userId
      data.title = this.journalTit

      if(status === 'draft') {
        if(this.journalStatus === 'DRAFT' || this.journalStatus === 'PENDING') {
          data.isSubmit = false
        }
      }
      else if(status === 'undo') {
        data.afterDo = 'UNDO'
      }
      else if(status === 'approve') {
        data.afterDo = 'APPROVE'
      }
      else if(status === 'submit') {
        if(editid) {
          data.afterDo = 'SUBMIT'
        }else{
          data.isSubmit = true
        }
      }

      if(!data.isNewsletter) {
        data.recipient = this.journalStuInfo.id
      }

      // draft submit 生成pdf并上传
      if(status === 'draft' || status === 'submit') {
        // let pages = this.$refs.page,
        // pdf;

        // for(let idx = 0; idx < pages.length; idx++) {
        //   let canvas = await html2canvas(pages[idx], {
        //     backgroundColor: "transparent",
        //     allowTaint: true,
        //     useCORS: true,
        //   });

        //   let pageData = canvas.toDataURL('image/jpeg', 1.0)
        //   if(pdf == null) {
        //     pdf = new jsPDF({
        //       orientation: canvas.width > canvas.height ? "l" : "p",
        //       unit: "pt",
        //       format: [canvas.width, canvas.height]
        //     })
        //   }
        //   pdf.setPage(idx+1)
        //   pdf.addImage(pageData, 'JPEG', 0, 0, canvas.width, canvas.height)
        //   if(idx + 1 < pages.length) {
        //     pdf.addPage({
        //       orientation: canvas.width > canvas.height ? "l" : "p",
        //       unit: "pt",
        //       format: [canvas.width, canvas.height]
        //     })
        //   }
        // }

        // // 将base64格式的字符串转换为file文件
        // var buffer = pdf.output("datauristring")
        // var myfile = this.dataURLtoFile(buffer, moment().valueOf() + 'files.pdf')
        // var formData = new FormData()
        // formData.append("file", myfile)
        // formData.append("multipartFile", myfile)

        this.createpdf(data, status, editid)
      }
      else {
        if(editid) {
          this.editJournal(data, status, editid)
        }else{
          this.createJournal(data, status)
        }
      }
    },

    async createpdf(data, status, editid) {
      this.loading = true
      let pages = this.$refs.page;
      let toImages = []
      let pdf
      let scale= 3;
      let time = 0
      let ut = navigator.userAgent
      for (let idx = 0; idx < pages.length; idx++) {
        if((/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent))){
            time = time + 16000
          }
      setTimeout(()=>{
        let width = pages[idx].getBoundingClientRect().width * scale
        let height = pages[idx].getBoundingClientRect().height * scale
        // toImages.push(domtoimage.toJpeg(pages[idx]))
        toImages.push(domtoimage.toJpeg(pages[idx],
        {
          quality: 0.95,
          width: pages[idx].getBoundingClientRect().width * scale,
          height: pages[idx].getBoundingClientRect().height * scale,
          style: {
            margin:0,
            transform: 'scale(' + scale + ')',
            // transform: 'scale(1)',
            transformOrigin: 'top left',
            width: pages[idx].getBoundingClientRect().width * scale,
            height: pages[idx].getBoundingClientRect().height * scale,
          }
        }
      ).then(data => domtoimage.toJpeg(pages[idx],
            {
              quality: 0.95,
              width: pages[idx].getBoundingClientRect().width * scale,
              height: pages[idx].getBoundingClientRect().height * scale,
              style: {
                margin:0,
                transform: 'scale(' + scale + ')',
                // transform: 'scale(1)',
                transformOrigin: 'top left',
                width: pages[idx].getBoundingClientRect().width * scale,
                height: pages[idx].getBoundingClientRect().height * scale,
              }
            })))
            },time)
      }
      setTimeout(()=>{
      Promise.allSettled(toImages).then(arr => {
        console.log(arr)
        for(let idx = 0; idx < arr.length; idx++) {
          let width = pages[idx].getBoundingClientRect().width * scale
          let height = pages[idx].getBoundingClientRect().height * scale
          console.log(width)
          console.log(height)
          if (pdf == null) {
            pdf = new jsPDF({
              orientation: width > height ? "l" : "p",
              unit: "mm",
              format : width > height ? [297, 210] : [210, 297],
            })
          }
          pdf.setPage(idx + 1)
          let img = arr[idx].value
          console.log(img)
          pdf.addImage(img, 'JPEG', 5, 5, width > height ? 297 - 10 : 210 - 10, width > height ? 210 - 10: 297 - 10)
          if (idx + 1 < pages.length) {
            pdf.addPage({
              orientation: width > height ? "l" : "p",
              unit: "mm",
              format: [width, height]
            })
          }
        }

        // 将base64格式的字符串转换为file文件
        var buffer = pdf.output("datauristring")
        var myfile = this.dataURLtoFile(buffer, moment().valueOf() + 'files.pdf')
        var formData = new FormData()
        formData.append("file", myfile)
        formData.append("multipartFile", myfile)
        console.log(formData)

        // 上传pdf
        if (formData) {
          Ajax.post(
            "/media/public/file/uploadExt",
            formData, {
            headers: { "Content-Type": "multipart/form-data" }
          })
            .then((res) => {
              if (res.code === "0000") {
                data.filePath = res.data.filePath

                if(editid) {
                  this.editJournal(data, status, editid)
                }else{
                  this.createJournal(data, status)
                }
              }
            })
        }

        this.loading = false
      })
      },time)
    },

    createJournal(data, status){
      Ajax.post('/journal/addJournalV1', data)
        .then((res) => {
          if(res.code === '0000'){
            let sn = ''
            this.loading = false
            this.loadingShow = true

            if(status === 'draft') {
              this.loadingText = (this.createType === '0' ? 'Newsletter' : 'Portfolio') + ' has been saved'
              sn = 'draft'
            }
            else if(status === 'undo') {
              this.loadingText = (this.createType === '0' ? 'Newsletter' : 'Portfolio') + ' has been moved to draft'
              sn = 'draft'
            }
            else if(status === 'approve') {
              this.loadingText = (this.createType === '0' ? 'Newsletter' : 'Portfolio') + ' has been posted'
              sn = 'posted'
            }
            else if(status === 'submit') {
              this.loadingText = (this.createType === '0' ? 'Newsletter' : 'Portfolio') + ' has been submitted'
              sn = 'pending'
            }

            setTimeout(()=>{
              this.isEdit = false
              this.$router.push('/journal/class/' + this.classid + '?time=' + this.thetime + '&classname=' + this.classname + '&typename=' + (this.createType === '0' ? 'newsletter' : 'portfolio') + '_' + sn)
            }, 1500)
          }else{
            this.loading = false
            this.loadingShow = true
            this.loadingText = res.message
            this.saveCoverSw = false
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    editJournal(data, status, editid){
      Ajax.put('/journal/' + editid, data)
        .then((res) => {
          if(res.code === '0000'){
            let sn = ''
            this.loading = false
            this.loadingShow = true

            if(status === 'draft') {
              this.loadingText = (this.createType === '0' ? 'Newsletter' : 'Portfolio') + ' has been edited'
              if(this.journalStatus === 'DRAFT') {
                sn = 'draft'
              }else if(this.journalStatus === 'PENDING'){
                sn = 'pending'
              }
            }
            else if(status === 'undo') {
              this.loadingText = (this.createType === '0' ? 'Newsletter' : 'Portfolio') + ' has been moved to draft'
              sn = 'draft'
            }
            else if(status === 'approve') {
              this.loadingText = (this.createType === '0' ? 'Newsletter' : 'Portfolio') + ' has been posted'
              sn = 'posted'
            }
            else if(status === 'submit') {
              this.loadingText = (this.createType === '0' ? 'Newsletter' : 'Portfolio') + ' has been submitted'
              sn = 'pending'
            }

            setTimeout(()=>{
              this.isEdit = false
              this.$router.push('/journal/class/' + this.classid + '?time=' + this.thetime + '&classname=' + this.classname + '&typename=' + (this.createType === '0' ? 'newsletter' : 'portfolio') + '_' + sn)
            }, 1500)
          }else{
            this.loading = false
            this.loadingShow = true
            this.loadingText = res.message
            this.saveCoverSw = false
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    selectModel(name) {
      if(!this.templatesNvalid && name) {
        let obj = this.journalPageList[this.journalPageActive]
        obj.no = name
        this.journalPageList[this.journalPageActive] = obj
      }
    },

    postdata(v, index) {
      let obj = this.journalPageList[index]
      obj.data = v
      this.journalPageList[index] = obj
    },

    addNoteToList() {
      let list = this.journalPageList
      list[this.noteIndex].note = this.addNote
      this.journalPageList = list
      this.addNoteShow(false, null)
    },

    addNoteShow(e, index) {
      this.addNoteSw = e
      this.noteIndex = index

      if(parseFloat(index).toString() != "NaN") {
        this.addNote = this.journalPageList[index].note || ''
      }

      if(!e) {
        this.noteIndex = 0
        this.addNote = ''
      }
    },

    validate(val) {
      if(this.addNote.length > 500){
        this.errorNote['formTit'].show = true
      }else{
        this.errorNote['formTit'].show = false
      }
    },

    toPreview() {
      sessionStorage.setItem("journalPageDataTemp__", JSON.stringify(this.journalPageList))
      let journalPageList = JSON.parse(sessionStorage.getItem("journalPageDataTemp__"))
      for(let item of journalPageList) {
        item.data.edit = this.HTMLEncode(item.data.edit)
      }
      sessionStorage.setItem("journalPageData", JSON.stringify(journalPageList))
      sessionStorage.setItem("journalPageDataTit", this.journalTit)
      let url = this.$router.resolve({path: '/journal/view?type=' + this.createTypeName[this.createType] })
      let win = window.open(url.href, '_blank')
      win.onload = function () {
        setTimeout(function () {
          win.document.title = 'Preview'
        }, 100)
      }
    },

    HTMLEncode(html) {
      var temp = document.createElement("div");
      (temp.textContent != null) ? (temp.textContent = html) : (temp.innerText = html);
      var output = temp.innerHTML;
      temp = null;
      return output;
    },

    pageAddLast() {
      let list = this.journalPageList
      list.splice(list.length, 0, {
        id: '',
        no: '',
        data: {}
      })
      this.journalPageList = list

      setTimeout(()=>{
        this.selectJournal(list.length - 1)
      }, 100)
    },

    pageDeleteOpen(index) {
      this.pageDeleteIndex = index
      this.pageDeleteSw = true
    },

    pageDelete() {
      let list = this.journalPageList,
      index = this.pageDeleteIndex,
      v = index === (list.length - 1) ? index - 1 : index
      list.splice(index, 1)
      this.journalPageList = list
      this.selectJournal(v)
      this.pageDeleteSw = false
    },

    pageMove(type, index) {
      let list = this.journalPageList,
      np = list[index]

      if(type === 'up') {
        list.splice(index, 1)
        list.splice(index - 1, 0, np)
        this.journalPageList = list
        this.selectJournal(index - 1)
      }
      if(type === 'down') {
        list.splice(index, 1)
        list.splice(index + 1, 0, np)
        this.journalPageList = list
        this.selectJournal(index + 1)
      }
    },

    pageDuplicate(index) {
      let list = this.journalPageList
      sessionStorage.setItem("dataTemp__", JSON.stringify(list[index]))
      list.splice(index + 1, 0, JSON.parse(sessionStorage.getItem("dataTemp__")))
      this.journalPageList = list

      setTimeout(()=>{
        this.selectJournal(index + 1)
      }, 100)
    },

    pageAdd(index) {
      let list = this.journalPageList
      list.splice(index + 1, 0, {
        id: '',
        no: '',
        data: {}
      })
      this.journalPageList = list
      setTimeout(()=>{
        this.selectJournal(index + 1)
      }, 100)
    },

    toBlur() {
      let sp = 0,
      indexData = this.journalPageList[this.journalPageActive].data
      for(let item in indexData) {
        if(indexData[item] != '' && item != 'logo'){
          sp += 1
        }
      }

      if(sp > 0) {
        this.templatesNvalid = true
      }else {
        this.templatesNvalid = false
      }
    },

    selectJournal(index) {
      //journal 滚动
      // console.log(index, this.journalPageActive, this.createType)
      if(index != this.journalPageActive) {
        const h = this.createType == 1 ? (470 + 54) : (666 + 54)
        document.getElementsByClassName('ggPage-main-center')[0].scrollTo({
          top: h * index,
          behavior: "smooth"
        })
      }

      this.journalPageActive = index

      //journal 是否添加过内容
      let sp = 0,
      indexData = this.journalPageList[index].data
      for(let item in indexData) {
        if(indexData[item] != '' && item != 'logo' ){
          sp += 1
        }
      }

      //journal 是否可以选择模板
      if(this.journalPageList[index].no != '' && sp > 0) {
        this.templatesNvalid = true
      }else {
        this.templatesNvalid = false
      }
    },

    backpage() {
      this.isEdit = false
      this.$router.go(-1)
    },

    getHeight(){
      const h = document.documentElement.clientHeight
      this.pageLeftHeight = h - 162;
    }

  },
};
</script>
<style lang='less' scoped>
.journal-page-left {

  &.newsletter {
    .page-min {
      width: 120px;
      height: 170px;
    }
  }

  &.portfolio {
    .page-min {
      width: 240px;
      height: 170px;
    }
  }

  .title {
    margin: 0 0 20px 20px;
    color: #B4B4B4;
    font-family: 'opensans-semibold';
    font-size: 12px;
  }

  ul {
    padding-bottom: 87px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 4px;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-track {
      background-color: #fff;
    }

    &.nvalid {
      li .page-min {
        opacity: .2;
        cursor: not-allowed;
      }
    }

    li {
      .page-min {
        margin: 0 auto 20px auto;
        width: 120px;
        height: 170px;
        cursor: pointer;
        border: 1px solid #eaeaea;

        &.newsletter-a {
          background: url(~@/assets/images/journal/newsletter-a.png) no-repeat;
          background-size: cover;
        }
        &.newsletter-b {
          background: url(~@/assets/images/journal/newsletter-b.png) no-repeat;
          background-size: cover;
        }
        &.newsletter-c {
          background: url(~@/assets/images/journal/newsletter-c.png) no-repeat;
          background-size: cover;
        }
        &.newsletter-d {
          background: url(~@/assets/images/journal/newsletter-d.png) no-repeat;
          background-size: cover;
        }
        &.newsletter-e {
          background: url(~@/assets/images/journal/newsletter-e.png) no-repeat;
          background-size: cover;
        }
        &.newsletter-f {
          background: url(~@/assets/images/journal/newsletter-f.png) no-repeat;
          background-size: cover;
        }
        &.newsletter-g {
          background: url(~@/assets/images/journal/newsletter-g.png) no-repeat;
          background-size: cover;
        }
        &.newsletter-h {
          background: url(~@/assets/images/journal/newsletter-h.png) no-repeat;
          background-size: cover;
        }
        &.newsletter-i {
          background: url(~@/assets/images/journal/newsletter-i.png) no-repeat;
          background-size: cover;
        }
        &.newsletter-j {
          background: url(~@/assets/images/journal/newsletter-j.png) no-repeat;
          background-size: cover;
        }
        &.newsletter-k {
          background: url(~@/assets/images/journal/newsletter-k.png) no-repeat;
          background-size: cover;
        }
        &.newsletter-l {
          background: url(~@/assets/images/journal/newsletter-l.png) no-repeat;
          background-size: cover;
        }
        &.newsletter-m {
          background: url(~@/assets/images/journal/newsletter-m.png) no-repeat;
          background-size: cover;
        }
        &.newsletter-n {
          background: url(~@/assets/images/journal/newsletter-n.png) no-repeat;
          background-size: cover;
        }

        &.portfolio-a {
          background: url(~@/assets/images/journal/portfolio-a.png) no-repeat;
          background-size: cover;
        }
        &.portfolio-b {
          background: url(~@/assets/images/journal/portfolio-b.png) no-repeat;
          background-size: cover;
        }
        &.portfolio-c {
          background: url(~@/assets/images/journal/portfolio-c.png) no-repeat;
          background-size: cover;
        }
        &.portfolio-d {
          background: url(~@/assets/images/journal/portfolio-d.png) no-repeat;
          background-size: cover;
        }
        &.portfolio-e {
          background: url(~@/assets/images/journal/portfolio-e.png) no-repeat;
          background-size: cover;
        }
      }
    }
  }
}

.journal-create-main {
  position: relative;

  &.newsletter {
    .journal-page-main,
    .journal-title {
      width: 900px !important;
    }
  }

  &.portfolio {
    .journal-page-main,
    .journal-title {
      width: 900px !important;
    }

    .journal-page-list,
    .journal-page-box {
      width: 660px !important;
    }

    .journal-page {
      width: 660px !important;
      height: 470px !important;
    }
  }

  .journal-top-fixed {
    position: fixed;
    left: 312px;
    top: 121px;
    z-index: 2;
    background: #fff;

    .journal-title {
      width: 900px;
      height: 53px;
      overflow: hidden;

      &.error {
        input {
          border-bottom: 1px solid #ea4335;
        }

        .font-error {
          opacity: 1;
        }

        .font-num {
          color: #ea4335;
        }
      }

      .font-num,
      .font-error {
        margin-top: 2px;
        display: inline-block;
        font-size: 12px;
        font-family: Roboto;
        transition: ease .2s;
      }

      .font-num {
        float: right;
        color: #202124;
      }

      .font-error {
        opacity: 0;
        float: left;
        color: #ea4335;
      }

      input {
        display: block;
        width: 100%;
        font-size: 20px;
        border: 0;
        border-bottom: 1px solid #e0e0e0;
        line-height: 30px;
        outline: none;
        background-color: #fff;
        font-family: 'opensans-semibold';
        transition: ease-out .2s;

        &::-webkit-input-placeholder{
          color: #b4b4b4;
        }
      }
    }

    .journal-people-tag {
      padding: 8px 0;

      .tag {
        display: inline-block;
        padding: 0 6px 0 4px;
        height: 24px;
        background: #fff;
        border: 1px solid #E0E0E0;
        border-radius: 16px;

        .avatar {
          display: inline-block;
          margin-right: 8px;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          vertical-align: middle;
          background-size: contain;
        }

        .no {
          display: inline-block;
          vertical-align: middle;
          margin-right: 8px;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          background-color:#b4b4b4;
          line-height: 16px;
          color: #fff;
          text-align: center;
          font-size: 12px;
          font-family: 'opensans-semibold';
        }

        .name {
          text-align: left;
          font-family: Roboto;
          font-size: 12px;
          color: #202124;
        }
      }
    }
  }

  .journal-page-main {
    position: relative;
    width: 900px;

    .journal-page-list {
      float: right;
      padding: 0 0 112px 0;

      .savepdf {
        .journal-page-col {
          display: none !important;
        }

        .journal-page {
          border: 2px solid #eaeaea !important;
        }
      }

      .add-page-btn {
        margin-top: 20px;
        height: 36px;
        line-height: 36px;
        border: 1px solid #E0E0E0;
        border-radius: 4px;
        text-align: center;
        font-family: 'opensans-semibold';
        font-size: 14px;
        color: #5F6368;
        cursor: pointer;
      }

      .journal-page-box {
        position: relative;
        width: 470px;
        transition: ease .2s;

        &.active {
          .journal-page-bar-inner .journal-page-bar .journal-page-col {
            display: block;
          }

          .journal-page {
            border: 2px solid #1A73E8;
          }
        }

        .journal-page-bar-inner {
          position: relative;
          height: 54px;
          z-index: 1;

          .journal-page-bar {
            line-height: 54px;

            .journal-page-num {
              float: left;
              color: #5F6368;
              font-family: Roboto-medium;
              font-size: 14px;
            }

            .journal-page-col {
              display: none;
              float: right;

              .col-btn {
                position: relative;
                display: inline-block;
                margin-left: 7px;
                vertical-align: middle;
                cursor: pointer;

                .col-tit {
                  display: none;
                  position: absolute;
                  left: 50%;
                  bottom: -10px;
                  margin-left: -32px;
                  width: 64px;
                  height: 20px;
                  line-height: 20px;
                  border-radius: 4px;
                  font-size: 12px;
                  text-align: center;
                  color: #fff;
                  background: #606367;

                  &.p4 {
                    margin-left: -96px;
                    width: 192px;
                  }
                  &.p3 {
                    margin-left: -109px;
                    width: 218px;
                  }
                  &.p2 {
                    margin-left: -49px;
                    width: 98px;
                  }
                  &.p1 {
                    margin-left: -39px;
                    width: 78px;
                  }
                }

                &:hover {
                  .col-tit {
                    display: block;
                  }
                }

                &.die {
                  color: #606367;
                  cursor: not-allowed;

                  img {
                    opacity: .5;
                  }
                }
              }
            }
          }
        }

        .journal-page {
          position: relative;
          width: 470px;
          height: 666px;
          background: #fff;
          border: 2px solid #eaeaea;

          .normal-val {
            .nothing {
              position: absolute;
              top: 50%;
              left: 0;
              width: 100%;
              text-align: center;
              font-family: Roboto;
              font-size: 22px;
              color: #5f6368;
            }
          }
        }
      }
    }

  }

}

.cancel {
  position: fixed;
  right: 80px;
  bottom: 112px;

  .tit {
    float: left;
    margin-right: 20px;
    font-size: 13px;
    color: #5F6368;
    line-height: 33px;

    .cancel-btn {
      padding: 2px 12px;
    }
  }

  .con {
    float: left;

    .el-button {
      width: 110px;
      padding: 0 32px 0 0;
      text-align: center;
      line-height: 36px;

      &:hover,
      &:focus,
      &:visited,
      &:active {
        color: #5F6368;
        border-color: #E0E0E0;
        background-color: #fff;
      }

      .el-tooltip {
        display: block;
        font-family: 'opensans-semibold';

        &:hover {
          background-color: #eee;
        }
      }

      .el-tooltip.disabled {
        color: #b4b4b4;
        font-family: 'opensans-semibold';
        border: 0;
      }

      .iconfont {
        position: absolute;
        right: 0;
        top: 0;
      }

      * {
        color: #202124;
        font-family: 'opensans-semibold';
        font-size: 14px !important;
      }
    }
  }
}

.add-note-dialog {
  .dialog-body {
    padding: 20px 0 40px 0;

    .add-note-input {
      margin: 0;

      textarea {
        font-family: Roboto;
        font-size: 14px;
      }
    }

    &.error {
      .font-error {
        opacity: 1;
      }

      .font-num {
        color: #ea4335;
      }
    }

    .font-num,
    .font-error {
      margin: 5px 0 0 0;
      display: inline-block;
      font-size: 12px;
      font-family: Roboto;
      transition: ease-out .2s;
    }

    .font-num {
      float: right;
      color: #202124;
    }

    .font-error {
      opacity: 0;
      float: left;
      color: #ea4335;
    }
  }
}
</style>
