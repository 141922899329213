<!-- class students list -->
<template>
  <div class="content">
   <!-- <base-breadcrumb
      :data="[
        {
          routeName: 'locations',
          name: user.schoolName,
        },
        {
          routeName: 'moments',
          name: 'Moments',
        },
        {
          routeName: 'momentClass',
          params:{id:1},
          name: 'Nursery 2',
        },
        {
          name: 'hahahahahah',
        },
      ]"
      slot="breadcrumb"
    /> -->
    <GgPage
      pageType="4"
      title="Moments"
      @forward="forward"
      @next="next"
    >
      <el-button type="text" class="back-btn" @click="back()"><i class="el-icon-back"></i></el-button>
      <el-button type="text" v-if="hasPermission('Manage albums_Edit')" class="add-photo-button icon-button" @click="addPhoto"><el-tooltip effect="light" popper-class="file-tooltip-top" :visible-arrow="false" content="Add media" placement="bottom">
       <svg-icon iconClass="add_photo"></svg-icon>
      </el-tooltip>
      </el-button>
      <gg-menu-bar v-if="hasPermission('Manage albums_Edit')||hasPermission('Manage albums_Full')">
         <el-tooltip popper-class="file-tooltip-top" :visible-arrow="false" content="More options" placement="bottom">
        <i class="iconmore_vert-24px iconfont" />
         </el-tooltip>
        <template slot="menus">
          <gg-menu-bar-item
            @click="renameAlbum()"
            v-if="hasPermission('Manage albums_Edit')"
            >Rename album</gg-menu-bar-item
          >
          <gg-menu-bar-item
            @click="setAbbumCover()"
            v-if="hasPermission('Manage albums_Edit')"
            >Set album cover</gg-menu-bar-item
          >
          <gg-menu-bar-item
            @click="deleteAbbum()"
            v-if="hasPermission('Manage albums_Full')"
            >Delete album</gg-menu-bar-item
          >
        </template>
      </gg-menu-bar>

      <div slot="topAppBar">
        <!-- <month-switch @change="monthChange"></month-switch> -->
      </div>
      
      
      <GgPageHeader :title="info.albumsName" :subTitle1="imgList.length+' photos'" class="mar-b-0" />
      <div class="optbar" v-show="checkedList.length>0">
        <el-button type="text" @click="clearCheck"><i class="el-icon-close"/></el-button>
        <template v-if="!isSetCover">
          <span class="count">{{checkedList.length}} Selected</span>
          <div class="btns">
            <!-- <el-tooltip popper-class="file-tooltip-top" :visible-arrow="false" content="Share" placement="bottom"> -->
            <!-- <shareBtn @ok="onShareSuccess" :list="checkedList"/> -->
            <!-- </el-tooltip> -->
             <el-tooltip effect="light" popper-class="file-tooltip-top" :visible-arrow="false" content="Add to album" placement="bottom" v-if="hasPermission('Manage albums_Edit')">
            <addToAlbumBtn :classId="$route.params.id" :albumId="$route.params.aid" @ok="onAddToAlbumSuccess" :list="checkedList"></addToAlbumBtn>
             </el-tooltip>
             
            <gg-menu-bar class="optbar-menu-bar" v-if="hasPermission('Manage media_Full') || hasPermission('Manage media_Edit') || hasPermission('Manage albums_Full') || hasPermission('Manage media_Full')">
               <el-tooltip popper-class="file-tooltip-top" :visible-arrow="false" content="More options" placement="bottom">
              <i class="iconmore_vert-24px iconfont" />
              </el-tooltip>
              <template slot="menus">
                <gg-menu-bar-item
                  v-if="hasPermission('Manage media_Full')"
                  @click="handleDownload()"
                  >Download</gg-menu-bar-item
                >
                <gg-menu-bar-item
                  v-if="hasPermission('Manage media_Edit')"
                  @click="handleFavorite()"
                  >{{checkedListAllFavorited?'Remove from favorite':'Favorite'}}</gg-menu-bar-item
                >
                <gg-menu-bar-item
                  @click="handleRemove()"
                  v-if="hasPermission('Manage albums_Edit')"
                  >Remove from album</gg-menu-bar-item
                >
                <gg-menu-bar-item
                  @click="handleDelete()"
                  v-if="hasPermission('Manage media_Full')"
                  >Delete</gg-menu-bar-item
                >
              </template>
            </gg-menu-bar>
          </div>
        </template>
        <template v-else>
          <span class="count">Select album cover</span>
          <div class="btns">
            <baseButton @click="handleSetAlbum()" type="primary">Done</baseButton>
          </div>
        </template>
      </div>
      <image-list :classId="$route.params.id" :list="imgList" :singleSelect="isSetCover" :loading="loading" @checkChange="onCheckChange" ref="imageList"/>
    </GgPage>
    <album-edit-form ref="albumEditForm" @ok="handleRenameOk" />
    <photo-selecter ref="photoSelecter" v-if="info.albumsName.length>0" :info="info"  @ok="handlePhotoSelecterOk"/>
    <Snackbar
      :visible.sync="snackbarData.visible"
      :content="snackbarData.content"
    />
  </div>
</template>

<script>
import { Ajax } from "@/common";
import moment from 'moment';
import { mapState } from "vuex";
import { batchImgDownload, hasPermission } from './../../utils/utils.js'
import SvgIcon from "@/components/SvgIcon";
import classSideMenu from './../../modules/classSideMenu'
import monthSwitch from './../../modules/monthSwitch'
import imageList from './../../modules/imageList'
import shareBtn from './../../modules/shareBtn'
import addToAlbumBtn from './../../modules/addToAlbumBtn'
import albumEditForm from './modules/albumEditForm'
import photoSelecter from './modules/photoSelecter'
import classMixin from './../../mixin/classMixin'
export default {
  components: {SvgIcon,classSideMenu,monthSwitch,imageList,albumEditForm,shareBtn,addToAlbumBtn,photoSelecter},
  mixins: [classMixin],
  data() {
    return {
      boolean: true,
      loading:false,
      info:{
        albumsName: "",
        mediaCount: 0,
        mediaDTOList: []
      },
      defSwitchVal:[],
      date:this.$route.query.time?moment(parseInt(this.$route.query.time)):moment(),
      imgList: [
        
      ],
      checkedList:[],
      snackbarData: {
        visible: false,
        content: "",
      },
      isSetCover:false
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user
    }),
    checkedListAllFavorited() {
      if(this.checkedList.length > 0){
        const unfavourite = this.checkedList.filter(o=>o.favourite == 0)
        if(unfavourite.length>0){
          return false
        }else{
          return true
        }
      }
      return false
    }
  },
  async created() {
    this.initMonthSwitchVal()
    await this.fetchData()
    if(this.$route.query.action=='setCover'){
      this.initSetCover()
    }
  },
  methods: {
    hasPermission,
    initSetCover(){
      this.$refs.imageList.clearCheck()
      this.isSetCover = true
      // if(){

      // }else{

      // }
      this.$refs.imageList.check(this.imgList[0])
    },
    async handleSetAlbum(){
      if(this.checkedList.length!=1){
        return
      }
      this.loading = true;
      var res = await Ajax.post("/moments/albums/setAlbumCover", {
        classId: this.$route.params.id,
        id: this.$route.params.aid,
        logo: this.checkedList[0].path
      })
      
      this.loading = false;
      if(res.code=='0000'){
        this.fetchData()
        this.clearCheck()
      }
      
    },
    addPhoto(){
      this.$refs.photoSelecter.show()
    },
   initMonthSwitchVal(){
      var _def = moment()
      if(this.$route.query.time&&this.$route.query.time.length==13){
        _def = moment(parseInt(this.$route.query.time))
      }
      this.date = _def
      this.defSwitchVal = _def
    },
    monthChange(val){
      this.date = val[0]
      console.log(this.data)
      this.fetchData()
    },
    async fetchData(){
      this.loading = true;
      // var resClass = await Ajax.get("/usermanage/class/selectByIdV1", {
      //   id: this.$route.params.id,
      // })
      var res = await Ajax.get("/moments/albums/getMediaListByAlbumIdV1", {
        albumId: this.$route.params.aid,
        // date: this.date.format('YYYY-MM'),
        size:9999
      })
      
      this.loading = false;
      if(res.code=='0000'){
        this.info = res.data
        this.imgList = res.data.mediaDTOList
      }
      
    },
    handleDownload(){
      batchImgDownload(this.checkedList)
      this.$refs.imageList.clearCheck()
    },
    async handleFavorite(){
      var res
      if(this.checkedListAllFavorited){
        const _arr = this.checkedList.map(o=>o.id)
        res = await Ajax.post("/moments/favourite/cancleFavorite", _arr)
      }else{
        const _arr = this.checkedList.map(o=>{
          return {
            "classId": this.$route.params.id,
            "mediaId": o.id,
          }
        })
        res = await Ajax.post("/moments/favourite/favoriteBatch", _arr)
      }
      if(res.code=='0000'){
        this.$refs.imageList.clearCheck()
        this.fetchData()
      }
    },
    handleRemove(){
      console.log(this.checkedList)
      const _arr = this.checkedList.map(o=>{
        return {
          "albumsId": this.$route.params.aid,
          "mediaId": o.id
        }
      })
      this.$confirm(
        "Once removed from the album, it will continue to stay in photos. ",
        'Remove photos from album?',
        {
          cancelButtonText: "Cancel",
          confirmButtonText: "Remove",
          cancelButtonClass: "gg_cancel_button",
          confirmButtonClass: "gg_submit_button",
          customClass: "gg_confirm",
          showClose: false
        }
      )
        .then(() => {
          Ajax.post("/moments/albums/removeFromAlbum", _arr).then(res=>{
            if(res.code=='0000'){
              this.handleSnack('Photo has been removed')
              this.$refs.imageList.clearCheck()
              this.fetchData()
              
            }else{
                this.handleSnack(res.message)

            }
          })
        })
        .catch(() => {});
    },
    handleDelete(){
      //Cannot delete a shared photo
      if(this.checkedList.filter(o=>o.shareStatus==2).length>0){
        this.handleSnack('Cannot delete a shared photo')
        return
      }
      this.$confirm(
         "Once deleted, the photo will move to trash and stay for 30 days .",
        "Delete photo?",
          {
            cancelButtonText: "Cancel",
            confirmButtonText: "Delete",
            cancelButtonClass: "gg_cancel_button",
            confirmButtonClass: "gg_submit_button",
            customClass: "gg_confirm",
            showClose: false
          }
        )
          .then(() => {
            Ajax.post("/moments/albums/deleteBatch", this.checkedList.map(o=>{
              return {
                "albumId": this.$route.params.aid,
                "id":o.id,
                "path":o.path
              }
            })).then(res=>{
              if(res.code=='0000'){
                this.handleSnack('Photo has been deleted')
                this.$refs.imageList.clearCheck()
                this.fetchData()
              }else{
                this.handleSnack(res.message)
              }
            }).catch(()=>{})
          })
          .catch(() => {});
    },
    handlePhotoSelecterOk(){ 
      this.handleSnack('Photo has been added to album')
      this.fetchData()
    },
    onShareSuccess(){
      this.$refs.imageList.clearCheck()
    },
    onAddToAlbumSuccess(){
      this.$refs.imageList.clearCheck()
    },
    onCheckChange(val){
      this.checkedList = val
    },
    clearCheck(){
      this.isSetCover = false
      this.$refs.imageList.clearCheck()
    },
    forward(val) {
      console.log(val);
    },
    next(val) {
      console.log(val);
    },
    back(){
      this.$router.go(-1)
    },
    renameAlbum(){
      this.$refs.albumEditForm.show({
        id: this.$route.params.aid,
        name: this.info.albumsName
      })
    },
    handleRenameOk(){
      this.fetchData()
    },
    setAbbumCover(){
      this.initSetCover()
    },
    deleteAbbum(){
      this.$confirm('Once the album is deleted, it will continue to stay in photos.', 'Delete album?', {
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Delete',
        cancelButtonClass: "gg_cancel_button",
        confirmButtonClass: "gg_submit_button",
        customClass: "gg_confirm",
        callback: (action) => {
          if (action == "confirm") {
           this.doDelete()
          }
        },
      })
    },
    doDelete(){
      Ajax.post("/moments/albums/deleteAlbumById?id="+this.$route.params.aid).then(res=>{
        if(res.code=='0000'){
          // 返回album列表
        
          this.$router.replace({ name: "momentClassAlbum" ,params:{id:this.$route.params.id},query:this.$route.query});
        }
      }).catch(()=>{
       
      })
    },
    handleSnack(content) {
      this.snackbarData.content = content;
      this.snackbarData.visible = true;
    },
  },
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
/deep/ .ggPage-main{
  position: relative;
  padding-top:60px;
}
.ggPageTitle{
  /deep/.ggPageTitle-left .titleInfo .title{
    font-family:'opensans-semibold';
    color:#5f6368
  }
}
.content.has_breadcrumb .edoovo-breadcrumb {
    z-index: 10;
}
.back-btn{
  position: absolute;
  left:15px;
  top:0;
  .el-icon-back{
    color:#666;
    font-size:30px;
  }
}
.add-photo-button{
  position: absolute;
  right:112px;
  top:20px;
  padding:2px;
  border-radius: 50%;
  &:hover{
    background:#eee;
  }
  .svg-icon {
    width: 22px;
    height: 22px;
  }
}
.menu_bar{
  position: absolute;
  right:80px;
  top:22px;
  z-index: 3;
  overflow: visible;
  /deep/ .menu_bar_button{
    line-height: 12px;
    border-radius: 100%;
    // background: rgba(255,255,255,0.2);
    // // &:hover{
      
    // // }
  }
  /deep/ .drop_down_menus{
    position: absolute;
    top: 28px!important;
    right:0!important;
    div{
      white-space: nowrap!important;
    }
  }

  
}
.optbar{
  .optbar-menu-bar{position: static;}
}

.content{
  /deep/ .image-list{
    padding-bottom:112px;
  }
}
</style>