<template>
  <BaseFormDialog3
        v-model="visible"
        :title="title"
        :data="formDataList"
        @submit="handleSubmit"
        :submitBtnTxt="btnTxt"
        :renderPass="cansub"
        textReminder=""
        
      >
        <template slot="renderHtml" slot-scope="scope">
          <gg-input
            v-if="scope.data.key === 'albumName'"
            :key="scope.data.key"
            :ref="scope.data.key"
            :placeholder="scope.data.placeholder"
            v-model="albumName"
            :error="errorObj"
          />
        </template>
      </BaseFormDialog3>
</template>
<script>
import { Ajax } from "@/common";
export default {
  data() {
    return {
      id:0,
      title:"",
      btnTxt:'',
      visible: false,
      loading:false,
      cansub:false,
      formDataList: [
        {
          placeholder: "Album name",
          key: "albumName",
          value: "",
          renderHtml: true,
        },
      ],
      albumName:'',
      currentAlbumName: '',
      errorObj: {
        show: false,
        message: "",
      },
    };
  },
  watch: {
    albumName(newVal,oldVal) {
      this.formDataList[0].value=newVal
      if(newVal.length!=0){
        if(newVal.length<=40){
          if(this.albumName!=this.currentAlbumName){
            this.cansub = true
            this.errorObj={
              show: false,
              message: "",
            }
          }else{
            this.cansub = false
          }
          
        }else{
          this.cansub = false
          this.errorObj={
            show: true,
            message: "Character limit of 40",
          }
        }
      }else{
        this.cansub = false
      }
    },
  },
  methods: {
    show(record){
      this.id=0
      this.albumName=''
      this.currentAlbumName = ''
      this.formDataList[0].value=''
      this.title = 'Create new album'
      this.btnTxt = 'Create'
      if(record){
        this.id=record.id
        this.albumName=record.name
        this.currentAlbumName = record.name
        this.formDataList[0].value=record.name
        this.title = 'Rename album'
        this.btnTxt = 'Save'
      }
      this.visible = true
    },
    handleSubmit(){
      let formData = {};
        this.formDataList.forEach((item) => {
        formData[item.key] = item.value;
      });
      
      this.subData()
    },
    async subData(){
     
      this.loading = true;
      var res;
      if(this.id!=0){
        // 编辑
        res = await Ajax.post("/moments/albums/renameAlbum", {
          classId: this.$route.params.id,
          id:this.id,
          name:this.albumName
        }).catch(()=>{
          this.loading = false;
        })
      }else{
        res = await Ajax.post("/moments/albums/createAlbum", {
          classId: this.$route.params.id,
          name:this.albumName
        }).catch(()=>{
          this.loading = false;
        })
      }
      this.loading = false;
      
      if(res.code=='0000'){
      
        this.visible = false
        this.$emit('ok',this.id!=0?'Album has been renamed':'Album has been created')
        
      }else{
        setTimeout(() => {
          this.errorObj={
            show: true,
            message: res.message,
          }
          this.cansub = false
        }, 100);
        
      }
    },
    
  },
};
</script>