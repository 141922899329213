<!-- class students list -->
<template>
  <div v-if="hasPermission('Manage media_Create')">
    <el-upload
      
      ref="photoUpload"
      action=""
      accept="image/jpeg,image/png"
      :auto-upload="false"
      :on-change="uploadFileChange"
      :multiple="true"
      :file-list="fileListTemp"
      :limit="10"
      :on-exceed="onExceed"
      
    >
      <span @mousedown="btnClick">
      <slot></slot></span>
    </el-upload>
    <gg-Dialog
      :visible.sync="studentSelecterShow"
      width="580px"
      ref="studentSelecter"
    >
      <div slot="title">
        Select student
      </div>
      <div class="ct" v-loading="loading">
        <div class="values" @click.self="valuesClick">
          <div class="value" v-for="item in selectedTemp" :key="item.id">
            
            <span v-if="item.photo" class="avatar" :style="'background-image:url('+item.photo+')'"></span>
            <span
              v-else
              class="icon-text"
              >{{ (item.name).toUpperCase() | getIconText }}</span
            >
            <span class="name">{{item.name}}</span>
            <el-button type="text" icon="el-icon-error" @click="checkItem(item)" v-if="item.id!=studentId"></el-button>
          </div>
          <div class="ipt-search">
            <input type="text" ref="ipt-search-input" :style="'width:'+searchInputW+'px'" v-model="keywords" autocomplete="off">
            <span class="ipt-search-val" ref="ipt-search-val">{{keywords}}</span>
          </div>
          <div class="placeholder" v-if="keywords.length===0 && selectedTemp.length===0" @click.self="valuesClick">Type a name or email address</div>
        </div>
        <div class="options">
          <div class="tit">SEARCH RESULTS</div>
          <div class="student-option-list">
            <div class="item" @click="checkAll" v-if="options.length>0">
              <span class="checkbox" :class="{'checked':isCheckAll}">
                <i class="el-icon-check"></i>
              </span>
              <span class="avatar" :style="'background-image:url('+require('@/assets/images/icons/student-all.png')+')'"></span>
              <div class="wd">
                <div class="wd-main">
                  <span class="name">Select all</span>
                </div>
              </div>
            </div>
            <div class="item" style="cursor:text" v-else>
              <span class="avatar"><i class="iconfont iconyiquxiao" /></span>
              <div class="wd">
                <div class="wd-main">
                  <span class="name">No results</span>
                </div>
              </div>
            </div>
            <div class="item" :class="{'disabled':item.id==studentId}" v-for="item in options" :key="item.id"  @click="checkItem(item)">
              <span class="checkbox" :class="{'checked':selectedTemp.find(o=>o.id==item.id)}">
                <i class="el-icon-check"></i>
              </span>
              <span v-if="item.photo" class="avatar" :style="'background-image:url('+item.photo+')'"></span>
              <span
                v-else
                class="icon-text"
                >{{ (item.name).toUpperCase() | getIconText }}</span
              >
              <div class="wd">
                <div class="wd-main">
                  <span class="name">{{item.name}}</span>
                  <span class="mail">-</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <gg-cancel-button @click="close">Cancel</gg-cancel-button>
        <gg-submit-button
        :disabled="selectedTemp.length==0"
        @click="doUpload"
          >Upload</gg-submit-button
        >
      </span>
    </gg-Dialog>
    <Snackbar
      :visible.sync="snackbarData.visible"
      :content="snackbarData.content"
    />
    <div class="progress-box" v-if="uploading">
      <div class="wd">Uploading photos ...</div>
      <div class="cur" >{{progress.cur}} of {{progress.total}}</div>
      <baseButton @click="stopUpload" type="primary">Stop</baseButton>
      <div class="progress-bar">
        <span class="ratio" :style="'width:'+progress.ratio+'%'"></span>
      </div>
    </div>
  </div>
</template>

<script>

import { Ajax } from "@/common";
import { mapState } from "vuex";
import { hasPermission } from './../utils/utils.js';
import moment from 'moment';
export default {
  props: {
    classId:{
      default:'',
      type:String
    },
    studentId:{
      default:'',
      type:String
    },
    albumId:{
      default:'',
      type:String
    }
  },
  data() {
    return {
      studentSelecterShow:false,
      loading:false,
      options:[],
      selected:[], // 当前值
      selectedTemp:[], // 当前操作中的值，未确定
      fileListTemp:[],
      snackbarData: {
        visible: false,
        content: "",
      },
      uploading:false,
      progress:{
        total:0,
        cur:0,
        ratio:0,
      },
      keywords: '',
      searchInputW:10,
    };
  },
  computed:{
    isCheckAll(){
      return this.selectedTemp.length!==0&&this.selectedTemp.length === this.options.length
    },
    
    ...mapState({
      user: (state) => state.user
    }),
  },
  watch:{
    keywords: function () {
      // console.log(this.$refs['ipt-search-val'].offsetWidth)
      this.$nextTick(()=>{
        this.searchInputW = this.$refs['ipt-search-val']?this.$refs['ipt-search-val'].offsetWidth+6:10
      })
      const res = this.keywords.trim().toLowerCase()
      if(res==''){
        this.options = [...this.optionsAll]
      }else{
        
        this.options = this.optionsAll.filter(o=>{
          const _name = o.name.toLowerCase()
          return _name.indexOf(res)>=0
        })
      }
    },
  },
  filters: {
    getIconText(text) {
      if (!text) {
        return '';
      }
      const arr = text.split(' ');
      const first = arr[0].substr(0, 1);
      if (arr.length > 1) {
        const last = arr[arr.length - 1].substr(0, 1);
        return `${first}${last}`;
      }
      return first;
    }
  },
  created() {
    this.fetchStudents()
  },
  methods: {
    hasPermission,
    valuesClick(){
      this.$refs['ipt-search-input'].focus()
    },
    btnClick(){
      console.log('uploadClick')
      this.fileListTemp = []
    },
    open(){
      this.studentSelecterShow = true
      
      this.initDefault()
    },
    initDefault(){
      this.selected = []
      this.selectedTemp = []
      if(this.studentId.length>0){
         console.log(this.studentId,'this.options',this.options)
        const _data = this.options.find(o=>o.id==this.studentId)
        this.selected = [_data]
        this.selectedTemp = [_data]
      }
    },
    close(){
      this.studentSelecterShow = false
      this.keywords = ''
    },
    checkAll() {
      if(this.isCheckAll){
        // 取消全选
        if(this.studentId.length>0){
          const _data = this.options.find(o=>o.id==this.studentId)
          this.selectedTemp = [_data]
        }else{
          this.selectedTemp = []

        }
      }else{
        // 全选
        this.selectedTemp = [...this.options]
      }
      this.keywords = ''
    },
    checkItem(item) {
      if(item.id == this.studentId){
        return
      }
      const _i = this.selectedTemp.findIndex(o=>o.id==item.id)
      if(_i>=0){
        // 取消选中
        this.selectedTemp.splice(_i,1)
      }else{
        // 选中
        this.selectedTemp.push(item)
      }
      this.keywords = ''
    },
    fetchStudents(){
      this.loading = true;
      Ajax.get("/usermanage/student/getStudentByClassId", {
        classId:this.classId
      })
		  .then((res) => {
		    this.loading = false;
        if (res.code == "0000") {
          this.options = res.data.map(o=>{
            return {
              id:o.id,
              photo:o.photo,
              name:o.firstName+' '+o.lastName
            }
          })
          this.optionsAll = [...this.options]
          console.log('this.options',this.options)
        }
		  })
		  .catch(() => {
		    this.loading = false;
		  });
     
    },
    stopUpload(){
      this.uploading = false;
      this.handleSnack(this.progress.cur+" photo has been uploaded");
      this.$emit('onFinish')
      setTimeout(() => {
        this.fileListTemp = [];
        this.progress={
          total:0,
          cur:0,
          ratio:0,
        }
      }, 500);
      
    },
    async doUpload(){
      this.selected = [...this.selectedTemp]
      var i = 0;
      this.studentSelecterShow = false
      this.keywords = ''
      this.uploading = true;
      while(i<this.fileListTemp.length&&this.uploading){
        await this.fetchUpload(this.fileListTemp[i],i)
        i++
        if(i>this.fileListTemp.length-1){
          this.stopUpload();
        }
      }
    
    },
    async fetchUpload(_file,_i){
      var t=this
      let formData = new FormData();
      formData.append("file", _file);
      formData.append("multipartFile", _file.raw);
      var upres = await Ajax.post("/media/public/file/uploadExt", formData, {
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: progressEvent => {
          
          let process = (progressEvent.loaded / progressEvent.total * 100 | 0)
          // console.log( `上传进度：${process}%,${_i+1} of ${this.fileListTemp.length}`)
          this.progress={
            total:this.fileListTemp.length,
            cur:_i+1,
            ratio:process,
          }
        }
      }).catch((err) => {
        console.log(err);
      });
      if (upres.code === "0000") {
        var _students = this.selected.map(o=>{
          return {
            "classId": this.classId,
            "studentId": o.id
          }
        })

        var _data = {
          "mediaList": [
            {
              "fileDisplayName":upres.data.fileDisplayName,
              "path": upres.data.filePath,
              "publishId": t.user.userId,
              "schoolId": t.user.locationId,
              "topic": "",
              "uploadFrom": "web browser",
              "uploadTime": moment().format('YYYY-MM-DD')
            }
          ],
          studentInfoList:_students
        }
        var _path = "/moments/media/uploadBatch"
        if(this.albumId.length>0){
          // 使用相册中的上传接口
          _data = {
            "albumId": this.albumId,
            "mediaList": [
              {
                "fileDisplayName":upres.data.fileDisplayName,
                "path": upres.data.filePath,
                "publishId": t.user.userId,
                "schoolId": t.user.locationId,
                "topic": "",
                "uploadFrom": "web",
                "uploadTime": moment().format('YYYY-MM-DD')
              }
            ],
            studentInfoList:_students
          }
          _path = "/moments/albums/upload"
        }
        var upres = await Ajax.post(_path,_data ).catch((err) => {
          console.log(err);
        });
        this.$emit('uploadSuccess')
      }
      if (upres.code === "9999") {
        this.$confirm(
          "The maximum amount of files that can be uploaded is 10.",
          'Maximum number of files exceeded',
          {
            showConfirmButton: false,
            showCancelButton: false,
            customClass: "gg_confirm"
          }
        );
      }
     
      
    },
    uploadFileChange(file, fileList) {
      console.log('准备上传',file, fileList)
      this.fileListTemp = fileList
      this.open()
      
    },
    onExceed(files, fileList){
      // this.handleSnack("Maximum upload size exceeded");
      this.$confirm(
          "The maximum amount of files that can be uploaded is 10.",
          'Maximum number of files exceeded',
          {
            showConfirmButton: false,
            showCancelButton: false,
            customClass: "gg_confirm"
          }
        );
    },
    handleSnack(content) {
      this.snackbarData.content = content;
      this.snackbarData.visible = true;
    },
  },
};
</script>
<style lang='less' scoped>
/deep/ .el-upload-list{display: none;}
.progress-box{
  position: fixed;
  z-index:999;
  bottom:16px;
  left:50%;
  transform: translate(-50%,0);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  width:290px;
  box-sizing: border-box;
  background:#fff;
  border-radius: 4px;
  padding:12px 16px;
  overflow: hidden;
  .wd{
    line-height: 20px;
    height: 20px;
    color:#202124;
    font-size:14px;
  }
  .cur{
    line-height: 20px;
    margin-top:4px;
    color:#5F6368;
    font-size:14px;
  }
  .progress-bar{
    position: absolute;
    bottom:0;
    left:0;
    width:100%;
    height:8px;
    background:#E0E0E0;
    .ratio{
      float: left;
      height: 100%;
      background: #1A73E8;
    }
  }
  .base-button{
    position: absolute;
    right:10px;
    bottom: 16px;
  }
}
.value{
    display: inline-block;
    height:32px;
    line-height: 30px;
    padding:0 4px;
    border:1px solid #E0E0E0;
    border-radius: 15px;
    font-size: 0;
    margin: 0 8px 4px 0;
    cursor:default;
    .avatar{
      width:24px;
      height:24px;
      border-radius: 100%;
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
      vertical-align: middle;
      display: inline-block;
      background-color: #eee;
    }
    .icon-text {
      border-radius: 50%;
      text-align: center;
      align-items: center;
      justify-content: center;
      background:#b4b4b4;
      color: #fff;
      width: 24px;
      height: 24px;
      line-height: 24px;
      font-size: 12px;
      font-family: 'opensans-semibold';
      vertical-align: middle;
      display: inline-block;
    }
    .name{
      font-size:14px;
      color:#202124;
      margin-left:8px;
      vertical-align: middle;
    }
    .el-button{
      padding:0;
      vertical-align: middle;
      color:#5F6368;
      font-size: 20px;
      margin-left:8px;
    }
  }
.ct{
      padding: 24px;
      .values{
        padding: 4px 0;
        border-bottom: 1px solid #1A73E8;
        min-height: 41px;
        font-size: 0;
        cursor: text;
        position: relative;
        .ipt-search{
          display: inline-block;
          vertical-align: middle;
          position: relative;
          input{
            width: .75em;
            max-width: 100%;
            line-height: 32px;
            padding: 0;
            margin: 0;
            border: 0 none;
            outline: none;
            font-size: 14px;
          }
          .ipt-search-val{
            position: absolute;
            top: 0;
            left: 0;
            white-space: pre;
            opacity: 0;
            pointer-events: none;
            font-size: 14px;
          }
        }
        .placeholder{
          position: absolute;
          top: 2px;
          left: 0;
          font-size: 14px;
          line-height: 32px;
        }
      }
      .options{
        margin-top:25px;
        .student-option-list{
          overflow-y: auto;
          height: 350px;
        }
        .tit{
          line-height: 16px;
          color:#5F6368;
          font-size: 12px;
        }
        .item{
          height:36px;
          line-height: 36px;
          padding:0 5px;
          font-size: 0;
          margin-top:20px;
          cursor:pointer;
          .checkbox{
            height:20px;
            width:20px;
            line-height: 16px;
            text-align: center;
            display: inline-block;
            border:2px solid #5f6368;
            border-radius: 2px;
            vertical-align: middle;
            margin-right: 18px;
            .el-icon-check{
              font-size: 16px;
              color:#fff;
              display:  none;
            }
            &.checked{
              border-color:#1A73E8;
              background:#1A73E8;
              .el-icon-check{
                display: block;
              }
            }
          }
          &.disabled{
            .checkbox{
              &.checked{
                border-color:#b4b4b4;
                background:#b4b4b4;
                .el-icon-check{
                  display: block;
                }
              }
            }
          }
          .avatar{
            width:32px;
            height:32px;
            border-radius: 100%;
            background-position: center center;
            background-size: cover;
            background-repeat: no-repeat;
            background-color: #eee;
            vertical-align: middle;
            display: inline-block;
            margin-right: 18px;
            text-align: center;
            font-size: 0;
            line-height: 32px;
            .iconyiquxiao{
              vertical-align: middle;
              font-size: 18px;
            }
          }
           .icon-text {
              border-radius: 50%;
              text-align: center;
              align-items: center;
              justify-content: center;
              color: #fff;
              background:#b4b4b4;
              width: 32px;
              height: 32px;
              margin-right: 18px;
              line-height: 32px;
              font-size: 12px;
              font-family: 'opensans-semibold';
              vertical-align: middle;
              display: inline-block;
            }
          .wd{
            vertical-align: middle;
            display: inline-block;
          }
          .wd-main{
            display: flex;
            flex-direction: column;
          }
          .name{
            font-size:14px;
            color:#202124;
            line-height: 20px;
            font-family: Roboto;
            font-weight: 500;
          }
          .mail{
            font-size:12px;
            color:#202124;
            line-height: 16px;
            font-family: Roboto;
            font-weight: 400;
          }
        }
      }
    }
</style>