<!-- class students list -->
<template>
  <div class="content has_breadcrumb journal-inner">
    <GgPage pageType="" title="Journal">
      <div slot="topAppBar">
        <search :query="query" />
      </div>
      <div class="journal-list-inner">
        <div class="journal-create-main">
          <div class="journal-page-main" v-loading="loading">
            <div class="journal-page-list">

              <el-table
                :data="list"
                style="width: 100%; margin-bottom: 50px;">
                <el-table-column
                  prop="title"
                  label="标题 ">
                </el-table-column>
                <el-table-column
                  prop="filePath"
                  label="PDF">
                  <template slot-scope="scope">
                    {{ scope.filePath ? '有' : '无' }}
                  </template>
                </el-table-column>
                <el-table-column
                  label="操作"
                  width="200">
                  <template slot-scope="scope">
                    <el-button @click="toPreview(scope.row.id, scope.row.newsletter ? 0 : 1, scope.row)">
                      查看 
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>

              <!-- <div class="journal-list-box-inner">
                <div class="journal-box" v-for="(item, index) in list" :key="index">
                  <div class="journal-img" :style="'background-image:url(' + item.cover + ');'"></div>
                  <div class="journal-tit" :title="item.title">{{ item.title }}</div>
                  <div class="journal-tag" v-if="item.newsletter">All students</div>
                  <div class="journal-tag" v-if="!item.newsletter && item.recipientInfo">
                    <div class="avatar" v-if="item.recipientInfo.photo"><img :src="item.recipientInfo.photo"></div>
                    <div class="no" v-else>
                      {{ item.recipientInfo.firstName ? item.recipientInfo.firstName.substring(0, 1).toUpperCase() : '-' }}{{ item.recipientInfo.lastName ? item.recipientInfo.lastName.substring(0, 1).toUpperCase() : '-' }}
                    </div>
                    <div class="name">{{ item.recipientInfo.firstName || '' + ' ' + item.recipientInfo.lastName || '' }}</div>
                  </div>
                  <div class="journal-col">
                    <el-button v-if="!item.filePath" @click="toPreview(item.id, item.newsletter ? 0 : 1, item)">
                      Preview 
                    </el-button>
                  </div>
                  
                </div>
              </div> -->
            </div>

            <el-pagination
            style="float: right"
              background
              @size-change="loadJournal"
              @current-change="loadJournal"
              layout="prev, pager, next"
              :page-size="10"
              :total="total">
            </el-pagination>

          </div>


        </div>
      </div>

    </GgPage>
  </div>
</template>

<script>
import moment from 'moment';
import { Ajax } from "@/common";
import { mapState } from "vuex";
import { htmlDecode, hasPermission } from './../utils/utils.js';
import search from './../modules/search'
export default {
  components: {
    search
  },
  computed: {
    ...mapState({
      user: (state) => state.user
    }),
  },
  data() {
    return {
      total: 0,
      pages: 0,
      pageNum: 1,
      loading: false,
      loadingText: '',
      loadingShow: false,
      journalType: 0,
      journalIndex: '',
      journalRecallSw: false,
      journalApproveSw: false,
      journalUndoSw: false,
      journalSubmitSw: false,
      journalDeleteSw: false,
      list: [],
      query: {}
    };
  },
  watch:{

  },
  created() {
    this.query = this.$route.query || {}
    this.getJournal()
  },
  mounted(){
  
  },
  methods: {

    moment,

    hasPermission,

    htmlDecode,

    dateDiff(date) {
      let a = moment.utc(date),
      b = moment()
      return a.diff(b, 'days')
    },

    loadJournal(e) {
      this.pageNum = e
      this.getJournal()
    },

    getJournal() {
      this.loading = true
      let data = {
        currentPage: this.pageNum,
        pageSize: 10
      }

      Ajax.get("/journal/allFilePathIsNull/", data).then((res) => {
        if(res.code === '0000'){
          this.list = res.data.records
          this.pages = res.data.pages
          this.total = res.data.total
        }
        this.loading = false
      });
    },

    toPreview(id, type, item) {
      // this.$router.push({ name: 'journalDraft', query: { 
      //   id: id, 
      //   name: item.title, 
      //   type: type,
      //   classid: this.$route.params.id
      // }})

      let url = this.$router.resolve({path: '/journal/tempdraft?id=' + id + '&name=' + item.title + '&type=' + type + '&classid=' + this.$route.params.id });
      let win = window.open(url.href, '_blank')
    },

    toEdit(id, type, item) {
      this.$router.push({ name: 'journalCreate', query: { editid: id, type: type, classname: this.classname, classid: this.classid, time: this.moment().valueOf() }})
    },

    journalRecall() {
      Ajax.put('/journal/' + this.journalIndex + '/recall?staffId=' + this.user.userId).then((res) => {
        if(res.code === '0000'){
          this.loadingShow = true
          this.journalRecallSw = false
          this.loadingText = (this.journalType === 0 ? 'Newsletter' : 'Portfolio') + ' has been recalled'

          setTimeout(()=>{
            let typename = (this.journalType === 0 ? 'newsletter' : 'portfolio') + '_draft',
            classid = this.$route.query.classid
            this.$router.push('/journal/class/' + classid + '?typename=' + typename)
          }, 1500)
        }
      });
    },

    journalRecallShow(id, type, item) {
      this.journalRecallSw = true
      this.journalIndex = id
      this.journalType = type
    },

    journalApprove() {
      Ajax.put('/journal/' + this.journalIndex + '/process?approve=' + true + '&staffId=' + this.user.userId).then((res) => {
        if(res.code === '0000'){
          this.loadingShow = true
          this.journalApproveSw = false
          this.loadingText = (this.journalType === 0 ? 'Newsletter' : 'Portfolio') + ' has been posted'

          setTimeout(()=>{
            let typename = (this.journalType === 0 ? 'newsletter' : 'portfolio') + '_posted',
            classid = this.$route.query.classid
            this.$router.push('/journal/class/' + classid + '?typename=' + typename)
          }, 1500)
        }
      });
    },
    
    journalApproveShow(id, type, item) {
      this.journalApproveSw = true
      this.journalIndex = id
      this.journalType = type
    },

    journalUndo() {
      Ajax.put('/journal/' + this.journalIndex + '/cancel?staffId=' + this.user.userId).then((res) => {
        if(res.code === '0000'){
          this.loadingShow = true
          this.journalUndoSw = false
          this.loadingText = (this.journalType === 0 ? 'Newsletter' : 'Portfolio') + ' has been moved to draft'

          setTimeout(()=>{
            let typename = (this.journalType === 0 ? 'newsletter' : 'portfolio') + '_draft',
            classid = this.$route.query.classid
            this.$router.push('/journal/class/' + classid + '?typename=' + typename)
          }, 1500)
        }
      });
    },

    journalUndoShow(id, type, item) {
      this.journalUndoSw = true
      this.journalIndex = id
      this.journalType = type
    },

    journalSubmit() {
      Ajax.put('/journal/' + this.journalIndex + '/submit?staffId=' + this.user.userId).then((res) => {
        if(res.code === '0000'){
          this.loadingShow = true
          this.journalSubmitSw = false
          this.loadingText = (this.journalType === 0 ? 'Newsletter' : 'Portfolio') + ' has been submitted'

          setTimeout(()=>{
            let typename = (this.journalType === 0 ? 'newsletter' : 'portfolio') + '_pending',
            classid = this.$route.query.classid
            this.$router.push('/journal/class/' + classid + '?typename=' + typename)
          }, 1500)
        }
      });
    },

    journalSubmitShow(id, type, item) {
      this.journalSubmitSw = true
      this.journalIndex = id
      this.journalType = type
    },

    journalDelete() {
      Ajax.delete('/journal/' + this.journalIndex + '?staffId=' + this.user.userId).then((res) => {
        if(res.code === '0000'){
          this.journalDeleteSw = false
          this.loadingText = (this.journalType === 0 ? 'Newsletter' : 'Portfolio') + ' has been deleted'
          this.getJournal()
        }
      });
    },

    journalDeleteShow(id, type) {
      this.journalDeleteSw = true
      this.journalIndex = id
      this.journalType = type
    },

    momentTime(date) {
      return moment.utc(date).local().format("DD/MM/YYYY, hh:mm A");
    }
    
  },
};
</script>
<style lang='less' scoped>
.journal-list-inner {
  margin: auto;
  width: 1373px;
}

.journal-create-main {
  position: relative;

  .journal-top-fixed {
    width: 1373px;
    background: #fff;

    .journal-people-tag {
      padding: 0 0 10px 0;

      .tag {
        display: inline-block;
        padding: 0 6px 0 4px;
        height: 24px;
        background: #fff;
        border: 1px solid #E0E0E0;
        border-radius: 16px;

        .no {
          display: inline-block;
          margin-right: 8px;
          width: 16px;
          height: 16px;
          background: #b4b4b4;
          border-radius: 50%;
          line-height: 16px;
          text-align: center;
          color: #fff;
          font-size: 12px;
          font-family: 'OpenSans-Bold';
          vertical-align: middle;
        }

        .avatar {
          margin-right: 8px;
          display: inline-block;
          width: 16px;
          height: 16px;
          vertical-align: middle;

          img {
            display: block;
            width: 16px;
            height: 16px;
            border-radius: 50%;
          }
        }

        .name {
          margin-right: 8px;
          display: inline-block;
          font-size: 12px;
          font-family: Roboto;
          font-weight: 400;
          line-height: 16px;
          color: #202124;
          vertical-align: middle;
        }
      }
    }

    .title {
      position: relative;
      margin-top: 0;
      padding-left: 20px;
      padding-bottom: 30px;
      border-bottom: 1px solid #e3e3e3;
      color: #606367;
      font-size: 24px;
      font-family: 'OpenSans-Bold';
    }

  }

  .journal-page-main {
    padding-bottom: 100px;
    position: relative;
  } 
}

.journal-list-box-inner {
  position: relative;
  overflow: hidden;
  padding-top: 20px;
  margin-bottom: 92px;
  margin-right: -48px;

  .journal-box {
    float: left;
    position: relative;
    margin: 0 20px 20px 0;
    width: 328px;
    height: 228px;
    background: #fff;
    border: 1px solid #E0E0E0;
    border-radius: 8px;

    .journal-col {
      position: absolute;
      right: 10px;
      bottom: 10px;
    }

    .journal-tag {
      display: inline-block;
      margin: 0 0 0 20px;
      padding: 0 5px;
      background: #fff;
      border: 1px solid #E0E0E0;
      border-radius: 16px;
      line-height: 22px;
      text-align: left;
      font-family: Roboto;
      font-size: 12px;
      color: #202124;

      .no {
        display: inline-block;
        margin-right: 8px;
        width: 16px;
        height: 16px;
        background: #b4b4b4;
        border-radius: 50%;
        line-height: 16px;
        text-align: center;
        color: #fff;
        font-size: 12px;
        font-family: 'OpenSans-Bold';
        vertical-align: middle;
        overflow: hidden;
      }

      .avatar {
        margin-right: 8px;
        display: inline-block;
        width: 16px;
        height: 16px;
        vertical-align: middle;

        img {
          display: block;
          width: 16px;
          height: 16px;
          border-radius: 50%;
        }
      }

      .name {
        margin-right: 8px;
        display: inline-block;
        font-size: 12px;
        font-family: Roboto;
        font-weight: 400;
        line-height: 16px;
        color: #202124;
        vertical-align: middle;
      }
    }

    .journal-tit {
      margin: 20px 0 10px 20px;
      width: 256px;
      line-height: 20px;
      font-size: 16px;
      text-align: left;
      font-family: Roboto;
      color: #202124;
      overflow: hidden;
      text-overflow:ellipsis;
      white-space: nowrap;
    }

    .journal-img {
      display: block;
      width: 100%;
      height: 136px;
      border-radius: 8px 8px 0 0;
      background-size: cover; 
      background-position: top;
    }
  }
}

.journal-inner .empty-box {
  position: fixed;
}
</style>