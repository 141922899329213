<!-- class students list -->
<template>
  <div class="content has_breadcrumb">
    <base-breadcrumb
      :data="[
        {
          routeName: 'locations',
          name: user.schoolName,
        },
        {
          routeName: 'moments',
          name: 'Moments',
        },
        {
          routeName: 'momentClass',
          params:{id:$route.params.cid},
          name: classname,
        },
        {
          name: studentname,
        },
      ]"
      slot="breadcrumb"
    />
    <GgPage
      pageType="2"
      title="Moments"
      @forward="forward"
      @next="next"
    >
     <div slot="topAppBar" class="topAppBar">
        <!-- <month-switch @change="dayChange" :defaultVal="defSwitchVal"></month-switch> -->
        <search :classId="$route.params.cid"></search>
      </div>
      
      <template v-slot:left>
        <div class="mar-l-b">
         <upload-btn :classId="$route.params.cid" :studentId="$route.params.id" @uploadSuccess="fetchList()"><gg-create-button label="Upload" /></upload-btn>
        </div>
        <student-side-menu activeIndex="momentStudentUploaded" />
      </template>
      <GgPageHeader2 class="mar-b-0"  :squareInfo="userInfo" :title="userInfo.title" :subTitle1="userInfo.subTitle1" >
        <div
          slot="title-label"
          class="title-label"
        >
          <span>{{user.schoolName}}</span>
        </div>
        <!-- <div slot="subTitleLabel" class="title-label">Teacher</div> -->
        <div slot="info">
          <div class="title-status mar-b-0">{{userInfo.status}}</div>
        </div>
      </GgPageHeader2>
      <div class="optbar" v-show="checkedList.length>0">
        <el-button type="text" @click="clearCheck"><i class="el-icon-close"/></el-button>
        <span class="count">{{checkedList.length}} Selected</span>
        <div class="btns">
          <!-- <el-tooltip popper-class="file-tooltip-top" :visible-arrow="false" content="Share" placement="bottom"> -->
            <shareBtn @ok="onShareSuccess" :list="checkedList"/>
          <!-- </el-tooltip> -->
          <el-tooltip effect="light" popper-class="file-tooltip-top" :visible-arrow="false" content="Add to album" placement="bottom" v-if="hasPermission('Manage albums_Edit')">
            <addToAlbumBtn :classId="$route.params.cid" @ok="onAddToAlbumSuccess" :list="checkedList"></addToAlbumBtn>
          </el-tooltip>
           <el-tooltip effect="light" popper-class="file-tooltip-top" :visible-arrow="false" content="Delete" placement="bottom">
            <deleteBtn @ok="onDeleteSuccess" :list="checkedList"/>
          </el-tooltip>
          
            <gg-menu-bar v-if="hasPermission('Manage media_Full') || hasPermission('Manage media_Edit')">
              <el-tooltip popper-class="file-tooltip-top" :visible-arrow="false" content="More options" placement="bottom">
              <i class="iconmore_vert-24px iconfont" />
              </el-tooltip>
              <template slot="menus">
                <gg-menu-bar-item
                  v-if="hasPermission('Manage media_Full')"
                  @click="handleDownload()"
                  >Download</gg-menu-bar-item
                >
                <gg-menu-bar-item
                  v-if="hasPermission('Manage media_Edit')" 
                  @click="handleFavorite()"
                >
                  {{checkedListAllFavorited?'Remove from favorite':'Favorite'}}
                </gg-menu-bar-item>
              </template>
            </gg-menu-bar>
          
        </div>
      </div>
      <image-list :classId="$route.params.cid" :list="imgList" v-loading="loading" @checkChange="onCheckChange" ref="imageList"  @refresh="fetchList"/>
    </GgPage>
  </div>
</template>

<script>
import { Ajax } from "@/common";
import moment from 'moment';
import { batchImgDownload, hasPermission } from './../utils/utils.js'
import { mapState } from "vuex";
import uploadBtn from './../modules/uploadBtn'
import SvgIcon from "@/components/SvgIcon";
import studentSideMenu from './../modules/studentSideMenu'
import monthSwitch from './../modules/monthSwitch'
import search from './../modules/search'
import imageList from './../modules/imageList'
import deleteBtn from './../modules/deleteBtn'
import shareBtn from './../modules/shareBtn'
import addToAlbumBtn from './../modules/addToAlbumBtn'
import bus from '../utils/bus'
import studentMixin from './../mixin/studentMixin'
export default {
  components: {SvgIcon,studentSideMenu,monthSwitch,search,imageList,uploadBtn,deleteBtn,addToAlbumBtn,shareBtn},
  data() {
    return {
      boolean: true,
      loading:false,
      classname:this.$store.state.moments.curClassName,
      studentname:this.$store.state.moments.curStudentName,
      defSwitchVal:0,
      date:this.$route.query.time?moment(parseInt(this.$route.query.time)).valueOf():moment().valueOf(),
      imgList: [
       
      ],
      checkedList:[],
      userInfo: this.$store.state.moments.curStudent,
    };
  },
  mixins: [studentMixin],
  computed: {
    ...mapState({
      user: (state) => state.user
    }),
    checkedListAllFavorited() {
      if(this.checkedList.length > 0){
        const unfavourite = this.checkedList.filter(o=>o.favourite == 0)
        if(unfavourite.length>0){
          return false
        }else{
          return true
        }
      }
      return false
    }
  },
  created() {
    this.initMonthSwitchVal()
    this.fetchData()
    bus.$on('refreshImageList', this.fetchData)  
  },
  methods: {
    hasPermission,
    initMonthSwitchVal(){
      var _def = moment().valueOf()
      if(this.$route.query.time&&this.$route.query.time.length==13){
        _def = parseInt(this.$route.query.time)
      }
      this.date = _def
      this.defSwitchVal = _def
    },
    dayChange(val){
      this.date = val
      this.fetchData()
    },
    handleDownload(){
      batchImgDownload(this.checkedList)
      this.$refs.imageList.clearCheck()
    },
    async handleFavorite(){
      var res
      if(this.checkedListAllFavorited){
        const _arr = this.checkedList.map(o=>o.id)
        res = await Ajax.post("/moments/favourite/cancleFavorite", _arr)
      }else{
        const _arr = this.checkedList.map(o=>{
          return {
            "classId": this.$route.params.id,
            "mediaId": o.id,
          }
        })
        res = await Ajax.post("/moments/favourite/favoriteBatch", _arr)
      }
      if(res.code=='0000'){
        this.$refs.imageList.clearCheck()
        this.fetchList()
      }
    },
    async fetchData(){
      this.fetchList()
      var resClass = await Ajax.get("/usermanage/class/selectByIdV1", {
        id: this.$route.params.cid,
      })
       var resStudent = await Ajax.get("/usermanage/student/selectByStuId", {
        stuId: this.$route.params.id,
      })
    
      if(resClass.code=='0000'&&resStudent.code=='0000'){
        this.classname = resClass.data.className
        this.$store.commit("SET_CURCLASSNAME", this.classname);
        this.studentname = resStudent.data.firstName+' '+resStudent.data.lastName
        this.$store.commit("SET_CURSTUDENTNAME", this.studentname);
        this.userInfo = {
          title: resStudent.data.firstName+' '+resStudent.data.lastName,
          avatar: resStudent.data.photo,
          firstName: resStudent.data.firstName,
          lastName: resStudent.data.lastName,
          subTitle1: resStudent.data.studentId,
          status: resStudent.data.status,
        }
        this.$store.commit("SET_CURSTUDENT", this.userInfo);
      }
      
    },
    
    onShareSuccess(){
      this.$refs.imageList.clearCheck()
      this.fetchList()
    },
    onDeleteSuccess(){
      this.$refs.imageList.clearCheck()
      this.fetchList()
    },
    onAddToAlbumSuccess(){
      this.$refs.imageList.clearCheck()
    },
    async fetchList(){
      if(this.date>new Date().getTime()){
        this.imgList = []
        return
      }
      this.loading = true;
      var res = await Ajax.post("/moments/media/getMediaListV1", {
        classId: this.$route.params.cid,
        studentId: this.$route.params.id,
        status: 0,
        // date: moment(this.date).startOf('day').utc().format('YYYY-MM-DD HH:mm:ss'),
        size:9999
      })
      this.loading = false;
      if(res.code=='0000'){
         this.imgList = res.data.records
      }
    },
    onCheckChange(val){
      this.checkedList = val
    },
    clearCheck(){
      this.$refs.imageList.clearCheck()
    },
    forward(val) {
      console.log(val);
    },
    next(val) {
      console.log(val);
    },
  },
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
/deep/ .ggPage-main{
  position: relative;
}
/deep/ .ggPageTitle-left .squareBox {
    border-radius: 100%;
}
.content.has_breadcrumb .edoovo-breadcrumb {
    z-index: 10;
}
// /deep/ .ggPageTitle-left .title{
//   font-family: Roboto!important;
//   font-weight: 400!important;
// }
 .content.has_breadcrumb{
    /deep/ .ggPage-main-center-content{
      height:auto;
    }
  }
</style>