<template>
  <div class="model-box portfolio-a">
    <div class="title">
      <div class="ui-title">{{ modelData.title }}</div>
    </div>

    <div class="sp clearfix">
      <div class="l">
        <div class="ui-img-box">
          <div class="ui-img">
            <div class="s-img" :style="'background-image: url(' + modelData.img1 + ');'"></div>
          </div>
        </div>
      </div>
      <div class="r">
        <div class="title-1">
          <div class="ui-title-1">{{ modelData.title1 }}</div>
        </div>
        <div class="title-2">
          <div class="ui-title-2">{{ modelData.title2 }}</div>
        </div>
        <div class="title-3">
          <div class="ui-title-3">{{ modelData.title3 }}</div>
        </div>
      </div>
    </div>

    <div class="logo">
      <div class="ui-logo" :style="'background-image: url(' + modelData.logo + ');'"></div>
    </div>

  </div>
</template>

<script>
import moment from 'moment'
import { htmlDecode } from './../../utils/utils.js'

export default {
  props:{
    data: {
      default: () => ({}),
      type: Object
    },
    index: {
      default: 0,
      type: Number
    }
  },
  data() {
    return {
      modelData: {}
    };
  },
  mounted(){
    
  },
  watch:{
    data: function() {
      this.modelData = this.data
    },
    modelData: function() {
      this.$emit('postdata', this.modelData, this.index)
    }
  },
  created() {
    this.modelData = this.data
  },
  methods: {
    htmlDecode
  }
};
</script>
<style lang='less'>
@import '~@/assets/css/journalModel.less';
</style>