<template>
  <div class="ui-text-edit">
    <div class="edit-editing">
      <input type="text" :maxlength="len" :placeholder="mgs ? '' : placeholder" v-model="inputContent" @focus="clearPlaceholder(true)" @blur="clearPlaceholder(false)">
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: {
    width:{
      default: 100,
      type: Number
    },
    height:{
      default: 100,
      type: Number
    },
    len:{
      default: 1000,
      type: Number
    },
    placeholder:{
      default: '',
      type: String
    },
    modelkey:{
      default: '',
      type: String
    },
    value:{
      default: '',
      type: String
    }
  },
  data() {
    return {
      mgs: false,
      inputContent: ''
    };
  },
  watch: {
    value: function() {
      this.inputContent = this.value
    },
    inputContent: function() {
      this.$emit('getContent',this.inputContent, this.modelkey)
    }
  },
  computed: {

  },
  created() {
    this.inputContent = this.value
  },
  mounted(){
    
  },
  methods: {
    clearPlaceholder(b) {
      this.mgs = b
      if(!b){
        this.$emit('toBlur')
      }
    }
  }
};
</script>
<style lang='less' scoped>
.ui-text-edit {
  position: relative;
  width: 100%;
  height: 100%;
  border: 1px dashed #707070;
  box-sizing: initial;
  cursor: pointer;
  transition: ease .2s;

  .edit-editing {
    width: 100%;
    height: 100%;

    input {
      display: block;
      width: 100%;
      height: 100%;
      border: 0;
      background: transparent;
      outline: none;
    }
  }

  &:hover {
    border-color: #1A73E8;
  }
}
</style>