<!-- class students list -->
<template>
  <div>
    <gg-flex-menus
      :menus="menuDatas"
      @select="menuSelect"
      :default-active="activeIndex"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
// const _menuDatas= [
//         {
//           name: "Photos",
//           key: "momentStudentPhotos",
//           children: [
//             {
//               name: "Uploaded",
//               key: "momentStudentUploaded",
//               children: [],
//             },
//             {
//               name: "Pending",
//               key: "momentStudentPending",
//               children: [],
//             },
//             {
//               name: "Shared",
//               key: "momentStudentShared",
//               children: [],
//             }
//           ],
//         },
//       ];
export default {
  props: {
    activeIndex:{
      default:'momentStudentUploaded',
      type:String
    }
  },
  data() {
    return {
      menuDatas: [
        
      ],
      
    };
  },
   computed: {
    ...mapState({
      pendingCountStudent: (state) => state.moments.pendingCountStudent
    }),
  },
  methods: {
    generateMenuDatas(){
      return [
        {
          name: "Photos",
          key: "momentStudentPhotos",
          children: [
            {
              name: "Uploaded",
              key: "momentStudentUploaded",
              children: [],
            },
            {
              name: "Pending",
              key: "momentStudentPending",
              extra:this.pendingCountStudent,
              children: [],
            },
            {
              name: "Shared",
              key: "momentStudentShared",
              children: [],
            }
          ],
        },
      ]
    },
    menuSelect(key, item) {
      this.$router.push({name:key,params:{id:this.$route.params.id},query:{time:this.$route.query.time+'' || ''}})
    },
    init(){
      const _menuDatas = this.generateMenuDatas()
      this.menuDatas = [..._menuDatas]
      for(var i=0;i<_menuDatas.length;i++){
        const _cIdx = _menuDatas[i].children.findIndex(o=>o.key === this.activeIndex)
        if(_cIdx>=0){
          this.menuDatas[i].active = true
          break;
        }
      }
    }
  },
  watch:{
    pendingCountStudent(){
      // console.log(3333,this.pendingCount)
      this.init()
    }
  },
  created() {
    this.init()
  },
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
</style>