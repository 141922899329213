<!-- class students list -->
<template>
  <div class="content has_breadcrumb">
    <base-breadcrumb
      :data="[
        {
          routeName: 'locations',
          name: user.schoolName,
        },
        {
          routeName: 'moments',
          name: 'Moments',
        },
        {
          name: classname,
        },
      ]"
      slot="breadcrumb"
    />
    <GgPage pageType="2" title="Moments">
      <div slot="topAppBar">
        <month-switch @change="dayChange" :defaultVal="defSwitchVal"></month-switch>
        <search :classId="$route.params.id"></search>
      </div>
      <template v-slot:left>
        <div class="mar-l-b">
          <upload-btn :classId="$route.params.id"><gg-create-button label="Upload" /></upload-btn>
        </div>
        <class-side-menu activeIndex="momentClass" />
      </template>
      <BaseList :title="classname" :data="list"  @onClickItem="itemClick" v-loading="loading">
        <!-- ===== -->
        <!-- 空状态 -->
        <!-- ===== -->
        <div slot="empty" style="text-align: center" v-if="!loading">
          <div
            style="
              display:inline-block;
              width: 371px;
              text-align: center;
              margin-top: 210px;
              margin-bottom: 20px;
            "
          >
            <img
              style="width: 100%"
              :src="require('@/assets/images/moments/empty.png')"
            />
          </div>
          <p style="font-size: 22px;color:#5f6368;font-family: Roboto;">No data for the day.</p>
        </div>
      </BaseList>
    </GgPage>
  </div>
</template>

<script>
import moment from 'moment';
import { Ajax } from "@/common";
import { mapState } from "vuex";
import classSideMenu from './../modules/classSideMenu'
import monthSwitch from './../modules/monthSwitch'
import search from './../modules/search'
import uploadBtn from './../modules/uploadBtn'
import classMixin from './../mixin/classMixin'
export default {
  components: {monthSwitch,search,classSideMenu,uploadBtn},
  computed: {
    ...mapState({
      user: (state) => state.user
    }),
  },
  mixins: [classMixin],
  data() {
    return {
      loading:false,
      classname:this.$store.state.moments.curClassName,
      defSwitchVal:0,
      date:this.$route.query.time?parseInt(this.$route.query.time):moment().valueOf(),
      activeIndex: "momentStudents",
      list: [
      ],
    };
  },
  created() {
    this.initMonthSwitchVal()
    this.fetchData()
  },
  methods: {
    initMonthSwitchVal(){
      var _def = moment().valueOf()
      if(this.$route.query.time&&this.$route.query.time.length==13){
        _def = parseInt(this.$route.query.time)
      }
      this.date = _def
      this.defSwitchVal =_def
    },
     dayChange(val){
      this.date = val
      this.fetchData()
    },
    itemClick(item){
      console.log(item)
      // this.$router.push('/moment/class/'+this.$route.params.id+'/student/'+item.id)
      this.$store.commit("SET_CURSTUDENTNAME", item.leading.text);
      this.$store.commit("SET_CURSTUDENT", {
      title: "",
      avatar: "",
      firstName: "",
      lastName: "",
      subTitle1: "",
      status: "",
    });

      this.$router.push({name:'momentStudentIndex',params:{cid:this.$route.params.id,id:item.id},query:{time:this.$route.query.time || ''}})
    },
    async fetchData(){
      this.loading = true;
      var resClass = await Ajax.get("/usermanage/class/selectByIdV1", {
        id: this.$route.params.id,
      })

      if(this.date>new Date().getTime()){
        this.loading = false;
        this.list = []
        return
      }


      var _query = {
        date: moment(this.date).startOf('day').utc().format('YYYY-MM-DD HH:mm:ss'),
        staffId: this.$store.state.user.userId
      }
      if(this.$route.params.id!=0){
        _query.classId= this.$route.params.id
      }
	  
      

      var resList = await Ajax.get("/moments/share/getStudentListByClassIdV1", _query)
      this.loading = false;
      if(resClass.code=='0000'&&resList.code=='0000'){
        this.classname = resClass.data.className
        
        this.$store.commit("SET_CURCLASSNAME", this.classname);
        this.list = resList.data.map(o=>{
          return {
            id:o.studentId,
            leading: {
              icon: o.studentPhoto || '',
              bgColor: o.themeColor || '#B4B4B4',
              text: o.studentName,
            },
            
            trailing: {
              text: o.shared,
              subText: "Shared",
            },
          }
        })
      }
      
    }
  },
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.ggPage{
  /deep/.base-list .title{
    font-family:'opensans-semibold';
    color:#5f6368
  }
}
/deep/ .ggPage-main-center .base-list{
  padding-bottom:112px!important;
  .title{
    font-family: 'opensans-semibold';
    margin-left:0;
    padding-left:10px;
  }
  .main-column{
    font-family: 'opensans-semibold';
    position: relative;
    padding-left:52px;
    display: block;
  }
  .main-column{
    .icon,.icon-text{
      border-radius: 50%;
      position: absolute;
      left:0;
      top:50%;
      transform: translate(0,-50%);
    }
    .icon-text{
      width:32px;
      height:32px;
    }
  }
  ul li {
    border-top:0 none;
    border-bottom: 1px solid #e0e0e0;
    &:nth-child(2) {
      border-bottom: 1px solid #e0e0e0;
    }
    // &:nth-last-child(1) {
    //   border-bottom: 2px solid #e0e0e0;
    // }
  }
}


</style>