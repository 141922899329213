<template>
  <div>
    <div class="drop-box-overlay" v-if="droped" @click="droped=false"></div>

    <div class="announcement search-bar">
      <div class="box">
        <label for="keyword">
          <div class="bar-search-icon" @click="searchPost">
            <img class="normal-icon" :src="require('@/assets/images/bar_search_1.png')" />
          </div>
        </label>
        <input type="text" class="input-x" placeholder="Search for photos" v-model="keywords" id="keyword" @keyup.enter="searchPost">
        <el-button v-if="keywords" type="text" icon="el-icon-close" class="drop-btn clear-btn" @click="clearKeywords"></el-button>
        <el-button v-else type="text" icon="el-icon-caret-bottom" class="drop-btn" @click="openDrop"></el-button>
      </div>
      
      <div class="drop-box" v-if="droped">
        <div class="area">
          <div class="line">
            <div class="tit">Students</div>
            <md-field md-inline>
              <label>Enter a name or email address</label>
              <md-input v-model="studentName"></md-input>
            </md-field>
          </div>
          <div class="line">
            <div class="tit">Tags</div>
            <tag-selecter :options="tagsOptions" @onCheck="handleTagCheck"/>
          </div>
          <div class="line">
            <div class="tit">Note</div>
            <md-field md-inline>
              <label>Enter words found in the note</label>
              <md-input v-model="note"></md-input>
            </md-field>
          </div>
          <div class="line clearfix">
            <div class="tit">Date uploaded</div>
            <md-datepicker class="datepicker" v-model="beginDate"  md-immediately>
              <img src="@/assets/images/icons/arrow_drop.svg" class="calendar-arrow" />
              <label>From date</label>
            </md-datepicker>
            <span class="datepickerspan">to</span>
            <md-datepicker class="datepicker" v-model="endDate"  md-immediately>
              <img src="@/assets/images/icons/arrow_drop.svg" class="calendar-arrow" />
              <label>To date</label>
            </md-datepicker>
          </div>
        </div>
        <div class="hr"></div>
        <div class="btns">
          <baseButton class="cancel-btn" @click="droped=false">Cancel</baseButton>
          <baseButton class="base-button primary" @click="searchPost">Search</baseButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { Ajax } from "@/common";
import { mapState } from "vuex";
import TagSelecter from "./detail/modules/tagSelecter";

export default {
  components:{TagSelecter},
  computed: {
    ...mapState({
      user: (state) => state.user
    }),
  },
  props:{
    query: {
      default:()=>{},
      type: Object
    },
    classId:{
      default: '',
      type: String
    }
  },
  watch:{
    searchQuery(){
      this.$emit('checkChange',this.searchQuery)
    }
  },
  data() {
    return {
      note: '',
      searchQuery: [],
      tagList: [],
      tagsOptions: [],
      studentName: '',
      searching:false,
      droped:false,
      keywords:'',
      beginDate:null,
      endDate:null
    };
  },
  mounted(){
    
  },
  created() {
    this.fetchTags()
  },
  methods: {

    moment,

    clearKeywords() {
      this.keywords = ''
      this.note = ''
      this.studentName = ''
      this.tagList = []
      this.beginDate = null
      this.endDate = null
      this.searchQuery = null
      this.$emit('backnormal')
    },

    handleTagCheck(val){
      this.tagList = [...val]
    },

    async fetchTags(){
      var res = await Ajax.post("/usermanage/setting/getList", {
        "dataLevel": "moments",
        "dataType": "learingDomain",
        "userId": this.user.userId

      })
      if(res.code=='0000'){
        this.tagsOptions = res.data
      }
    },

    searchPost(){
      const keywords = this.keywords,
      note = this.note,
      tagList = this.tagList,
      studentName = this.studentName.indexOf(' ') > -1 ? this.studentName.split(' ') : [this.studentName],
      postedDateStart = this.beginDate ? moment(this.beginDate).format('YYYY-MM-DD') : '',
      postedDateEnd = this.endDate ? moment(this.endDate).format('YYYY-MM-DD') : ''

      // 简易搜索 
      if(!this.droped && keywords) {
        let query = {}
        query.keywords = keywords
        this.searchQuery = query
        return false
      } 

      if(keywords || studentName || note || postedDateStart || postedDateEnd || tagList) {
        let query = {}
        if(keywords) {
          query.keywords = keywords
        }

        if(note) {
          query.note = note
        }
        
        if(tagList) {
          query.tagList = []
          for(let item of tagList) {
            query.tagList.push(item.id)
          }
        }

        if(studentName.length > 0){
          query.studentList = studentName
        }

        if(postedDateStart){
          query.beginDate = postedDateStart
        }

        if(postedDateEnd){
          query.endDate = postedDateEnd
        }
        this.searchQuery = query
        this.droped = false
      }
    },

    openDrop(){
      this.droped = true
      this.keywords = ''
      this.note = ''
      this.studentName = ''
      this.tagList = []
      this.beginDate = null
      this.endDate = null
    }
  },
};
</script>
<style lang='less' scoped>
.search-btn{
  position: absolute;
  right:0;
  top:50%;
  transform: translate(0,-50%);    
  font-size: 18px;
  color:#fff;
}
.drop-box-overlay{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  overflow: hidden;
}
.search-bar{
  position: relative;
  z-index: 100;
  margin: 24px 80px 24px 0;
  font-family: Roboto;
  height: 40px;

  .box{
    position: relative;
    width: 100%;
    height:40px;
    line-height:40px;
    border-radius: 4px;
    border: 1px solid #e0e0e0;
    background: #fff;
    font-size: 0;

    .focus-icon {
      display: none;
    }

    &.focus {
      background: #fff;

      .bar-search-icon:hover {
        background: #eee;
      }

      .drop-btn:hover {
        background: #eee;
      }

      input {
        color: #20212480;

        &:-internal-autofill-previewed,
        &:-internal-autofill-selected {
          -webkit-text-fill-color: #20212480 !important;
          transition: background-color 5000s ease-in-out 0s !important;
        }
      }

      .focus-icon {
        display: inline-block;
      }

      .drop-btn {
        color: #606367 !important;
      }
    }

    label{
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      width: 40px;
      height: 40px;
      line-height: 40px;
      text-align: center;

      .bar-search-icon {
        display: inline;
      }
    }
    i{
      vertical-align: middle;
    }
    input{
      background:transparent;
      display:inline-block;
      border:0 none;
      outline:none;
      color:#202124;
      margin: 0 40px;
      width: 100%;
      height: 40px;
      font-size: 14px;
      font-family: Roboto;
      box-sizing: border-box;

      &:-internal-autofill-previewed,
      &:-internal-autofill-selected {
        -webkit-text-fill-color: #202124 !important;
        transition: background-color 5000s ease-in-out 0s !important;
      }

      &::placeholder {
        color: #202124;
        opacity: .5;
      }
    }

    .drop-btn{
      position: absolute;
      right: 0;
      top: -1px;
      padding: 0;
      display: block;
      width: 40px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      color:#202124;

      .el-icon-caret-bottom {
        display: inline;
      }
    }
  }
 
  .drop-box{
    position: absolute;
    left:0;
    top:41px;
    background:#fff;
    border-radius: 8px;
    width: 100%;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
    font-size:14px;
    color:#202124;
    line-height: 20px;

    .area{
      height: 300px;
      padding: 20px 24px 0;
    }
    .hr{
      height:0;
      border-bottom:1px solid #E0E0E0;
    }
    .line{
      position: relative;
      min-height: 60px;
      padding-left: 154px;
      
      .tit{
        position: absolute;
        left:0;
        top:9px;
        line-height: 48px;
        color:#202124;
        font-size:14px;
        font-weight: 500;
      }
      .md-field {
        margin-bottom: 0;
        font-weight: 500;
        font-size: 16px;

        label {
          color: #B4B4B4;
        }
      }
      
      .datepickerspan{
        float:left;
        line-height: 68px;
        margin:0 15px;
      }

      &:last-child {
        .tit{
          top:13px;
        }
      }
    }
    .datepicker{
      float:left;
      width:160px;

      .md-date-icon {
        display: none;
      }
    }
    .btns{
      text-align: right;
      padding:10px 20px;
      .el-button{
        margin-left:30px;
        &.cancel{
          color:#5F6368;
        }
      }
    }
  }
}
.student-selecter{
  min-height: 49px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  /deep/ .edit-btn{
    top:10px;
  }
}

.search-btn {
  width: 48px;
  height: 48px;
  vertical-align: middle;
  display: inherit;
  text-align: center;
  border-radius: 50%;

  img {
    width: 24px;
    height: 24px;
    vertical-align: middle;
  }

  &:hover {
    background: #ffffff33;
  }
}

.bar-search-icon {
  width: 36px;
  height: 36px;
  line-height: 34px;
  text-align: center;
  vertical-align: middle;
  display: inherit;
  border-radius: 50%;
  margin-top: -4px;
  cursor: pointer;

  img {
    display: inherit;
    width: 24px;
    height: 24px;
    vertical-align: middle;
  }

  &:hover {
    background: #ffffff33;
  }
}

.md-field.md-theme-default:after {
  border-bottom: 1px solid #e0e0e0;
}

.md-field:before {
  height: 1px;
}

.search-bar .box .clear-btn {
  top: 0;
}
</style>