<!-- class students list -->
<template>
  <div class="content has_breadcrumb">
    <base-breadcrumb
      :data="[
        {
          routeName: 'locations',
          name: user.schoolName,
        },
        {
          routeName: 'moments',
          name: 'Moments',
        },
        {
          name: classname,
        },
      ]"
      slot="breadcrumb"
    />
    <GgPage
      pageType="2"
      title="Moments"
      @forward="forward"
      @next="next"
    >
       <div slot="topAppBar" class="topAppBar">
        <!-- <month-switch @change="dayChange" :defaultVal="defSwitchVal"></month-switch> -->
        <search :classId="$route.params.id"></search>
      </div>
      
      <template v-slot:left>
        <div class="mar-l-b">
           <upload-btn :classId="$route.params.id"><gg-create-button label="Upload" /></upload-btn>
        </div>
        <class-side-menu activeIndex="momentClassShared" />
      </template>
      <GgPageHeader :title="classname" class="mar-b-0" />
      <div class="optbar" v-show="checkedList.length>0">
        <el-button type="text" @click="clearCheck"><i class="el-icon-close"/></el-button>
        <span class="count">{{checkedList.length}} Selected</span>
        <div class="btns">
          <el-tooltip effect="light" popper-class="file-tooltip-top" :visible-arrow="false" content="Add to album" placement="bottom" v-if="hasPermission('Manage albums_Edit')">
          <addToAlbumBtn :classId="$route.params.id" @ok="onAddToAlbumSuccess" :list="checkedList"></addToAlbumBtn>
          </el-tooltip>
          <el-tooltip effect="light" popper-class="file-tooltip-top" :visible-arrow="false" content="Cannot delete a shared photo" placement="bottom">
            <deleteBtn @ok="onDeleteSuccess" disabled :list="checkedList"/>
          </el-tooltip>
         
          <gg-menu-bar v-if="hasPermission('Manage media_Full') || hasPermission('Manage media_Edit')">
             <el-tooltip popper-class="file-tooltip-top" :visible-arrow="false" content="More options" placement="bottom">
            <i class="iconmore_vert-24px iconfont" />
            </el-tooltip>
            <template slot="menus">
              <gg-menu-bar-item
                v-if="hasPermission('Share media_Full')"
                @click="handleRecall()"
                >Recall</gg-menu-bar-item
              >
              <gg-menu-bar-item
                v-if="hasPermission('Manage media_Full')"
                @click="handleDownload()"
                >Download</gg-menu-bar-item
              >
              <gg-menu-bar-item
                v-if="hasPermission('Manage media_Edit')"
                @click="handleFavorite()"
                >{{checkedListAllFavorited?'Remove from favorite':'Favorite'}}</gg-menu-bar-item
              >
            </template>
          </gg-menu-bar>
          
        </div>
      </div>
      <image-list :classId="$route.params.id" :list="imgList" :loading="loading" @checkChange="onCheckChange" ref="imageList"/>
    </GgPage>
  </div>
</template>

<script>
import { Ajax } from "@/common";
import moment from 'moment';
import { mapState } from "vuex";
import uploadBtn from './../modules/uploadBtn'
import SvgIcon from "@/components/SvgIcon";
import classSideMenu from './../modules/classSideMenu'
import monthSwitch from './../modules/monthSwitch'
import search from './../modules/search'
import imageList from './../modules/imageList'
import deleteBtn from './../modules/deleteBtn'
import addToAlbumBtn from './../modules/addToAlbumBtn'
import classMixin from './../mixin/classMixin'
import bus from '../utils/bus'
import { batchImgDownload, hasPermission } from '../utils/utils.js'
export default {
  components: {SvgIcon,classSideMenu,monthSwitch,search,imageList,uploadBtn,deleteBtn,addToAlbumBtn},
  data() {
    return {
      boolean: true,
      loading:false,
      classname:this.$store.state.moments.curClassName,
      defSwitchVal:0,
      date:this.$route.query.time?moment(parseInt(this.$route.query.time)).valueOf():moment().valueOf(),
      imgList: [
        
      ],
      checkedList:[]
    };
  },
  mixins: [classMixin],
  computed: {
    ...mapState({
      user: (state) => state.user
    }),
    checkedListAllFavorited() {
      if(this.checkedList.length > 0){
        const unfavourite = this.checkedList.filter(o=>o.favourite == 0)
        if(unfavourite.length>0){
          return false
        }else{
          return true
        }
      }
      return false
    }
  },
  created() {
    this.initMonthSwitchVal()
    this.fetchData()
    bus.$on('refreshImageList', this.fetchData)  
  },
  methods: {
    hasPermission,
    initMonthSwitchVal(){
      var _def = moment().valueOf()
      if(this.$route.query.time&&this.$route.query.time.length==13){
        _def = parseInt(this.$route.query.time)
      }
      this.date = _def
      this.defSwitchVal = _def
    },
    dayChange(val){
      this.date = val
      this.fetchData()
    },
    async fetchData(){
      this.fetchList()
      var resClass = await Ajax.get("/usermanage/class/selectByIdV1", {
        id: this.$route.params.id,
      })
      if(resClass.code=='0000'){
        this.classname = resClass.data.className
        this.$store.commit("SET_CURCLASSNAME", this.classname);
      }
      
    },
    onShareSuccess(){
      this.$refs.imageList.clearCheck()
    },
    onDeleteSuccess(){
      this.$refs.imageList.clearCheck()
      this.fetchList()
    },
    onAddToAlbumSuccess(){
      this.$refs.imageList.clearCheck()
    },
    async fetchList(){
      if(this.date>new Date().getTime()){
        this.imgList = []
        return
      }
      this.loading = true;
      var res = await Ajax.post("/moments/media/getMediaListV1", {
        classId: this.$route.params.id,
        status: 2,
        // date: moment(this.date).startOf('day').utc().format('YYYY-MM-DD HH:mm:ss'),
        size:9999
      })
      this.loading = false;
      if(res.code=='0000'){
         this.imgList = res.data.records
      }
    },
    handleRecall(){
      this.$confirm(
        `Once recalled, the photos will change from shared to uploaded and not be seen by parents.`,
        "Recall photos?",
        {
          cancelButtonText: "Cancel",
          confirmButtonText: "Recall",
          cancelButtonClass: "gg_cancel_button",
          confirmButtonClass: "gg_submit_button",
          customClass: "gg_confirm",
          showClose: false,
        }
      )
        .then(() => {
          const _batchNoList = this.checkedList.map(o=>o.batchNo)
          Ajax.post("/moments/media/reCallBatch", _batchNoList).then(res=>{
              if(res.code=='0000'){
                // bus.$emit('refreshImageList')
                this.clearCheck()
                this.fetchList()
              }else{

              }
            })
        })
        .catch(() => {});
    },
    handleDownload(){
      batchImgDownload(this.checkedList)
      this.$refs.imageList.clearCheck()
    },
    async handleFavorite(){
      var res
      if(this.checkedListAllFavorited){
        const _arr = this.checkedList.map(o=>o.id)
        res = await Ajax.post("/moments/favourite/cancleFavorite", _arr)
      }else{
        const _arr = this.checkedList.map(o=>{
          return {
            "classId": this.$route.params.id,
            "mediaId": o.id,
          }
        })
        res = await Ajax.post("/moments/favourite/favoriteBatch", _arr)
      }
      
      if(res.code=='0000'){
        this.$refs.imageList.clearCheck()
        this.fetchList()
      }
    },
    onCheckChange(val){
      this.checkedList = val
    },
    clearCheck(){
      this.$refs.imageList.clearCheck()
    },
    forward(val) {
      console.log(val);
    },
    next(val) {
      console.log(val);
    },
  },
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
/deep/ .ggPage-main{
  position: relative;
}
.ggPageTitle{
  /deep/.ggPageTitle-left .titleInfo .title{
    font-family:'opensans-semibold';
    color:#5f6368
  }
}
.content.has_breadcrumb .edoovo-breadcrumb {
    z-index: 10;
}
.content.has_breadcrumb{
  /deep/ .ggPage-main-center-content{
    height:auto;
  }
}
</style>