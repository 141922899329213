<template>
<span>
  <el-button type="text" class="icon-button" @click="handleAlbum" v-if="hasPermission('Manage albums_Edit')"><svg-icon iconClass="album"></svg-icon></el-button>

  <gg-Dialog
    :visible.sync="albumSelecterShow"
    width="580px"
    ref="studentSelecter"
  >
    <div slot="title">
      Add to album
    </div>
    <div class="ct" v-loading="loading">
      <div class="values">
        <div class="value" v-for="item in selectedTemp" :key="item.id">
          <span class="avatar" :style="'background-image:url('+rootPath+item.avatar+')'"></span>
          <span class="name">{{item.name}}</span>
          <el-button type="text" icon="el-icon-error" @click="checkItem(item)" v-if="item.id!=albumId"></el-button>
        </div>
      </div>
      <div class="options">
        <div class="tit">SEARCH RESULTS</div>
        
       
        <div class="item" v-for="item in options" :key="item.id"  @click="checkItem(item)" :class="{'disabled':item.id==albumId}">
          <span class="checkbox" :class="{'checked':selectedTemp.find(o=>o.id==item.id)}">
            <i class="el-icon-check"></i>
          </span>
          <span class="avatar" :style="'background-image:url('+rootPath+item.avatar+')'"></span>
          <div class="wd">
            <div class="wd-main">
              <span class="name">{{item.name}}</span>
              <span class="mail">{{item.count+' photos'}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <gg-cancel-button @click="close">Cancel</gg-cancel-button>
      <gg-submit-button
      :disabled="selectedTemp.length==0 || (selectedTemp.length==1&&selectedTemp[0].id==albumId)"
      @click="subData"
        >Submit</gg-submit-button
      >
    </span>
  </gg-Dialog>
  
  <Snackbar
      :visible.sync="snackbarData.visible"
      :content="snackbarData.content"
    />   
</span>
</template>
<script>
import { Ajax } from "@/common";
import SvgIcon from "@/components/SvgIcon";
import { hasPermission } from './../utils/utils.js';
export default {
  components: {SvgIcon},
  props:{
    list:{
      default:()=>[],
      type:Array
    },
    classId:{
      default:'',
      type:String
    },
    albumId:{
      default:'',
      type:String
    },
  },
  data(){
    return {
      rootPath:process.env.VUE_APP_fileBasePath,
      albumSelecterShow:false,
      loading:false,
      options:[],
      selected:[], // 当前值
      selectedTemp:[], // 当前操作中的值，未确定
      snackbarData: {
        visible: false,
        content: "",
      },
    }
  },
  created() {
  },
  methods:{
    hasPermission,
    handleAlbum(){
      this.albumSelecterShow = true
      this.fetchAlbums()
      this.initDefault()
    },
    initDefault(){
      this.selected = []
      this.selectedTemp = []
      if(this.albumId.length>0){
        const _data = this.options.find(o=>o.id==this.albumId)
        this.selected = [_data]
        this.selectedTemp = [_data]
      }
    },
    close(){
      this.albumSelecterShow = false
    },
    checkItem(item) {
       if(item.id == this.albumId){
        return
      }
      const _i = this.selectedTemp.findIndex(o=>o.id==item.id)
      if(_i>=0){
        // 取消选中
        this.selectedTemp.splice(_i,1)
      }else{
        // 选中
        this.selectedTemp.push(item)
      }
    },
    fetchAlbums(){
      this.loading = true;
      Ajax.get("/moments/albums/getAlbumListByClassIdV1", {
        classId:this.classId,
      })
		  .then((res) => {
		    this.loading = false;
        if (res.code == "0000") {
          this.options = res.data.map(o=>{
            return {
              id:o.id,
              avatar:o.logo,
              name:o.name,
              count:o.mediaCount
            }
          })
        
        }
		  })
		  .catch(() => {
		    this.loading = false;
		  });
    },
    subData(){
      let _arr = []
      for(var i=0;i<this.list.length;i++){
        for(var j=0;j<this.selectedTemp.length;j++){
          _arr.push({
            "albumsId": this.selectedTemp[j].id,
            "mediaId": this.list[i].id
          })
        }
      }
     Ajax.post("/moments/albums/addToAlbumBatch", _arr).then(res=>{
              if(res.code=='0000'){
                this.handleSnack('Photo has been added to album')
                this.$emit('ok')
                this.albumSelecterShow = false
              }else{
                this.handleSnack(res.message)
              }
            }).catch(()=>{})
        
    },
    handleSnack(content) {
      this.snackbarData.content = content;
      this.snackbarData.visible = true;
    },
  }
}
</script>
<style lang="less" scoped>
span{

    display: inline-block;
    text-align: center;
    margin: 0 10px;
}
.svg-icon{
  height:20px;
}

.value{
    display: inline-block;
    height:32px;
    line-height: 30px;
    padding:0 35px 0 37px;
    border:1px solid #E0E0E0;
    border-radius: 15px;
    font-size: 0;
    margin-right: 8px;
    cursor:default;
    position: relative;
    max-width: 100%;
    .avatar{
      width:24px;
      height:24px;
      border-radius: 100%;
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
      vertical-align: middle;
      position: absolute;
      top:4px;
      left:5px;
      background-color:#eee;
      margin:0;
    }
    .name{
      font-size:14px;
      color:#202124;
      margin:0;
      display: block;
      text-overflow: ellipsis;overflow: hidden;white-space: nowrap;
    }
    .el-button{
      padding:0;
      vertical-align: middle;
      color:#5F6368;
      font-size: 20px;
      // margin-left:8px;
      position: absolute;
      top:5px;
      right:5px;
    }
  }
.ct{
      padding: 24px;
      .values{
        padding: 4px 0;
        border-bottom: 1px solid #1A73E8;
        min-height: 41px;
      }
      .options{
        margin-top:25px;
        .tit{
          line-height: 16px;
          color:#5F6368;
          font-size: 12px;
        }
        .item{
          min-height:36px;
          line-height: 36px;
          padding:0 5px 0 82px;
          font-size: 0;
          margin-top:20px;
          position: relative;
          cursor:pointer;
          .checkbox{
            height:20px;
            width:20px;
            line-height: 16px;
            text-align: center;
            display: inline-block;
            border:2px solid #445468;
            border-radius: 2px;
            vertical-align: middle;
            margin: 0 18px 0 0;
            left: 0;
            position: absolute;
            top: 50%;
            margin-top: -10px;
            .el-icon-check{
              font-size: 16px;
              color:#fff;
              display:  none;
            }
            &.checked{
              border-color:#1A73E8;
              background:#1A73E8;
              .el-icon-check{
                display: block;
              }
            }
          }
           &.disabled{
            .checkbox{
              &.checked{
                border-color:#B4B4B4;
                background:#B4B4B4;
                .el-icon-check{
                  display: block;
                }
              }
            }
          }
          .avatar{
            width:36px;
            height:36px;
            background-position: center center;
            background-size: cover;
            background-repeat: no-repeat;
            background-color:#eee;
            vertical-align: middle;
            display: inline-block;
            margin-right: 10px;
            margin-left:0;
            position: absolute;
            left:38px;
            top:50%;
            margin-top:-18px;
          }
          .wd{
            vertical-align: middle;
            display: inline-block;
          }
          .wd-main{
            display: flex;
            flex-direction: column;
          }
          .name{
            text-align: left;
            font-size:14px;
            color:#202124;
            line-height: 20px;
            font-weight: 500;
            font-family: Roboto;
          }
          .mail{
            text-align: left;
            font-size:12px;
            color:#202124;
            line-height: 16px;
            font-weight: 400;
            font-family: Roboto;
          }
        }
      }
    }
</style>