var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content has_breadcrumb"},[_c('base-breadcrumb',{attrs:{"slot":"breadcrumb","data":[
      {
        routeName: 'locations',
        name: _vm.user.schoolName,
      },
      {
        routeName: 'moments',
        name: 'Moments',
      },
      {
        name: _vm.classname,
      } ]},slot:"breadcrumb"}),_c('GgPage',{attrs:{"pageType":"2","title":"Moments"},scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('div',{staticClass:"mar-l-b"},[_c('upload-btn',{attrs:{"classId":_vm.$route.params.id}},[_c('gg-create-button',{attrs:{"label":"Upload"}})],1)],1),_c('class-side-menu',{attrs:{"activeIndex":"momentClass"}})]},proxy:true}])},[_c('div',{attrs:{"slot":"topAppBar"},slot:"topAppBar"},[_c('month-switch',{attrs:{"defaultVal":_vm.defSwitchVal},on:{"change":_vm.dayChange}}),_c('search',{attrs:{"classId":_vm.$route.params.id}})],1),_c('BaseList',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"title":_vm.classname,"data":_vm.list},on:{"onClickItem":_vm.itemClick}},[(!_vm.loading)?_c('div',{staticStyle:{"text-align":"center"},attrs:{"slot":"empty"},slot:"empty"},[_c('div',{staticStyle:{"display":"inline-block","width":"371px","text-align":"center","margin-top":"210px","margin-bottom":"20px"}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":require('@/assets/images/moments/empty.png')}})]),_c('p',{staticStyle:{"font-size":"22px","color":"#5f6368","font-family":"Roboto"}},[_vm._v("No data for the day.")])]):_vm._e()])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }