<template>
<span>
  <el-tooltip class="item" effect="light" :content="list.length<=10?'Share':'Cannot share more than 10 photos'" placement="bottom" :visible-arrow="list.length>10" v-if="hasPermission('Manage media_Create')">
    <el-button type="text" class="icon-button" :class="{'disabled':list.length>10}" @click="handleShare"><i class="el-icon-share"></i></el-button>
  </el-tooltip>

  <BaseFormDialog3
    v-model="visible"
    :title="title"
    :data="formDataList"
    @submit="handleSubmit"
    :submitBtnTxt="btnTxt"
    :renderPass="cansub"
    textReminder=""
    
  >
    <template slot="renderHtml" slot-scope="scope">
      <gg-input
        v-if="scope.data.key === 'caption'"
        :key="scope.data.key"
        :ref="scope.data.key"
        :placeholder="scope.data.placeholder"
        v-model="caption"
        :error="errorObj"
      />
    </template>
  </BaseFormDialog3>
  <Snackbar
      :visible.sync="snackbarData.visible"
      :content="snackbarData.content"
    /> 
</span>
</template>
<script>
import { hasPermission } from './../utils/utils.js';
import { Ajax } from "@/common";
import { mapState } from "vuex";
import moment from 'moment';
import SvgIcon from "@/components/SvgIcon";
import bus from '../utils/bus'
export default {
  components: {SvgIcon},
  props:{
    list:{
      default:()=>[],
      type:Array
    },
  },
  data(){
    return {
      title:"Add a caption",
      btnTxt:'Next',
      visible: false,
      loading:false,
      cansub:false,
      formDataList: [
        {
          placeholder: "Caption",
          key: "caption",
          value: "",
          renderHtml: true,
        },
      ],
      caption:'',
      currentCaption: '',
      errorObj: {
        show: false,
        message: "",
      },
      snackbarData: {
        visible: false,
        content: "",
      },
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.user
    }),
  },
  watch: {
    caption(newVal,oldVal) {
      this.formDataList[0].value=newVal
      if(newVal.length!=0){
        if(newVal.length<=80){
          
            this.cansub = true
            this.errorObj={
              show: false,
              message: "",
            }
         
        }else{
          this.cansub = false
          this.errorObj={
            show: true,
            message: "Character limit of 80",
          }
        }
      }else{
        this.cansub = false
      }
    },
  },
  methods:{
    hasPermission,
    handleShare(){
      if(this.list.length>10){return}
      this.caption=''
      this.currentCaption = ''
      this.formDataList[0].value=''
      this.visible = true
    },
    handleSubmit(){
      let formData = {};
        this.formDataList.forEach((item) => {
        formData[item.key] = item.value;
      });
      this.visible = false
      this.goNext()
    },
    goNext(){
      this.$confirm(
        "Once submitted, the photos will be sent for approval before being shared and viewed by parents. ",
        'Share media?',
        {
          cancelButtonText: "Cancel",
          confirmButtonText: "Submit",
          cancelButtonClass: "gg_cancel_button",
          confirmButtonClass: "gg_submit_button",
          customClass: "gg_confirm",
          showClose: false
        }
      )
        .then(() => {
          const _arr = this.list.map(o=>{
            return {
                "caption": this.caption,
                "mediaId": o.id,
                "shareByTeacher": this.user.userId,
                "shareSubmissionTime": moment().format('DD/MM/YYYY, hh:mm A'),
                "fileDisplayName":o.fileDisplayName
            }
          })
          Ajax.post("/moments/share/shareBatch", _arr).then(res=>{
            if(res.code=='0000'){
              bus.$emit('refreshImageList')
              this.$emit('ok')
            }else{
              this.handleSnack(res.message)
            }
          })
        })
        .catch(() => {});
    },
    handleSnack(content) {
      this.snackbarData.content = content;
      this.snackbarData.visible = true;
    },
  }
}
</script>
<style lang="less" scoped>
span{

    display: inline-block;
    text-align: center;
    margin: 0 10px;
}

 .el-button{
    color:#777;
   
      display: inline-block;
      vertical-align: middle;
    &.disabled{
      cursor:not-allowed;color:#999;
    }
    .el-icon-share{
      font-size: 18px;
    }
  }
</style>