<template>
  <div>
    <div class="ui-img-upload">
      <div class="btn" @click="showimgbth">
        <img v-if="!file64" class="spbtn" src="@/assets/images/icons/add_photo_alternate.svg" />
        <div v-else class="img" :style="'background-image: url(' + file64 + ');'"></div>
      </div>
      <div v-if="showmanu" class="create-dropdown-ui">
        <div class="create-dropdown-list">
          <div class="create-dropdown-list-inner">
            <ul class="create-class-list">
              <!-- <li @click="uploadImage"> -->
              <li>
                <el-upload
                  class="ui-img-upload-wrapper"
                  ref="uploadFileInp"
                  list-type="picture"
                  multiple
                  :auto-upload="false"
                  :show-file-list="false"
                  action="/media/public/file/uploadExt"
                  accept="image/jpeg,image/png,application/pdf"
                  :on-change="uploadFileChange"
                  >
                  <el-button class="add-file-btn" size="small" type="primary">
                    Upload from computer
                  </el-button>
                </el-upload>
              </li>
              <li @click="showMomentsPhoto">Choose from Moments</li>
            </ul>
          </div>
        </div>
        <div class="create-dropdown-bg" @click="showmanu = !showmanu"></div>
      </div>
    </div>

    <imgCutModal
      class="mp-dialog-cut"
      :title="'Photo'"
      :openCropper="fileObj"
      :visible.sync="isImgCutShow"
      v-if="isImgCutShow"
      @_uploadCallback="uploadCallback"
	    :fixed-number="fixednumber"
    />

    <gg-Dialog width="580px" :visible.sync="fileSizeShow" title="File is too large" class="draft-dialog mp-dialog">
      <div class="dialogContent">
        The maximum size of a file that can be uploaded is 25MB.
        <div class="delete" @click="fileSizeShow = false"><img class="add-file-icon" src="@/assets/images/icons/close.svg" /></div>
      </div>
      <span slot="footer" class="dialog-footer"></span>
    </gg-Dialog>

    <div class="moments-photo-inner" v-if="momentsPhotoSw">
      <div class="moments-photo-main">
        <div class="optbar" v-show="checkedList.length > 0">
          <el-button type="text" @click="closeOptbar"><i class="el-icon-close"/></el-button>
          <span class="count">Select photo</span>
          <div class="btns">
            <baseButton @click="selectDoneImg" type="primary">Done</baseButton>
          </div>
        </div>
        <div class="search-bar">
          <photos-search @checkChange="onSearchChange" @backnormal="backnormal" />
        </div>
        <div class="photos-albums-bar">
          <el-tabs v-model="activePhotosAlbums" @tab-click="handleClick">
            <el-tab-pane label="Photos" name="Photos">
              <div class="img-list-b" v-infinite-scroll="loadMoments" :style="{ height: h + 'px' }">
                <image-list :searchResults="searchResults" :singleSelect="true" :classId="classid" :list="imgList" :loading="loading" @checkChange="onCheckChange" ref="imageList"/>
              </div>
            </el-tab-pane>
            <el-tab-pane label="Albums" name="Albums">
              <div class="albums-list" :style="{ height: h + 'px' }">
                <albums-list :classId="classid" :loading="loading" :list="albumList" @checkChange="onCheckChange" />
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>

      <img class="close-btn" @click="closeMoments" src="@/assets/images/icons/arrow_back_grey.svg" />
    </div>

  </div>
</template>

<script>
import moment from 'moment';
import { Ajax } from "@/common";
import { mapState } from "vuex";
import imageList from './imageList'
import albumsList from './albumsList'
import photosSearch from './photosSearch'
export default {
  components: { imageList, photosSearch, albumsList },
  computed: {
    ...mapState({
      user: (state) => state.user
    }),
  },
  props:{
    // boxwh:{
    //   default: ()=>[230,208],
    //   type: Array
    // },
    fixednumber:{
      default: ()=>[1,1],
      type: Array
    },
    query: {
      default:()=>{},
      type: Object
    },
    width:{
      default: 100,
      type: Number
    },
    modelkey:{
      default: '',
      type: String
    },
    height:{
      default: 100,
      type: Number
    },
    value:{
      default: '',
      type: String
    }
  },
  data() {
    return {
      page: 0,
      pageNum: 1,
      searchQuery: null,
      fileObj: {},
      isImgCutShow: false,
      searchResults: false,
      albumList: [],
      optbarSw: false,
      activePhotosAlbums: 'Photos',
      momentsPhotoSw: false,
      imgList: [],
      checkedList: [],
      date: this.$route.query.time ? moment(parseInt(this.$route.query.time)) : moment(),
      loading: false,
      classid: '',
      file64: '',
      _file64: '',
      file: '',
      fileBlob: '',
      h: '',
      rootPath: process.env.VUE_APP_fileBasePath,
      fileSizeShow: false,
      showmanu: false
    };
  },
  mounted(){
    window.onresize = () => {
      this.getHeight()
    }
    this.getHeight()
  },
  watch:{
    value: function() {
      this.file = this.value
    },
    file: function() {
      this.$emit('getImg', this.file, this.modelkey)
    }
  },
  created() {
    this.classid = this.$route.query.classid || ''
    this.file = this.value
    if(this.file) {
      this.base64Callback(this.file)
    }
  },
  methods: {

    moment,

    closeMoments(){
      this.page = 0
      this.pageNum = 1
      this.searchQuery = null
      this.momentsPhotoSw = !this.momentsPhotoSw
    },

    loadMoments(){
      if((this.pageNum < this.pages) && !this.loading && this.searchQuery) {
        this.searchMoments(true)
      }
    },

    onSearchChange(val){
      this.pageNum = 1
      this.searchQuery = val
      this.searchMoments()
      this.searchResults = true
    },

    async searchMoments(s){
      this.loading = true
      this.pageNum = s ? this.pageNum + 1 : this.pageNum
      let query = this.searchQuery || {}
      query.current = this.pageNum
      query.size = 20
      query.classId = this.classid
      
      var res = await Ajax.post("/moments/media/getMediaListForJournal", query)
      this.loading = false;
      if(res.code=='0000'){
        if(this.pageNum === 1){
          this.imgList = res.data.records
        }else{
          this.imgList.push(...res.data.records)
        }
        this.pages = res.data.pages
      }
    },

    uploadFileChange(file, a){
      this.fileObj = file
      this.isImgCutShow = true;
    },

    async base64Callback(url) {
      try {
        const res = await this.getBase64(url)
        this.file64 = res
      } catch (err) {
        console.log(err)
      }
    },

    async _base64Callback(url) {
      try {
        const res = await this.getBase64(url)
        this._file64 = res
      } catch (err) {
        console.log(err)
      }
    },

    getBase64(url) {
      return new Promise((resolve, reject) => {
        var Img = new Image()
        var dataURL = ''
        Img.setAttribute('crossOrigin', 'Anonymous')
        Img.src = url + '?v=' + Math.random()
        Img.onload = function() {
          // 要先确保图片完整获取到，这是个异步事件
          var canvas = document.createElement('canvas') // 创建canvas元素
          var width = Img.width // 确保canvas的尺寸和图片一样
          var height = Img.height
          canvas.width = width
          canvas.height = height
          canvas.getContext('2d').drawImage(Img, 0, 0, width, height) // 将图片绘制到canvas中
          dataURL = canvas.toDataURL('image/jpeg') // 转换图片为dataURL
          resolve(dataURL)
        }
      })
    },

    backnormal() {
      this.imgListData()
    },

    dataURLtoBlob(dataurl) {
      var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);
      while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },

    getObjectURL(file) {
      var url = null;
      if (window.createObjectURL !== undefined) { // basic
          url = window.createObjectURL(file);
      } else if (window.URL !== undefined) { // mozilla(firefox)
          url = window.URL.createObjectURL(file);
      } else if (window.webkitURL !== undefined) { // webkit or chrome
          url = window.webkitURL.createObjectURL(file);
      }
      return url;
    },

    blobToFile(base64, fileName) {
      let arr = base64.split(','), 
      type = arr[0].match(/:(.*?);/)[1], 
      bstr = atob(arr[1]), 
      n = bstr.length, 
      u8arr = new Uint8Array(n);

      while(n--){
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], fileName, {type});
    },

    selectDoneImg() {
      this.momentsPhotoSw = false
      this.showmanu = false
      let file = this.checkedList[0]
      this.checkedList = []
      this.file = `${process.env.VUE_APP_fileBasePath}${file.path}`
      this._base64Callback(this.file)
      setTimeout(()=>{
        let base64 = this._file64,
        blob = this.dataURLtoBlob(base64),
        file = this.blobToFile(base64, moment().valueOf() + 'files.jpg'),
        fileObj = {}

        fileObj.url = this.getObjectURL(blob)
        fileObj.raw = file
        fileObj.name = file.name
        fileObj.uid = file.name.split('files.jpg')[0]
        fileObj.size = file.size
        fileObj.status = 'ready'

        this.fileObj = fileObj
        this.isImgCutShow = true;
      }, 800)
    },

    closeOptbar() {
      this.$refs.imageList.clearCheck()
    },

    handleClick(tab, event) {
      this.activePhotosAlbums = tab.name

      if(tab.name === 'Photos' && this.imgList.length <= 0) {
        this.imgListData()
      }
      if(tab.name === 'Albums' && this.albumList.length <= 0) {
        this.albumsListData()
      }
    },

    showMomentsPhoto() {
      this.showmanu = false
      this.imgListData()
    },

    async albumsListData(){
      this.loading = true;
      var res = await Ajax.get("/moments/albums/getAlbumListByClassId", {
        classId: this.classid,
        date: this.date.format('YYYY-MM')
      })
      
      this.loading = false;
      if(res.code=='0000'){
        this.albumList = res.data.map(o=>{
          return {
            id:o.id,
            classId:o.classId,
            name: o.name,
            src: o.logo || '',
            count: o.mediaCount
          }
        })
      }
    },
    
    async imgListData(){
      this.momentsPhotoSw = true
      this.loading = true;
      var res = await Ajax.post("/moments/media/getMediaListForJournal", {
        classId: this.classid,
        date: this.date.format('YYYY-MM'),
        size: 9999
      })
      this.loading = false;
      if(res.code=='0000'){
         this.imgList = res.data.records
      }
    },

    onCheckChange(val){
      this.checkedList = val
    },

    uploadImage() {
      this.isImgCutShow = true;
    },

    uploadCallback(formData, fileData) {
      let size = Math.floor(fileData.size / 1024)
      if(size > (25 * 1024)) {
        this.fileSizeShow = true
        return false
      }

      formData.append("multipartFile", fileData.raw);
      Ajax.post("/media/public/file/upload", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((res) => {
          if (res.code === "0000") {
            this.file = `${process.env.VUE_APP_fileBasePath}${res.data.filePath}`
            this.base64Callback(this.file)
            this.showmanu = false
            this.isImgCutShow = false;
          }
        })
        .catch(() => {
          this.isImgCutShow = false;
        });
    },

    showimgbth() {
      this.showmanu = !this.showmanu
    },

    getHeight(){
      const h = document.documentElement.clientHeight
      this.h = h - 190;
    },

  },
};
</script>
<style lang='less' scoped>
.ui-img-upload {
  position: relative;
  width: 200px;
  height: 100px;
  background: #F4F4F4;
  border: 2px solid #707070;
  box-sizing: initial;
  cursor: pointer;
  transition: ease .2s;

  img {
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -15px 0 0 -15px;
  }

  .img {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }

  &:hover {
    border-color: #1A73E8;
  }

  .create-dropdown-ui {
    position: absolute;
    right: -220px;
    top: 50%;
    margin-top: -40px;
    width: 202px;

    .create-dropdown-bg {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 101;
      overflow: hidden;
    }

    .create-dropdown-list {
      position: relative;
      z-index: 102;
      width: 202px;
      background: #fff;
      box-shadow: 0px 3px 3px #0000004D;
      border-radius: 4px;

      .create-dropdown-list-inner {
        padding: 8px 0;

        .create-class-list {
          padding: 0;

          li {
            cursor: pointer;
            text-align: center;
            line-height: 32px;
            font-size: 14px;
            font-family: Roboto;

            &.active,
            &:hover {
              background-color: #eee;
            }
          }
        }
      }
    }
  }

}

.ui-img-upload-wrapper {

  .el-button--primary {
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    border: 0;
    background: #fff;
    cursor: pointer;
    text-align: center;
    line-height: 32px;
    font-size: 14px;
    font-family: Roboto;
    color: #2e2e2e;
    transition: ease-out 0s;

    &:hover {
      background: #eee;
    }
  }
}

.moments-photo-inner {
  position: fixed;
  top: 64px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 998;
  background: #fff;
  overflow: hidden;

  .moments-photo-main {
    margin: auto auto auto 80px;
    background: #fff;
    
    .albums-list,
    .img-list-b {
      padding: 0 80px 82px 0;
      height: 500px;
      overflow-y: auto;
    }
  }

  .close-btn {
    position: absolute;
    left: 24px;
    top: 24px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
}

.optbar {
  z-index: 101;
  text-align: left;
} 
</style>