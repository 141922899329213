<template>
  <div ref="imageTofile" class="model-box newsletter-m" :class="{ 'savecover' : saveCoverSw }">
    <div class="title">
      <div v-if="saveCoverSw" class="title">
        <div class="ui-title">{{ modelData.title }}</div>
      </div>
      <input-edit 
        v-else
        class="ui-title"
        :placeholder="!saveCoverSw ? 'Click to add title' : ''"
        @toBlur="toBlur"
        :len="28"
        @getContent="getInput"
        :modelkey="'title'"
        :value="modelData.title"
      />
    </div>

    <div class="hr"></div>

    <div class="img-list clearfix">
      <div class="ui-img-box">
        <div class="edit edit-x">
          <div v-if="saveCoverSw" v-html="modelData.edit" style="word-break: break-all; overflow: hidden; height: 138px; color: #3E556A; font-size: 15px;"></div>
          <text-edit 
            v-else
            :placeholder="!saveCoverSw ? 'Click to add description' : ''"
            @getContent="getContent"
            @toBlur="toBlur"
            :modelkey="'edit'"
            :height="138"
            :color="'#3E556A'"
            :fontsize="15"
            :value="modelData.edit"
          />
        </div>
      </div>

      <div class="ui-img-box">
        <img-upload 
        class="ui-img"
        @getImg="getImg"
        :fixednumber="[1.5,1]"
        :modelkey="'img1'"
        :value="modelData.img1"
      />
      </div>
    </div>

    <div class="img-list hm clearfix" style="padding: 5px 15px;">
      <div class="ui-img-box">
        <img-upload 
        class="ui-img"
        :fixednumber="[1,1.5]"
        @getImg="getImg"
        :modelkey="'img2'"
        :value="modelData.img2"
      />
      </div>

      <div class="ui-img-box">
        <img-upload 
        class="ui-img"
        @getImg="getImg"
        :fixednumber="[1,1.5]"
        :modelkey="'img3'"
        :value="modelData.img3"
      />
      </div>

      <div class="ui-img-box">
        <img-upload 
        class="ui-img"
        :fixednumber="[1,1.5]"
        @getImg="getImg"
        :modelkey="'img4'"
        :value="modelData.img4"
      /></div>

      <div class="ui-img-box">
        <img-upload 
        class="ui-img"
        :fixednumber="[1,1.5]"
        @getImg="getImg"
        :modelkey="'img5'"
        :value="modelData.img5"
      /></div>
    </div>

     <div class="img-list clearfix">
      <div class="ui-img-box">
        <img-upload 
        class="ui-img"
        :fixednumber="[1.5,1]"
        @getImg="getImg"
        :modelkey="'img6'"
        :value="modelData.img6"
      />
      </div>

      <div class="ui-img-box">
        <img-upload 
        class="ui-img"
        @getImg="getImg"
        :fixednumber="[1.5,1]"
        :modelkey="'img7'"
        :value="modelData.img7"
      />
      </div>
    </div>

  </div>
</template>

<script>
import { Ajax } from "@/common";
import { mapState } from "vuex";
import moment from 'moment'
import html2canvas from 'html2canvas'
import imgUpload from '../../modules/model/ui/imgUpload'
import textEdit from '../../modules/model/ui/textEdit'
import inputEdit from '../../modules/model/ui/inputEdit'

export default {
  components: {
    textEdit,
    inputEdit,
    imgUpload
  },
  props:{
    saveCoverSw: {
      default: false,
      type: Boolean
    },
    data: {
      default: () => ({}),
      type: Object
    },
    index: {
      default: 0,
      type: Number
    }
  },
  data() {
    return {
      cover: '',
      htmlUrl: '',
      rootPath: process.env.VUE_APP_fileBasePath,
      modelData: {}
    };
  },
  mounted(){
    
  },
  watch:{
    saveCoverSw: function() {
      if(this.saveCoverSw && this.index === 0) {
        setTimeout(()=>{
          this.toImage()
        }, 300)
      }
    },
    data: function() {
      this.modelData = this.data
    },
    modelData: function() {
      this.$emit('postdata', this.modelData, this.index)
    }
  },
  created() {
    this.modelData = this.data
  },
  methods: {

    toBlur() {
      this.$emit('toBlur')
    },

    toImage() {
      // 第一个参数是需要生成截图的元素,第二个是自己需要配置的参数,宽高等
      html2canvas(this.$refs.imageTofile, {
        backgroundColor: null,
      }).then((canvas) => {
        let url = canvas.toDataURL("image/jpg");
        this.htmlUrl = url; // 把生成的base64位图片上传到服务器,生成在线图片地址
        if (this.htmlUrl) {
          // 渲染完成之后让图片显示，用v-show可以避免一些bug
          let blob = this.convertBase64UrlToBlob(this.htmlUrl)
          let file = this.blobToFile(this.htmlUrl, moment().valueOf() + 'files.jpg')
          const fd = new FormData();
          fd.append('multipartFile', file);
          // fd.append("multipartFile", file.raw);
          this.uploadFileChange(fd)
        }
      });
    }, 

    blobToFile(base64, fileName) {
      let arr = base64.split(','), 
      type = arr[0].match(/:(.*?);/)[1], 
      bstr = atob(arr[1]), 
      n = bstr.length, 
      u8arr = new Uint8Array(n);

      while(n--){
          u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], fileName, {type});
    },

    convertBase64UrlToBlob(urlData) {
      //去掉url的头，并转换为byte 
      var split = urlData.split(',');
      var bytes=window.atob(split[1]);        
      //处理异常,将ascii码小于0的转换为大于0  
      var ab = new ArrayBuffer(bytes.length);  
      var ia = new Uint8Array(ab);  
      for (var i = 0; i < bytes.length; i++) {  
        ia[i] = bytes.charCodeAt(i);  
      }  
      return new Blob( [ab] , {type : split[0]});  
    },

    uploadFileChange(fd) {
      if (fd) {
        Ajax.post(
          "/media/public/file/uploadExt", 
          fd, {
          headers: { "Content-Type": "multipart/form-data" }
        })
          .then((res) => {
            if (res.code === "0000") {
              this.cover = `${process.env.VUE_APP_fileBasePath}${res.data.filePath}`
              this.$emit('postCover', this.cover)
            }
            if (res.code === "9999") {
              this.handleSnack("Maximum upload size exceeded")
            }
          })
          .catch((err) => {
            console.log(err)
          });
      }
    },

    getImg(v, key) {
      let obj = this.modelData
      obj[key] = v
      this.modelData = null
      this.modelData = obj
    },

    getInput(v, key) {
      let obj = this.modelData
      obj[key] = v
      this.modelData = null
      this.modelData = obj
    },

    getContent(v, key) {
      let obj = this.modelData
      obj[key] = v
      this.modelData = null
      this.modelData = obj
    }
  }
};
</script>
<style lang='less'>
@import '~@/assets/css/journalModel.less';
</style>