<!-- class students list -->
<template>
  <div class="content has_breadcrumb">
    <base-breadcrumb
      :data="[
        {
          routeName: 'locations',
          name: user.schoolName,
        },
        {
          routeName: 'moments',
          name: 'Moments',
        },
        {
          name: classname,
        },
      ]"
      slot="breadcrumb"
    />
    <GgPage
      pageType="2"
      title="Moments"
      @forward="forward"
      @next="next"
    >
      <div slot="topAppBar" class="topAppBar">
        <!-- <month-switch @change="monthChange" :defaultVal="defSwitchVal"></month-switch> -->
        <search :classId="$route.params.id"></search>
      </div>
      
      
      <template v-slot:left>
        <div class="mar-l-b">
          <upload-btn :classId="$route.params.id"><gg-create-button label="Upload" /></upload-btn>
        </div>
        <class-side-menu activeIndex="momentClassAlbum" />
      </template>
      <GgPageHeader :title="classname" class="mar-b-0 album-page-header" :menuData="menuData" @menuCallback="handleMenuClick" />
       <div style="text-align: center" v-if="imgList.length==0&&!loading">
        <div
          style="
            display:inline-block;
            width: 213px;
            text-align: center;
            margin-top: 210px;
            margin-bottom: 20px;
            line-height: 200px;
          "
        >
          <img
            style="width: 100%"
            :src="require('@/assets/images/moments/empty_photo.png')"
          />
        </div>
        <p style="font-size: 22px;color:#5f6368;font-family: Roboto;">No album yet.</p>
      </div>
      <div class="album-list" v-loading="loading"  v-else>
        <el-row :gutter="20">
          <el-col :lg="4" :sm="6"  v-for="(item, index) in imgList" :key="index">
            <div class="item">
              <div class="img-box" :class="{'disabledOpt':!(hasPermission('Manage albums_Edit')||hasPermission('Manage albums_Full'))}"
                  >
                <div
                  class="cover"
                  :style="'background-image:url('+rootPath+item.src+')'"
                  @click.self="itemClick(item)"
                >
                </div>
                <gg-menu-bar v-if="hasPermission('Manage albums_Edit')||hasPermission('Manage albums_Full')">
                  <i class="iconmore_vert-24px iconfont" />
                  <template slot="menus">
                    <gg-menu-bar-item
                      @click="renameAlbum(item)"
                      v-if="hasPermission('Manage albums_Edit')"
                      >Rename album</gg-menu-bar-item
                    >
                    <gg-menu-bar-item
                      @click="setAbbumCover(item)"
                      v-if="hasPermission('Manage albums_Edit')"
                      >Set album cover</gg-menu-bar-item
                    >
                    <gg-menu-bar-item
                      @click="deleteAbbum(item)"
                      v-if="hasPermission('Manage albums_Full')"
                      >Delete album</gg-menu-bar-item
                    >
                  </template>
                </gg-menu-bar>
              </div>
              <b>{{item.name}}</b>
              <span v-if="item.count>0">{{item.count}} photos</span>
              <span v-else>No photo</span>
            </div>
          </el-col>
        </el-row>
        
      </div>
    </GgPage>
    <album-edit-form ref="albumEditForm" @ok="handleOk" />
    <Snackbar
      :visible.sync="snackbarData.visible"
      :content="snackbarData.content"
    />
  </div>
</template>

<script>
import { Ajax } from "@/common";
import moment from 'moment';
import { mapState } from "vuex";
import uploadBtn from './../../modules/uploadBtn'
import SvgIcon from "@/components/SvgIcon";
import classSideMenu from './../../modules/classSideMenu'
import monthSwitch from './../../modules/monthSwitch'
import search from './../../modules/search'
import imageList from './../../modules/imageList'
import albumEditForm from './modules/albumEditForm'
import { hasPermission } from './../../utils/utils.js';
import classMixin from './../../mixin/classMixin'
export default {
  components: {SvgIcon,classSideMenu,monthSwitch,search,imageList,uploadBtn,albumEditForm},
  mixins: [classMixin],
  data() {
    return {
      boolean: true,
      loading:false,
      classname:this.$store.state.moments.curClassName,
      defSwitchVal:[],
      date:this.$route.query.time?moment(parseInt(this.$route.query.time)):moment(),
      imgList: [
        
      ],
      
      snackbarData: {
        visible: false,
        content: "",
      },
      rootPath:process.env.VUE_APP_fileBasePath
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user
    }),
    menuData(){
      return hasPermission('Manage albums_Create')?[
        {
          label:'Create album',
          key:'create'
        }
      ]:[]
    }
  },
  created() {
    this.initMonthSwitchVal()
    this.fetchData()
  },
  methods: {
    hasPermission,
    handleOk(content) {
      this.snackbarData.content = content;
      this.snackbarData.visible = true;
      this.fetchData()
    },
    initMonthSwitchVal(){
      var _def = moment()
      if(this.$route.query.time&&this.$route.query.time.length==13){
        _def = moment(parseInt(this.$route.query.time))
      }
      this.date = _def
      this.defSwitchVal = _def
    },
    // monthChange(val){
    //   console.log(val)
    //   this.date = val[0]
    //   this.fetchData()
    // },
    async fetchData(){
      this.loading = true;
      var resClass = await Ajax.get("/usermanage/class/selectByIdV1", {
        id: this.$route.params.id,
      })
      var res = await Ajax.get("/moments/albums/getAlbumListByClassIdV1", {
        classId: this.$route.params.id,
        // date: this.date.format('YYYY-MM')
      })
      
      this.loading = false;
      if(resClass.code=='0000'&&res.code=='0000'){
        this.classname = resClass.data.className
        this.$store.commit("SET_CURCLASSNAME", this.classname);
        this.imgList = res.data.map(o=>{
          return {
            id:o.id,
            classId:o.classId,
            name: o.name,
            src: o.logo || '',
            count: o.mediaCount
          }
        })
      }
      
    },
    handleMenuClick(item) {
      console.log('121212',item)
      if(item.key=='create'){
        this.$refs.albumEditForm.show()
      }
    },
    itemClick(item){
      this.$router.push({name:'momentClassAlbumPhotos',params:{
        id:this.$route.params.id,
        aid:item.id
      },query:{time:this.$route.query.time+'' || ''}})
    },
    forward(val) {
      console.log(val);
    },
    next(val) {
      console.log(val);
    },
    renameAlbum(item){
       this.$refs.albumEditForm.show(item)
    },
    setAbbumCover(item){
      this.$router.push({name:'momentClassAlbumPhotos',params:{
        id:this.$route.params.id,
        aid:item.id
      },query:{time:this.$route.query.time+'' || '',action:'setCover'}})
    },
    deleteAbbum(item){
      this.$confirm('Once the album is deleted, it will continue to stay in photos.', 'Delete album?', {
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Delete',
        cancelButtonClass: "gg_cancel_button",
        confirmButtonClass: "gg_submit_button",
        customClass: "gg_confirm",
        callback: (action) => {
          if (action == "confirm") {
           this.doDelete(item)
          }
        },
      })
    },
    doDelete(item){
      Ajax.post("/moments/albums/deleteAlbumById?id="+item.id).then(res=>{
        if(res.code=='0000'){
          this.fetchData()
        }
      }).catch(()=>{
       
      })
    }
  },
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
/deep/ .el-dialog {
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/deep/ .el-dialog .el-dialog__body {
  flex: 1;
  overflow: auto;
}

/deep/ .ggPage-main{
  position: relative;
}
.ggPageTitle{
  /deep/.ggPageTitle-left .titleInfo .title{
    font-family:'opensans-semibold';
    color:#5f6368
  }
}
.content.has_breadcrumb .edoovo-breadcrumb {
    z-index: 10;
}
.album-page-header{
  /deep/ .menu_bar{
    height:40px;
    width: 40px;
    line-height: 25px;
    font-size: 20px;
    .iconfont{
      font-size:20px;
    }
  }
}
.album-list{
  margin-top:20px;
    width: 100%;
   padding-bottom:112px;
    .item{
      margin-bottom:20px;
      border-radius: 4px;
      cursor: pointer;
      font-size: 0;
      line-height: 0;
    }
    b{
      margin-top:5px;
      line-height: 20px;
      font-size: 14px;
      color:#202124;
      display: block;
      text-overflow: ellipsis;overflow: hidden;white-space: nowrap;
      font-family: Roboto;
      font-weight: 500;
    }
    span{
      margin-top:5px;
      line-height: 20px;
      font-size: 14px;
      color:#999;
      display: block;
      font-family: Roboto;
      font-weight: 400;
    }
    .img-box{
      width:100%;
      position: relative;
      border-radius: 8px;
      overflow: hidden;
      .cover{
        width: 100%;
        padding-bottom:100%;
        background-color:#7E868B;
        background-size:cover;
        background-position: center center;
        background-repeat: no-repeat;
        position: relative;
        &:before{
          content:' ';
          position: absolute;
          height:30px;
          width:100%;
          left:0;
          top:0;
          z-index: 2;
          opacity: 0;
          background: linear-gradient(to bottom, rgba(0,0,0,0.4), rgba(0,0,0,0));
        }
      }
     
      &.disabledOpt{
        .cover:before{
          display: none;
        }
      }
      .menu_bar{
        position: absolute;
        right:8px;
        top:8px;
        z-index: 3;
        overflow: visible;
        opacity: 0;
        /deep/ .menu_bar_button{
          line-height: 22px;
          border-radius: 100%;
          background: rgba(255,255,255,0.2);
          // &:hover{
            
          // }
        }
        /deep/ .drop_down_menus{
          position: absolute;
          top: 28px!important;
          right:0!important;
          div{
            white-space: nowrap!important;
          }
        }

        .iconfont{
          color:#fff;
        }
      }
      &:hover{
        .cover:before{
          opacity: 1;
        }
        .menu_bar{
          opacity: 1;
        }
      }
    }
    
  }
  .content.has_breadcrumb{
    /deep/ .ggPage-main-center-content{
      height:auto;
    }
  }
</style>