<!-- people -->
<template>
  <div class="content has_breadcrumb">
    <base-breadcrumb
      :data="[
        {
          routeName: 'locations',
          name: schoolName,
        },
        {
          name: 'People',
        },
      ]"
      slot="breadcrumb"
    />
    <GgPage pageType="2" title="People">
      <template v-slot:left>
        <gg-flex-menus
          :menus="menuDatas"
          @select="menuSelect"
          :default-active="activeIndex"
        />
      </template>
      <template v-if="dataType === 'schoolName'">
        <GgPageHeader :title="schoolName" />
        <border-less-table :data="personalInfoData" />
      </template>
      <edoovo-table
        v-if="dataType === 'teachers'"
        height="100%"
        :ref="teachersData.ref"
        :data="teachersData.tableData"
        rowKey="id"
        :title="teachersData.title"
        defaultValue="-"
        class="list-tableBox"
        v-loading="teachersData.isLoading"
        @sortChange="_onSortChangeTeachers"
        :sort="teachersData.sort"
      >
        <!-- ===== -->
        <!-- 空状态 -->
        <!-- ===== -->
        <div slot="empty" style="text-align: center">
          <div
            style="
              width: 213px;
              text-align: center;
              border-radius: 50%;
              margin-bottom: 12px;
              line-height: 200px;
            "
          >
            <img
              style="width: 100%"
              :src="require('@/assets/images/products/noData.svg')"
            />
          </div>
          <p style="font-size: 22px">No Teachers yet.</p>
        </div>
        <!-- ====== -->
        <!-- 筛选组件 -->
        <!-- ====== -->
        <div
          v-if="filterRenderDataTeachers.length"
          class="list-filterBox"
          slot="filter"
        >
          <base-filter
            ref="tablefilter"
            @filterCallback="_filterCallbackTeachers"
            :renderData="filterRenderDataTeachers"
            :multKeys="[]"
          />
        </div>
        <!-- =========== -->
        <!-- 每一项column -->
        <!-- =========== -->
        <edoovo-table-column
          size="L"
          label="Name"
          class="user_name"
          prop="name"
          sortName="firstName"
          :tooltip="true"
          :getTooltipText="(row) => `${row.firstName} ${row.lastName}`"
          v-slot="{ row }"
        >
          <div class="table-cell-box" @click="toStaffInfo(row)">
            <head-img
              class="table-cell-img"
              :src="row.photo ? `${row.photo}?` : ``"
              :firstName="row.firstName"
              :lastName="row.lastName"
              line-height="32"
            />
            <span class="table-cell-name"
              >{{ row.firstName }} {{ row.lastName }}</span
            >
          </div>
        </edoovo-table-column>
        <edoovo-table-column size="L" label="Email" prop="emailAddress" />
        <edoovo-table-column
          size="M"
          label="Phone number"
          prop="phone"
          v-slot="{ row }"
          :getTooltipText="(row) => `${row.countryCode} ${row.phoneNumber}`"
        >
          {{ row.countryCode }} {{ row.phoneNumber }}
        </edoovo-table-column>
        <edoovo-table-column
          size="M"
          label="Working title"
          prop="jobTitle"
          sortName="jobTitle"
        />
        <edoovo-table-column
          size="M"
          label="Type"
          prop="workType"
          sortName="workType"
        />
      </edoovo-table>
      <!-- Students -->
      <edoovo-table
        v-if="dataType === 'students'"
        height="100%"
        :ref="studentsData.ref"
        :data="studentsData.tableData"
        rowKey="id"
        :title="studentsData.title"
        defaultValue="-"
        class="list-tableBox"
        v-loading="studentsData.isLoading"
        @sortChange="_onSortChangeStudents"
        :sort="studentsData.sort"
      >
        <!-- ===== -->
        <!-- 空状态 -->
        <!-- ===== -->
        <div slot="empty" style="text-align: center">
          <div
            style="
              width: 213px;
              text-align: center;
              border-radius: 50%;
              margin-bottom: 12px;
              line-height: 200px;
            "
          >
            <img
              style="width: 100%"
              :src="require('@/assets/images/products/noData.svg')"
            />
          </div>
          <p style="font-size: 22px">No Students yet.</p>
        </div>
        <!-- ====== -->
        <!-- 筛选组件 -->
        <!-- ====== -->
        <div
          v-if="filterRenderDataStudents.length"
          class="list-filterBox"
          slot="filter"
        >
          <base-filter
            ref="tablefilter"
            @filterCallback="_filterCallbackStudents"
            :renderData="filterRenderDataStudents"
            :multKeys="[]"
          />
        </div>
        <!-- =========== -->
        <!-- 每一项column -->
        <!-- =========== -->
        <edoovo-table-column
          size="L"
          label="Name"
          class="user_name"
          prop="name"
          sortName="firstName"
          :tooltip="true"
          :getTooltipText="(row) => `${row.firstName}${row.lastName}`"
          v-slot="{ row }"
        >
          <div class="table-cell-box" @click="toStudentInfo(row)">
            <head-img
              class="table-cell-img"
              :src="row.photo ? `${row.photo}` : ``"
              :firstName="row.firstName"
              :lastName="row.lastName"
              line-height="32"
            />
            <span class="table-cell-name"
              >{{ row.firstName }} {{ row.lastName }}</span
            >
          </div>
        </edoovo-table-column>
        <edoovo-table-column
          size="S"
          label="Gender"
          prop="gender"
          sortName="gender"
        />
        <edoovo-table-column size="M" label="Age" prop="age" />
        <!-- <edoovo-table-column size="M" label="Contacts" prop="contacts" /> -->
		<edoovo-table-column size="M" label="Contacts" prop="contacts"  v-slot="{ row }"
			class="table-cell-box">
			<div @click="openContactsDialog(row)">
				{{ row.contacts||'-' }}
			</div>
		</edoovo-table-column>
      </edoovo-table>
    </GgPage>
	<!-- contacts number dialog -->
	<gg-dialog :visible.sync="contacts.visible" title="Contacts" :showClose="true">
		<div class="contactsDialog" v-loading="contacts.loading">
			<div class="title">Family</div>
			<edoovo-table height="300px" ref="multipleTable" :data="contacts.family.dataList" rowKey="id"
				defaultValue="-" class="list-tableBox">
				<!-- ===== -->
				<!-- 空状态 -->
				<!-- ===== -->
				<div slot="empty" style="text-align: center">
					<div style="
	        width: 213px;
	        text-align: center;
	        border-radius: 50%;
	        margin-bottom: 12px;
	        line-height: 200px;
	      ">
						<img style="width: 100%; height: 100px"
							:src="require('@/assets/images/products/noData.svg')" />
					</div>
					<p style="font-size: 22px">No staff yet.</p>
				</div>
				<!-- =========== -->
				<!-- 每一项column -->
				<!-- =========== -->
	
				<edoovo-table-column size="L" label="Name" prop="name"  :tooltip="true"
					v-slot="{ row }" :getTooltipText="(row) => `${row.firstName}`">
					<div>
						<head-img class="table-cell-img" :src="row.photo ? `${row.photo}?${+new Date()}` : ``"
							:firstName="row.firstName" :lastName="row.lastName" line-height="32" />
						<span class="table-cell-name">{{ row.firstName }} {{ row.lastName }}</span>
					</div>
				</edoovo-table-column>
				<edoovo-table-column size="M" label="Relationship" prop="releationship" 
					v-slot="{ row }" :getTooltipText="(row) => `${row.releationship}`">
					{{ row.releationship }}
				</edoovo-table-column>
				<edoovo-table-column size="L" label="Email" prop="email"  />
				<edoovo-table-column size="M" label="Phone" prop="phoneNumber"
                  :getTooltipText="(row) => `${row.countryCode?row.countryCode:''} ${row.phoneNumber?row.phoneNumber:'-'}`"
					v-slot="{ row }">
					{{ row.countryCode }} {{ row.phoneNumber || '-'}}
				</edoovo-table-column>
				<edoovo-table-column size="M" label="Status" prop="parentStatus" 
					v-slot="{ row }" :getTooltipText="(row) => `${row.parentStatus=='Connected'?'Connected':'Pending'}`">
					{{ row.parentStatus=='Connected'?'Connected':'Pending' }}
				</edoovo-table-column>
			</edoovo-table>
			<div class="title" v-if='contacts.pickup.dataList.length!=0'>Pickup</div>
			<edoovo-table max-height="300px" ref="multipleTable" :data="contacts.pickup.dataList" rowKey="id"
				defaultValue="-" class="list-tableBox" v-if='contacts.pickup.dataList.length!=0'>
				<!-- ===== -->
				<!-- 空状态 -->
				<!-- ===== -->
				<div slot="empty" style="text-align: center">
					<div style="
	        width: 213px;
	        text-align: center;
	        border-radius: 50%;
	        margin-bottom: 12px;
	        line-height: 100px;
	      ">
						<img style="width: 100%; height: 100px"
							:src="require('@/assets/images/products/noData.svg')" />
					</div>
					<p style="font-size: 22px">No staff yet.</p>
				</div>
				<!-- =========== -->
				<!-- 每一项column -->
				<!-- =========== -->
				<edoovo-table-column size="L" label="Name" prop="name"  :tooltip="true"
					v-slot="{ row }" :getTooltipText="(row) => `${row.firstName}`" @click="() => {}">
					<div>
						<head-img class="table-cell-img" :src="row.photo ? `${row.photo}?${+new Date()}` : ``"
							:firstName="row.firstName" :lastName="row.lastName" line-height="32" />
						<span class="table-cell-name">{{ row.firstName }} {{ row.lastName }}</span>
					</div>
				</edoovo-table-column>
				<edoovo-table-column size="M" label="Relationship" prop="releationship" 
					v-slot="{ row }" :getTooltipText="(row) => `${row.releationship}`">
					{{ row.releationship }}
				</edoovo-table-column>
	
				<edoovo-table-column size="M" label="Phone" prop="phoneNumber" 
					v-slot="{ row }">
					{{ row.countryCode }}{{ row.phoneNumber || "-"}}
				</edoovo-table-column>
			</edoovo-table>
		</div>
	</gg-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { Ajax } from "@/common";
import moment from 'moment'
export default {
  name: "people",
  components: {},
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  data() {
    return {
      query: {
        size: 10000,
        current: 1,
      },
      menuDatas: [
        {
          name: "",
          key: "schoolName",
          active: true,
          children: [
            {
              name: "Teachers",
              key: "teachers",
            },
            {
              name: "Students",
              key: "students",
            },
          ],
        },
      ],
      activeIndex: "schoolName",
      dataType: "schoolName",
      schoolName: "",
      personalInfoData: [
        { label: "Total staff", value: "", id: "totalStaff" },
        { label: "Total students", value: "", id: "totalStudent" },
      ],
      teachersData: {
        ref: "teachersTable",
        totalNum: 0,
        title: "Teachers",
        sort: { name: "", type: "" },
        isLoading: false,
        tableData: [],
      },
      filterRenderDataTeachers: [
        { filterKey: "firstName", type: "input", title: "Name" },
        { filterKey: "emailAddress", type: "input", title: "Email" },
        { filterKey: "phoneNumber", type: "input", title: "Phone number" },
        {
          filterKey: "jobTitle",
          type: "radio",
          title: "Working title",
          radioData: [],
        },
        {
          filterKey: "workType",
          type: "radio",
          title: "Type",
          radioData: [],
        },
      ],
      studentsData: {
        ref: "studentsTable",
        totalNum: 0,
        title: "Students",
        sort: { name: "", type: "" },
        isLoading: false,
        tableData: [],
      },
      queryStudents: {
        size: 10000,
        current: 1,
      },
      filterRenderDataStudents: [
        { filterKey: "firstName", type: "input", title: "Student name" },
        {
          filterKey: "gender",
          type: "radio",
          title: "Gender",
          radioData: [],
        },
        { filterKey: "dateOfBirth", type: "date", title: "Date of birth" },
        { filterKey: "className", type: "input", title: "Class" },
        { filterKey: "contact", type: "input", title: "Contacts" },
      ],
	  contacts: {
	  	visible: false,
	  	loading: false,
	  	family: {
	  		isShow: false,
	  		dataList: [{
	  			name: 123,
	  			email: "sssjjs"
	  		}],
	  	},
	  	pickup: {
	  		isShow: false,
	  		dataList: [{
	  			name: 123,
	  			relationShip: "sssjjs"
	  		}],
	  	},
	  },
    };
  },
  methods: {
	  formDate(date){
		 
	  	let a
	  	if(date){
	  	 a = moment.utc(date).local().format('DD/MM/yyyy')	
	  	}else{
	  		a='-'
	  	}
	  	return a
	  	
	  },
	  computedAge(value, index) {
	  				// this.$nextTick(() => {
	  				let dateOfBirth = value
	  				let currentYear = new Date().getFullYear();
	  				let currentMonth = new Date().getMonth() + 1;
	  				let birthYear = dateOfBirth && Number(dateOfBirth.split("/")[2]);
	  				let birthMonth = dateOfBirth && Number(dateOfBirth.split("/")[1]);
	  				let age = currentYear - birthYear;
	  				let monthTotal = age * 12 + currentMonth - birthMonth;
					
	  				if (dateOfBirth !== null) {
	  					this.studentsData.tableData[index].age = `${parseInt(
	    monthTotal / 12
	  )}y ${monthTotal % 12}m`;
	  					// this.tableData.find((item) => item.key == "age").value =
	  console.log(this.studentsData.tableData[index])
	  
	  				} else {
	  					this.studentsData.tableData[index].age = ''
	  					// this.tableData.find((item) => item.key == "age").value = "";
	  
	  				}
	  				// });
	  			},
	  _getPickupData(id) {
	  	this.contacts.loading = true;
	  	Ajax.get("/usermanage/pickup/selectByStudentId", {
	  			studentId: id,
	  		})
	  		.then((res) => {
	  			this.contacts.loading = false;
	  			this.contacts.pickup.dataList = res.data;
	  		})
	  		.catch(() => {
	  			this.contacts.loading = false;
	  		});
	  },
	  _getFamilyData(id) {
	  	this.contacts.loading = true;
	  	Ajax.get("/usermanage/parent/getListByStudentId", {
	  			studentId: id,
	  		})
	  		.then((res) => {
	  			this.contacts.loading = false;
	  			this.contacts.family.dataList = res.data;
	  		})
	  		.catch(() => {
	  			this.contacts.loading = false;
	  		});
	  },
	  openContactsDialog(row) {
	  	this.contacts.visible = true;
	  	this._getFamilyData(row.id);
	  	this._getPickupData(row.id);
	  },
    menuSelect(key) {
      this.activeIndex = key;
      this.dataType = key;
      switch (key) {
        case "schoolName":
          this.getData();
          break;
        case "teachers":
          this.getTeachers();
          break;
        case "students":
          this.getStudents();
          break;
      }
    },
    getData() {
      this.menuDatas.forEach((item) => {
        item.name = this.schoolName;
      });

      Ajax.get("/usermanage/location/selectTotalByLocationId", {
        locationId: this.user.locationId,
      }).then((res) => {
        if (res.code === "0000") {
          this.personalInfoData.forEach((item) => {
            item.value = res.data[item.id];
          });
        }
      });
    },
    getTeachers() {
      this.teachersData.isLoading = true;
      let params = Object.assign({}, this.query, {
        userId: this.user.userId,
        locationId: this.user.locationId,
      });
      Ajax.post("/usermanage/staff/pageList", params)
        .then((res) => {
          if (res.code === "0000") {
            this.teachersData.isLoading = false;
            let { records, total } = res.data;
            this.teachersData.tableData = records;
            this.teachersData.totalNum = total;
          }
        })
        .catch((err) => {
          this.teachersData.isLoading = false;
        });
    },
    _onSortChangeTeachers(sort) {
      this.teachersData.sort = sort;
      this.query.sortName = sort.name;
      this.query.sortType = sort.type;
      this.getTeachers();
    },
    _filterCallbackTeachers(data) {
      let obj = {};
      if (data.length == 0) {
        this.query = {
          size: 10000,
          current: 1,
        };
      }

      data.filter((i) => {
        obj[i.filterKey] = i.filterVal;
      });

      this.query = {
        size: 10000,
        current: 1,
      };
      this.query = Object.assign(obj, this.query);
      this.getTeachers();
    },
    toStaffInfo(row) {
      console.log(row);
      sessionStorage.setItem(
        "staffInfo",
        JSON.stringify({
          userId: row.userId,
          type: "0",
        })
      );

      this.$store.commit("SET_BREADCRUMDATA", [
        {
          routeName: "locations",
          name: this.schoolName,
        },
        {
          routeName: "people",
          name: "People",
        },
        {
          name: `${row.firstName} ${row.lastName}`,
        },
      ]);
      this.$router.push({ path: "staffInfo/personalInfo" ,query:{people:true}});
	
    },
    toStudentInfo(row){
      sessionStorage.setItem(
        "studentInfo",
        JSON.stringify({
          studentId: row.id,
          type: "0",
        })
      );
      this.$store.commit("SET_LOCATIONID", row.schoolId);
      this.$store.commit("SET_BREADCRUMDATA", [
        {
          routeName: "locations",
          name: this.schoolName,
        },
        {
          routeName: "people",
          name: "People",
        },
        {
          name: `${row.firstName} ${row.lastName}`,
        },
      ]);
      // this.$router.push({ path: "/studentsInfo/personalInfo",query:{people:true,name:this.schoolName} });
	    this.$router.push({ name: 'peopleStudentsPersonalInfo',query:{people:true,name:this.schoolName} })
    },
    getStudents() {
      this.studentsData.isLoading = true;
      let params = Object.assign({}, this.queryStudents, {
        userId: this.user.userId,
        locationId: this.user.locationId,
      });
      Ajax.post("/usermanage/student/stuPageList", params)
        .then((res) => {
          if (res.code === "0000") {
            this.studentsData.isLoading = false;
            let { records, total } = res.data;
            this.studentsData.tableData = records;
			this.studentsData.tableData.forEach((item, index) => {
				let a=this.formDate(item.dateOfBirth)
				console.log(a)
				if( a=='-'){
					a=null
				}
				
				this.computedAge(a, index)
			})
            this.studentsData.totalNum = total;
          }
        })
        .catch((err) => {
          this.studentsData.isLoading = false;
        });
    },
    _onSortChangeStudents(sort) {
      this.studentsData.sort = sort;
      this.queryStudents.sortName = sort.name;
      this.queryStudents.sortType = sort.type;
      this.getStudents();
    },
    _filterCallbackStudents(data) {
      let obj = {};
      if (data.length == 0) {
        this.queryStudents = {
          size: 10000,
          current: 1,
        };
      }

      data.filter((i) => {
        obj[i.filterKey] = i.filterVal;
      });

      this.queryStudents = {
        size: 10000,
        current: 1,
      };
      this.queryStudents = Object.assign(obj, this.queryStudents);
      this.getStudents();
    },
    getSettingList(dataLevel, dataType) {
      let params = {
        dataLevel: dataLevel,
        dataType: dataType,
        userId: this.user.userId,
      };

      Ajax.post("/usermanage/setting/getList", params)
        .then((res) => {
          let arr = [];
          res.data.forEach((item1) => {
            this.filterRenderDataTeachers.forEach((item) => {
              let obj = {};
              if (item1.dataType === item.filterKey) {
                obj = {
                  key: item1.value,
                  value: item1.value,
                };
                arr.push(obj);
              }
            });
            this.filterRenderDataStudents.forEach((item) => {
              let obj = {};
              if (item1.dataType === item.filterKey) {
                obj = {
                  key: item1.value,
                  value: item1.value,
                };
                arr.push(obj);
              }
            });
          });

          if (dataLevel === "staff") {
            this.filterRenderDataTeachers.find(
              (item) => item.filterKey === dataType
            ).radioData = arr;
          } else {
            this.filterRenderDataStudents.forEach((item) => {
              if (item.filterKey === dataType) {
                item.radioData = arr;
              }
            });
          }
        })
        .catch((err) => {
          console.log(err, 11);
        });
    },
  },
  created() {
    this.schoolName = this.user.schoolName;
    this.getData();
    this.getSettingList("staff", "workType");
    this.getSettingList("staff", "jobTitle");
    this.getSettingList("student", "gender");
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      let schoolName = vm.user.schoolName;
      vm.schoolName = schoolName;
    });
  },
};
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.form-dialog .content {
		min-height: 500px !important;
	}
.contactsDialog {
		.title {
			margin-bottom: 20px;
			font-family: "Roboto-medium";
			font-size: 14px;
			color: #202124;
		}

		.list-tableBox {
			margin-bottom: 20px;
			max-height: 200px;
		}
	}
</style>
