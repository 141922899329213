<!-- class students list -->
<template>
  <transition name="slide-fade">
    <div class="image-infobox" v-if="isshow">
      <el-button type="text" icon="el-icon-close" @click="close()"></el-button>
      <div class="title">{{type}}</div>
      <div v-loading="loading">
        <info v-if="type=='Info'" :item="item" :classId="classId" :info="info" @infoOk="onInfoOk"/>
        <share v-if="type=='Share'" :item="item" :classId="classId" :info="info" @ok="onShareOk"/>
      </div>
    </div>
  </transition>
</template>

<script>
import { Ajax } from "@/common";
import info from './info'
import share from './share'
export default {
  props: {
    classId:{
      default: '',
      type: String
    },
    item:{
      default: ()=>{},
      type: Object
    },
  },
  data() {
    return {
      loading:false,
      isshow:false,
      type:'Info',
      info:{}
    }
  },
  components:{
    info,
    share
  },
  computed: {
    isInTrash() {
      return this.$route.name == 'momentClassTrash'
    }
  },
  watch:{
    // item() { 
    //   this.fetchData()
    // },
  },
  mounted(){
    // this.fetchData()
  },
  methods: {
    show(type){
       this.isshow = false;
      if(type){
        this.type = type;
      }
      this.fetchData()
    },
    close(){
      this.isshow = false;
      this.$emit('onClose')
    },
    fetchData(needshow = true){
      this.info={}
      this.loading = true;
      Ajax.get("/moments/media/getMediaInfoById", {
        id:this.isInTrash?this.item.mediaId:this.item.id,
      })
		  .then((res) => {
		    this.loading = false;
        if (res.code == "0000") {
          this.info = res.data
          if(needshow){
            this.isshow = true;
          }
          
        }
		  })
		  .catch(() => {
		    this.loading = false;
		  });
     
    },
    onInfoOk(){
      Ajax.get("/moments/media/getMediaInfoById", {
        id:this.item.id,
      })
		  .then((res) => {
        if (res.code == "0000") {
          this.info = res.data
        }
		  })
    },
    onShareOk(status){
      this.fetchData()
      this.$emit('shareStatus',status)
    }
  },
  created() {
    
  },
};
</script>
<style lang='less'>
.image-infobox{
  .form-item{
    margin-top:32px;
    .tit{
      line-height: 22px;
      color:#5F6368;
      font-size:16px;
      font-weight: 500;
      margin-bottom:20px;
      font-family: Roboto;
    }
    .subtit{
      line-height: 18px;
      color:#B4B4B4;
      font-size:12px;
      font-family: Roboto;
      font-weight: 400;
      margin-bottom:8px;
    }
    .note{
      label{
        color:#B4B4B4;
        font-size:12px;
        font-family: Roboto;
        font-weight: 400;
      }
      textarea{
        font-size: 16px;
        font-family: Roboto;
        font-weight: 400;
        color: #202124;
        line-height: 21px;
      }
       .val{
        font-size: 16px;
        font-family: Roboto;
        font-weight: 400;
        color: #202124;
        line-height: 21px;
        word-break: break-all;
        margin-bottom: 15px;
        display: block;
      }
    }
    
    .album{
      position: relative;
      padding:0 0 0 56px;
      &:nth-of-type(n+1){
        margin-top:10px;
      }
      .face{
        position: absolute;
        left:0;
        top:0;
        width:36px;
        height:36px;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: #b4b4b4;
        border-radius: 4px;
      }
      b{
        display: block;
        line-height: 22px;
        height: 22px;
        color: #202124;
        font-family: Roboto;
        font-weight: 400;
        font-size:16px;
        font-weight: normal;
      }
      span{
        display: block;
        line-height: 16px;
        font-family: Roboto;
        font-weight: 400;
        height: 16px;
        color:#98999A;
        font-size:12px;
      }
    }
    .detail{
      .item{
        line-height: 24px;
        margin-bottom: 20px;
        font-size: 0;
      }
      .svg-icon{
        margin-right: 20px;
      }
      span{
        color: #202124;
        font-size: 16px;
        font-family: Roboto;
        font-weight: 400;
        vertical-align: middle;
      }
      .avatar{
        width:24px;
        height:24px;
        border-radius: 100%;
        margin-right:8px;
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        vertical-align: middle;
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
  .btns{
    text-align: right;
    .el-button{
      margin-left:30px;
      &.reject{
        color:#5F6368;
      }
    }
  }
}
</style>
<style lang='less' scoped>
.slide-fade-enter-active,.slide-fade-leave-active {
  transition: all .4s ease;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for below version 2.1.8 */ {
  transform: translateX(440px);
  opacity: 0;
}
.image-infobox{
  width:440px;
  height:100%;
  background:#fff;
  box-sizing: border-box;
  padding:20px;
  position: relative;
    overflow-y: auto;
  .el-button{
    font-size: 24px;
    color:#333;
    padding:0;
    position: absolute;
    left:20px;
    top:20px;
    margin:0;
    border: 0 none;
  }
  .title{
    padding-left:44px;
    font-size:22px;
    line-height: 24px;
    font-family: 'opensans-semibold';
    margin-bottom: 52px;
color: #202124;
  }
  
}
</style>