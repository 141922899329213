<template>
  <div class="content has_breadcrumb">
    <base-breadcrumb
      :data="[
        {
          routeName: 'locations',
          name: user.schoolName,
        }, {
          routeName: 'announcement',
          name: activeIndex,
        }, {
          name: htmlDecode(announcementdData.title),
        }
      ]"
      slot="breadcrumb"
    />
    <GgPage
      topType="location"
      pageType="2"
      title="Announcements"
    >
      <div slot="topAppBar"><search></search></div>
      <template v-slot:left>
        <div class="mar-l-b" v-if="hasPermission('Announcements_Create') && activeIndex != 'Create'">
          <gg-create-button @click="openDialogAdd(null)" label="Create announcement" />
        </div>
        <gg-flex-menus
          :menus="menuDatas"
          @select="onMenuSelect"
          :default-active="activeIndex"
        />
      </template>

      <div class="announcement-inner collapse-list">
        <div class="title">
          <div class="tit">{{ htmlDecode(announcementdData.title) }}</div>
          <div class="des">
            <div class="avatar" v-if="announcementdData.publishUserPhoto"><img :src="announcementdData.publishUserPhoto"></div>
            <div class="no" v-else>
              {{ announcementdData.publishUserFirstName ? announcementdData.publishUserFirstName.substring(0, 1).toUpperCase() : '-' }}{{ announcementdData.publishUserLastName ? announcementdData.publishUserLastName.substring(0, 1).toUpperCase() : '-' }}
            </div>
            <div class="name">{{ announcementdData.publishUserFirstName || '' + ' ' + announcementdData.publishUserLastName || '' }}</div>
            <div class="tag">{{ announcementdData.roleName }}</div>
            <div class="date">saved on {{ momentTime(announcementdData.modifytime) }}</div>
          </div>
          <div class="draft-col" v-if="menuData.length > 0">
            <gg-menu-bar>
              <i class="iconmore_vert-24px iconfont" />
              <template slot="menus" v-if="announcementdData.questionContent.instruction || announcementdData.questionContent.dueDate || announcementdData.questionContent.options.length > 0">
                <gg-menu-bar-item
                  v-for="(menuitem, key) in menuData"
                  @click="menuClick(menuitem, announcementdData)"
                  :disabled="menuitem === 'Post' ? (!announcementdData.title || announcementdData.title.length > 255 || !announcementdData.body || !questionOptionsSw() || !announcementdData.classIds || announcementdData.questionContent.options.length < 2 || zzStr(htmlDecode(announcementdData.body)).length > 5000) : false"
                  :key="key"
                  >
                  <span>{{ typeof menuitem === 'object' ? menuitem.label : menuitem }}</span>
                  <hr v-if="menuitem === 'Post'" class="hr" />
                </gg-menu-bar-item>
              </template>
              
              <template slot="menus" v-else>
                <gg-menu-bar-item
                  v-for="(menuitem, key) in menuData"
                  @click="menuClick(menuitem, announcementdData)"
                  :disabled="menuitem === 'Post' ? (!announcementdData.title || announcementdData.title.length > 255 || !announcementdData.body || !announcementdData.classIds || zzStr(htmlDecode(announcementdData.body)).length > 5000) : false"
                  :key="key"
                  >
                  <span>{{ typeof menuitem === 'object' ? menuitem.label : menuitem }}</span>
                  <hr v-if="menuitem === 'Post'" class="hr" />
                </gg-menu-bar-item>
              </template>
            </gg-menu-bar>
          </div>
        </div>
        <div class="announcement-inner">
          <div class="draft-right">
            <div class="for-box">
              <div class="for-name">For</div>
              <div class="for-dropdown" style="cursor: default;">
                {{ announcementdData.sumClass }} class
              </div>
              <div class="for-select-list">
                <ul class="class-list">
                  <li v-for="(item, index) in classList" :key="index" v-show="item.select">
                    <div class="no" :style="'background-color:' + item.themeColor">{{ item.ai }}</div>
                    <div class="name">{{ item.className }}</div>
                  </li>
                </ul>
              </div>
            </div>

          </div>

          <div class="draft-con">
            <div class="edit-box p-box">

              <div class="edit-name">
                Details
              </div>

              <div class="edit-editing" v-html="announcementdData.body"></div>

              <div class="file-inner">

                <div class="file-list-inner">
                  <div class="file-box" @click="viewfile(item)" v-for="(item, index) in announcementdData.addFile" :key="index">
                    <div class="file-head">
                      <pdf
                          style="width: 103px; height: 72px; text-align: center;" 
                          class="pdf-preview" 
                          v-if="returnFileFormat(item.url) === 'pdf'" 
                          :id="item.id" 
                          :page="1" 
                          :src="item.url" />
                      <el-image
                        v-else
                        style="width: 103px; height: 72px;"
                        :src="item.url"
                        fit="cover">
                      </el-image>
                    </div>
                    <div class="file-body">
                      <div class="file-name">File name: {{ item.name }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="response-inner p-box" v-if="announcementdData.questionContent.instruction || announcementdData.questionContent.dueDate || announcementdData.questionContent.options.length > 0">
              <div class="response-col">
                <div class="response-name">
                  Response
                  <span class="date" v-if="announcementdData.questionContent.dueDate">Due on {{ responsesMomentTime(announcementdData.questionContent.dueDate) }}</span>
                </div>

                <div class="response-option-inner">
                  <div class="response-tit">{{ htmlDecode(announcementdData.questionContent.instruction) }}</div>
                  <div class="response-option">
                    <md-radio 
                    v-for="(item, index) in announcementdData.questionContent.options" 
                    :key="index" 
                    :disabled="true"
                    :label="item.label"
                    v-model="responseRadio" 
                    class="md-primary" 
                    :value="index">{{ item.label }}</md-radio>
                  </div>
                </div>
                
              </div>
            </div>

          </div>
          
        </div>
      </div>

    </GgPage>
    <Snackbar
      :visible.sync="snackbarData.visible"
      :content="snackbarData.content"
    />

    <gg-Dialog width="580px" :visible.sync="isDialogShow" title="Post announcement?" class="draft-dialog mp-dialog">
      <div class="dialogContent">Once the announcement is posted, all the parents of the selected classes will be able to view it.</div>
      <span slot="footer" class="dialog-footer">
        <gg-cancel-button @click="isDialogShow = false">Cancel</gg-cancel-button>
        <gg-submit-button @click="updateAnnouncementStatus">Post</gg-submit-button>
      </span>
    </gg-Dialog>

    <gg-Dialog width="580px" :visible.sync="isDelectShow" title="Delete announcement?" class="draft-dialog mp-dialog">
      <div class="dialogContent">Once deleted, you will not be able to recover the data.</div>
      <span slot="footer" class="dialog-footer">
        <gg-cancel-button @click="isDelectShow = false">Cancel</gg-cancel-button>
        <gg-submit-button @click="removeAnnouncement">Delete</gg-submit-button>
      </span>
    </gg-Dialog>

    <Snackbar :visible.sync="loadingShow" :content="loadingText" />
  </div>
</template>

<script>
import pdf from 'vue-pdf'
import search from '../modules/search'
import moment from 'moment'
import { Ajax } from "@/common";
import { mapState } from "vuex";
import { hasPermission } from './../utils/utils.js';
export default {
  components: {
    pdf,
    search
  },
  computed: {
    ...mapState({
      breadcrumb: (state) => state.breadcrumb,
      user: (state) => state.user
    }),
  },
  data() {
    return {
      saveobj: {},
      rootPath: process.env.VUE_APP_fileBasePath,
      isDelectShow: false,
      isDialogShow: false,
      status: {
        'DRAFT': 0,
        'POSTED': 1,
        'ARCHIVE': 2
      },
      radioDisabled: false,
      loadingText: '',
      loadingShow: false,
      announcementdData: {},
      id: '',
      responseRadio: '',
      menuData: [],
      activeIndex: 'Draft',
      fileProgress: true,
      amount: 50,
      selectedDate: null,
      loading: false,
      module: {
        title: "",
        moduleType: "",
        moduleName: "",
      },
      disabledAddoption: true,
      tableData: [],
      // isAllowed: true,
      oldVal: "",
      snackbar: { isShow: false, content: "" },
      responseBoxShow: true,
      content: '',
      classselectList: [],
      classList: [],
      menuDatas: [
        {
          name: "Draft",
          key: "Draft",
          active: true
        }, {
          name: "Posted",
          key: "Posted",
          active: true
        }, {
          name: "Archived",
          key: "Archived",
          active: true
        }
      ],
      snackbarData: {
        visible: false,
        content: "",
      }
    };
  },
  created() {
    this.getClass()

    if(hasPermission("Announcements_Full")) {
      this.menuData = ['Post', 'Edit', 'Delete']
    }
    else if(hasPermission("Announcements_Edit")) {
      this.menuData = ['Edit']
    }

    if(this.$route.params.id) {
      this.id = this.$route.params.id || ''
      this.announcementdData = JSON.parse(sessionStorage.getItem("announcementdData"))

      const options = this.announcementdData.questionContent.options
      for(let i=0;i<options.length;i++) {
        if(options[i].value === '1'){
          this.responseRadio = options[i].label
          break
        }
      }
    }
  },
  mounted() {
  },
  methods: {

    hasPermission,

    questionOptionsSw() {
      let optionsSw = true,
      optionsInstructionSw = true,
      questionContent = this.announcementdData.questionContent

      for (let i=0;i<questionContent.options.length;i++) {
        if(questionContent.options[i].label.length > 100) {
          optionsSw = false
          break
        }
      }
      if(questionContent.instruction.length > 255) {
        optionsInstructionSw = false
      }
      return optionsSw && optionsInstructionSw
    },

    responsesMomentTime(date) {
      return moment.utc(date).local().format("DD/MM/YYYY");
    },

    htmlDecode (text){
      var temp = document.createElement("div");
      temp.innerHTML = text;
      var output = temp.innerText || temp.textContent;
      temp = null;
      return output;
    },

    zzStr(str) {
      return str.replace(/(<([^>]+)>)/ig,"").replace(/[\r\n]/g,"")
    },

    returnFileFormat(url) {
      let reg = /\.([0-9a-z]+)(?:[\?#]|$)/i
      return url.match(reg)[1]
    },

    viewfile(file) {
      const needPrint = 1;
      let reg = /\.([0-9a-z]+)(?:[\?#]|$)/i;
      let fileFormat = file.name.match(reg)[1];

      if (fileFormat === "pdf") {
        let routeData = this.$router.resolve({ path: '/testFileView', query: { url: file.url, name: file.name, needPrint: needPrint } });
        window.open(routeData.href, '_blank');
      } else {
        let routeData = this.$router.resolve({ path: '/testFileView', query: { imgUrl: file.url, name: file.name, needPrint: needPrint } });
        window.open(routeData.href, '_blank');
      }
    },

    menuClick(e, item) {
      if(e === 'Post') {
        this.isDialogShow = true
        this.saveobj = {
          title: item.title,
          classid: item.classIds
        }
      }
      if(e === 'Delete') {
        this.isDelectShow = true
      }
      if(e === 'Edit') {
        localStorage.setItem("announcementdData", JSON.stringify(this.announcementdData))
        this.$router.push({ name: 'announcementCreate', query: { editid: item.id }})
      }
    },

    removeAnnouncement() {
      let data = {}
      data.id = this.id
      this.isDelectShow = false
      
      Ajax.post("/announcement/announcements/remove/" + this.id, data)
        .then((res) => {
          if(res.code === '0000'){
            this.loadingText = 'Draft has been deleted'
            this.loadingShow = true
            setTimeout(()=> {
              this.$router.push({ name: 'announcement'})
            }, 1200)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    updateAnnouncementStatus() {
      let data = {}
      data.id = this.id
      data.status = 'post'
      data.publishUser = this.user.userId
      data.title = this.saveobj.title
      data.classId = this.saveobj.classid
      this.isDialogShow = false
      
      Ajax.post("/announcement/announcements/updateStatusByIdV1", data)
        .then((res) => {
          if(res.code === '0000'){
            this.loadingText = 'Announcement has been posted'
            this.loadingShow = true

            setTimeout(()=> {
              this.$router.push({ name: 'announcementPosted'})
            }, 1200)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    momentTime(date) {
      return moment.utc(date).local().format("DD/MM/YYYY, hh:mm A");
    },

    getClassInfo() {
      let id = this.id, data = {}
      data.userId = id
      Ajax.get("/announcement/announcements/getRecipientsByIdV1/" + id, data)
        .then((res) => {
          if(res.code === '0000'){
            this.classselectList = res.data
            for(let i=0;i<this.classselectList.length;i++) {
              this.classselectList[i].ai = this.classselectList[i].className ? this.classselectList[i].className.substring(0, 2).toUpperCase() : '-'
            }

            for(let i=0;i<res.data.length;i++) {
              for(let j=0;j<this.classList.length;j++) {
                if(res.data[i].classId === this.classList[j].id){
                  this.classList[j].select = true
                  break
                }
              }
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getClass() {
      Ajax.get(
        "/usermanage/class/listByClassV1?staffId=" + this.user.userId + '&locationId=' + this.user.locationId)
        .then((res) => {
          let tempdata = [], temparr = {}

          for(let item of res.data) {
            for(let classid of this.announcementdData.classIds) {
              if(item.id === classid) {
                temparr = item
                temparr.select = true
                temparr.ai = 
                  item.className.indexOf(' ') > -1 ? 
                  (item.className.split(' ')[0].substring(0, 1).toUpperCase() + item.className.split(' ')[1].substring(0, 1)).toUpperCase() : 
                  item.className.substring(0, 1).toUpperCase();
                tempdata.push(temparr)
              }
            }
          }
      
          this.classList = tempdata
        });
    },

    // getClass() {
    //   let formData = new FormData();
    //   formData.append("staffId", this.user.userId);
    //   formData.append("locationId", this.user.locationId);

    //   Ajax.post(
    //     "/usermanage/class/selectByStaffId", 
    //     formData, {
    //       headers: { "Content-Type": "multipart/form-data" }
    //     })
    //     .then((res) => {
    //       let tempdata = [], temparr, classData = [], key = '', idArr = []
    //       for(let item in res.data) {
    //         key = item
    //         for(let item_ of res.data[key]) {
    //           if(!idArr.includes(item_.classId)) {
    //             idArr.push(item_.classId)
    //             classData.push(item_)
    //           }
    //         }
    //       }

    //       for(let item of classData) {
    //         for(let classid of this.announcementdData.classIds) {
    //           if(item.classId === classid) {
    //             temparr = item
    //             temparr.select = true
    //             temparr.ai = 
    //               item.className.indexOf(' ') > -1 ? 
    //               (item.className.split(' ')[0].substring(0, 1).toUpperCase() + item.className.split(' ')[1].substring(0, 1)).toUpperCase() : 
    //               item.className.substring(0, 1).toUpperCase();
    //             tempdata.push(temparr)
    //           }
    //         }
    //       }

    //       this.classList = tempdata
    //     });
    // },

    toStudentListPage(){},

    openDialogAdd(){
      this.$router.push({ name: 'announcementCreate'})
    },

    onMenuSelect(key, item){
      this.activeIndex = key;

      if(key === 'Draft') {
        this.$router.push({ name: 'announcement'})
      } else {
        this.$router.push({ name: 'announcement' + key})
      }
      // this.query.status = key;
      // this.usersData.tableTitle = `${item.name} Staff`;
      // this.getData();
    }
  }
};
</script>
<style lang='less' scoped>
.collapse-list {
  .title {
    position: relative;
    padding-left: 8px;
    padding-bottom: 26px;
    border-bottom: 1px solid #e3e3e3;
    color: #202124;
    font-size: 22px;
    font-weight: 600;
    font-family: OpenSans-Bold;

    .tit {
      margin-bottom: 5px;
      line-height: 27px;
      font-size: 20px;
      font-family: Open Sans;
      font-weight: 600;
      line-height: 27px;
      padding-right: 70px;
      word-wrap: break-word;
    }

    .des {
      .no {
        display: inline-block;
        margin-right: 8px;
        width: 24px;
        height: 24px;
        background: #b4b4b4;
        border-radius: 50%;
        line-height: 24px;
        text-align: center;
        color: #fff;
        font-size: 12px;
        font-family: Open Sans;
        font-weight: 600;
      }

      .avatar {
        margin-right: 8px;
        display: inline-block;
        width: 24px;
        height: 24px;
        vertical-align: middle;

        img {
          display: block;
          width: 24px;
          height: 24px;
          border-radius: 50%;
        }
      }

      .name {
        margin-right: 8px;
        display: inline-block;
        font-size: 12px;
        font-family: Roboto;
        font-weight: 400;
        line-height: 19px;
        color: #202124;
        vertical-align: middle;
      }
      
      .tag {
        margin-right: 8px;
        display: inline-block;
        padding: 4px 9px;
        height: 20px;
        font-family: Roboto;
        line-height: 12px;
        font-weight: 400;
        background: #E0E0E0;
        border-radius: 10px;
        font-size: 12px;
        vertical-align: middle;
      }

      .date {
        margin-right: 8px;
        display: inline-block;
        font-size: 12px;
        font-family: Roboto;
        font-weight: 400;
        line-height: 16px;
        color: #B4B4B4;
      }
    }

    .draft-col {
      position: absolute;
      right: 20px;
      top: 0;
    }
  }
}

.announcement-inner {
  font-family: 'Roboto';
  padding-top: 20px;

  .draft-con {
    background: #fff;
    overflow: hidden;
    
    .edit-box {
      border: 1px solid #E0E0E0;
      border-radius: 13px;

      .edit-name {
        padding-left: 20px;
        line-height: 68px;
        border-bottom: 1px solid #E0E0E0;
        color: #5F6368;
        font-size: 20px;
        font-family: 'opensans-semibold';
      }

      .edit-tit {
        padding: 0 20px;

        .md-field.md-theme-default:after {
          border-bottom: 1px solid #E0E0E0;
        }
      }

      .edit-editing {
        padding: 20px;
        font-size: 14px;
        font-family: Roboto;
        font-weight: 500;
        line-height: 19px;
        color: #202124;
        word-break: break-all;
      }

      .file-inner {
        padding: 0 20px 20px 20px;

        .add-file-btn {
          width: 104px;
          height: 32px;
          line-height: 32px;
          background-color: #fff;
          border: 1px solid #E0E0E0;
          border-radius: 4px;
          cursor: pointer;
          font-size: 13px;
          color: #5F6368;

          .add-file-icon {
            margin: 0 5px 0 10px;
            display: inline-block;
            width: 16px;
            height: 16px;
            vertical-align: middle;
          }

          &:hover {
            background-color: #e8f0fe;
          }
        }

        .file-list-inner {

          .file-box {
            margin-top: 10px;
            position: relative;
            height: 72px;
            line-height: 72px;
            background: #fff;
            border: 1px solid #E0E0E0;
            border-radius: 4px;
            overflow: hidden;
            cursor: pointer;

            .file-head {
              float: left;
              width: 103px;
              height: 72px;

              img {
                display: block;
                width: 103px;
                height: 72px;
              }
            }

            .file-body {
              padding: 0 20px;
              font-size: 12px;
              color: #202124;
              overflow: hidden;

              .delete {
                position: absolute;
                right: 20px;
                top: 22px;
                width: 24px;
                height: 24px;
                cursor: pointer;

                img {
                  display: block;
                  width: 24px;
                  height: 24px;
                }

                &:hover {
                  opacity: .7;
                }
              }
            }
          }

        }
      }
    }

    .response-inner {
      margin-bottom: 20px;

      .response-option-inner {
        padding: 20px;

        .response-tit {
          margin-bottom: 20px;
          font-size: 14px;
          font-family: Roboto;
          font-weight: 400;
          line-height: 19px;
          color: #202124;
        }

        .response-option {
          line-height: 19px;
        }
      }

      .add-response-btn {
        margin: 20px 0;
        color: #1A73E8;
        font-size: 13px;
        cursor: pointer;

        &:hover {
          opacity: .7;
        }
      }

      .response-col {
        margin-top: 20px;
        border: 1px solid #E0E0E0;
        border-radius: 13px;

        .response-name {
          position: relative;
          padding-left: 20px;
          line-height: 68px;
          border-bottom: 1px solid #E0E0E0;
          color: #5F6368;
          font-size: 20px;
          font-family: 'Open Sans';

          .date {
            margin-left: 10px;
            font-size: 12px;
            font-family: Roboto;
            font-weight: 400;
            line-height: 16px;
            color: #B4B4B4;
          }

          .delete {
            position: absolute;
            right: 20px;
            top: 22px;
            width: 24px;
            height: 24px;
            cursor: pointer;

            img {
              display: block;
              width: 24px;
              height: 24px;
            }

            &:hover {
              opacity: .7;
            }
          }
        }

        .response-info {

          .response-date {
            float: right;
            position: relative;
            margin: 0 20px 0 80px;
            width: 220px;

            .calendar-today {
              position: absolute;
              right: 0;
              top: 21px;
              width: 24px;
              height: 24px;
            }
          }

          .response-tit {
            padding: 0 0 0 20px;
            overflow: hidden;

            .md-field.md-theme-default:after {
              border-bottom: 1px solid #E0E0E0;
            }
          }
        }

      }
      
    }
  }

  .draft-right {
    float: right;
    padding-left: 20px;
    width: 364px;
    background: #fff;

    .cancel {
      position: fixed;
      right: 80px;
      bottom: 120px;

      .tit {
        float: left;
        margin-right: 20px;
        font-size: 13px;
        color: #5F6368;
        line-height: 33px;
      }

      .con {
        float: left;

        .el-button {
          width: 92px;
          padding: 0 32px 0 0;
          text-align: center;
          line-height: 35px;

          .el-tooltip {
            display: block;
            border-right: 1px solid #DCDFE6;
            font-weight: normal;
            color: #1a73e8;
          }

          .el-tooltip.disabled {
            color: #b4b4b4;
            font-weight: bold;
          }

          .iconfont {
            position: absolute;
            right: 10px;
            top: 0;
          }
          
          * {
            font-weight: bold;
            font-size: 13px !important;
          }
        }
      }
    }

    .for-box {
      padding: 0 2px 20px 0;
      width: 342px;
      border: 1px solid #E0E0E0;
      border-radius: 13px;

      .for-name {
        position: relative;
        padding-left: 20px;
        line-height: 68px;
        color: #5F6368;
        font-size: 20px;
        font-family: 'opensans-semibold';
      }

      .for-dropdown {
        position: relative;
        margin: auto 20px;
        padding: 0 20px;
        height: 32px;
        line-height: 32px;
        border: 1px solid #E0E0E0;
        border-radius: 4px;
        font-size: 14px;
        color: #5F6368;
        cursor: pointer;

        &:hover {
          .dropdown-list {
            display: block;
          }
        }

        .arrow-drop {
          position: absolute;
          right: 4px;
          top: 4px;
          width: 20px;
          height: 20px;
        }

        .dropdown-list {
          display: none;
          position: absolute;
          left: 0;
          top: 32px;
          padding: 8px 0;
          width: 300px;
          max-height: 156px;
          background: #fff;
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
          border-radius: 4px;
          overflow-y: auto;

          .select-all {
            padding-left: 16px;
            line-height: 28px;
            font-size: 14px;
            color: #202124;

            .el-checkbox {
              width: 100%;
              color: #202124;
            }

            &:hover {
              background-color: #eee;
            }
          }

          .class-list {
            padding: 0;

            li {

              .el-checkbox {
                width: 100%;
              }

              &:hover {
                background-color: #eee;
              }
            }
          }
        }
      }

      .class-list {
        padding: 7px 0 0 0;

        li {
          padding: 0 0 0 16px;
          line-height: 28px;
          font-size: 14px;
          color: #202124;

          .no {
            display: inline-block;
            margin-right: 8px;
            width: 20px;
            height: 20px;
            background: #7e7e7e;
            border-radius: 50%;
            line-height: 20px;
            text-align: center;
            color: #fff;
            font-size: 11px;
          }

          .name {
            display: inline-block;
            font-size: 14px;
            color: #202124;
          }
        }
      }

      .for-select-list {
        padding: 0 20px 0 20px;
        width: 317px;
        max-height: 150px;
        overflow-y: auto;

        &::-webkit-scrollbar {
          width: 4px;
          border-radius: 4px;
        }

        &::-webkit-scrollbar-track {
          background-color: #fff;
        }
      }
    }
    
  }
}

// 选项
.el-table {
  /deep/tbody tr:hover {
    .isDisplay {
      display: inherit !important;
    }
  }
}
/deep/.el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #fff;
}
/deep/.section-main {
  margin: 0;
  max-height: calc(100vh - 112px);
  overflow-y: auto;
}
/deep/.el-table td {
  padding: 3px 0;
}
.list-table {
  .not-allowed {
    cursor: not-allowed !important;
    color: #cbcbcb !important;
  }
  .iconfont {
    font-size: 24px;
    line-height: 24px;
    margin-left: 16px;
    cursor: pointer;
  }
  .rowName {
    position: relative;
    padding-left: 40px;
    font-family: Roboto;
    font-size: 14px;
    color: #202124;

    .md-input {
      font-size: 14px;
    }

    .my-handle {
      position: absolute;
      cursor: grab;
      left: 0;
      transform: translateY(-50%);
      top: 50%;
    }

    .editName {
      width: 100%;
      display: flex;
      align-items: center;
    }
  }
  .cell {
    .action {
      position: absolute;
      top: 12px;
      right: 0;
    }
  }

  .el-table .cell {
    overflow: inherit;
  }
  .md-field {
    margin-bottom: 0;
    padding-top: 0;
    min-height: 32px;
  }
  .md-field.md-invalid {
    margin-bottom: 20px;
  }
  .md-field .md-error {
    bottom: -13px;
  }
}

.wraper {
  padding: 20px 0 20px 10px;
  
  .append {

    .base-button {
      font-family: Roboto;
      font-size: 13px;
      color: #1A73E8;
    }
  }
}

.file-progress {
  position: fixed;
  left: 50%;
  bottom: 0;
  margin-left: -170px;
  padding: 15px;
  width: 340px;
  height: 80px;
  box-sizing: border-box;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);;
  font-size: 14px;

  dt {
    margin-bottom: 5px;
    display: block;
    font-weight: bold;
  }

  .stop {
    position: absolute;
    right: 15px;
    top: 15px;
    padding: 6px 12px;

    &:hover {
      background: #1a73e8;
      color: #fff;
    }
  }

  .progress {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 8px;
    border-radius: 0 0 4px 4px;
  }
}

.menu_bar {
  margin-top: 9px;
  width: 40px;
  height: 40px;
  line-height: inherit;

  .drop_down_menus {
    padding: 0;
  }
}

.menu_bar .menu_bar_button:hover {
  background: none;
}

.p-box {
  &:last-child {
    margin-bottom: 112px !important;
  }
}

</style>