<!-- class students list -->
<template>
  <div class="content has_breadcrumb journal-inner">
    <base-breadcrumb
      :data="[
        {
          routeName: 'locations',
          name: user.schoolName,
        },
        {
          routeName: 'journal',
          name: 'Journal',
        },
        {
          path: '/journal/class/' + classId,
          name: classname,
        },
        {
          name: journalData.title,
        },
      ]"
      slot="breadcrumb"
    />
    <GgPage pageType="" title="Journal">
      <div slot="topAppBar">
        <search />
      </div>
      <div class="journal-list-inner">
        <div class="journal-create-main" :style="{ paddingTop: (createTypeName[createType] === 'portfolio' ? '130' : '96') + 'px' }" :class="createTypeName[createType]">
          <div class="journal-top-fixed">
            <div class="title">

              <div class="tit" v-if="journalData.title">{{ htmlDecode(journalData.title) }}</div>

              <div class="journal-people-tag" v-if="createTypeName[createType] === 'portfolio' && journalStuInfo">
                <div class="tag">
                  <span class="avatar" :style="'background-image:url('+journalStuInfo.photo+')'" v-if="journalStuInfo.photo"></span>
                  <div class="no" v-else><img src="@/assets/images/icons/person_black_24dp.svg" /></div>
                  <!-- <div class="no" v-else>
                    {{ journalStuInfo.firstName ? journalStuInfo.firstName.substring(0, 1).toUpperCase() : '-' }}{{ journalStuInfo.lastName ? journalStuInfo.lastName.substring(0, 1).toUpperCase() : '-' }}
                  </div> -->
                  <span class="name">{{ journalStuInfo.firstName + ' ' + journalStuInfo.lastName }}</span>
                </div>
              </div>

              <div class="des" v-if="journalPortfolioRecord">
                <div class="avatar" v-if="journalPortfolioRecord.photo"><img :src="journalPortfolioRecord.photo"></div>
                <div class="no" v-else>
                  {{ journalPortfolioRecord.firstName ? journalPortfolioRecord.firstName.substring(0, 1).toUpperCase() : '-' }}{{ journalPortfolioRecord.lastName ? journalPortfolioRecord.lastName.substring(0, 1).toUpperCase() : '-' }}
                </div>
                <div class="name">{{ (journalPortfolioRecord.firstName || '-') + ' ' + (journalPortfolioRecord.lastName || '-') }}</div>
                <div v-if="journalRole && journalRole.name === 'Company admin'" class="tag">Company admin</div>
                <div v-else class="tag">{{ journalRole.roleName || '-' }}</div>
                <div class="date" v-if="journalData.status === 'POSTED'">posted on {{ momentTime(journalPortfolioRecord.timestamp) }}</div>
                <div class="date" v-else>saved on {{ momentTime(journalPortfolioRecord.timestamp) }}</div>
              </div>

              <div class="draft-col" v-if="menuData.length > 0">
                <gg-menu-bar>
                  <i class="iconmore_vert-24px iconfont" />
                  <template slot="menus">
                    <gg-menu-bar-item
                      :class="{ 'hr-class' : menuitem === '|' }"
                      v-for="(menuitem, key) in menuData"
                      @click="menuClick(menuitem)"
                      :key="key"
                      >
                      <span>{{ menuitem === '|' ? '' : menuitem }}</span>
                      <hr v-if="menuitem === '|'" class="hr" />
                    </gg-menu-bar-item>
                  </template>
                </gg-menu-bar>
              </div>
            </div>
          </div>

          <div class="journal-page-main">
            <div class="journal-page-list">
              <div class="journal-page-box"
                v-for="(item, index) in journalPageList"
                :key="index"
              >
                <div class="journal-page-bar-inner">
                  <div class="journal-page-bar">
                    <div class="journal-page-num">Page {{ index + 1 }}/{{ journalPageList.length }}</div>
                  </div>
                </div>
                <div class="journal-page">
                  <components v-if="item.no" :data="item.data" :index="index" :is="item.no + '-s'"></components>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <gg-Dialog width="580px" :visible.sync="journalDeleteSw" :title="'Delete ' + (journalType === 0 ? 'newsletter' : 'portfolio') + '?'" class="draft-dialog mp-dialog">
        <div class="dialogContent">Once deleted, you will not be able to recover the {{ journalType === 0 ? 'newsletter' : 'portfolio' }} data.</div>
        <span slot="footer" class="dialog-footer">
          <gg-cancel-button @click="journalDeleteSw = false">Cancel</gg-cancel-button>
          <gg-submit-button @click="journalDelete">Delete</gg-submit-button>
        </span>
      </gg-Dialog>

      <gg-Dialog width="580px" :visible.sync="journalSubmitSw" :title="'Submit ' + (journalType === 0 ? 'newsletter' : 'portfolio') + '?'" class="draft-dialog mp-dialog">
        <div class="dialogContent">Once submitted, the {{ journalType === 0 ? 'newsletter' : 'portfolio' }} will be subjected to approval for sharing.</div>
        <span slot="footer" class="dialog-footer">
          <gg-cancel-button @click="journalSubmitSw = false">Cancel</gg-cancel-button>
          <gg-submit-button @click="journalSubmit">Submit</gg-submit-button>
        </span>
      </gg-Dialog>

      <gg-Dialog width="580px" :visible.sync="journalUndoSw" :title="'Undo submission?'" class="draft-dialog mp-dialog">
        <div class="dialogContent">Once undone, the {{ journalType === 0 ? 'newsletter' : 'portfolio' }} will change from pending to draft.</div>
        <span slot="footer" class="dialog-footer">
          <gg-cancel-button @click="journalUndoSw = false">Cancel</gg-cancel-button>
          <gg-submit-button @click="journalUndo">Undo</gg-submit-button>
        </span>
      </gg-Dialog>

      <gg-Dialog width="580px" :visible.sync="journalApproveSw" :title="'Approve ' + (journalType === 0 ? 'newsletter' : 'portfolio') + '?'" class="draft-dialog mp-dialog">
        <div class="dialogContent">Once approved, the {{ journalType === 0 ? 'newsletter' : 'portfolio' }} will be shared and viewed by parents.</div>
        <span slot="footer" class="dialog-footer">
          <gg-cancel-button @click="journalApproveSw = false">Cancel</gg-cancel-button>
          <gg-submit-button @click="journalApprove">Approve</gg-submit-button>
        </span>
      </gg-Dialog>

      <gg-Dialog width="580px" :visible.sync="journalRecallSw" :title="'Recall ' + (journalType === 0 ? 'newsletter' : 'portfolio') + '?'" class="draft-dialog mp-dialog">
        <div class="dialogContent">Once recalled, the {{ journalType === 0 ? 'newsletter' : 'portfolio' }} will change from posted to draft and not be seen by parents.</div>
        <span slot="footer" class="dialog-footer">
          <gg-cancel-button @click="journalRecallSw = false">Cancel</gg-cancel-button>
          <gg-submit-button @click="journalRecall">Recall</gg-submit-button>
        </span>
      </gg-Dialog>

      <Snackbar :visible.sync="loadingShow" :content="loadingText" />

    </GgPage>
  </div>
</template>

<script>
import moment from 'moment';
import { Ajax } from "@/common";
import { mapState } from "vuex";
import { htmlDecode, hasPermission } from './../utils/utils.js';
import search from './../modules/search'
import newsletterAS from './../modules/model/newsletterAS'
import newsletterBS from './../modules/model/newsletterBS'
import newsletterCS from './../modules/model/newsletterCS'
import newsletterDS from './../modules/model/newsletterDS'
import newsletterES from './../modules/model/newsletterES'
import newsletterFS from './../modules/model/newsletterFS'
import newsletterGS from './../modules/model/newsletterGS'
import newsletterHS from './../modules/model/newsletterHS'
import newsletterIS from './../modules/model/newsletterIS'
import newsletterJS from './../modules/model/newsletterJS'
import newsletterKS from './../modules/model/newsletterKS'
import newsletterLS from './../modules/model/newsletterLS'
import newsletterMS from './../modules/model/newsletterMS'
import newsletterNS from './../modules/model/newsletterNS'
import portfolioAS from './../modules/model/portfolioAS'
import portfolioBS from './../modules/model/portfolioBS'
import portfolioCS from './../modules/model/portfolioCS'
import portfolioDS from './../modules/model/portfolioDS'
import portfolioES from './../modules/model/portfolioES'

export default {
  components: {
    search,
    newsletterAS,
    newsletterBS,
    newsletterCS,
    newsletterDS,
    newsletterES,
    newsletterFS,
    newsletterGS,
    newsletterHS,
    newsletterIS,
    newsletterJS,
    newsletterKS,
    newsletterLS,
    newsletterMS,
    newsletterNS,
    portfolioAS,
    portfolioBS,
    portfolioCS,
    portfolioDS,
    portfolioES
  },
  computed: {
    ...mapState({
      user: (state) => state.user
    }),
  },
  data() {
    return {
      journalRole: null,
      journalType: 0,
      journalIndex: '',
      journalRecallSw: false,
      journalApproveSw: false,
      journalUndoSw: false,
      journalSubmitSw: false,
      journalDeleteSw: false,
      loadingText: '',
      classId: '',
      classname: '',
      loadingShow: false,
      journalPortfolioRecord: null,
      journalData: {},
      menuData: [],
      journalStuInfo: null,
      journalPageList: [],
      rootPath: process.env.VUE_APP_fileBasePath,
      journalRecord: null,
      height: '',
      createType: '',
      createTypeName: ['newsletter', 'portfolio'],
      createTypeArr: ['Create a newsletter', 'Create a portfolio']
    };
  },
  watch: {

  },
  created() {
    this.createType = this.$route.query.type || '0'
    this.classname = sessionStorage.getItem("journalClassName")
    this.classId = sessionStorage.getItem("journalClassId")
    this.getJournal()
  },
  mounted(){

  },
  methods: {

    moment,

    hasPermission,

    htmlDecode,

    dateDiff(date) {
      let a = moment.utc(date),
      b = moment()
      return a.diff(b, 'days')
    },

    geJournalPortfolioRecord() {
      let data = {
        journalId: this.$route.query.id
      }
      Ajax.get('journal/' + this.$route.query.id + '/record', data).then((res) => {
        if(res.code === '0000'){
          this.journalPortfolioRecord = res.data
          this.geJournalRole(res.data.userId)
        }
      });
    },

    geJournalRecord(np) {
      let data = {
        journalId: this.$route.query.id,
        event: 'APPROVE'
      }
      Ajax.get('journal/' + this.$route.query.id + '/record', data).then((res) => {
        if(res.code === '0000'){
          this.journalRecord = res.data

          if(np.status === 'POSTED') {
            this.menuData = ['Preview']
            if(np.status === 'DRAFT' && this.hasPermission('Share journals_Create')) {
              this.menuData.push('Submit')
            }
            if(np.status === 'PENDING' && this.hasPermission('Share journals_Edit')) {
              this.menuData.push('Undo')
            }
            if(np.status === 'PENDING' && this.hasPermission('Share journals_Full')) {
              this.menuData.push('Approve')
            }
            if(np.status === 'POSTED' && this.dateDiff(this.journalRecord.timestamp) === 0 && this.hasPermission('Share journals_Full')) {
              this.menuData.push('Recall')
            }
            if((np.status === 'DRAFT' || np.status === 'PENDING') && this.hasPermission('Manage journals_Edit') || (np.status === 'DRAFT' || np.status === 'PENDING') && this.hasPermission('Manage journals_Full')) {
              this.menuData.push('|')
            }
            if((np.status === 'DRAFT' || np.status === 'PENDING') && this.hasPermission('Manage journals_Edit')) {
              this.menuData.push('Edit')
            }
            if((np.status === 'DRAFT' || np.status === 'PENDING') && this.hasPermission('Manage journals_Full')) {
              this.menuData.push('Delete')
            }
          }

        }
      });
    },

    getStudentsInfo(id){
      let data = {
        stuId: id
      }
      Ajax.get('/usermanage/student/selectByStuId', data).then((res) => {
        if(res.code === '0000'){
          this.journalStuInfo = res.data
        }
      });
    },

    geJournalRole(id){
      let data = {
        userId: id
      }
      Ajax.get('/usermanage/role/findCommonUserRole', data).then((res) => {
        if(res.code === '0000'){
          this.journalRole = res.data
        }
      });
    },

    getJournal() {
      let data = {
        id: this.$route.query.id
      }
      Ajax.get("journal/" + this.$route.query.id, data).then((res) => {
        if(res.code === '0000'){
          this.journalData = res.data
          this.journalPageList = JSON.parse(res.data.content)
          this.geJournalRecord(res.data)
          this.getStudentsInfo(res.data.recipient)
          this.geJournalPortfolioRecord()

          if(res.data.status != 'POSTED') {
            this.menuData = ['Preview']
            if(res.data.status === 'DRAFT' && this.hasPermission('Share journals_Create')) {
              this.menuData.push('Submit')
            }
            if(res.data.status === 'PENDING' && this.hasPermission('Share journals_Edit')) {
              this.menuData.push('Undo')
            }
            if(res.data.status === 'PENDING' && this.hasPermission('Share journals_Full')) {
              this.menuData.push('Approve')
            }
            if(res.data.status === 'POSTED' && this.dateDiff(this.journalRecord.timestamp) === 0 && this.hasPermission('Share journals_Full')) {
              this.menuData.push('Recall')
            }
            if((res.data.status === 'DRAFT' || res.data.status === 'PENDING') && this.hasPermission('Manage journals_Edit') || (res.data.status === 'DRAFT' || res.data.status === 'PENDING') && this.hasPermission('Manage journals_Full')) {
              this.menuData.push('|')
            }
            if((res.data.status === 'DRAFT' || res.data.status === 'PENDING') && this.hasPermission('Manage journals_Edit')) {
              this.menuData.push('Edit')
            }
            if((res.data.status === 'DRAFT' || res.data.status === 'PENDING') && this.hasPermission('Manage journals_Full')) {
              this.menuData.push('Delete')
            }
          }
        }
      });
    },

    toPreview() {
      sessionStorage.setItem("journalPageData", JSON.stringify(this.journalPageList))
      sessionStorage.setItem("journalPageDataTit", this.journalData.title)
      sessionStorage.setItem("journalStatus", this.journalData.status)
      sessionStorage.setItem("journalFilePath", this.journalData.filePath)
      let url = this.$router.resolve({path: '/journal/view?type=' + this.createTypeName[this.createType] });
      let win = window.open(url.href, '_blank')
      win.onload = function () {
        setTimeout(function () {
          win.document.title = 'Preview'
        }, 100)
      }
    },

    journalRecall() {
      Ajax.put('/journal/' + this.journalIndex + '/recall?staffId=' + this.user.userId).then((res) => {
        if(res.code === '0000'){
          this.loadingShow = true
          this.journalRecallSw = false
          this.loadingText = (this.journalType === 0 ? 'Newsletter' : 'Portfolio') + ' has been recalled'

          setTimeout(()=>{
            let typename = (this.journalType === 0 ? 'newsletter' : 'portfolio') + '_draft',
            classid = this.$route.query.classid
            this.$router.push('/journal/class/' + classid + '?typename=' + typename)
          }, 1500)
        }
      });
    },

    journalRecallShow(id, type, item) {
      this.journalRecallSw = true
      this.journalIndex = id
      this.journalType = type
    },

    journalApprove() {
      Ajax.put('/journal/' + this.journalIndex + '/process?approve=' + true + '&staffId=' + this.user.userId).then((res) => {
        if(res.code === '0000'){
          this.loadingShow = true
          this.journalApproveSw = false
          this.loadingText = (this.journalType === 0 ? 'Newsletter' : 'Portfolio') + ' has been posted'

          setTimeout(()=>{
            let typename = (this.journalType === 0 ? 'newsletter' : 'portfolio') + '_posted',
            classid = this.$route.query.classid
            this.$router.push('/journal/class/' + classid + '?typename=' + typename)
          }, 1500)
        }
      });
    },

    journalApproveShow(id, type, item) {
      this.journalApproveSw = true
      this.journalIndex = id
      this.journalType = type
    },

    journalUndo() {
      Ajax.put('/journal/' + this.journalIndex + '/cancel?staffId=' + this.user.userId).then((res) => {
        if(res.code === '0000'){
          this.loadingShow = true
          this.journalUndoSw = false
          this.loadingText = (this.journalType === 0 ? 'Newsletter' : 'Portfolio') + ' has been moved to draft'

          setTimeout(()=>{
            let typename = (this.journalType === 0 ? 'newsletter' : 'portfolio') + '_draft',
            classid = this.$route.query.classid
            this.$router.push('/journal/class/' + classid + '?typename=' + typename)
          }, 1500)
        }
      });
    },

    journalUndoShow(id, type, item) {
      this.journalUndoSw = true
      this.journalIndex = id
      this.journalType = type
    },

    journalSubmit() {
      Ajax.put('/journal/' + this.journalIndex + '/submit?staffId=' + this.user.userId).then((res) => {
        if(res.code === '0000'){
          this.loadingShow = true
          this.journalSubmitSw = false
          this.loadingText = (this.journalType === 0 ? 'Newsletter' : 'Portfolio') + ' has been submitted'

          setTimeout(()=>{
            let typename = (this.journalType === 0 ? 'newsletter' : 'portfolio') + '_pending',
            classid = this.$route.query.classid
            this.$router.push('/journal/class/' + classid + '?typename=' + typename)
          }, 1500)
        }
      });
    },

    journalSubmitShow(id, type) {
      this.journalSubmitSw = true
      this.journalIndex = id
      this.journalType = type
    },

    journalDelete() {
      Ajax.delete('/journal/' + this.journalIndex + '?staffId=' + this.user.userId).then((res) => {
        if(res.code === '0000'){
          this.journalDeleteSw = false
          this.loadingText = (this.journalType === 0 ? 'Newsletter' : 'Portfolio') + ' has been deleted'
          setTimeout(()=>{
            this.$router.go(-1)
          }, 1500)
        }
      });
    },

    journalDeleteShow(id, type) {
      this.journalDeleteSw = true
      this.journalIndex = id
      this.journalType = type
    },

    toEdit(id, type) {
      this.$router.push({ name: 'journalCreate', query: {
        editid: id,
        type: type,
        classname: this.$route.query.name,
        classid: this.$route.query.classid,
        time: this.moment().valueOf()
      }})
    },

    menuClick(e) {
      let id = this.journalData.id,
      type = this.journalData.newsletter ? 0 : 1

      if(e === 'Preview') {
        this.toPreview()
      }
      if(e === 'Submit') {
        this.journalSubmitShow(id, type)
      }
      if(e === 'Undo') {
        this.journalUndoShow(id, type)
      }
      if(e === 'Approve') {
        this.journalApproveShow(id, type)
      }
      if(e === 'Recall' && this.dateDiff(this.journalRecord.timestamp) === 0) {
        this.journalRecallShow(id, type)
      }
      if(e === 'Edit') {
        this.toEdit(id, type)
      }
      if(e === 'Delete') {
        this.journalDeleteShow(id, type)
      }
    },

    momentTime(date) {
      return moment.utc(date).local().format("DD/MM/YYYY, hh:mm A");
    }

  },
};
</script>
<style lang='less' scoped>
.journal-list-inner {
  margin: auto;
  width: 1353px;
}

.journal-create-main {
  position: relative;

  &.newsletter {
    .journal-page-main {
      width: 900px !important;
    }
  }

  &.portfolio {
    .journal-page-main {
      width: 1016px !important;
    }

    .journal-page-list,
    .journal-page-box {
      width: 660px !important;
    }

    .journal-page {
      width: 660px !important;
      height: 470px !important;
    }
  }

  .journal-top-fixed {
    position: fixed;
    left: 50%;
    top: 121px;
    z-index: 1;
    margin-left: -676px;
    width: 1353px;
    background: #fff;

    .journal-people-tag {
      padding: 0 0 10px 0;

      .tag {
        display: inline-block;
        padding: 0 6px 0 4px;
        height: 24px;
        background: #fff;
        border: 1px solid #E0E0E0;
        border-radius: 16px;

        .avatar {
          display: inline-block;
          margin-right: 8px;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          vertical-align: middle;
          background-size: contain;
        }

        .no {
          display: inline-block;
          vertical-align: middle;
          margin-right: 8px;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          background-color:#b4b4b4;
          line-height: 16px;
          color: #fff;
          text-align: center;
          font-size: 12px;
          font-family: 'opensans-semibold';
        }

        .name {
          text-align: left;
          font-family: Roboto;
          font-size: 12px;
          color: #202124;
        }
      }
    }

    .title {
      position: relative;
      padding-left: 8px;
      padding-bottom: 26px;
      border-bottom: 1px solid #e3e3e3;
      color: #202124;

      .tit {
        margin-bottom: 10px;
        line-height: 27px;
        font-size: 22px;
        font-family: 'OpenSans-Bold';
      }

      .des {
        .no {
          display: inline-block;
          margin-right: 8px;
          width: 32px;
          height: 32px;
          background: #b4b4b4;
          border-radius: 50%;
          line-height: 32px;
          text-align: center;
          color: #fff;
          font-size: 14px;
          font-family: 'OpenSans-Bold';
        }

        .avatar {
          margin-right: 8px;
          display: inline-block;
          width: 32px;
          height: 32px;
          vertical-align: middle;

          img {
            display: block;
            width: 32px;
            height: 32px;
            border-radius: 50%;
          }
        }

        .name {
          margin-right: 8px;
          display: inline-block;
          font-size: 12px;
          font-family: Roboto;
          font-weight: 400;
          line-height: 19px;
          color: #202124;
          vertical-align: middle;
        }

        .tag {
          margin-right: 8px;
          display: inline-block;
          padding: 4px 9px;
          height: 20px;
          font-family: Roboto;
          line-height: 12px;
          font-weight: 400;
          background: #E0E0E0;
          border-radius: 10px;
          font-size: 12px;
          vertical-align: middle;
        }

        .date {
          margin-right: 8px;
          display: inline-block;
          font-size: 12px;
          font-family: Roboto;
          font-weight: 400;
          line-height: 16px;
          color: #B4B4B4;
        }
      }

      .draft-col {
        position: absolute;
        right: 10px;
        top: 5px;
      }
    }

  }

  .journal-page-main {
    position: relative;
    width: 900px;
    overflow: hidden;

    .journal-page-list {
      float: right;
      padding-bottom: 112px;
      width: 470px;

      .add-page-btn {
        margin-top: 20px;
        height: 36px;
        line-height: 36px;
        border: 1px solid #E0E0E0;
        border-radius: 4px;
        text-align: center;
        font-family: 'opensans-semibold';
        font-size: 14px;
        color: #5F6368;
        cursor: pointer;
      }

      .journal-page-box {
        position: relative;
        width: 470px;

        .journal-page-bar-inner {
          height: 54px;

          .journal-page-bar {
            line-height: 54px;
            overflow: hidden;

            .journal-page-num {
              float: left;
              color: #5F6368;
              font-family: Roboto;
              font-size: 14px;
            }

            .journal-page-col {
              display: none;
              float: right;

              img {
                display: inline-block;
                margin-left: 7px;
                vertical-align: middle;
                cursor: pointer;

                &.die {
                  color: #606367;
                  cursor: not-allowed;
                  opacity: .5;
                }
              }
            }
          }
        }

        .journal-page {
          width: 470px;
          height: 666px;
          background: #F3F3F3;
          border: 1px solid #f4f4f4;
        }
      }
    }

  }

}

</style>
