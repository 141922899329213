<template>
  <div style="position: relative" class="date-picker">
    <div class="datebox">
      <div>{{moment(this.value).format('dddd, DD MMMM YYYY')}}</div>
    </div>
    <div class="select-icon-arrow" >
      <md-drop-down-icon @click='cliDatepicker' />
    </div>
    <md-datepicker class="date-picker-input"
      v-if="isInit"
      ref='datepicker'
      v-model="value"
      @md-closed="onClosed" />
  </div>
</template>

<script>
import MdDropDownIcon from '../../../components/edoovo-components/common/MdDropDownIcon.vue';
import moment from 'moment'
export default {
	components: {	
	  MdDropDownIcon
	},
  props:{
    defaultVal:{
      // 时间戳
      default: 0,
      type: [Number,String]
    }
  },
  data() {
    return {
      value:new Date(),
      isInit:false,
    };
  },
  mounted(){
    this.initValue()
  },
  watch:{
    defaultVal(){
      this.initValue()
    },
  },
  methods: {
    moment,
    cliDatepicker(){
		  this.$refs.datepicker.click()
	  },
    initValue(){
      this.value = new Date(this.defaultVal)
      
      console.log('initValue',this.value)

      setTimeout(() => {
        this.isInit = true
      })
    },
    onClosed(){
      setTimeout(() => {
        console.log(this.value)
        this.$emit('change', moment(this.value).valueOf())
        this.$router.push({ name: this.$route.name, query: { time: moment(this.value).valueOf() } })
      })
    }
  },
};
</script>
<style lang='less' scoped>
.datebox{
	display: flex;
	line-height: 41px;
	padding: 0 10px;
	width: 85%;
	justify-content: space-between;
	position: absolute;
	background: #fff;
	z-index: 11;
	height: 100%;
	background: #488FED;
	pointer-events: none;
	line-height: 41px;
	font-family: 'Open Sans';
	font-size: 20px;
	bottom: 0px;
	cursor: pointer;
}
.date-picker {
  width: 400px;
  height: 45px;
  padding-top: 2px;
  border: 2px solid transparent;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.2);
  .select-icon-arrow{
    position: absolute;
    right: 16px;
    top:1.5px;
    line-height: 40px;
    .md-icon /deep/ svg{
      fill:#fff;      
    }
  }
  .arrow-group {
    display: flex;
    align-items: center;
    height: 48px;
    .arrow {
      flex: 1;
      width: 48px;
      height: 48px;
      line-height: 48px;
      text-align: center;
    }
  }
  /deep/ .md-field {
    padding-top: 0;
  }
  /deep/ .md-field.md-theme-default>.md-icon:after {
    display: none;
  }
  /deep/ .md-field.md-theme-default:after {
    display: none;
  }
}
.date-picker-input {
  width: 85%;
  /deep/.md-input-action.md-clear {
    display: none;
  }
  /deep/ svg {
    display: none;
  }
  /deep/ input {
    -webkit-text-fill-color: #fff !important;
  }
}
</style>